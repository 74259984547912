import { Button } from 'primereact/button';
import { ScrollPanel } from 'primereact/scrollpanel';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import StoreFilters from '../StoreFilters/storeFilters.component';

const MobileStoreFilters: React.FC<any> = () => {
    const location = useLocation();
    const storetype = ["Groceries", "Vegetables", "Fruits", "Dairy", "Nuts","Poultry","Oils","Millets"];
    const serviceoptions = ["Delivery", "In-store Shopping", "In-store Pickup"];

    const [filters, setFilters] = useState<any[]>([]);
    const [isChecked, setIsChecked] = useState(false);
    const [storefilters, setStoreFilters] = useState<any>(
        JSON.parse(new URLSearchParams(location.search).get("storefilters") as string) ||
        ""
      );

  const updateFilters = (key: any, value: any) => {
    setStoreFilters((prevFilters: any) => {
      const updatedFilters = { ...prevFilters };

      // if (key === "preference") {
      //   updatedFilters[key] = value;
      // } else {
      if (updatedFilters[key] && !updatedFilters[key].includes(value)) {
        updatedFilters[key] = [...updatedFilters[key], value];
      } else {
        updatedFilters[key] = [value];
        //   }
      }
      return updatedFilters;
    });
  };

  const removeFilter = (key: string, valueToRemove: string) => {
    setStoreFilters((prevFilters: any) => {
      const updatedFilters = { ...prevFilters };

      if (updatedFilters[key]) {
        updatedFilters[key] = updatedFilters[key].filter(
          (value: string) => value !== valueToRemove
        );
        if (updatedFilters[key].length === 0) {
          delete updatedFilters[key];
        }
      }

      return updatedFilters;
    });
  };

  const onSelect = (e: any, type: any) => {
    updateFilters(type, e);
    setIsChecked(true);
  };
    const onRemove = (e: any) => {

        const hasFilters = filters.filter((item: any) => e !== item).length > 0;
        setIsChecked(hasFilters);
        const newfilters = filters.filter((item: any) => e !== item);
        setFilters(newfilters);
    }

    const clearAllFilters = () => {
        setStoreFilters({});
    
        const checkboxes = document.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach((checkbox: any) => {
          checkbox.checked = false;
        });
    
        const radioButtons = document.querySelectorAll('input[type="radio"]');
        radioButtons.forEach((radioButton: any) => {
          radioButton.checked = false;
        });
      };

    const navigate = useNavigate();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    const isActive = (section: any) => splitLocation === section ? "nav-link fil-opt-active" : "nav-link adv-fil-opt";

    const [isStoreTypeOpen, setIsStoreTypeOpen] = useState(true);
    const handleStoreTypeDropdownToggle = () => {
        setIsStoreTypeOpen(true);
        setIsServiceOpen(false)
    };

    const [isServiceOpen, setIsServiceOpen] = useState(false);
    const handleServiceDropdownToggle = () => {
        setIsServiceOpen(!isServiceOpen);
        setIsStoreTypeOpen(false)
    };

    return (
        <div className='container animate' style={{ overflow: "hidden" }}>
            <div className='filters-close-head pt-4 d-flex justify-content-between align-items-center mb-2'>
                <span className='f-head'>Filters</span>
                <i className="pi pi-times cls_icons"
                    onClick={() => navigate("/searchstore")}
                ></i>
            </div>
            <hr style={{ color: "#ececec", opacity: "100%" }} />
            <div className='mobile-filters'>
                <div className='col-6 filters-options-adv p-0'>
                    <ul className='opt-menu'>
                        <li className='list-menu'>
                            <a className={`adv-fil-opt ${isActive("foodtype")}`} onClick={handleStoreTypeDropdownToggle} >
                                Store Type
                            </a>
                        </li>
                        <li className='list-menu'>
                            <a className={`adv-fil-opt ${isActive("service")}`} onClick={handleServiceDropdownToggle}>
                                Service Options
                            </a>
                        </li>
                    </ul>
                </div>
                <ScrollPanel style={{ width: "100%", height: "calc(100vh - 130px)" }} >
                <div className='pl-3 pt-3'>
                {isStoreTypeOpen && (
                                <div className="submenu mt-3 mb-3 p-0 gap-3  foodtype-submenu">
                                    {storetype.map((e: any, index) => (
                                        <div key={index} className="filter-item">
                                            <label className="d-flex gap-2">
                                                <input
                                                    className='check-wrapper'
                                                    type="checkbox"
                                                    id={`custom-checkbox-${index}`}
                                                    name="feature"
                                                    checked={
                                                        storefilters.storetype && storefilters.storetype.includes(e)
                                                      }
                                                      onChange={(event) =>
                                                        event.target.checked
                                                          ? onSelect(e, "storetype")
                                                          : removeFilter("storetype",e)
                                                      }
                                                      value={e}
                                                />
                                                <span className="advfilterItemData">
                                                    {e}
                                                </span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}

                   {isServiceOpen && (
                    <div className="submenu p-0 mt-3 mb-3 gap-3 service-submenu">
                        {serviceoptions.map((e: any, index) => (
                            <div key={index} className="filter-item">
                                <label className="d-flex gap-2">
                                    <input
                                        className='check-wrapper'
                                        type="checkbox"
                                        id={`custom-checkbox-${index}`}
                                        name="feature"
                                       
                                        checked={
                                            storefilters.servicetype && storefilters.servicetype.includes(e)
                                          }
                                          onChange={(event) =>
                                            event.target.checked
                                              ? onSelect(e, "servicetype")
                                              : removeFilter("servicetype", e)
                                          }
                                          value={e}
                                 
                                    />
                                    <span className="filterItemData">
                                        {e}
                                    </span>
                                </label>
                            </div>
                        ))}
                    </div>
                )}
                </div>
                </ScrollPanel>

            </div>
            <div className='buttons-two-adv-filters'>
                <Button
                    className="clr-btn-adv-filter"
                    label="Clear All"
                    severity="success"
                    onClick={clearAllFilters}
                />
                <Button
                    className={isChecked ? "show-green-rest-filter" : "show-rest-adv-filter"}
                    label="Show Organicstores"
                    disabled={!Object.values(storefilters).some(value => value !== "")}
                    onClick={() => {
                      const queryParams = new URLSearchParams();
                      const city = localStorage.getItem("city") as string;
                      let parsedCity = JSON.parse(city);
                      queryParams.append("city", parsedCity.city);
                      queryParams.append(
                        "storefilters",
                        JSON.stringify(storefilters)
                      );
                      queryParams.append("storetype", storefilters["storetype"] || "");
                      queryParams.append("servicetype", storefilters["servicetype"] || "");
                     
                      const queryString = queryParams.toString();
                      navigate(`/searchstore/?${queryString}`);
                    }}
                />


            </div>

        </div>
    );
}

export default MobileStoreFilters;
