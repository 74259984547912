import React, { useEffect, useRef, useState } from "react";
import "./profile.page.css";
import Navbar from "../../components/UI/Navbar/navBar.component";
import { useLocation, useSearchParams } from "react-router-dom";
import Footer2 from "../../components/UI/Footer/footer.component";
import { Skeleton } from "primereact/skeleton";
import { Image } from "primereact/image";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { EditUserDetails, GetDietitianReviews, GetProfile, GetStoreReviews, GetSuggestedRestaurants, GetSuggestedStores, GetUserLikedCuisines, GetUserLikedDietitians, GetUserLikedStores, GetUserLikes, GetUserReviews } from "../../api/user.api";
import { Divider } from "primereact/divider";
import { TotalReviews } from "../../api/user.api";
import previewimage from "../../assets/icons/previewimage.png";
import { Dialog } from "primereact/dialog";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import FavoriteRestaurant from "../../components/UI/Cards/FavRestaurantCard/favRestaurantCard.component";
import FavoriteCuisine from "../../components/UI/Cards/FavCuisineCard/favCuisineCard.component";
import UserRestReviews from "../../components/UI/Cards/Reviews/UserRestReviews/userRestReviews.component";
import FavoriteStore from "../../components/UI/Cards/FavStoreCard/favStoreCard.component";
import UserStoreReviews from "../../components/UI/Cards/Reviews/UserStoreReviews/userStoreReview.component";
import SuggestedRestaurant from "../../components/UI/Cards/SuggestedRestaurantCard/suggestedRestaurantCard.component";
import SuggestedStore from "../../components/UI/Cards/SuggestedStoreCard/suggestedStoreCard.component";
import { Avatar } from "primereact/avatar";
import FavDietitianCard from "../../components/UI/Cards/FavDietitianCard/favDietitianCard.component";
import UserDietitianReviews from "../../components/UI/Cards/Reviews/UserDietitianReviews/userDietitianReviews.component";
import { ScrollPanel } from "primereact/scrollpanel";

interface Store {
  serviceoptions: any;
  storetype: any;
  _id: string;
  storeID: string;
  images: string[];
  storename: string;
  address: string;
  street: string;
  city: string;
  district: string;
  state: string;
  pincode: string;
  mobileno: string;
}

const Profile = () => {
  const location = useLocation();
  const [selectedCity, setSelectedCity] = useState(null);
  const [totallikes, settotallikes] = useState<any>(); // Loader state..
  const [totalratings, settotalratings] = useState<any>();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [isFDropdownOpen, setIsFDropdownOpen] = useState(true);
  const [isRDropdownOpen, setIsRDropdownOpen] = useState(true);
  const [isSDropdownOpen, setIsSDropdownOpen] = useState(true);
  const toast = useRef<Toast>(null);
  const show = (message: any, severity: any, summary: any) => {
    if (toast.current) {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
        life: 2000,
      });
    } else {
    }
  };
  const cities = [
    { city: "Hyderabad", code: "HYD" },
    { city: "Chennai", code: "CHE" },
    { city: "Bengaluru", code: "BEN" },
    { city: "Mumbai", code: "MUM" },
    { city: "New Delhi", code: "NDL" },
  ];
  const [profile, setProfile] = useState({
    email: "",
    firstname: "",
    lastname: "",
    image: "",
    createdAt: "",
    birthday: "",
    city: "",
    country: "",
    phoneno: 0,
  });
  const [value, setValue] = useState();
  const [lvisible, setLvisible] = useState(false);
  const [id] = useSearchParams();
  useEffect(() => {
    setLvisible(true);
    //loadLazyData();
    // CheckForReview();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [searchparams] = useSearchParams();
  const [city, setCity] = useState(searchparams.get("city") as string);
  const getDate = (inputdata: any) => {
    const joinedDate = new Date(inputdata);
    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return (
      joinedDate.getDate() +
      " " +
      months[joinedDate.getMonth()] +
      " " +
      joinedDate.getFullYear()
    );
  };

  const formatDate = (inputDate: string) => {
    const dateParts = inputDate.split('/').map(Number);

    const formattedDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0])
      .toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      });
    return formattedDate;
  }


  const [readmore, setreadmore] = useState(false);
  const [visible, setVisible] = useState(false);
  const SubmitEditChanges = async () => {

    await EditUserDetails(profile)
      .then((res) => {
        if (res.status === 200) {
          show("Profile Updated", "info", "info");
        } else {
          show("Error uploading, please try again", 500, "Error");
        }
        setVisible(false);
      })
      .catch((err) => {
        if (err.response.status === 413) {
          show(" Please upload image less than 1 MB ", "error", "Error");
        }
      });

  };
  const footerContent = (
    <div className="edit-dialog d-flex justify-content-center">
      <Button
        label="Save"
        onClick={() => SubmitEditChanges()}
        autoFocus
        className="Edit-save-button m-0"
      />
    </div>
  );

  const handleInputChange = (event: any) => {
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = async (e: any) => {
      setProfile({ ...profile, image: e.target.result });
    };
  };
  const handleRemoveImage = () => {
    // Check if profile.firstName exists and is not empty
    if (profile.firstname && profile.firstname.trim() !== "") {
      // Extract the first letter of the first name
      const firstLetter = profile.firstname.trim().charAt(0);
      // Set the profile image to the first letter
      setProfile({ ...profile, image: firstLetter });
    } else {
      // If firstName is empty or undefined, set image to empty string
      setProfile({ ...profile, image: "" });
    }
  };


  const toCamelCase = (str: any) => {
    if (str) {
      return str
        .replace(/\b(\w)/g, function (match: any, capture: any) {
          return capture.toUpperCase();
        })
        .replace(/\s+/g, " ");
    }
  };

  // const [selectPage, setSelectPage] = useState(0);
  const [showabout, setshowabout] = useState(true);
  const [showfavrests, setfavrests] = useState(false);
  const [showfavcusi, setfavcusi] = useState(false);
  const [showfavorg, setfavorg] = useState(false);
  const [showreviewrest, setreviewrest] = useState(false);
  const [showreviewstore, setreviewstore] = useState(false);
  const [showsugrest, setsugrest] = useState(false);
  const [showsugstore, setsugstore] = useState(false);
  const [showfavdietitians, setfavdietitians] = useState(false);
  const [showreviewdietitians, setreviewdietitians] = useState(false);



  const handleFDropdownToggle = () => {
    setIsFDropdownOpen(!isFDropdownOpen);
  };
  const handleRDropdownToggle = () => {
    setIsRDropdownOpen(!isRDropdownOpen);
  };
  const handleSDropdownToggle = () => {
    setIsSDropdownOpen(!isSDropdownOpen);
  };
  const handleprofile = () => {
    setfavrests(false);
    setfavcusi(false);
    setshowabout(true);
    setfavorg(false);
    setreviewrest(false);
    setreviewstore(false);
    setsugrest(false);
    setsugstore(false);
    setfavdietitians(false);
    setreviewdietitians(false);
  };
  const handlefavrat = () => {
    setfavrests(true);
    setfavcusi(false);
    setshowabout(false);
    setfavorg(false);
    setreviewrest(false);
    setreviewstore(false);
    setsugrest(false);
    setsugstore(false);
    setfavdietitians(false);
    setreviewdietitians(false);
  };
  const handlefavcusine = () => {
    setfavrests(false);
    setfavcusi(true);
    setshowabout(false);
    setfavorg(false);
    setreviewrest(false);
    setreviewstore(false);
    setsugrest(false);
    setsugstore(false);
    setfavdietitians(false);
    setreviewdietitians(false);
  };
  const handlefavorg = () => {
    setfavrests(false);
    setfavcusi(false);
    setshowabout(false);
    setfavorg(true);
    setreviewrest(false);
    setreviewstore(false);
    setsugrest(false);
    setsugstore(false);
    setfavdietitians(false);
    setreviewdietitians(false);
  };
  const handlefavdietitian = () => {
    setfavrests(false);
    setfavcusi(false);
    setshowabout(false);
    setfavorg(false);
    setreviewrest(false);
    setreviewstore(false);
    setsugrest(false);
    setsugstore(false);
    setfavdietitians(true);
    setreviewdietitians(false);
  }
  const handlereviewrest = () => {
    setfavrests(false);
    setfavcusi(false);
    setshowabout(false);
    setfavorg(false);
    setreviewrest(true);
    setreviewstore(false);
    setsugrest(false);
    setsugstore(false);
    setfavdietitians(false);
    setreviewdietitians(false);
  };
  const handlereviewstore = () => {
    setfavrests(false);
    setfavcusi(false);
    setshowabout(false);
    setfavorg(false);
    setreviewrest(false);
    setreviewstore(true);
    setsugrest(false);
    setsugstore(false);
    setfavdietitians(false);
    setreviewdietitians(false);
  };
  const handlereviewdietitians = () => {
    setfavrests(false);
    setfavcusi(false);
    setshowabout(false);
    setfavorg(false);
    setreviewrest(false);
    setreviewstore(false);
    setsugrest(false);
    setsugstore(false);
    setfavdietitians(false);
    setreviewdietitians(true);

  }
  const handlesugcusine = () => {
    setfavrests(false);
    setfavcusi(false);
    setshowabout(false);
    setfavorg(false);
    setreviewrest(false);
    setreviewstore(false);
    setsugrest(false);
    setsugstore(true);
    setfavdietitians(false);
    setreviewdietitians(false);
  };
  const handlesugrest = () => {
    setfavrests(false);
    setfavcusi(false);
    setshowabout(false);
    setfavorg(false);
    setreviewrest(false);
    setreviewstore(false);
    setsugrest(true);
    setsugstore(false);
    setfavdietitians(false);
    setreviewdietitians(false);
  };
  useEffect(() => {
    const loadProfileData = async () => {
      try {
        const resData1 = await GetProfile();
        setProfile(resData1.data.data);
        localStorage.setItem("image", resData1.data.data.image);
        const countData = await TotalReviews();
        settotallikes(countData.data.data.Data.Likes);
        settotalratings(countData.data.data.Data.Reviews);
        setLvisible(false);
      } catch (error) {
        console.error("Error loading profile data", error);
      } finally {
        setLvisible(false);
      }
    };

    setLvisible(true);
    loadProfileData();
    LikedRestaurants();
    likedCuisine();
    LikedStores();
    LikedDietitians();
    RestReviews();
    StoreReviews();
    DietitianReviews();
    suggestedRestaurants();
    fetchSuggestedStores();
  }, []);

  const handleNavigationChange = (selectedValue: any) => {
    switch (selectedValue) {
      case 'profile':
        handleprofile();
        break;
      case 'fav-restaurants':
        handleFDropdownToggle();
        handlefavrat();
        break;
      case 'fav-cuisines':
        handleFDropdownToggle();
        handlefavcusine();
        break;
      case 'fav-organic-stores':
        handleFDropdownToggle();
        handlefavorg();
        break;
      case 'fav-dietitians':
        handleFDropdownToggle();
        handlefavdietitian();
        break;
      case 'review-restaurants':
        handleRDropdownToggle();
        handlereviewrest();
        break;
      case 'review-organic-stores':
        handleRDropdownToggle();
        handlereviewstore();
        break;
      case 'review-dietitians':
        handleRDropdownToggle();
        handlereviewdietitians();
        break;
      case 'suggested-restaurants':
        handleSDropdownToggle();
        handlesugrest();
        break;
      case 'suggested-organic-stores':
        handleSDropdownToggle();
        handlesugcusine();
        break;
      default:
        // Handle default case or do nothing
        break;
    }
  };

  const [likedRestaurants, setLikedRestaurants] = useState([]);
  const [likedCuisines, setLikedCuisines] = useState([]);
  const [likedStores, setLikedStores] = useState([]);
  const [likedDietitians, setLikedDietitians] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [storereviews, setStoreReviews] = useState([]);
  const [dietitianreviews, setDietitianReviews] = useState([]);
  const [suggestedRest, setSuggestedRest] = useState([]);
  const [suggestedStores, setSuggestedStores] = useState<Store[]>([]);

  const LikedRestaurants = async () => {
    try {
      const response = await GetUserLikes();

      if (response.data.data) {
        setLikedRestaurants(response.data.data);
      } else {
        console.error("Liked restaurants data is undefined or null");
      }
    } catch (error) {
      console.error("Error fetching liked restaurants:", error);
    }
  };

  const likedCuisine = async () => {
    try {
      const response = await GetUserLikedCuisines();
      setLikedCuisines(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const LikedStores = async () => {
    try {
      const response = await GetUserLikedStores();
      setLikedStores(response.data.data);
    } catch (error) {
      console.error("Error fetching liked restaurants:", error);
    }
  };
  const LikedDietitians = async () => {
    try {
      const response = await GetUserLikedDietitians();
      setLikedDietitians(response.data.data);
    } catch (error) {
      console.error("Error fetching liked dietitians:", error);
    }
  }
  const RestReviews = async () => {
    try {
      const response = await GetUserReviews();
      setReviews(response.data.data);
    } catch (error) {
      console.error("Error fetching liked restaurants:", error);
    }
  };
  const StoreReviews = async () => {
    try {
      const response = await GetStoreReviews();
      setStoreReviews(response.data.data);
    } catch (error) {
      console.error("Error fetching liked restaurants:", error);
    }
  };
  const DietitianReviews = async () => {
    try {
      const response = await GetDietitianReviews();
      setDietitianReviews(response.data.data);
    } catch (error) {
      console.error("Error fetching dietitian Reviews:", error);
    }
  };
  const suggestedRestaurants = async () => {
    try {
      const response = await GetSuggestedRestaurants();
      setSuggestedRest(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchSuggestedStores = async () => {
    try {
      const response = await GetSuggestedStores();
      setSuggestedStores(response.data.data);
    } catch (error) {
      console.error("Error fetching suggested stores:", error);
    }
  };


  return (
    <main style={{ overflow: "hidden" }}>
      <Toast ref={toast} />
      <div className="container-fluid p-0 ">
        <div className="row">
          <Navbar />
        </div>
      </div>
      <div className="container internal-screen" style={{ minHeight: "calc(100vh - 324px)" }}>
        <div className="IFE-heading d-flex justify-content-between align-items-center">
          <div className="Food-Events-heading">Account</div>
          <div className="d-flex align-items-center FE-heading-nav">
            <a href="/" className="d-flex">
              {" "}
              <span className="home-span">Home</span>
            </a>
            <i
              className="bi bi-chevron-right"
              style={{ color: "#C2C2C2", fontSize: "12px" }}
            ></i>
            <span className="event-span">Account</span>
          </div>
        </div>
        <section className="page-content details-content p-0">
          <div className=" main-accounts-div d-flex">
            <div className="d-flex gap-4 col-lg-12 col-md-12 mb-7 profile-sidenav">
              <div className="tabs col-lg-3 col-md-3">
                <a
                  className={
                    showabout
                      ? "tab-active tab-headings d-flex gap-2 align-items-center"
                      : "tab-headings d-flex gap-2 align-items-center"
                  }
                  onClick={handleprofile}
                >
                  <div className="tab-icon" style={{ padding: "10px" }}>
                    <i
                      className={
                        showabout ? "bi bi-person-fill" : "bi bi-person"
                      }
                      style={{ fontSize: "17px" }}
                    ></i>
                  </div>

                  <span>Profile</span>
                </a>

                <a
                  className={
                    showfavrests || showfavcusi || showfavorg || showfavdietitians
                      ? "tab-active tab-headings d-flex gap-2 align-items-center"
                      : "tab-headings d-flex gap-2 align-items-center"
                  }
                  onClick={handleFDropdownToggle}
                >
                  <div className="tab-icon" style={{ padding: "10px" }}>
                    <svg
                      width="18"
                      height="16"
                      viewBox="0 0 18 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.00077 15.75C8.80193 15.7498 8.61129 15.6707 8.47077 15.53L1.70077 8.74C0.784635 7.80535 0.271484 6.54876 0.271484 5.24C0.271484 3.93123 0.784635 2.67464 1.70077 1.74C2.62906 0.811802 3.88804 0.290355 5.20077 0.290355C6.5135 0.290355 7.77248 0.811802 8.70077 1.74L9.00077 2L9.28077 1.72C9.73975 1.256 10.2865 0.887952 10.8891 0.637296C11.4917 0.386641 12.1381 0.258385 12.7908 0.259998C13.4427 0.257077 14.0887 0.383759 14.6913 0.632682C15.2939 0.881604 15.841 1.2478 16.3008 1.71C17.2169 2.64464 17.7301 3.90123 17.7301 5.21C17.7301 6.51876 17.2169 7.77535 16.3008 8.71L9.53077 15.5C9.46373 15.5751 9.38225 15.636 9.2912 15.6789C9.20014 15.7219 9.10137 15.746 9.00077 15.75ZM5.21077 1.75C4.7566 1.74669 4.30627 1.83336 3.88577 2.00499C3.46526 2.17662 3.08291 2.42983 2.76077 2.75C2.11164 3.40215 1.74722 4.28485 1.74722 5.205C1.74722 6.12515 2.11164 7.00784 2.76077 7.66L9.00077 13.94L15.2308 7.68C15.5534 7.35773 15.8093 6.97503 15.9839 6.55378C16.1586 6.13254 16.2484 5.681 16.2484 5.225C16.2484 4.76899 16.1586 4.31746 15.9839 3.89621C15.8093 3.47496 15.5534 3.09227 15.2308 2.77C14.9111 2.44923 14.5307 2.19554 14.1117 2.02381C13.6927 1.85209 13.2436 1.7658 12.7908 1.77C12.3366 1.76669 11.8863 1.85336 11.4658 2.02499C11.0453 2.19662 10.6629 2.44983 10.3408 2.77L9.53077 3.58C9.3877 3.71575 9.198 3.79143 9.00077 3.79143C8.80355 3.79143 8.61384 3.71575 8.47077 3.58L7.66077 2.77C7.34028 2.44622 6.95867 2.18932 6.53809 2.01423C6.11751 1.83913 5.66634 1.74931 5.21077 1.75Z"
                        fill="#818181"
                      />
                    </svg>
                  </div>

                  <span>Favorites</span>
                  <i
                    className={`pi ${isFDropdownOpen ? "pi-angle-up" : "pi-angle-down"
                      }`}
                    style={{
                      fontSize: "1rem",
                      position: "relative",
                      left: "calc(100% - 65%)",
                    }}
                  ></i>
                </a>
                {isFDropdownOpen && (
                  <div className="submenu">
                    <a
                      className={showfavrests ? "nav-link active sidenav-item" : "nav-link sidenav-item"}

                      onClick={handlefavrat}
                    >
                      <span>Restaurants</span>
                    </a>
                    <a
                      className={showfavcusi ? "nav-link active sidenav-item" : "nav-link sidenav-item"}
                      onClick={handlefavcusine}
                    >
                      <span >Cuisines</span>
                    </a>
                    <a
                      className={showfavorg ? "nav-link active sidenav-item" : "nav-link sidenav-item"}
                      onClick={handlefavorg}
                    >
                      <span>Organic Stores</span>
                    </a>
                    <a
                      className={showfavdietitians ? "nav-link active sidenav-item" : "nav-link sidenav-item"}
                      onClick={handlefavdietitian}
                    >
                      <span>Dietitians</span>
                    </a>
                  </div>
                )}
                <hr />

                <a
                  className={
                    showreviewstore || showreviewrest
                      ? "tab-active tab-headings d-flex gap-2 align-items-center"
                      : "tab-headings d-flex gap-2 align-items-center"
                  }
                  onClick={handleRDropdownToggle}
                >
                  <div className="tab-icon" style={{ padding: "10px" }}>
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.1993 17.7499C15.0769 17.7497 14.9565 17.7188 14.8493 17.6599L9.99926 15.1099L5.14926 17.6599C5.02601 17.7261 4.88672 17.7565 4.74712 17.7476C4.60752 17.7388 4.47317 17.6911 4.35926 17.6099C4.24552 17.5266 4.15722 17.4132 4.10424 17.2825C4.05126 17.1518 4.03568 17.009 4.05926 16.8699L4.99926 11.4699L1.05926 7.64993C0.96051 7.55109 0.891336 7.42663 0.859533 7.29058C0.827729 7.15453 0.834561 7.01231 0.879256 6.87993C0.921123 6.74707 0.999643 6.62872 1.10579 6.5385C1.21193 6.44828 1.34138 6.38985 1.47926 6.36993L6.89926 5.57993L9.32926 0.669932C9.39842 0.553905 9.49652 0.457827 9.61397 0.391106C9.73142 0.324384 9.86418 0.289307 9.99926 0.289307C10.1343 0.289307 10.2671 0.324384 10.3845 0.391106C10.502 0.457827 10.6001 0.553905 10.6693 0.669932L13.0993 5.57993L18.5193 6.36993C18.6571 6.38985 18.7866 6.44828 18.8927 6.5385C18.9989 6.62872 19.0774 6.74707 19.1193 6.87993C19.164 7.01231 19.1708 7.15453 19.139 7.29058C19.1072 7.42663 19.038 7.55109 18.9393 7.64993L14.9993 11.4699L15.9293 16.8699C15.9528 17.009 15.9373 17.1518 15.8843 17.2825C15.8313 17.4132 15.743 17.5266 15.6293 17.6099C15.5026 17.6975 15.3532 17.7462 15.1993 17.7499ZM9.99926 13.5199C10.1203 13.5214 10.2396 13.5486 10.3493 13.5999L14.1993 15.5999L13.4693 11.3099C13.4495 11.1896 13.4581 11.0663 13.4946 10.95C13.5311 10.8336 13.5943 10.7274 13.6793 10.6399L16.7993 7.63993L12.4893 6.99993C12.3701 6.98097 12.2571 6.93389 12.1597 6.86259C12.0623 6.79129 11.9833 6.69783 11.9293 6.58993L9.99926 2.68993L8.06926 6.59993C8.01517 6.70783 7.93617 6.80129 7.83879 6.87259C7.74141 6.94389 7.62845 6.99097 7.50926 7.00993L3.19926 7.63993L6.31926 10.6399C6.40419 10.7274 6.46744 10.8336 6.50391 10.95C6.54038 11.0663 6.54906 11.1896 6.52926 11.3099L5.79926 15.6299L9.64926 13.6299C9.75652 13.5682 9.87597 13.5307 9.99926 13.5199Z"
                        fill="#818181"
                      />
                    </svg>
                  </div>

                  <span>Reviews</span>
                  <i
                    className={`pi ${isRDropdownOpen ? "pi-angle-up" : "pi-angle-down"
                      }`}
                    style={{
                      fontSize: "1rem",
                      position: "relative",
                      left: "calc(100% - 64%)",
                    }}
                  ></i>
                </a>
                {isRDropdownOpen && (
                  <div className="submenu">
                    <a
                      className={
                        showreviewrest ? "nav-link active sidenav-item" : "nav-link sidenav-item"
                      }
                      onClick={handlereviewrest}
                    >
                      <span>Restaurants</span>
                    </a>
                    <a
                      className={
                        showreviewstore ? "nav-link active sidenav-item" : "nav-link sidenav-item"
                      }
                      onClick={handlereviewstore}
                    >
                      <span>Organic Stores</span>
                    </a>
                    <a
                      className={
                        showreviewdietitians ? "nav-link active sidenav-item" : "nav-link sidenav-item"
                      }
                      onClick={handlereviewdietitians}
                    >
                      <span>Dietitians</span>
                    </a>

                  </div>
                )}

                <a
                  className={
                    showsugstore || showsugrest
                      ? "tab-active tab-headings d-flex gap-2 align-items-center"
                      : "tab-headings d-flex gap-2 align-items-center"
                  }
                  onClick={handleSDropdownToggle}
                >
                  <div className="tab-icon" style={{ padding: "10px" }}>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.97071 1.34921C3.16069 1.12756 3.43804 1 3.72997 1H12.27C12.562 1 12.8393 1.12756 13.0293 1.34921L15.6389 4.39374C15.8719 4.6656 16 5.01186 16 5.36992V5.625C16 6.93668 14.9367 8 13.625 8C12.8629 8 12.1846 7.64102 11.75 7.08291C11.3154 7.64102 10.6371 8 9.875 8C9.11287 8 8.43458 7.64102 8 7.08291C7.56542 7.64102 6.88714 8 6.125 8C5.36286 8 4.68458 7.64102 4.25 7.08291C3.81542 7.64102 3.13713 8 2.375 8C1.06332 8 0 6.93668 0 5.625V5.36992C0 5.01186 0.128088 4.6656 0.361115 4.39374L2.97071 1.34921ZM4.75 5.625C4.75 6.38439 5.36561 7 6.125 7C6.88439 7 7.5 6.38439 7.5 5.625C7.5 5.34886 7.72386 5.125 8 5.125C8.27614 5.125 8.5 5.34886 8.5 5.625C8.5 6.38439 9.11561 7 9.875 7C10.6344 7 11.25 6.38439 11.25 5.625C11.25 5.34886 11.4739 5.125 11.75 5.125C12.0261 5.125 12.25 5.34886 12.25 5.625C12.25 6.38439 12.8656 7 13.625 7C14.3844 7 15 6.38439 15 5.625V5.36992C15 5.25057 14.9573 5.13515 14.8796 5.04453L12.27 2H3.72997L1.12037 5.04453C1.0427 5.13515 1 5.25057 1 5.36992V5.625C1 6.38439 1.61561 7 2.375 7C3.13439 7 3.75 6.38439 3.75 5.625C3.75 5.34886 3.97386 5.125 4.25 5.125C4.52614 5.125 4.75 5.34886 4.75 5.625ZM1.5 8.5C1.77614 8.5 2 8.72386 2 9V15H3V10C3 9.44772 3.44772 9 4 9H7C7.55228 9 8 9.44772 8 10V15H14V9C14 8.72386 14.2239 8.5 14.5 8.5C14.7761 8.5 15 8.72386 15 9V15H15.5C15.7761 15 16 15.2239 16 15.5C16 15.7761 15.7761 16 15.5 16H0.5C0.223858 16 0 15.7761 0 15.5C0 15.2239 0.223858 15 0.5 15H1V9C1 8.72386 1.22386 8.5 1.5 8.5ZM4 15H7V10H4V15ZM9 10C9 9.44772 9.44771 9 10 9H12C12.5523 9 13 9.44772 13 10V13C13 13.5523 12.5523 14 12 14H10C9.44771 14 9 13.5523 9 13V10ZM12 10H10V13H12V10Z"
                        fill="#818181"
                      />
                    </svg>
                  </div>

                  <span>Suggested</span>
                  <i
                    className={`pi ${isSDropdownOpen ? "pi-angle-up" : "pi-angle-down"
                      }`}
                    style={{
                      fontSize: "1rem",
                      position: "relative",
                      left: "calc(100% - 70%)",
                    }}
                  ></i>
                </a>
                {isSDropdownOpen && (
                  <div className="submenu">
                    <a
                      className={showsugrest ? "nav-link active sidenav-item" : "nav-link sidenav-item"}
                      onClick={handlesugrest}
                    >
                      <span>Restaurants</span>
                    </a>
                    <a
                      className={showsugstore ? "nav-link active sidenav-item" : "nav-link sidenav-item"}
                      onClick={handlesugcusine}
                    >
                      <span>Organic Stores</span>
                    </a>
                  </div>
                )}
              </div>

              {/* side nave mobileview */}

              <div className="mobile-options" style={{ maxWidth: '100%' }}>
                <select
                  className="form-select"
                  id="navigation-dropdown"
                  onChange={(event) => handleNavigationChange(event.target.value)}
                >
                  <optgroup label="Profile">
                    <option value="profile">
                      <a
                        className="tab-headings d-flex gap-2 align-items-center"
                        onClick={handleprofile}
                      >
                        <span>Profile</span>
                      </a>
                    </option>
                  </optgroup>
                  <optgroup label="Favorites">
                    <option value="fav-restaurants">
                      <a
                        className="tab-headings d-flex gap-2 align-items-center"
                        onClick={() => {
                          handleFDropdownToggle();
                          handlefavrat();
                        }}
                      >
                        <span>Restaurants</span>
                      </a>
                    </option>
                    <option value="fav-cuisines">
                      <a
                        className="tab-headings d-flex gap-2 align-items-center"
                        onClick={() => {
                          handleFDropdownToggle();
                          handlefavcusine();
                        }}
                      >
                        <span>Cuisines</span>
                      </a>
                    </option>
                    <option value="fav-organic-stores">
                      <a
                        className="tab-headings d-flex gap-2 align-items-center"
                        onClick={() => {
                          handleFDropdownToggle();
                          handlefavorg();
                        }}
                      >
                        <span>Organic Stores</span>
                      </a>
                    </option>
                    <option value="fav-dietitians">
                      <a
                        className="tab-headings d-flex gap-2 align-items-center"
                        onClick={() => {
                          handleFDropdownToggle();
                          handlefavdietitian();
                        }}
                      >
                        <span>Dietitians</span>
                      </a>
                    </option>
                  </optgroup>
                  <optgroup label="Reviews">
                    <option value="review-restaurants">
                      <a
                        className="tab-headings d-flex gap-2 align-items-center"
                        onClick={() => {
                          handleRDropdownToggle();
                          handlereviewrest();
                        }}
                      >
                        <span>Restaurants</span>
                      </a>
                    </option>
                    <option value="review-organic-stores">
                      <a
                        className="tab-headings d-flex gap-2 align-items-center"
                        onClick={() => {
                          handleRDropdownToggle();
                          handlereviewstore();
                        }}
                      >
                        <span>Organic Stores</span>
                      </a>
                    </option>
                    <option value="review-dietitians">
                      <a
                        className="tab-headings d-flex gap-2 align-items-center"
                        onClick={() => {
                          handleRDropdownToggle();
                          handlereviewdietitians();
                        }}
                      >
                        <span>Dietitians</span>
                      </a>
                    </option>



                  </optgroup>
                  <optgroup label="Suggested">
                    <option value="suggested-restaurants">
                      <a
                        className="tab-headings d-flex gap-2 align-items-center"
                        onClick={() => {
                          handleSDropdownToggle();
                          handlesugrest();
                        }}
                      >
                        <span>Restaurants</span>
                      </a>
                    </option>
                    <option value="suggested-organic-stores">
                      <a
                        className="tab-headings d-flex gap-2 align-items-center"
                        onClick={() => {
                          handleSDropdownToggle();
                          handlesugcusine();
                        }}
                      >
                        <span>Organic Stores</span>
                      </a>
                    </option>
                  </optgroup>
                </select>
              </div>




              {!showabout ? (
                ""
              ) : (

                <div className="tab-links col-lg-9 col-md-9">
                  <div className="container">
                    <div className="row">
                      <p className="main-heading-profiles mb-1">Profile</p>
                      <hr className="mb-3" style={{ color: "#ececec", opacity: "100%" }} />
                      <div className="row mb-5 col-lg-12 col-md-12 profile-cover-background">
                        <div className="d-flex">

                          <div className="col-lg-2 col-md-2 d-flex align-items-center justify-content-center">
                            {!lvisible ? (
                              <div className="profile-img">
                                {(localStorage.getItem("image") as string) === "undefined" ? (
                                  <Avatar
                                    label={localStorage.getItem("username")?.toString()[0].toUpperCase()}
                                    className="letter-circle"
                                    shape="circle"
                                  />
                                ) : (
                                  <Avatar
                                    image={localStorage.getItem("image") as string}
                                    className="mr-2 profile-img"
                                    shape="circle"
                                  />
                                )}
                              </div>
                            ) : (
                              <Skeleton width="100px" height="100px"></Skeleton>
                            )}
                          </div>
                          <div className="col-lg-10 col-md-10 col-sm-12 for-mobile-like-reviews d-flex justify-content-between ">
                            <div className="col-lg-7 col-md-7 d-flex flex-column justify-content-center align-items-start">
                              <div className="page-title">
                                {!lvisible ? (
                                  <h1 className="profile-name">
                                    {toCamelCase(profile.firstname)}{" "}
                                    {toCamelCase(profile.lastname)}
                                  </h1>
                                ) : (
                                  <Skeleton
                                    width="10rem"
                                    className="mb-2"
                                  ></Skeleton>
                                )}
                              </div>
                              {!lvisible ? (
                                <div className="city-edit">
                                  <p className="area-data mb-0">
                                    <h1 className="ym-address">
                                      {(profile.email)}
                                    </h1>
                                  </p>
                                </div>
                              ) : (
                                <Skeleton
                                  width="15rem"
                                  className="mb-2"
                                ></Skeleton>
                              )}

                            </div>
                            <div className="d-flex justify-content-end align-items-center">
                              {!lvisible ? (
                                <div className="button-edit">
                                  <Button
                                    icon="pi pi-pencil"
                                    className="btn-edit-label"
                                    label="Edit Profile"
                                    rounded
                                    outlined
                                    severity="success"
                                    aria-label="Edit"
                                    onClick={() => setVisible(true)}
                                  />
                                </div>
                              ) : (
                                <Skeleton
                                  width="5rem"
                                  className="mb-2"
                                ></Skeleton>
                              )}
                            </div>


                          </div>
                        </div>
                      </div>

                      {lvisible ? (
                        <div className="col-lg-4 col-sm-4">
                          <Skeleton width="10rem" className="mb-2"></Skeleton>
                          <Skeleton width="15rem" className="mb-2"></Skeleton>
                        </div>
                      ) : (
                        <>
                          <div className="col-lg-4 col-sm-4 joined-div">
                            <p className="overview-heading">Joined</p>
                            <p className="profile-details">
                              {getDate(profile.createdAt)}
                            </p>
                          </div>
                        </>
                      )}
                      {lvisible ? (
                        <div className="col-lg-4 col-sm-4">
                          <Skeleton width="10rem" className="mb-2"></Skeleton>
                          <Skeleton width="15rem" className="mb-2"></Skeleton>
                        </div>
                      ) : (
                        <>
                          <div className="col-lg-4 col-sm-4 dob-div">
                            <p className="overview-heading">DOB</p>
                            <p className="profile-details">
                              {profile.birthday
                                ? getDate(profile.birthday)
                                : "--"}
                            </p>
                          </div>
                        </>
                      )}

                      {lvisible ? (
                        <div className="col-lg-4 col-sm-4">
                          <Skeleton width="10rem" className="mb-2"></Skeleton>
                          <Skeleton width="15rem" className="mb-2"></Skeleton>
                        </div>
                      ) : (
                        <>
                          <div className="col-lg-4 col-sm-4 mobilenum-div" >
                            <p className="overview-heading">Phone</p>
                            <p className="profile-details">
                              {profile.phoneno ? profile.phoneno : "--"}
                            </p>
                          </div>
                        </>
                      )}

                      {lvisible ? (
                        <div className="col-lg-4 col-sm-4">
                          <Skeleton width="10rem" className="mb-2"></Skeleton>
                          <Skeleton width="15rem" className="mb-2"></Skeleton>
                        </div>
                      ) : (
                        <>
                          <div className="col-lg-4 col-sm-4 city-divv">
                            <p className="overview-heading">City</p>
                            <p className="profile-details">
                              {profile.city ? profile.city : "--"}
                            </p>
                          </div>
                        </>
                      )}

                    </div>

                  </div>
                </div>
              )}
              {!showfavrests ? (
                ""
              ) : (
                <div className="tab-links col-md-9">
                  <div className="container">
                    <div className="row">
                      <div className="row">
                        <div className=" d-flex align-items-center justify-content-between">
                          <p className="main-heading-profiles">Favorite Restaurants ({likedRestaurants.length})</p>
                        </div>
                        <hr style={{ color: "#ececec", opacity: "100%" }} />
                        
                          <div>
                            {!showfavrests ? "" : <FavoriteRestaurant likedRestaurants={likedRestaurants} />}
                          </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!showfavcusi ? (
                ""
              ) : (
                <div className="tab-links col-md-9">
                  <div className="container">
                    <div className="row">
                      <div className="row">
                        <div className=" d-flex align-items-center justify-content-between">
                          <p className="main-heading-profiles">Favorite Cuisines ({likedCuisines.length})</p>
                        </div>
                        <hr style={{ color: "#ececec", opacity: "100%" }} />
                      
                          <div>{!showfavcusi ? "" : <FavoriteCuisine likedCuisines={likedCuisines} />}</div>
                      
                      </div>
                    </div>
                  </div>
                </div>
              )}


              {!showfavorg ? (
                ""
              ) : (
                <div className="tab-links col-md-9">
                  <div className="container">
                    <div className="row">
                      <div className="row">
                        <div className=" d-flex align-items-center justify-content-between">
                          <p className="main-heading-profiles">Favorite Organic Stores ({likedStores.length})</p>
                        </div>
                        <hr style={{ color: "#ececec", opacity: "100%" }} />
                       
                          <div>{!showfavorg ? "" : <FavoriteStore likedStores={likedStores} />}</div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!showfavdietitians ? (
                ""
              ) : (
                <div className="tab-links col-md-9">
                  <div className="container">
                    <div className="row">
                      <div className="row">
                        <div className=" d-flex align-items-center justify-content-between">
                          <p className="main-heading-profiles">Favorite Dietitians ({likedDietitians.length})</p>
                        </div>
                        <hr style={{ color: "#ececec", opacity: "100%" }} />
                        
                          <div>{!showfavdietitians ? "" : <FavDietitianCard likedDietitians={likedDietitians} />}</div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!showreviewrest ? (
                ""
              ) : (
                <div className="tab-links col-md-9">
                  <div className="container">
                    <div className="row">
                      <div className="row">
                        <div className=" ">
                          <p className="main-heading-profiles">Restaurant Reviews ({reviews.length})</p>
                        </div>
                        <hr style={{ color: "#ececec", opacity: "100%" }} />
           
                          <div>
                            {!showreviewrest ? "" : <UserRestReviews reviews={reviews} />}
                          </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!showreviewstore ? (
                ""
              ) : (
                <div className="tab-links col-md-9">
                  <div className="container">
                    <div className="row">
                      <div className="row">
                        <div className=" d-flex align-items-center justify-content-between">
                          <p className="main-heading-profiles">Organic Store Reviews ({storereviews.length})</p>
                        </div>
                        <hr style={{ color: "#ececec", opacity: "100%" }} />
                        
                          <div>
                            {!showreviewstore ? "" : <UserStoreReviews storereviews={storereviews} />}
                          </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!showreviewdietitians ? (
                ""
              ) : (
                <div className="tab-links col-md-9">
                  <div className="container">
                    <div className="row">
                      <div className="row">
                        <div className=" d-flex align-items-center justify-content-between">
                          <p className="main-heading-profiles">Dietitian Reviews ({dietitianreviews.length})</p>
                        </div>
                        <hr style={{ color: "#ececec", opacity: "100%" }} />
                       
                          <div>
                            {!showreviewdietitians ? "" : <UserDietitianReviews dietitianreviews={dietitianreviews} />}
                          </div>
                       
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!showsugrest ? (
                ""
              ) : (
                <div className="tab-links col-md-9">
                  <div className="container">
                    <div className="row">
                      <div className="row">
                        <div className=" d-flex align-items-center justify-content-between">
                          <p className="main-heading-profiles">Suggested Restaurants ({suggestedRest.length})</p>
                        </div>
                        <hr style={{ color: "#ececec", opacity: "100%" }} />
                       
                          <div>
                            {!showsugrest ? "" : <SuggestedRestaurant suggestedRest={suggestedRest} />}
                          </div>
                      
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!showsugstore ? (
                ""
              ) : (
                <div className="tab-links col-md-9">
                  <div className="container">
                    <div className="row">
                      <div className="row">
                        <div className=" d-flex align-items-center justify-content-between">
                          <p className="main-heading-profiles">Suggested Organic Stores ({suggestedStores.length})</p>
                        </div>
                        <hr style={{ color: "#ececec", opacity: "100%" }} />
                     
                        <div>{!showsugstore ? "" : <SuggestedStore suggestedStores={suggestedStores} />}</div>

                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>

      <Footer2 city={city} />

      <Dialog
        header={"Edit Profile"}
        visible={visible}
        closeOnEscape={false}
        style={{ width: "30vw" }}
        onHide={() => setVisible(false)}
        footer={footerContent}
        className="edit-model"
        breakpoints={{
          "1920px": "50vw",
          "1420px": "50vw",
          "1120px": "50vw",
          "960px": "90vw",
          "641px": "90vw",
          "450px": "90vw",
        }}
      >
        <hr />
        <div className="pt-3">
          <div className="col-lg-12 col-md-12 col-sm-12 d-flex align-items-center ">
            <div className="col-lg-3 col-md-3 col-sm-3 col-mb-3 d-flex align-items-center justify-content-center p-0">
              <img
                className="edit-image"
                src={profile.image}
                height="95px"
                width="95px"
              />
            </div>
            <div className="col-lg-9 col-md-9 col-sm-9 flex flex-column col-mb-9 gap-2 choose-profile ml-3">
              <p className="edit-changeimg m-0">Profile Photo</p>
              <p className="plofile-edit m-0">
                Recommended size 500*500 px
              </p>
              <div className="d-flex justify-content-start gap-3">
                <div className="file-input-container">
                  <input type="file" id="rgba" className="choose" onChange={handleInputChange} style={{ display: "none" }} />
                  <label htmlFor="rgba" className="change-button">
                    <span className="mr-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 12 13" fill="none">
                        <path d="M9.10985 0.609835C9.25629 0.463388 9.49373 0.463388 9.64018 0.609835L11.8902 2.85984C12.0366 3.00628 12.0366 3.24372 11.8902 3.39016L4.39018 10.8902C4.35427 10.9261 4.31144 10.9543 4.26428 10.9732L0.514282 12.4732C0.375001 12.5289 0.215919 12.4962 0.109846 12.3902C0.00377242 12.2841 -0.0288804 12.125 0.0268322 11.9857L1.52683 8.23573C1.54569 8.18858 1.57394 8.14575 1.60985 8.10983L9.10985 0.609835ZM8.40534 2.375L10.125 4.09467L11.0947 3.125L9.37501 1.40533L8.40534 2.375ZM9.59468 4.625L7.87501 2.90533L3.00001 7.78033V8H3.37501C3.58212 8 3.75001 8.16789 3.75001 8.375V8.75H4.12501C4.33212 8.75 4.50001 8.91789 4.50001 9.125V9.5H4.71968L9.59468 4.625ZM2.27375 8.50659L2.1946 8.58574L1.04816 11.4519L3.91427 10.3054L3.99342 10.2263C3.85122 10.173 3.75001 10.0358 3.75001 9.875V9.5H3.37501C3.1679 9.5 3.00001 9.33211 3.00001 9.125V8.75H2.62501C2.46421 8.75 2.32704 8.64879 2.27375 8.50659Z" fill="#818181" />
                      </svg>
                    </span>
                    Change
                  </label>
                </div>
                <div>
                  <button className="change-button" onClick={handleRemoveImage}>
                    <i className="pi pi-trash mr-1" style={{ fontSize: "11px" }}></i>
                    Remove
                  </button></div>

              </div>
            </div>
          </div>
          <br />
          <div className="flex flex-column">
            <div className="flex flex-column icon-dob gap-2 ">
              <label className="edit-labels">First Name <b style={{ color: "red" }}>*</b></label>
              <InputText style={{ backgroundColor: "#f5f5f5" }} value={profile.firstname} />
            </div>{" "}
            <br />
            <div className="flex flex-column icon-dob gap-2 ">
              <label className="edit-labels">Last Name <b style={{ color: "red" }}>*</b></label>
              <InputText style={{ backgroundColor: "#f5f5f5" }} value={profile.lastname} />
            </div>{" "}
            <br />
            <div className="flex flex-column icon-dob gap-2 ">
              <label className="edit-labels">Email</label>
              <InputText style={{ backgroundColor: "#f5f5f5" }} value={profile.email} />
            </div>{" "}
            <br />
            <div className="flex flex-column icon-dob gap-2 ">
              <label className="edit-labels">Date of Birth</label>
              <Calendar
                className="edit-input"
                placeholder="DD/MM/YYYY"

                value={new Date(profile.birthday)}
                onChange={(e: CalendarChangeEvent) => {
                  setProfile({ ...profile, birthday: e.value as string });
                }}
                maxDate={new Date()}
                showIcon
              />
            </div>{" "}
            <br />
            <div className="flex flex-column icon-dob gap-2 ">
              <label className="edit-labels">Mobile</label>
              <InputNumber
                className="edit-input"
                placeholder="Enter mobile number"
                value={profile.phoneno}
                onChange={(e: any) =>
                  setProfile({ ...profile, phoneno: e.value })
                }
                useGrouping={false}
              />
            </div>
          </div>
          <br />
          <div className="flex flex-column  gap-2 edit-profile-dropdown">
            <label className="edit-labels">City</label>

            <Dropdown
              value={selectedCity}
              onChange={(e) => {
                setSelectedCity(e.value);
                setProfile({ ...profile, city: e.value.city });
              }}
              options={cities}
              optionLabel="city"
              placeholder="Select a City"
              className="down"
            />
          </div>
        </div>
      </Dialog>

    </main>
  );
};

export default Profile;
