import React, { useRef, useState } from "react";
import "./contactUs.pages.css";
import Navbar from "../../components/UI/Navbar/navBar.component";
import Footer from "../../components/UI/Footer/footer.component";
import Map from "../../components/UI/Map/map.component";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { abort } from "process";
import { Toast } from "primereact/toast";
import { SubmitMessage } from "../../api/contactus.api";
import { MapContainer, Marker, TileLayer } from "react-leaflet";

type resObj = {
  name: string;
  email: string;
  message: string;
};

const ContactUs: React.FC<any> = () => {
  const [city, setCity] = useState<string>(localStorage.getItem("city") || "");
  const [showMap, setShowMap] = useState(true);
  const [list, setList] = useState<string[]>([]);
  const [checked, setChecked] = React.useState(false);
  const [renderMap, setRenderMap] = useState(true);






  const [search, setSearch] = useState<string[]>([]);
  const CheckBox = () => {
    setChecked(!checked);
  }

  const validateEmail = (email: string) => {
    const regx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regx.test(String(email).toLowerCase());
  };
  const initialState = {
    name: "",
    email: "",
    message: "",
  };
  const toast = useRef<Toast>(null);
  const show = (message: any, severity: any, summary: any) => {
    if (toast.current) {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
        life: 2000,
      });
    } else {
      //console.log("toast issue");
    }
  };
  const [resObj, setResObj] = useState<resObj>(initialState);
  const SubmitResObj = async (e: any) => {
    if (checked) {
      e.preventDefault();
      if (resObj.name.length > 0 && resObj.email.length > 0 && resObj.message.length > 0) {
        if (validateEmail(resObj.email)) {
          await SubmitMessage(resObj)
            .then((response) => {
              if (response.data.status === 200) {
                show("Message Submitted", "info", "Info");
                setResObj(initialState)
                setChecked(false);
              }
            })
            .catch((error) => {
              // //console.log(error.response.status);
            });
        } else {
          e.preventDefault();
          show("Enter valid email id", "warn", "Warn");
        }
      } else {
        e.preventDefault();
        show("Please enter mandatory fields", "warn", "Warn");
      }
    } else {
      e.preventDefault();
      show("Please Agree", "warn", "Warn");
    }
  }
  return (
    <div className="container-fluid p-0">
      <Toast ref={toast}></Toast>
      <div>
        <Navbar />
      </div>
      <div className="container">
        <div className="IFE-heading d-flex justify-content-between align-items-center">
          <div className="contact-us-heading">Contact Us</div>
          <div className="d-flex align-items-center FE-heading-nav">
            <a href="/" className="d-flex">
              {" "}
              <span className="home-span">Home</span>
            </a>
            <i
              className="bi bi-chevron-right"
              style={{ color: "#C2C2C2", fontSize: "12px" }}
            ></i>
            <span className="event-span">Contact Us</span>
          </div>
        </div>
        <div className=" information-top">
          <p className="invite">We Would love to hear from you</p>
          <p className="invite-caption">Let us know how we can help</p>
        </div>
        <div className="d-flex justify-content-between contact-info">
          <div className="contact-details-box d-flex flex-column align-items-center gap-3">
            <div className="icon-bg d-flex justify-content-center align-items-center">
              <i className="pi pi-phone"></i>
            </div>
            <div className="d-flex flex-column">
              <p className="ph-text">Phone</p>
              <p className="day-time-text">Mon - Fri 9am to 6pm</p>
            </div>
            <div >
              <a href="tel:+914049522074" className="ph-num d-flex justify-content-center align-items-center pl-4 pr-4">
                +91 40 49522074
              </a>

            </div>
          </div>
          <div className="contact-details-box d-flex flex-column align-items-center gap-3">
            <div className="icon-bg d-flex justify-content-center align-items-center">
              <i
                className="pi pi-envelope
"
              ></i>
            </div>
            <div className="d-flex flex-column">
              <p className="ph-text">Email</p>
              <p className="day-time-text">Send us a mail</p>
            </div>
            <div>
              <a href="mailto:info@yeldam.com" className="ph-num d-flex justify-content-center align-items-center pl-4 pr-4">
                info@yeldam.com
              </a>
            </div>
          </div>
          <div className="contact-details-box d-flex flex-column align-items-center gap-3">
            <div className="icon-bg d-flex justify-content-center align-items-center">
              <i className="pi pi-map-marker"></i>
            </div>
            <div className="d-flex flex-column">
              <p className="ph-text">Visit</p>
              <p className="day-time-text">Say hello at our office</p>
            </div>
            <div>
              <a href="https://www.google.com/maps/place/Wallero+Technologies+Pvt+Ltd/@17.4895552,78.3899054,17z/data=!3m1!4b1!4m6!3m5!1s0x3bcb918d3088147b:0x1edb904a419c2ef6!8m2!3d17.4895501!4d78.3924803!16s%2fg%2f11c2nsxx1h?entry=ttu"
                className="ph-num d-flex justify-content-center align-items-center pl-4 pr-4" target="_blank"
              >
                View on Google Maps
              </a>

            </div>
          </div>
          <div className="contact-details-box d-flex flex-column align-items-center gap-3">
            <div className="icon-bg d-flex justify-content-center align-items-center">
              <i className="pi pi-comments"></i>
            </div>
            <div className="d-flex flex-column">
              <p className="ph-text">Chat</p>
              <p className="day-time-text">Ask our Yeldam Bot</p>
            </div>
            <div className="ph-num d-flex justify-content-center align-items-center pl-4 pr-4">
              Coming Soon!{" "}
            </div>
          </div>
        </div>

        <div className="">
          {!showMap ? (
            ""
          ) : (
            <>
              <div className=" d-flex map-contacts-div justify-content-end align-items-end container p-0">
              {renderMap && (
        <>
          <MapContainer className="map" center={[17.48916, 78.39278]} zoom={12}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
              
                <Marker
                  position={{
                    lat: 17.48916,
                    lng: 78.39278,
                  }}
                 
                >
                  
                </Marker>
            
          
          
          </MapContainer>
        </>
      )}
              </div>
              <div className="contactDialog d-flex">
                <div className="getin-touch-box">
                  <div className="getin-touch-inside">
                    <div
                      className=""
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "16px",
                        overflowX: "hidden",
                        overflowY: "hidden",
                      }}
                    >
                      <div className="user-contact-details  d-flex gap-3 flex-column p-4">
                        <div className="get-in-touch">Get In Touch</div>
                        <div className="row gap-2">
                          <label className="get-input-label">Name</label>
                          <div className="flex flex-column email-entry">
                            <InputText
                              id="Name"
                              className="input-area"
                              placeholder="Enter Name"
                              aria-describedby="username-help"
                              value={resObj.name}
                              onChange={(e: any) => {
                                setResObj({ ...resObj, name: e.target.value });
                              }}
                            />
                          </div>
                        </div>

                        <div className="row gap-2">
                          <label className="get-input-label">Email</label>
                          <div className="flex flex-column email-entry">
                            <InputText
                              id="email"
                              className="input-area"
                              placeholder="Enter email"
                              aria-describedby="username-help"
                              value={resObj.email}
                              onChange={(e: any) => {
                                setResObj({ ...resObj, email: e.target.value });
                              }}
                            />
                          </div>
                        </div>
                        <div className="row gap-2">
                          <label className="get-input-label">Message</label>
                          <div>
                            <InputTextarea
                              id="message"
                              placeholder="Enter Your Message"
                              value={resObj.message}
                              autoResize
                              onChange={(e: any) => {
                                setResObj({
                                  ...resObj,
                                  message: e.target.value,
                                });
                              }}
                              rows={3}
                              cols={36}
                            />
                          </div>
                        </div>
                        <div className="recaptcha-div d-flex align-items-center p-2">
                          <div className="d-flex align-items-center gap-2">
                            <input
                              className="checking"
                              type="checkbox"
                              checked={checked} onChange={CheckBox}
                            />
                            <label className="robot-label">
                              I’m not a robot
                            </label>
                          </div>
                        </div>
                        <div>
                          <Button
                            className="button_submit"
                            label="Submit"
                            severity="success"
                            onClick={(e) => SubmitResObj(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

        </div>
      </div>
      <div className="row">
        <Footer localities={list} city={city} search={search} type={"restaurant"} />
      </div>
    </div>
  );
};

export default ContactUs;
