const Images = {
  personBannerImage: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/personBannerImage.webp",
  vegBannerImages: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/vegBannerImages.webp",

NavLogo:"https://yeldamimages.azureedge.net/yeldamwebimages/Logosmall.webp",

  American:
    "https://yeldamimages.azureedge.net/yeldamwebimages/american.svg",

  Chinese:
    "https://yeldamimages.azureedge.net/yeldamwebimages/chinese.svg",

  Continental:
    "https://yeldamimages.azureedge.net/yeldamwebimages/continental.svg",

  Direction:
    "https://yeldamimages.azureedge.net/yeldamwebimages/direction.png",

  GlutenFree:
    "https://yeldamimages.azureedge.net/yeldamwebimages/GlutenFree.svg",

  GuiltFree:
    "https://yeldamimages.azureedge.net/yeldamwebimages/GuiltFree.svg",

  Home: "https://yeldamimages.azureedge.net/yeldamwebimages/homebg.svg",

  Indian:
    "https://yeldamimages.azureedge.net/yeldamwebimages/indian.svg",

  Italian:
    "https://yeldamimages.azureedge.net/yeldamwebimages/italian.svg",

  LackOfTime:
    "https://yeldamimages.azureedge.net/yeldamwebimages/lackoftime.png",

  Layer:
    "	https://yeldamimages.azureedge.net/yeldamwebimages/webp/yeldam-logo.webp",

  Location:
    "https://yeldamimages.azureedge.net/yeldamwebimages/Location.svg",

  Login_img:
    "https://yeldamimages.azureedge.net/yeldamwebimages/Login_Img.svg",

  Mainlogo:
    "https://yeldamimages.azureedge.net/yeldamwebimages/Mainlogo.svg",


  Organic:
    "https://yeldamimages.azureedge.net/yeldamwebimages/Organic.svg",

  PageNotFound:
    "https://yeldamimages.azureedge.net/yeldamwebimages/Pagenotfound.svg",

  Ph: "https://yeldamimages.azureedge.net/yeldamwebimages/ph.png",

  Share: "https://yeldamimages.azureedge.net/yeldamwebimages/share.png",

  Thai: " https://yeldamimages.azureedge.net/yeldamwebimages/thai.svg",

  Traditional:
    " https://yeldamimages.azureedge.net/yeldamwebimages/Traditional.svg",

  Traval:
    " https://yeldamimages.azureedge.net/yeldamwebimages/Travel.png",

  Unawareness:
    " https://yeldamimages.azureedge.net/yeldamwebimages/Unawareness.png",


  WhyYeldam:
    "https://yeldamimages.azureedge.net/yeldamwebimages/whyyeldam.svg",

  new_veg:
    "https://yeldamimages.azureedge.net/yeldamwebimages/new-veg.png",

  new_vega:
    "https://yeldamimages.azureedge.net/yeldamwebimages/new-vega.png",

  new_Nonveg:
    "https://yeldamimages.azureedge.net/yeldamwebimages/new-nonveg.png",

  new_Healthy:
    "https://yeldamimages.azureedge.net/yeldamwebimages/new-healthy.png",

  new_Traditional:
    "https://yeldamimages.azureedge.net/yeldamwebimages/new-traditional.png",

  new_Organic:
    "https://yeldamimages.azureedge.net/yeldamwebimages/new-oragnic.png",

  lackoftime: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/lackoftime.webp",
  Travel: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/Travel.webp",
  Unaware: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/Unawareness.webp",

  linkedin: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/Socialmediaicons/linkedin.webp",
  twitter: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/Socialmediaicons/Twitter.webp",
  instagram: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/Socialmediaicons/insta.webp",
  facebook: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/Socialmediaicons/facebook.webp",



  veg: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/Homepage/Popular-catagories/veg.webp",

  non: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/Homepage/Popular-catagories/non-veg.webp",

  traditional: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/New-popular-categories/newtraditional.webp",

  organic: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/Homepage/Popular-catagories/organic.webp",

  dessert: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/Homepage/Popular-catagories/Dessert.webp",

  vega: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/Homepage/Popular-catagories/vegan.webp",

  new_Thai: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/new-cuisines/thai.webp",//done
  new_Italian: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/new-cuisines/itlian.webp",//done
  new_Chinese: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/new-cuisines/chinese.webp",//done
  new_Indian: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/NewCuisines/Italian.webp",
  new_continental: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/new-cuisines/Continental.webp",//done
  new_american: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/new-cuisines/american-1.webp",//done
  new_mexican: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/new-cuisines/mexican.webp",//done
  new_japanese: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/new-cuisines/japanese.webp",//done
  new_northindian: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/new-cuisines/north-indian-1.webp",//done
  new_southindian: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/new-cuisines/south-indian-1.webp",//done
  new_chettinad: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/new-cuisines/chettina.webp",//done
  new_spanish: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/new-cuisines/spanisn-1.webp",//done
  new_mughal: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/new-cuisines/Mughlai.webp",//done
  new_Mediterranean: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/new-cuisines/Mediterranean.webp",//done
  new_Korean:"https://yeldamimages.azureedge.net/yeldamwebimages/webp/new-cuisines/korean.webp",
  new_Rajasthani:"https://yeldamimages.azureedge.net/yeldamwebimages/webp/new-cuisines/rajasthani.webp",
  new_Arabian:"https://yeldamimages.azureedge.net/yeldamwebimages/webp/new-cuisines/arabian.webp",
  new_Asian:"https://yeldamimages.azureedge.net/yeldamwebimages/webp/new-cuisines/asian.webp", 
  new_Andhra:"https://yeldamimages.azureedge.net/yeldamwebimages/webp/new-cuisines/andhra.webp",
  new_Bengali:"https://yeldamimages.azureedge.net/yeldamwebimages/webp/new-cuisines/bengali.webp",


  // Local Famous dishes images
  momos: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/LocalFamous/Momos.webp",
  Noodles: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/LocalFamous/Noodles.webp",
  Paneer: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/LocalFamous/paneer.webp",
  Pasta: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/LocalFamous/Pasta.webp",
  thali: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/LocalFamous/Thali.webp",
  wrapers: "https://yeldamimages.azureedge.net/yeldamwebimages/webp/LocalFamous/Wrapers.webp",


  selfcooking:"https://yeldamimages.azureedge.net/yeldamwebimages/webp/Explore/selfcooking.webp",
  open:"https://yeldamimages.azureedge.net/yeldamwebimages/webp/Explore/open.webp",
  offers:"https://yeldamimages.azureedge.net/yeldamwebimages/webp/Explore/offer.webp",
  budget:"https://yeldamimages.azureedge.net/yeldamwebimages/webp/Explore/budget.webp",
  breakfast:"https://yeldamimages.azureedge.net/yeldamwebimages/webp/Explore/breakfast.webp",
  buffet:"https://yeldamimages.azureedge.net/yeldamwebimages/webp/Explore/Buffet.webp",


  fruitsalad:"https://yeldamimages.azureedge.net/yeldamwebimages/webp/specialcategory/fruit-salad.webp",
  vegsalad:"https://yeldamimages.azureedge.net/yeldamwebimages/webp/specialcategory/vegetables-salad.webp",
  sandwitch:"https://yeldamimages.azureedge.net/yeldamwebimages/webp/specialcategory/sandwitch.webp",
  oats:"https://yeldamimages.azureedge.net/yeldamwebimages/webp/specialcategory/oats.webp",
  juices:"https://yeldamimages.azureedge.net/yeldamwebimages/webp/specialcategory/juices.webp"


};

export default Images;
