import axios from "axios";
import HttpInstance from "./http.api";
import { apiUrl } from "../config/constants";

export const GetProfile = () => {
  const respObj = HttpInstance.get("/user/userprofile");
  return respObj;
};

const HttpInstanceImage=axios.create({
  baseURL: apiUrl,
  headers: {
    'Accept': '*/*',
   'Authorization': "Bearer "+localStorage.getItem('accessToken'),
   'Content-Type':'multipart/form-data'
  }
})

export const EditImage = (image: string) => {
  const respObj = HttpInstance.patch("/user/uploaduserimage", { image: image });
  return respObj;
};

export const GetUserReviews = () => {
  const respObj = HttpInstance.post("/review/getreviewsbyuserid");
  return respObj;
};

export const GetUserLikes = () => {
  const respObj = HttpInstance.post("/review/getlikedrestaurants");
  return respObj;
};

export const GetUserLikedCuisines = () => {
  const respObj = HttpInstance.post("/user/userlikedcuisines");
  return respObj;
};
export const GetUserLikedStores = () => {
  const respObj = HttpInstance.get("/organicstore/userlikedstores");
  return respObj;
};
export const GetUserLikedDietitians = () => {
  const respobj = HttpInstance.get("/dietitians/userlikeddietitians");
  return respobj
}
export const GetStoreReviews = () => {
  const respObj = HttpInstance.post("/organicstore/getstorereviewsbyuserid");
  return respObj;
};
export const GetDietitianReviews = () =>{
  const respobj = HttpInstance.post("/dietitians/getdietitianreviewsbyuserid");
  return respobj;
}
export const EditUserDetails = (EditObj: any) => {
  const headers = {
    Accept: "*/*",
    Authorization: "Bearer " + localStorage.getItem("accessToken"),
  };

  const respObj = axios.patch(apiUrl + "/user/edituserprofile", EditObj, {
    headers,
  });
  return respObj;
};
export const TotalReviews = () => {
  const respObj = HttpInstance.get("/user/userlikedrestcount");
  return respObj;
};
export const GetSuggestedRestaurants = () => {
  const respObj = HttpInstance.get("/userrestaurant/suggestedrestaurants");
  return respObj;
};
export const GetSuggestedStores = () => {
  const respObj = HttpInstance.get("/userstore/suggestedorganicstores");
  return respObj;
};
export const SubmitUserRestaurant = (resObj:any)=>{
  const respData= HttpInstanceImage.post("/userrestaurant/adduserrest",resObj)
  return respData
}
export const SubmitUserOrganicStore = (resObj:any)=>{
  const respData= HttpInstanceImage.post("/userstore/adduserstore",resObj)
  return respData
}
