import React from "react";
import "./App.css";
import { AppRouter } from "./components/Router/appRouter";

import "../src/assets/css/colors.css";
import "../src/assets/css/styles.css";
import "../src/assets/js/main.js";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css"; // core css
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
function App() {
  return (
    <>
      <AppRouter />
    </>
  );
}

export default App;
