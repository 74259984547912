import React, { useEffect, useState } from "react";
import "./favCuisineCard.component.css";
import "primeicons/primeicons.css";
import { Card } from "primereact/card";
import { GetUserLikedCuisines } from "../../../../api/user.api";
import { yeldamwebimagessas } from "../../../../config/constants";
import { useNavigate } from "react-router-dom";
import ImageLoader from "../../loader/imageloader.component";


const FavoriteCuisine: React.FC<any> = ({likedCuisines}) => {
  // const [likedCuisines, setLikedCuisines] = useState([]);
  const [like, setLike] = useState<boolean>(false);


  const navigate = useNavigate();
  return (
    <>
      {likedCuisines.length === 0 ? (
          <div className="d-flex align-items-center justify-content-center flex-column mt-6 mb-6 nodata-div">
          <img style={{height:"140px"}} src={'https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/about/nofavcusiene.webp' + "?" + yeldamwebimagessas} alt='' />
          <span className="oops mt-2">No favorite cuisines?</span>
          <span className="no-data-found">Let's find your next culinary adventure!</span>
          <button className="explore_btn mt-2" onClick={() =>  navigate('/')}>Explore Now</button>
        </div>
     
    ) : (
      <div className="favcusine-card-div d-flex mb-4 mt-4 gap-1">
        {likedCuisines.map((cuisine: any, index:any) => (
          <div className="cusine-card-div " key={index}>
            <Card className="fav-cusine-Card d-flex align-items-end justify-content-center">
              <div className="d-flex justify-content-center align-items-center">
                <ImageLoader className={"cusine-image"} src={cuisine.imageurl+"?"+yeldamwebimagessas} alt={"image"} width={"100px"} height={"100px"}/>
              </div>
              <div className="Cusine-Name-div d-flex justify-content-center">
                <span className="cusine-Name">{cuisine.name}</span>
              </div>
              <div className="d-flex justify-content-center">
                <div className="cuisine-like-count gap-1">
                  <i
                    className={like ? "pi pi-heart" : "pi pi-heart-fill"}
                    onClick={() => {
                      // LikeFunction(item._id);
                    }}
                  ></i>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    )}
    </>
  );
};
export default FavoriteCuisine;
