import React, { useState, useEffect, useRef } from "react";
import { Card } from "primereact/card";
import "./favRestaurantCard.component.css";
import { Toast } from "primereact/toast";
import "bootstrap-icons/font/bootstrap-icons.css";
// import { LikedRestaurant } from "../../../../api/ratings.api";
import { GetUserLikes } from "../../../../api/user.api";
import { LikedRestaurant } from "../../../../api/ratings.api";
import { devsas, yeldamwebimagessas } from "../../../../config/constants";
import Images from "./../../../../assets/images/blobImages";
import FoodPreferences from "../../FoodPreferences/foodpreferences.component";
import { Rating } from "react-simple-star-rating";
import RestaurantTimings from "../../RestaurantTimings/RestaurantTimings.component";
import { useNavigate } from "react-router-dom";

const FavoriteRestaurant: React.FC<any> = ({ likedRestaurants }) => {
  const [like, setLike] = useState<boolean>(false);
  // const [likedRestaurants, setLikedRestaurants] = useState([]);


  console.log("object liked", likedRestaurants);

  //   useEffect(() => {

  //     if (ndata && ndata.length > 0) {
  //       const likesArray = ndata.map((item: any) => item.isLiked);

  //       setLike(likesArray);
  //     }
  //   }, [ndata]);

  //   const getDayName = () => {
  //     var d = new Date();
  //     return d.toLocaleDateString("en-in", { weekday: "long" });
  //   };

  //   const handleMetersChange = (meters: any) => {
  //     const metersValue = meters;
  //     const kilometersValue = (metersValue / 1000).toFixed(2);
  //     return kilometersValue;
  //   };

  const navigate = useNavigate();

  return (
    <>
      {likedRestaurants.length > 0 && (
        <div>
          <div className=" profile-rest-store-cards mb-1 mt-4">
            {likedRestaurants?.map((item: any) => (
              <Card
                key={item?.restID}
                header={
                  <div className="rest-image-div">
                    <img
                      src={item.images[0] + "?" + devsas}
                      className="headerimg"
                      alt=""
                    />

                    <div className="card-icons">
                      <div className="card-like-icon">
                        <i
                          className={like ? "pi pi-heart" : "pi pi-heart-fill"}
                          onClick={() => {
                            // LikeFunction(item._id);
                          }}
                        ></i>
                      </div>
                      <div className=""></div>
                    </div>
                  </div>
                }
                className="fav-card"
              >
                <div
                  className="rest-content pt-0"
                  onClick={() => {
                    const queryParams = new URLSearchParams();
                    queryParams.append("city", item.city);
                    queryParams.append("restname", item.name);
                    queryParams.append('id', item?.restID);
                    const queryString = queryParams.toString();
                    navigate(`/searchrestaurant?${queryString}`);
                  }}
                >
                  <div>
                    <FoodPreferences preferences={item.preference} />
                  </div>
                  <div className="d-flex justify-content-between align-items-center ">
                    <div className="Res_">{item.name}</div>
                    <div
                      className="d-flex align-items-center"
                      style={{ whiteSpace: "nowrap", gap: "0.1rem",lineHeight:"1px" }}
                    >
                      <i
                        className="bi bi-star-fill"
                        style={{ color: "#F0C916", fontSize:"14px" }}
                      ></i>
                      <span className="rating-count">{item.avgrating}</span>
                      <span className="reviews-count">
                        ({item.reviewCount})
                      </span>
                    </div>
                  </div>

                  <div className="Address">{item.address1}</div>

                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-center distance-text">
                      <i
                        className="bi bi-geo-alt"
                        style={{ fontSize: "12px" }}
                      ></i>{" "}
                      6.8 Kms
                    </div>

                    <div
                      className=""
                      style={{
                        padding: "0px",
                      }}
                    >
                      <span
                        className="avg"
                        style={{
                          color: "#3C3C3C",
                          whiteSpace: "nowrap",
                          fontSize: "12px",
                        }}
                      >
                        <i
                          className="bi bi-currency-rupee mr-1"
                          style={{ fontSize: "12px" }}
                        ></i>
                        {item.averagecost} for two{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          </div >

          <div className="mobile-view-sugg-store d-flex flex-column">
            {likedRestaurants.map((item: any) => (
              <div>
                <Card key={item?.restID} className="mobile-card mt-3 mb-3">
                  <div className="d-flex gap-2 align-items-center">
                    <div className="store-image-div col-lg-4 col-md-4 col-sm-4">
                      <div className="rest-image-div">
                        <img
                          src={item.images[0] + "?" + devsas}
                          className="favstoreimg"
                          alt=""
                        />
                        <div className="adv-card-icons">
                          <div className="adv-card-like-icon">
                            <i
                              className={
                                like ? "pi pi-heart" : "pi pi-heart-fill"
                              }
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="details-div d-flex flex-column gap-2 col-8 pt-0 pb-0 pl-0">
                      <div>
                        <FoodPreferences preferences={item.preference} />
                      </div>
                      <div className="store-name">{item.name}</div>
                      <div className="rating-review-count d-flex gap-1 align-items-center">
                        <span className="rating-value">
                          {parseFloat(item.avgrating).toFixed(1)}
                        </span>
                        <Rating
                          initialValue={parseFloat(item.avgrating)}
                          readonly={true}
                          transition
                          size={20}
                          allowFraction
                        />
                        <span className="review-count-value">
                          ({item.reviewCount})
                        </span>
                      </div>
                      <div className="store-address">
                        {item.address1 + ", " + item.city}
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <RestaurantTimings
                          timings={[JSON.parse(item.timings)]}
                        />
                      </div>
                    </div>
                  </div>
                </Card>
                <hr />
              </div>
            ))}
          </div >
        </div>

      )}
      {likedRestaurants.length === 0 && (
        <div className="d-flex align-items-center justify-content-center flex-column mt-6 mb-6 nodata-div">
          <img
            src={
              "https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/about/nofavrest.webp" +
              "?" +
              yeldamwebimagessas
            }
            alt=""
          />
          <span className="oops mt-2">
            You haven't saved any favorites yet.
          </span>
          <span className="no-data-found">
            Tap favorite on any restaurant to start your list.
          </span>
          <button className="explore_btn mt-2" onClick={() => navigate("/")}>
            Explore Now
          </button>
        </div>
      )}

    </>
  );
};

export default FavoriteRestaurant;
