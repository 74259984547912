import React, { useState } from "react";
import "./advancedSearchResults.component.css";
import RestaurantCard from "../UI/Cards/RestaurantCard/restaurantCard.component";
import { ScrollPanel } from "primereact/scrollpanel";
import { devsas } from "../../config/constants";

interface results {
  onClick: any;
  restaurantsdata: any;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
}

const AdvancedSearchResults: React.FC<results> = ({ onClick, restaurantsdata,setActiveIndex }) => {

  const handleCardClick = (id: any,index:number) => {
    onClick(id,index);
    setActiveIndex(0);
  };


  return (
    <>


      <div className="Results-Heading d-flex justify-content-between p-2 align-items-center mb-0">
    <p className="rest-result-arrow ml-1">Restaurants <i className="pi pi-chevron-right" style={{fontSize:"10px"}}></i> <span>Search Results</span></p>
   
      </div>
      
        <ScrollPanel className="col-lg-12 card-scroll-behavior">
          {
            restaurantsdata?.map((item: any,index:number) => {
              return (
                <RestaurantCard
                  StoreName={item.name}
                  Address={item.address1}
                  city = {item.city}
                  state = {item.state}
                  pincode = {item.pincode}
                  reviewsCount={item.reviewCount}
                  storecardimg={item.thumbnails[0] + "?" + devsas}
                  rating={item.avgTotalRating}
                  timings={item.timings}
                  serviceoptions={item.serviceoptions}
                  preference={item.preference}
                  likerest = {item.isLiked}
                  restaurantid = {item._id}
                  onClick={() => handleCardClick(item._id, index)} 
                />
              )
            })
          }

        </ScrollPanel>
      

    </>
  );
};

export default AdvancedSearchResults;
