import React, { useEffect, useState } from "react";
import { GetStoreReviews } from "../../../../../api/user.api";
import "./userStoreReview.component.css";
import { devsas, yeldamwebimagessas } from "../../../../../config/constants";
import { useNavigate } from "react-router-dom";
const UserStoreReviews:React.FC<any> = ({storereviews}) => {

  function timeSince(date: any) {
    var seconds = Math.floor((Date.now() - date) / 1000);
    var interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

  const NoData = (item: string) => {
    return (
      <div className="no-reviews d-flex justify-content-center">
        {"No " + item + " yet!"}
      </div>
    );
  };

  const navigate = useNavigate();
  return (
    <>
      {storereviews.length === 0 ? (

        <div className="d-flex align-items-center justify-content-center flex-column mt-6 mb-6 nodata-div">
          <img style={{ height: "177px",width:"280px" }} src={'https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/about/noreviewsyet.webp' + "?" + yeldamwebimagessas} alt='' />
          <span className="oops mt-2">Share your experience!</span>
          <span className="no-data-found">Write a review and help others discover their next favorite spot.</span>
          <button className="explore_btn mt-2" onClick={() =>  navigate('/organicstores')}>Explore Now</button>
        </div>

      ) : (
        <div className="mt-1">
          {storereviews.map((item: any, index:any) => (
            <>
            <div
              key={index}
              className="gap-1 d-flex align-items-start ml-1 rating-reviews"
            >
              <img
                className="reviews-image col-3"
                alt=""
                src={item.storeimages[0] + "?" + devsas}
              />
              <div className="review-profile-text col-11">
              <div className="mb-1 mob-timeing">
                    <span className="date-review-mob">
                        {timeSince(new Date(item.createdAt)) + " ago"}
                      </span>
                    </div>
                <div className="d-flex justify-content-between align-item-center mb-2">
                  {/* name */}
                  <div className="">
                    <span className="rest-review-name"  style={{ cursor: "pointer" }}    onClick={() => {
                          const queryParams = new URLSearchParams();
                          queryParams.append("city", item.city);
                          queryParams.append("storename", item.storename);
                          queryParams.append('id',item?.storeID);
                          const queryString = queryParams.toString();
                          navigate(`/searchstore?${queryString}`);
                        }}>{item.storename}</span>
                  </div>
                  {/* rating */}
                  <div>
                    <span className="rating-count">{item.totalrating}</span>
                    <i
                      className="bi bi-star-fill ml-2 mr-2"
                      style={{ color: "#F0C916",fontSize:"14px" }}
                    ></i>
                     <span className="date-review-desk">
                    {timeSince(new Date(item.createdAt)) + " ago"}
                  </span>

                  </div>
                </div>

                <div className="rest-review-city mb-2">
                  {item.address},{item.city}
                </div>
              

                <p className="review-content">{item.review}</p>
                {/* <p className="text-right" style={{ color: "#36a41d" }} >
                                                            <a onClick={() => { setreadmore(!readmore) }} style={{ cursor: "pointer" }}>{readmore ? "Read more" : "Read less"}</a>
                                                        </p> */}
              </div>
            </div>
             <hr style={{color:"#ececec",height:"1px",opacity:"100%"}}/>
             </>
          ))}
        </div>
      )}
  
    </>
  );
};
export default UserStoreReviews;
