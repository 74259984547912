

import React, { useState } from "react";
import "./storeAdvancedSearchResults.component.css";
import OrganicstoreCard from "../UI/Cards/OrganicStoreCard/organicStoreCard.component";
import { Button } from "primereact/button";
import {
  AutoComplete,
  AutoCompleteChangeEvent,
  AutoCompleteCompleteEvent,
} from "primereact/autocomplete";
import { ScrollPanel } from "primereact/scrollpanel";
import storeimg from "../../assets/images/restt.png";
import { devsas, yeldamwebimagessas } from "../../config/constants";
import StoreFilters from "../StoreFilters/storeFilters.component";
import oops from "../../assets/images/oops.svg";

interface results {
  onClick: any;
  storesdata: any;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
}

const StoreAdvancedSearchResults: React.FC<results> = ({
  onClick, storesdata,setActiveIndex
}) => {

  const [filters, setFilters] = useState<any[]>([]);

  const handleCardClick = (id: any,index:number) => {
    onClick(id,index);
    setActiveIndex(0)
  };

  const removeFilter = (indexToRemove: any) => {
    const updatedFilters = filters.filter((_, index) => index !== indexToRemove);
    setFilters(updatedFilters);
  };
  return (
    <>


      <div className="Results-Heading d-flex align-items-center p-2 mb-0">
      <p className="rest-result-arrow ml-1">Organic Stores <i className="pi pi-chevron-right" style={{fontSize:"10px"}}></i> <span>Search Results</span></p>

      </div>

        <ScrollPanel className="col-lg-12 card-scroll-behavior">
          {
            storesdata.map((item: any,index:number) => {
              return (
                <OrganicstoreCard
                  StoreName={item.storename}
                  Address={item.address}
                  reviewsCount={item.reviewCount}
                  storecardimg={item.thumbnailimages[0] + "?" + devsas}
                  rating={item.avgTotalRating}
                  timings={item.timings}
                  serviceoptions={item.serviceoptions}
                  likedstore={item.isLiked}
                  storeid={item._id}
                  onClick={() => handleCardClick(item._id, index)} 
                />
              )
            })
          }


        </ScrollPanel>
      
    </>
  );
};

export default StoreAdvancedSearchResults;

