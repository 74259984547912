import React, { createContext, useContext, useEffect, useState } from 'react';
import { CheckStoreLike, LikeStore } from '../../api/ratings.api';
import { Dialog } from 'primereact/dialog';
import SignInSignUp from '../UI/SigninSignup/signInSignUp.component';
import { bool } from 'yup';

interface LikeContextType {
  likedStores: string[];
  toggleStoreLike: (storeId: string,isLikedstatus:boolean) => void;
}

const StoreLikeContext = createContext<LikeContextType | undefined>(undefined);

interface StoreLikeProviderProps {
  children: React.ReactNode;
  initialStores:any;
}

export const StoreLikeProvider: React.FC<StoreLikeProviderProps> = ({ children ,initialStores}) => {
  const [likedStores, setLikedStores] = useState<string[]>([]);
  const [signinvisible, setSigninVisible] = useState<boolean>(false);

  const toggleStoreLike = async (storeId: string, isLikedstatus:boolean) => {
    try {
      // Call the API function to toggle like status in the database
      if (localStorage.getItem("accessToken") === null) {
        setSigninVisible(true);
      }
      if (likedStores.includes(storeId) || isLikedstatus) {
        const isLiked = await CheckStoreLike(storeId);
        if (isLiked) {
        // If already liked, unlike it
        await LikeStore(storeId, false);
        setLikedStores(prevLikedStores => prevLikedStores.filter(id => id !== storeId));
        }
        else{
        await LikeStore(storeId, true);
        setLikedStores(prevLikedStores => [...prevLikedStores, storeId]);
        }
      } else {
        // If not liked, like it
        await LikeStore(storeId, true);
        setLikedStores(prevLikedStores => [...prevLikedStores, storeId]);
      }
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };
  useEffect(() => {
    // Initialize liked restaurants from initial data
    const initialLikedStores = initialStores.filter((store:any) => store.isLiked).map((store:any) => store._id);
    setLikedStores(initialLikedStores);
  }, [initialStores]);

  return (
    <StoreLikeContext.Provider value={{ likedStores, toggleStoreLike }}>
      {children}
      <Dialog
visible={signinvisible}
closeOnEscape={false}
className="login-model"
onHide={() => setSigninVisible(false)}
>
<SignInSignUp setVisible={setSigninVisible} />
</Dialog>
    </StoreLikeContext.Provider>
    
  );
};

export const useStoreLike = () => {
  const context = useContext(StoreLikeContext);
  if (!context) {
    throw new Error('useLike must be used within a LikeProvider');
  }
  return context;
};

