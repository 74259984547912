import React, { useEffect, useRef, useState } from "react";
import "./signinSignUp.component.css";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import OtpInput from "react-otp-input";
import Checkimg from "../../../assets/icons/checkcircle.svg";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import subtract from "../../../assets/icons/Subtract.svg";
import { IResolveParams, LoginSocialGoogle } from "reactjs-social-login";
import { signIn, UserSignup, verifyOtp } from "../../../api/auth.api";
import { string } from "yup/lib/locale";
import { EditUserDetails } from "../../../api/user.api";
import { yeldamwebimagessas } from "../../../config/constants";
import { Navigate, useNavigate } from "react-router-dom";
import Loader from "../loader/loader.components";
import { Toast } from "primereact/toast";
import ImageLoader from "../loader/imageloader.component";
import { classNames } from 'primereact/utils';

const SignInSignUp: React.FC<any> = ({ setVisible }) => {
  const [code, setCode] = useState("");
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [counter, setCounter] = useState(5);
  const [countdownActive, setCountdownActive] = useState(false);
  const [email, setEmail] = useState("");
  const [isAdding, setIsadding] = useState(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [dotsloading, setIsdotsloading] = useState<boolean>(false);
  const navigate = useNavigate();
  const toast = useRef<Toast>(null);
  const show = (message: any, severity: any, summary: any) => {
    if (toast.current) {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
        life: 2000,
      });
    } else {
    }
  };
  const [isValidEmail, setIsValidEmail] = useState(false);

  const handleEmailChange = (e: any) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
    setIsValidEmail(validateEmail(inputEmail));
  };
  const validateEmail = (email: any) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  const handleNext = async () => {
    setIsdotsloading(true);
    console.log("I am hitting continue button");
    setIsadding(true);
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email.match(emailPattern)) {
      console.error("Invalid email format. Please enter a valid email address.");
      return;
    }
    try {
      const response = await UserSignup(email);
      if (response.status === 200) {
        console.log(response.data);
        setIsadding(false);
        setIsdotsloading(false);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      } else {
        console.error("Error signing up:", response.data);
      }
    } catch (error) {
      console.error("Error signing up:", error);
    }
  };

  const handleOtp = async () => {
    setIsdotsloading(true)
    setIsadding(true);
    if (code.length === 6) {
      try {
        const response = await verifyOtp({ otp: parseInt(code) });
        console.log("Response from verifyOtp:", response);
        if (response && response.status === 200) {
          setTimeout(() => {
            setIsadding(false);
            setIsdotsloading(false);

            const signup = !!localStorage.getItem("accessToken");
            console.log("Response:", response);
            if (signup) {
              console.log("isAuthenticated obj", response);
              setIsadding(false);
              if (response.data.data.isverified === 0) {
                setCurrentIndex((prevIndex) => prevIndex + 1);
              } else if (response.data.data.isverified === 1) {
                window.location.href = "/";
              }
            } else {
              setIsadding(false);
              console.error("Access token not found in local storage");
            }
          }, 100);
        } else {
          setIsadding(false);
          console.error("Error verifying OTP");
        }
      } catch (error) {
        setIsadding(false);
        console.error("Error verifying OTP:", error);
      }
    }
  };
  const [phoneno, setPhoneno] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [birthday, setBirthday] = useState<Date | null>(null);
  const [selectedGender, setSelectedGender] = useState("");
  const genderlist = ["Male", "Female", "Others"];
  const redirect_url = window.location.hostname;

  const handlebackbutton = () => {
    setVisible(false);
  };
  const handleSubmit = async () => {
    setIsdotsloading(true)
    try {
      if (!firstname || !lastname || !selectedGender) {
        show("Please fill out all required fields", "warn", "Warning");
        setCountdownActive(false);
        setCurrentIndex((prevIndex) => prevIndex);
      }
      else {


        // const formattedBirthday = birthday
        //   ? `${String(birthday.getDate()).padStart(2, "0")}/${String(
        //     birthday.getMonth() + 1
        //   ).padStart(2, "0")}/${birthday.getFullYear()}`
        //   : "";
        const updatedProfile = {
          firstname: firstname,
          lastname: lastname,
          phoneno: phoneno,
          birthday: birthday,
          gender: selectedGender,
        };
        console.log("data checking", updatedProfile);
        const response = await EditUserDetails(updatedProfile);
        console.log("response");
        if (response.status === 200) {
          setCurrentIndex((prevIndex) => prevIndex + 1);
          setCountdownActive(true);
          setIsdotsloading(false)
        } else {
          console.error("Error saving user details:", response.data);
          setCountdownActive(false);
        }
      }
    } catch (error) {
      console.error("Error saving user details:", error);
    }
  };
  const isFormValid = () => {
    return firstname !== '' && lastname !== '' && phoneno !== '' && selectedGender !== null;
  };

  const handleskip = () => {
    window.location.href = "/";
  };

  const handleChange = async (code: any) => {
    const regex = /^[0-9]*$/;
    if (regex.test(code)) {
      setCode(code);
    }
  };


  useEffect(() => {
    let countdown: any;
    if (countdownActive && counter > 0) {
      countdown = setTimeout(() => setCounter(counter - 1), 1000);
    } else if (counter === 0) {
      setCountdownActive(false);
    }

    return () => clearTimeout(countdown);
  }, [counter, countdownActive]);

  useEffect(() => {
    if (counter === 0) {
      window.location.href = "/";
    }
  });

  const handleclickhere = () => {
    window.location.href = "/";
  }

  console.log("count", counter);

  return (
    <>
      <Toast ref={toast}></Toast>
      {currentIndex === 0 && (
        <div className="signin-container container p-1">
          <div className="row d-flex align-items-center signin-row">

            <div className="d-flex ">
              <div className="d-flex flex-column col-lg-6 col-md-6 gap-6  login-model-col-1">
                <div className="d-flex justify-content-between align-items-center">
                  <i
                    className="pi pi-times p-2 dialog_cancle_btn"
                    style={{ color: "#3c3c3c" }}
                    onClick={handlebackbutton}
                  ></i>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <ImageLoader src={"https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/Homepage/mansignin.webp" + "?" + yeldamwebimagessas} alt={"image"} width={"360px"} height={"360px"} />
                </div>
              </div>
              <div className="col sign-card-cont p-0">
                <div className="signin-content">
                  <div className="">
                    <div className="signin-card d-flex flex-column justify-content-between p-4 container ">
                      <div className="mobile-signin-head-div mt-0">
                        <div className="signin-head-div">
                          <p className="sign-in-heading">Sign In or Sign Up</p>
                          <p className="email-msg">
                            Enter your email to receive a one time passcode
                          </p>
                        </div>
                        <div className="mt-0 hide_back_btn">
                          <i
                            className="pi pi-times p-2 dialog_cancle_btn"
                            style={{ color: "#3c3c3c" }}
                            onClick={handlebackbutton}
                          ></i>
                        </div>
                      </div>
                      <div>
                        <label className="email-input-label mb-1">Email</label>
                        <div className="row gap-3">
                          <div className="flex flex-column email-entry">
                            <InputText
                              id="email"
                              className="input-area"
                              placeholder="Enter email"
                              aria-describedby="username-help"
                              value={email}
                              onChange={handleEmailChange}
                            />
                          </div>
                          <Button
                            className={`button_continue ${email.length === 0 || !isValidEmail ? 'disabled' : ''} ${dotsloading ? 'loading' : ''}`}
                            severity="success"
                            style={{ height: '44px', display: "flex", justifyContent: "center" }}
                            onClick={handleNext}
                            disabled={email.length === 0 || !isValidEmail || dotsloading}
                          >
                            {dotsloading ? (
                              <div className="spinner">
                                <div className="bounce1"></div>
                                <div className="bounce2"></div>
                                <div className="bounce3"></div>
                              </div>
                            ) : (
                              'Continue'
                            )}
                          </Button>
                        </div>
                      </div>


                      <div className="d-flex justify-content-center align-items-center">
                        <hr className="flex-grow-1" />
                        <span className="mx-2 or-text">or</span>
                        <hr className="flex-grow-1" />
                      </div>

                      <div>
                        <LoginSocialGoogle
                          client_id={
                            "1099419468908-pr1impl1km9otk1hc117kc7nuhhncfgj.apps.googleusercontent.com"
                          }
                          redirect_uri={redirect_url}
                          scope="profile email"
                          onResolve={({ provider, data }: IResolveParams) => {
                            console.log("data", data);
                            console.log("provider", provider);
                            if (provider === "google") {
                              if (data) {
                                console.log("provider", data);
                                signIn(provider, data)
                                  .then((response: number) => {
                                    console.log(response);
                                    // setIsLoaded(true)
                                    if (response === 200) {
                                      // setIsLoaded(true)
                                      const { pathname } = window.location;
                                      if (pathname === '/addrestaurant') {
                                        // setIsLoaded(true)
                                        navigate("/useraddrestaurant");
                                      }
                                      else if (pathname === '/') {
                                        // setIsLoaded(true)
                                        navigate("/");
                                      }
                                      else {
                                        // setIsLoaded(true)
                                        window.location.reload();
                                      }
                                    }
                                    setVisible(false);
                                    // setIsLoaded(false);
                                    window.location.reload();
                                  })
                                  .catch((error: any) => {
                                    console.log(error);
                                  });
                              }
                            }
                          }}
                          onReject={(err: any) => { setIsLoaded(false); }}
                        >
                          <div className="google_div">
                            <button type="button" className="google_login_btn">
                              <img className="mr-2" src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=" />
                              Continue with Google
                            </button>
                          </div>

                        </LoginSocialGoogle>
                      </div>

                      <div className="signin-disclimer">
                        <p>
                          By continuing you agree to Yeldam{" "}
                          <span style={{ color: "#36a41d", fontWeight: "500" }}>
                            Terms and Conditions, Privacy and Cookie Policy.
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!isLoaded ? "" : <Loader />}
      {currentIndex === 1 && (
        <div className="signin-container container p-1">
          <div className="row d-flex align-items-center justify-content-between signin-row">

            <div className="d-flex">
              <div className="d-flex flex-column col-lg-6 col-md-6 gap-6  login-model-col-1">
                <div className="mt-0">
                  <button className="back_btn" onClick={() => setCurrentIndex((prevIndex) => prevIndex - 1)}>
                    <i
                      className="pi pi-chevron-left"
                      style={{ color: "#3c3c3c", fontSize: "0.8rem" }}
                    ></i>
                    <span>Back</span>
                  </button>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <ImageLoader src={"https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/Homepage/mansignin.webp" + "?" + yeldamwebimagessas} alt={"image"} width={"360px"} height={"360px"} />
                </div>

              </div>
              <div className="col p-0 sign-card-cont">
                <div className="otp-content">
                  <div className="d-flex justify-content-center">
                    <div className="otp-card d-flex flex-column gap-6 p-4 justify-content-between">
                      <div className="mtfordesk">
                        <div className="otp-head-div">
                          <p className="otp-heading">Enter OTP</p>
                          <p className="otp-msg">
                            Enter the OTP that we sent to your email
                          </p>
                          <p className="mail-otp">{email}</p>
                        </div>
                        <div className="mobile-otp-back-btn-div">
                          <i
                            className="pi pi-arrow-left p-2 dialog_cancle_btn"
                            style={{ color: "#3c3c3c" }}
                            onClick={() => setCurrentIndex((prevIndex) => prevIndex - 1)}
                          ></i>
                        </div>

                      </div>
                      <div className="d-flex flex-column gap-3 pt-3 pb-3">
                        <label className="email-input-label ml-1">OTP</label>
                        <div className=" gap-3 flex-column d-flex justify-content-center align-items-start">

                          <div className="otp-typing-div d-flex justify-content-center p-0 m-0">
                            <OtpInput
                              value={code}
                              onChange={(value: string) => {
                                handleChange(value);
                              }}
                              numInputs={6}
                              inputStyle={{
                                border: "none",
                                borderRadius: "4px",
                                fontSize: "12px",
                                color: "#000",
                                fontWeight: "400",
                              }}
                              shouldAutoFocus={true}
                              renderInput={(props) => <input {...props} />}
                            />

                          </div>
                          <div className="otp-typing-div d-flex justify-content-center p-0 m-0">
                            <Button
                              className={`button_continue ${code.length !== 6 ? 'disabled' : ''}${dotsloading ? 'loading' : ''}`}
                              severity="success"
                              onClick={handleOtp}
                              style={{ width: "314px", height: "44px", display: "flex", justifyContent: 'center' }}
                              disabled={code.length !== 6}
                            >
                              {dotsloading ? (
                                <div className="spinner">
                                  <div className="bounce1"></div>
                                  <div className="bounce2"></div>
                                  <div className="bounce3"></div>
                                </div>
                              ) : (
                                'Verify'
                              )}
                            </Button>
                          </div>


                        </div>
                      </div>
                      <div className="resend-code">
                        <p>
                          Didn't receive code?{" "}
                          <button className="resend_btn">Resend OTP</button>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {currentIndex === 2 && (
        <div className="signin-container p-1">
          <div className="row d-flex align-items-center signin-row">

            <div className="d-flex">
              <div className="d-flex flex-column pt-1 col-lg-6 col-md-6 gap-6 login-model-col-1">
                <div className="d-flex justify-content-center">
                  <div className="verified-alert d-flex justify-content-center  gap-2">
                    <img
                      className="check-circle-img d-flex "
                      src={Checkimg}
                      alt=""
                    />
                    <span>Your email is verified</span>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-center">
                  <ImageLoader src={"https://yeldamimages.azureedge.net/yeldamwebimages/webp/LoginImages/welcome.webp" + "?" + yeldamwebimagessas} alt={"image"} width={"320px"} height={""} />
                </div>
              </div>
              <div className="col sign-card-cont p-0">
                <div className="signin-content" >
                  <div className="d-flex">
                    <div className="signin-card d-flex flex-column justify-content-between p-4" style={{ width: "362px" }}>
                      <div className="">
                        <p className="sign-in-heading">
                          Tell us more about you
                        </p>
                      </div>
                      <div className="row gap-2">
                        <label className="tell-input-label">First Name<b style={{ color: "red" }}>*</b></label>
                        <div className="flex flex-column email-entry">
                          <InputText
                            id="Name"
                            className="input-area"
                            placeholder="Enter First Name"
                            aria-describedby="username-help"
                            value={firstname}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row gap-2">
                        <label className="tell-input-label">Last Name<b style={{ color: "red" }}>*</b></label>
                        <div className="flex flex-column email-entry">
                          <InputText
                            id="Name"
                            className="input-area"
                            placeholder="Enter Last Name"
                            aria-describedby="username-help"
                            value={lastname}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="row gap-2">
                        <label className="tell-input-label">Phone Number<b style={{ color: "red" }}>*</b></label>
                        <div className="flex flex-column email-entry">
                          <InputText
                            id="phonenumber"
                            className="input-area"
                            placeholder="Enter Phone Number"
                            aria-describedby="username-help"
                            value={phoneno}
                            onChange={(e) => setPhoneno(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="row gap-2 gender-entry">
                        <label className="tell-input-label">Gender<b style={{ color: "red" }}>*</b></label>
                        <div className="flex flex-column ">
                          <Dropdown
                            placeholder="Select"
                            style={{height:'44px',transition:'0s'}}
                            className="gender-text-tell-us"
                            options={genderlist}
                            value={selectedGender}
                            onChange={(e) => setSelectedGender(e.value)}
                          />
                        </div>
                      </div>
                      <div className="row gap-1">
                        <Button
                          className={`button_continue ${!isFormValid() ? 'disabled' : ''}${dotsloading ? 'loading' : ''}`}
                          severity="success"
                          onClick={handleSubmit}
                          disabled={!isFormValid()}
                          style={{display:'flex', justifyContent:'center',height:'44px'}}
                        >
                          {dotsloading ? (
                            <div className="spinner">
                              <div className="bounce1"></div>
                              <div className="bounce2"></div>
                              <div className="bounce3"></div>
                            </div>
                          ) : (
                            'Save'
                          )}
                        </Button>


                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {currentIndex === 3 && (
        <div className="d-flex flex-column justify-content-center align-items-center pt-6">
          <img className="thank-up-img mb-3" src={subtract} alt="" />
          <p className="Thank-you">Thank You</p>
          <p className="thank-down-content">
            Your profile has been updated. Your information will help us to
            provide you with a better experience.
          </p>
          <p className="redirect mb-3">
            Auto redirection to home page in{" "}
            <span style={{ color: "#36a41d" }}>{counter} seconds</span>
          </p>
          <p className="redirect"><span onClick={handleclickhere} style={{ color: "#36a41d", cursor: "pointer", textDecoration: "underline" }}>Click Here</span> to redirect now</p>
        </div>
      )}
    </>
  );
};

export default SignInSignUp;
