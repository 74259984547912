import React, { useState } from "react";
import { Rating } from "react-simple-star-rating";
import "./restaurantReviews.component.css";
import { ScrollPanel } from "primereact/scrollpanel";
import { Avatar } from "primereact/avatar";
import { yeldamwebimagessas } from "../../../config/constants";
import norev1 from "../../../assets/images/no-rev-img1.svg"
import { Dialog } from "primereact/dialog";
import SignInSignUp from "../SigninSignup/signInSignUp.component";

type props = {
  reviewslist: string[];
  newdetails: any;
  setVisible: any;
}


const RestaurantReviews: React.FC<props> = ({ reviewslist, newdetails, setVisible }) => {

  const [signinvisible, setSigninVisible] = useState<boolean>(false);

  console.log("object newdetails rating", newdetails);

  function timeSince(date: any) {
    var seconds = Math.floor((Date.now() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  };


  const AddReview = () => {
    if (localStorage.getItem("accessToken") === null) {
      setSigninVisible(true);
    } else {
      setVisible(true);
    }
  };



  return (
    <div className="container p-0">
      {/* Ratings */}
      <div className="">
        <div className="rating-box">
          <div className="d-flex flex-column px-2">
            <div className="col-lg-12 col-md-12 col-sm-12 col-mb-12 d-flex foodrating p-0">
                <div className="col-5 p-0">
                  <h6 className="rating-text pt-2">
                    Food
                  </h6>
                </div>
                <div className="col-7 p-0 d-flex justify-content-end">
                  <Rating
                 
                    initialValue={parseFloat(newdetails?.foodavgrating)}
                    readonly={true}
                    transition
                    size={16}
                    allowFraction
                  />
                </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-mb-12 d-flex ambience_rating p-0">
                <div className="col-5 p-0">
                  <h6 className="rating-text pt-2">
                    Ambience
                  </h6>
                </div>
                <div className="col-7 p-0 d-flex justify-content-end">
                  <Rating
                 
                    initialValue={parseFloat(newdetails?.ambienceavgrating)}
                    readonly={true}
                    transition
                    size={16}
                    allowFraction
                  />
                </div>
              
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-mb-12 d-flex food_portion p-0">
                <div className="col-5 p-0">
                  <h6 className="rating-text pt-2">
                    Food Portion
                  </h6>
                </div>
                <div className="col-7 p-0 d-flex justify-content-end">
                  <Rating
                 
                    initialValue={parseFloat(newdetails?.portionavgrating)}
                    readonly={true}
                    transition
                    size={16}
                    allowFraction
                  />
                </div>
              
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-mb-12 d-flex pricing p-o">
                <div className="col-5 p-0">
                  <h6 className="rating-text pt-2">
                    Pricing
                  </h6>
                </div>
                <div className="col-7 p-0 d-flex justify-content-end">
                  <Rating
                 
                    initialValue={parseFloat(newdetails?.priceavgrating)}
                    readonly={true}
                    transition
                    size={16}
                    allowFraction
                  />
                </div>
              
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-mb-12 d-flex hospitality p-0">
                <div className="col-5 p-0">
                  <h6 className="rating-text pt-2">
                    Hospitality
                  </h6>
                </div>
                <div className="col-7 p-0 d-flex justify-content-end">
                  <Rating

                    initialValue={parseFloat(newdetails?.hospitalityavgrating)}
                    readonly={true}
                    transition
                    size={16}
                    allowFraction
                  />
                </div>
              
            </div>
          </div>
          <hr className="btm-line-rating" />
        </div>
      </div>

      {/* Reviews */}

      <div className="row">
        <div className="Main">
          <ScrollPanel
            style={{
              width: "100%",
              height: "350px",
             
            }}
            className="custombar2"
          >
            <>
            {reviewslist.length === 0 ?(
                <div className="d-flex align-items-center justify-content-center flex-column mt-4 gap-3 nodata-div">
                <img src={norev1} alt='' />
                <button className="norev-write-btn" onClick={() => AddReview()}>Write a review</button>
             
              </div>
            ): (
            <div>
              {reviewslist?.map((item: any, index: number) => {
                return (
                  <>
                    <div className="">
                      <div className=" Restaurant-review">
                        <div className="col-12 d-flex align-items-start p-0">
                          <div className="p-0 " >
                            <Avatar
                              image={item.image as string}
                              className="Review-image"
                              shape="circle"
                            />
                          </div>
                          <div className="col-10 d-flex flex-column review-text p-0">
                            <div className="d-flex justify-content-between p-0 align-items-center">
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "500",
                                  fontFamily: "Roboto",
                                  color:"#3c3c3c"
                                }}
                              >
                                {item.firstname +
                                  " " +
                                  item.lastname}
                              </span>
                              <span
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  fontFamily: "roboto",
                                  whiteSpace:"nowrap"
                                }}
                              >
                                {timeSince(
                                  new Date(item.createdAt)
                                ) + " ago"}
                              </span>
                            </div>
                            <div className="pt-1 pb-2 " style={{lineHeight:"10px"}}>
                              <Rating
                                initialValue={parseFloat(item.totalrating)}
                                readonly={true}
                                transition
                                size={16}
                                allowFraction
                              />
                            </div>
                            <p
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                textAlign: "justify",
                                margin:"0px"
                              }}
                            >
                              {item.review}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr className="mb-3 btm-line-rating"></hr>
                  </>
                );
              })}
            </div>
            )}
            </>

          </ScrollPanel>
        </div>
      </div>
      <Dialog
        visible={signinvisible}
        closeOnEscape={false}
        // style={{ width: "50vw" }}
        className="login-model"
        // breakpoints={{
        //   "1920px": "50vw",
        //   "1420px": "50vw",
        //   "1120px": "50vw",
        //   "1090px": "40vw",
        //   "980px": "50vw",
        //   "840px": "60vw",
        //   "740px": "50vw",
        //   "641px": "70vw",
        //   "541px": "70vw",
        //   "490px": "80vw",
        //   "350px": "90vw",
        // }}
        onHide={() => setSigninVisible(false)}
      >
        <SignInSignUp setVisible={setSigninVisible} />
      </Dialog>
    </div>
    
  );
};

export default RestaurantReviews;