import { Card } from "primereact/card";
import React, { useState } from "react";
import "./dietitianCard.css";
import { Rating } from "react-simple-star-rating";
import { useDietitianLike } from "../../../LikeContext/dietitianlikecontext";
import { Dialog } from "primereact/dialog";
import SignInSignUp from "../../SigninSignup/signInSignUp.component";

type props = {
  dietitianname: string;
  dietitianimg: any;
  speciality: string;
  experience: any;
  degree: string;
  rating: string;
  reviewsCount: string;
  likeddietitian: boolean;
  dietitianid: string;
  onClick: any;
};

const DietitianCard: React.FC<props> = ({
  dietitianname,
  dietitianimg,
  speciality,
  experience,
  degree,
  rating,
  reviewsCount,
  likeddietitian,
  dietitianid,
  onClick,
}) => {
  const [like, setLike] = useState<boolean>(false);
  const [signinvisible, setSigninVisible] = useState<boolean>(false);
  const { likedDietitians, toggleDietitianLike } = useDietitianLike();
  const isLikeddietitian = likedDietitians.includes(dietitianid);

  const handleLikeClick = () => {
    toggleDietitianLike(dietitianid, likeddietitian);
    if (localStorage.getItem("accessToken") === null) {

    }
  };

  console.log("diiiii", dietitianname)
  return (
    <>
      <div className="dietitian-card-div">
        <Card className="dietitian-card" onClick={onClick}>
          <div className="d-flex horizantal-card align-items-center">
            <div className="image-div col-lg-4 col-md-4 col-sm-4">
              <div className="rest-image-div">
                <img src={dietitianimg} alt="" style={{ objectFit: "cover" }} />
                <div className="adv-card-icons">
                  <div className="adv-card-like-icon">
                    <i
                      className={
                        isLikeddietitian
                          ? "pi pi-heart-fill cuisine-like-icon"
                          : "pi pi-heart cuisine-like-icon"
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLikeClick();
                      }}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="details-div d-flex flex-column col-8 pt-0 pb-0 pl-0">
              <div className="dietitian-name-card mb-1">{dietitianname}</div>
              <div className="speciality-card mb-1">{speciality}</div>
              <div className="mb-1 experencein-card">
                {experience} Years Experience
              </div>
              <div className="educationin-card mb-2">{degree}</div>
              <div className="rating-review-count d-flex gap-1 align-items-center mb-0">
                <span className="rating-value">{parseFloat(rating).toFixed(1)}</span>
                <Rating
                  initialValue={parseFloat(rating)}
                  readonly={true}
                  transition
                  size={16}
                  allowFraction
                />
                <span className="review-count-value" style={{ color: "#3c3c3c" }}>({reviewsCount})</span>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <Dialog
        visible={signinvisible}
        closeOnEscape={false}
        // style={{ width: "50vw" }}
        className="login-model"
        // breakpoints={{
        //   "1920px": "50vw",
        //   "1420px": "50vw",
        //   "1120px": "50vw",
        //   "1090px": "40vw",
        //   "980px": "50vw",
        //   "840px": "60vw",
        //   "740px": "50vw",
        //   "641px": "70vw",
        //   "541px": "70vw",
        //   "490px": "80vw",
        //   "350px": "90vw",
        // }}
        onHide={() => setSigninVisible(false)}
      >
        <SignInSignUp setVisible={setSigninVisible} />
      </Dialog>
    </>
  );
};

export default DietitianCard;
