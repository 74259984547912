import React, { useEffect, useState } from 'react';
import { yeldamwebimagessas } from '../../../config/constants';
import { Skeleton } from 'primereact/skeleton';

const FoodPreferences: React.FC<any> = ({ preferences }) => {
  const hasVeg = preferences.includes("Veg");
  const hasNonVeg = preferences.includes("Non-Veg");
  const hasPureVeg = preferences.includes("Pure-veg");
  let iconSrc = "";

  const [visible, setvisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setvisible(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const renderPreference = (type: any, index: number) => {
    if (type === "Veg") {
      iconSrc = `https://yeldamimages.azureedge.net/yeldamwebimages/webp/Homepage/Special-Restaurant/vegicon.webp?${yeldamwebimagessas}`;
    } else if (type === "Non-Veg") {
      iconSrc = `https://yeldamimages.azureedge.net/yeldamwebimages/webp/Homepage/Special-Restaurant/nonvegicon.webp?${yeldamwebimagessas}`;
    } else {
      iconSrc = `https://yeldamimages.azureedge.net/yeldamwebimages/webp/Homepage/Special-Restaurant/vegicon.webp?${yeldamwebimagessas}`;
    }
    const cleanedType = type.replace(/[- ]/g, '').toLowerCase();
    return (
      <div key={index} className={`${cleanedType.toLowerCase()} d-flex`}>
        <img
          src={iconSrc}
          style={{ width: "10px", height: "10px" }}
          alt={`${type}`}
        />
        {type}
      </div>
    );
  };

  const preferencesToRender: JSX.Element[] = [];

  if (hasPureVeg) {
    preferencesToRender.push(renderPreference("Pure Veg", preferencesToRender.length));
  }

  if (hasVeg && !hasNonVeg) {
    preferencesToRender.push(renderPreference("Veg", preferencesToRender.length));
  } else if (!hasVeg && hasNonVeg) {
    preferencesToRender.push(renderPreference("Non-Veg", preferencesToRender.length));
  } else if (hasVeg && hasNonVeg) {
    preferencesToRender.push(renderPreference("Veg", preferencesToRender.length));
    preferencesToRender.push(renderPreference("Non-Veg", preferencesToRender.length + 1));
  }

  return (
    <div className="d-flex mb-1 gap-3">
      {preferencesToRender.length > 0 ? (
        preferencesToRender
      ) : (
        <div>No preferences</div>
      )}
    </div>
  );
};

export default FoodPreferences;
