import React, { useState } from 'react';
import "./privacyAndCookie.pages.css";
import Navbar from '../../components/UI/Navbar/navBar.component';
import Footer from '../../components/UI/Footer/footer.component';
import { ScrollPanel } from 'primereact/scrollpanel';

const PrivacyAndCookie = () => {
    const [city, setCity] = useState<string>("");
    const [list, setList] = useState<string[]>([]);
    const [search, setSearch] = useState<string[]>([]);

    const listItems = document.querySelectorAll('#privacylist .pc-listitem');
    const labels = document.querySelectorAll('#privacyanswerList .pc-question');

    listItems.forEach((item, index) => {
        item.addEventListener('click', () => {
            labels[index].scrollIntoView({ behavior: 'smooth' });
        });

        item.addEventListener('mouseover', () => {
            labels[index].scrollIntoView({ behavior: 'smooth' });
        });
    });

    return (
        <div className="container-fluid p-0">
            <div>
                <Navbar />
            </div>

                  <div className="container internal-screen" style={{minHeight: "calc(100vh - 50px)"}}>
                <div className="PC-heading d-flex justify-content-between align-items-center">
                    <div className="Food-Events-heading">Privacy & Cookie Policy</div>
                    <div className="d-flex align-items-center FE-heading-nav">
                        <a href="/" className="d-flex">
                            {" "}
                            <span className="home-span">Home</span>
                        </a>
                        <i
                            className="bi bi-chevron-right"
                            style={{ color: "#C2C2C2", fontSize: "12px" }}
                        ></i>
                        <span className="event-span">Privacy & Cookie Policy</span>
                    </div>
                </div>

                <div className='pc-body d-flex gap-3'>
                    <div className='col-lg-9 col-md-9 pc-conditions'>
                        <ScrollPanel className="pc-scroll-behaviour">
                            <div className='d-flex'>

                                <ul id="privacyanswerList" className='p-2 d-flex flex-column gap-2'>
                                    <li>
                                        <p className='condition-answer mt-2'>
                                            This Privacy Policy outlines how YELDAM ("WE, OUR, US") is committed to protecting your
                                            privacy and using your sensitive information. It explains how your information is collected,
                                            used, and disclosed by YELDAM and its associated subdomains (collectively, "OUR service").
                                            By accessing or using our services, you indicate that you have read, understood, and
                                            agreed to the collection, storage, use, and disclosure of your personal information
                                            as described in this Privacy Policy and our Terms of Use.
                                        </p>
                                    </li>
                                    <li>
                                        <label className='pc-question' id='pc-I'>
                                            DEFINITIONS AND KEY TERMS
                                        </label>
                                        <p className='condition-answer mt-2'>
                                            <span style={{ fontWeight: "500" }}>COOKIE:</span> A small amount of data generated by a WEBSITE and saved by your web browser
                                            to remember information such as LOGIN INFORMATION, LANGUAGE, AND OTHER PREFERENCES.
                                        </p>

                                        <p className='condition-answer mt-2'>
                                            <span style={{ fontWeight: "500" }}>SERVICE:</span>
                                            Refers to the service provided by YELDAM on this platform.
                                        </p>
                                        <p className='condition-answer mt-2'>
                                            <span style={{ fontWeight: "500" }}>COMPANY:</span>
                                            Refers to YELDAM, which is responsible for your information under this Privacy Policy.
                                        </p>
                                        <p className='condition-answer mt-2'>
                                            <span style={{ fontWeight: "500" }}> YOU:</span>
                                            A person or entity registered with YELDAM to use our services.
                                        </p>
                                        <p className='condition-answer mt-2'>
                                            <span style={{ fontWeight: "500" }}>COUNTRY: </span>
                                            The country where YELDAM or its owners/founders are based, in this case, INDIA.
                                        </p>
                                        <p className='condition-answer mt-2'>
                                            <span style={{ fontWeight: "500" }}>CUSTOMER:</span>
                                            Refers to the company or person that signs up to use our service.
                                        </p>
                                        <p className='condition-answer mt-2'>
                                            <span style={{ fontWeight: "500" }}> DEVICE:</span>
                                            Any internet-connected device, such as a phone, tablet, or computer, used to access our service.
                                        </p>
                                        <p className='condition-answer mt-2'>
                                            <span style={{ fontWeight: "500" }}>IP ADDRESS:</span>
                                            A unique number assigned to an internet-connected device to identify its location.
                                        </p>
                                        <p className='condition-answer mt-2'>
                                            <span style={{ fontWeight: "500" }}> PERSONNEL:</span>
                                            Individuals employed by YELDAM to perform services on behalf of the company.
                                        </p>
                                        <p className='condition-answer mt-2'>
                                            <span style={{ fontWeight: "500" }}>PERSONAL DATA:</span>
                                            Any information that directly or indirectly allows for the identification of a natural person.
                                        </p>
                                        <p className='condition-answer mt-2'>
                                            <span style={{ fontWeight: "500" }}>THIRD-PARTY SERVICE:</span>
                                            Advertisers, sponsors, promotional and marketing partners, and others who provide content or products/services that may interest you.
                                        </p>
                                    </li>
                                    <li>
                                        <label className='pc-question' id='pc-I'>
                                            When You Download Our App and Create an Account ("Account")
                                        </label>
                                        <p className='sub-heading-terms mt-2 mb-1'>a. YOU GIVE US PERMISSION TO COLLECT </p>
                                        <p className='condition-answer mb-1'>certain voluntary information ("Registration Information") about you, including:</p>
                                        <ul className='ml-4'>
                                            <li className='condition-answer mt-1'>•	Your Name/Username. </li>
                                            <li className='condition-answer mt-1'>•	Your Phone number.</li>
                                            <li className='condition-answer mt-1'>•	Email Address.</li>
                                            <li className='condition-answer mt-1'>•	Date of Birth</li>
                                            <li className='condition-answer mt-1'>•	Password.</li>
                                            <li className='condition-answer mt-1'>•	Location (GPS): Helps us provide you with more relevant information based on your interests.</li>
                                            <li className='condition-answer mt-1'>•	Photo Gallery (Pictures): Grants access to upload pictures from your photo gallery, but you can deny this permission.</li>
                                            <li className='condition-answer mt-1'>•	Login information for social media accounts connected to YELDAM (e.g., Facebook, Instagram).</li>

                                        </ul>
                                        <p className='condition-answer mt-1'>When you visit, sign up, subscribe to our services, or respond to surveys/forms, we collect information from your preferred login device.</p>
                                        <p className='sub-heading-terms'>b. WE USE YOUR INFORMATION TO</p>
                                        <ul className='ml-4 mb-4'>
                                            <li className='condition-answer mt-1'>
                                                •	<span style={{ fontWeight: "500" }}>Personalize your experience: </span>We use the information you provide to respond better to your individual needs, such as sending you information about new restaurants, promotions, and offers.
                                            </li>
                                            <li className='condition-answer mt-1'>•	<span style={{ fontWeight: "500" }}>Improve customer services:</span> The information and feedback we receive from you help us improve our services, respond to service requests, and support your needs.</li>
                                            <li className='condition-answer mt-1'>•	Send periodic emails.</li>
                                            <li className='condition-answer mt-1'>•	Administer contests, promotions, and surveys.</li>
                                            <li className='condition-answer mt-1'>•	Test new technologies and processes to enhance and develop our app/sites.</li>
                                            <li className='condition-answer mt-1'>•	Resolve disputes, troubleshoot problems, enforce our terms and conditions of use.</li>
                                            <li className='condition-answer mt-1'>•	Investigate fraud, protect our legal rights, and enforce our terms and conditions of use.</li>


                                        </ul>


                                        <p className='sub-heading-terms'>c.  WE USE CUSTOMER INFORMATION FROM THIRD PARTIES TO</p>
                                        <ul className='ml-4'>
                                            <li className='condition-answer mt-1'>•	Engage trusted third-party service providers who assist us with functions and services. We may receive information when you contact us or show interest in becoming a customer.</li>
                                            <li className='condition-answer mt-1'>•	Occasionally collect publicly available information on social media websites.</li>
                                            <li className='condition-answer mt-1'>•	Enhance our app and verify users' authenticity. </li>
                                            <li className='condition-answer mt-1'>•	Display registration information (name, date of birth, city/location, username) on your profile page.</li>
                                            <li className='condition-answer mt-1'>•	You can control the visibility of your information on social media websites by adjusting your privacy settings.</li>

                                        </ul>
                                        <p className='condition-answer mt-1'>
                                            We collect personal and non-personal information to comply with Article 21 of the Indian Constitution.
                                        </p>

                                        <p className='sub-heading-terms'>d. WE MAY SHARE INFORMATION WITH THIRD PARTIES</p>

                                        <ul className='ml-4 mb-4'>
                                            <li className='condition-answer mt-1'>•	We may share personal and non-personal information with advertisers, contest sponsors, promotional and marketing partners based on the information you provide to us.</li>
                                            <li className='condition-answer mt-1'>•	In the event of a merger, asset sale, or business reorganization, we may share information with current and future affiliated companies and business partners.</li>
                                            <li className='condition-answer mt-1' > •	Trusted third-party services assist us with hosting, server maintenance, database storage, email management, and customer service.</li>
                                        </ul>


                                        <p className='sub-heading-terms'>e. WE DISCLOSE PERSONAL AND NON-PERSONAL INFORMATION ABOUT YOU TO</p>
                                        <p className='condition-answer'>
                                            •	Government or law enforcement officials, private partners, or as necessary to respond to claims, legal process, protect our rights and interests, ensure public safety, prevent illegal activities, or comply with court orders, laws, rules, and regulations.
                                        </p>
                                        <p className='sub-heading-terms'>f. WE USE YOUR EMAIL ID FOR THE FOLLOWING PURPOSES</p>
                                        <ul className='ml-4 mb-4'>
                                            <li className='condition-answer mt-1'>•	By submitting your email ID, you agree to:</li>
                                            <li className='condition-answer mt-1'>•	Receive emails from us, including promotional events and discounts.</li>
                                            <li className='condition-answer mt-1'>•	Use your email address for customer audience targeting on platforms like Facebook for custom advertising to specific individuals who have opted to receive communications from us.</li>
                                            <li className='condition-answer mt-1'>•	Send you information and updates related to our service.</li>
                                            <li className='condition-answer mt-1'>•	You can unsubscribe at any time by clicking on the provided unsubscribe option in the respective email.</li>
                                        </ul>
                                        <p className='sub-heading-terms'>g. WE HATE SPAM AS MUCH AS YOU DO</p>
                                        <p className='condition-answer ml-4'>
                                            •	We do not send unsolicited commercial emails.
                                        </p>
                                    </li>
                                    <li>
                                        <label className='pc-question' id='pc-I'>
                                            Is the Information Collected Through Our Service Secure?</label>
                                    </li>
                                    <p className='condition-answer'>We take precautions to ensure the security of your information.
                                        We have implemented physical, electronic, and managerial measures to help protect against
                                        unauthorized access, maintain data security, and use your information correctly.</p>
                                    <p className='condition-answer'>
                                        However, it's important to note that no security system or process is completely foolproof,
                                        including encryption systems. While we make reasonable efforts to protect your personal
                                        information, we cannot guarantee its absolute security.
                                    </p>

                                    <li>
                                        <label className='pc-question' id='pc-I'>
                                            How Do We Protect Your Information
                                        </label>
                                        <p className='condition-answer mt-3'>• We employ various security measures to safeguard your personal information.</p>
                                        <p className='condition-answer'>• While we strive to maintain the security of your information,
                                            we cannot guarantee that it will be completely secure from unauthorized access, disclosure,
                                            alteration, or destruction due to breaches of our physical, technical, or managerial safeguards.</p>
                                        <p className='condition-answer'>• We retain your personal data and information for no longer than necessary or as allowed by law.</p>
                                        <p className='condition-answer'>• We dispose of all data in accordance with our terms of service and Privacy Policy, ensuring that it is not retained beyond 60 days.</p>
                                        <p className='condition-answer'>• If you wish to have your data removed from our records, you can contact our support team using your registered phone number or email address to request deletion.</p>
                                    </li>
                                    <li>
                                        <label className='pc-question' id='pc-I'>
                                            Update or Correct Your Information
                                        </label>
                                        <p className='condition-answer mt-3'>• You have the right to request updates or corrections to the information you have provided.</p>
                                        <p className='condition-answer'>• You can request restrictions on certain uses and disclosures of personally identifiable information.</p>
                                        <p className='condition-answer'>• You can modify your communication preferences and the information you receive from us.</p>
                                        <p className='condition-answer'>• By canceling your account, you can delete the personally identifiable information associated with it.</p>
                                        <p className='condition-answer'>• Such updates, corrections, changes, and deletions will not affect other information we have stored.</p>
                                        <p className='condition-answer'>• We take reasonable steps to verify your identity before granting access to your profile or making corrections, such as requesting a unique password or OTP verification through your registered email address or phone number.</p>
                                        <p className='condition-answer'>• It is your responsibility to maintain the confidentiality of your unique password and account information.</p>
                                        <p className='condition-answer'>• If you are an end-user and wish to update, delete, or access any information we have about you, please contact us as our customer.</p>
                                    </li>
                                    <li>
                                        <label className='pc-question' id='pc-I'>
                                            Sale of Business
                                        </label>
                                        <p className='condition-answer mt-3'>
                                            In the event of a sale, merger, or transfer of all or substantially all of our assets or any of our Corporate Affiliates, or if we discontinue our business or undergo reorganization or a similar proceeding, we reserve the right to transfer information to a third party. The third party must agree to adhere to the terms of this Privacy Policy.
                                        </p>
                                    </li>
                                    <li>
                                        <label className='pc-question' id='pc-I'>
                                            Affiliates
                                        </label>
                                        <p className='condition-answer mt-3'>
                                            We may disclose your information to our Corporate Affiliates. Corporate Affiliates refer to any person or entity that directly or indirectly controls, is controlled by, or is under common control with us, whether through ownership or otherwise. Your information shared with these Corporate Affiliates is treated in accordance with the terms of this Privacy Policy.
                                        </p>
                                    </li>
                                    <li>
                                        <label className='pc-question' id='pc-I'>
                                            Social Media Providers
                                        </label>
                                        <p className='condition-answer mt-3'>
                                            Our site uses GOOGLE LOGIN, and EMAIL LOGIN as our social media providers, allow customers to create/connect their accounts. All your login information will be stored in our database. These features may collect information about your use of the services as well as post information about your activities on the social media service. Your interactions are governed by the respective privacy policy.
                                        </p>
                                        <p className='sub-heading-terms'>•	LINKING OTHER ACCOUNTS TO YELDAM:</p>
                                        <p className='condition-answer'>
                                            For users who opt for alternative login options, such as Google or email, the provided personal information is used solely for the creation of your Yeldam account. If you decide to disconnect the Yeldam application from your Google or email account, we will no longer have access to this data. Nevertheless, any personal information collected during the initial setup of your Yeldam account using Google or email login will persist unless you choose to delete your Yeldam account entirely, ensuring that we no longer retain access to this data.
                                        </p>

                                    </li>
                                    <li>
                                        <label className='pc-question' id='pc-I'>
                                            Profile Verification Information (Including Biometric Information)
                                        </label>
                                        <p className='condition-answer mt-3'>
                                            For safety, security, and an enhanced user experience, Yeldam may request users to verify their accounts, typically through email, to ensure authenticity and prevent the creation of fake profiles that could be utilized for malicious activities and cybercrime, including the submission of fraudulent reviews. This verification process is designed to safeguard the integrity of the Yeldam network and enhance the overall user experience. The request for email v
                                            erification serves to confirm the legitimacy of user accounts by sending emails.

                                        </p>
                                    </li>
                                  
                                    <li>
                                        <label className='pc-question' id='pc-I'>
                                            Links
                                        </label>
                                        <p className='condition-answer mt-3'>• We may keep track of how you interact with links available on YELDAM, including third-party services, by redirecting clicks or through other means. </p>
                                        <p className='condition-answer'>• We may share aggregate click statistics, such as how many times a particular link was clicked on. </p>
                                        <p className='condition-answer'>• The services may contain links to other websites not operated or controlled by us. </p>
                                        <p className='condition-answer'>• Please remember that when you use a link to go from our service to another website, our Privacy Policy is no longer in effect. </p>

                                    </li>
                                    <li>
                                        <label className='pc-question' id='pc-I'>
                                            Advertising
                                        </label>
                                        <p className='condition-answer mt-3'>
                                            Advertising keeps many of the websites and services you use free of charge. We work hard to make sure that ads are safe, unobtrusive, and as relevant as possible.
                                        </p>
                                    </li>
                                    <li>
                                        <label className='pc-question' id='pc-I'>
                                            Kids Privacy
                                        </label>
                                        <p className='condition-answer mt-3'>Our service provides a positive impact on children's food choices as well as adults'. We do not knowingly collect personally identifiable information from anyone under 13. If you are a parent or guardian and you are aware that your child has provided us with personal details that you feel uncomfortable with, please contact us.  </p>

                                    </li>
                                    <li>
                                        <label className='pc-question' id='pc-I'>
                                            Messages
                                        </label>
                                        <p className='condition-answer mt-3'>We review the content of messages sent to Yeldam to identify topics, sentiments, and trends, across our Users. We will take steps to remove personally identifying information from such messages prior to reviewing them. We will not share the content of User messages or information we derive from them with any third party. </p>
                                    </li>
                                    <li>
                                        <label className='pc-question' id='pc-I'>
                                            Tracking Technologies
                                        </label>
                                        <p className='condition-answer mt-3'>We will collect information about Wi-Fi access points as well as other 
                                        location information about your longitude and latitude and may save your device’s coordinates to offer certain
                                         features to you. This information helps us identify your physical location, and we may use it to personalize the App. 
                                         </p>
                                         <p className='sub-heading-terms'>
                                         GOOGLE MAPS APIs
                                         </p>
                                         <p className='condition-answer'>
                                         It is a robust tool that can be used to create a custom map and display live data syncing with your location to give you accurate results of the restaurants around you according to your preferred choice. This may collect information from you and your device for security purposes, which are held by its privacy policy. 
                                         </p>
                                         <ul className='ml-4 mb-4'>
                                         <li className='condition-answer mt-1'>
                                         •	COOKIES: We use cookies to enhance the performance and functionality of our services. Without these cookies, certain functions may not be available, or you would be required to enter your login details every time you visit our app as we would not be able to remember that you had logged into our app previously. 
                                         </li>
                                         <li className='condition-answer mt-1'>
                                         •	SESSIONS: YELDAM uses sessions to identify the areas of our website that you have visited most, which gives us better insight into your preferences and helps us deliver the best available results. 
                                         </li>
                                         <li className='condition-answer mt-1'>
                                         •	If you have given YELDAM access to your location services but wish to turn them off, you can do so by following these methods: 
                                         <ul className='ml-6 mt-3'>
                                         <li className='condition-answer mt-1'>•	On your browser</li>
                                         <li className='condition-answer mt-1'>•	At the top right, click More. Settings.</li>
                                         <li className='condition-answer mt-1'>•	Click Privacy and security. Site Settings.</li>
                                         <li className='condition-answer mt-1'>•	Click Location.</li>
                                         <li className='condition-answer mt-1'>•	Choose the option you want as your default setting.</li>

                                         </ul>
                                         </li>
                                         

                                         </ul>

                                    </li>
                                    <li>
                                        <label className='pc-question' id='pc-I'>
                                            Changes to Our Policy
                                        </label>
                                        <p className='condition-answer mt-3'>If we decide to change our privacy policy, we will post those changes on this page and/or update the Privacy Policy modification date below. </p>
                                    </li>
                                    <li>
                                        <label className='pc-question' id='pc-I'>
                                            Third-party Services
                                        </label>
                                        <p className='condition-answer mt-3'> •	We may display, include, or make available third-party content (including data, information, applications, and other product services) or provide links to third-party services. </p>
                                        <p className='condition-answer'>•	You acknowledge and agree that we shall not be responsible for any THIRD-PARTY SERVICES, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect thereof. </p>
                                        <p className='condition-answer'> •	We do not assume and shall not have any liability or responsibility to you or any other person or entity for any THIRD-PARTY SERVICES. </p>
                                        <p className='condition-answer'>•	You access the third-party services and use them entirely at your own risk and subject to such third parties' terms and conditions. </p>
                                    </li>
                                    <li>
                                        <label className='pc-question' id='pc-I'>
                                            Law and Harm
                                        </label>
                                        <p className='condition-answer mt-3'>We will cooperate with all third parties to ensure the safety
                                            of their intellectual property or other rights. We will also cooperate with law enforcement inquiries
                                            from within or outside your country of residence where we are required to by the law.
                                            This may include preserving or disclosing any of your information, including you
                                            r Registration Information if we believe in good faith that it is necessary to comply with a law
                                            or regulation or when we believe that disclosure is necessary to comply with a judicial proceeding,
                                            court order, or legal request; to protect the safety of any person; to address fraud, security,
                                            or technical issues (e.g., through anti-spam providers) to protect the service from criminal
                                            activity or to protect our rights or property or those of third parties. In such cases, we
                                            may raise or waive any legal objection or rights available to us. </p>
                                        <ul className='ml-4 mb-4'>
                                            <li className='condition-answer mt-1'>•	YOU HAVE THE RIGHT TO KNOW AND ACCESS. YOU CAN REQUEST A COPY OF YOUR DATA. </li>
                                            <li className='condition-answer mt-1'>•	RIGHT OF RECTIFICATION: If the data held is inaccurate, you have the right to have it corrected. </li>
                                            <li className='condition-answer mt-1'>•	RIGHT TO ERASURE: You have the right to have your data deleted in certain circumstances. </li>
                                            <li className='condition-answer mt-1'>•	RIGHT TO RESTRICT PROCESSING: In limited circumstances, you have the right to request that processing is stopped but the data retained. </li>
                                            <li className='condition-answer mt-1'>•	YOU HAVE THE RIGHT TO EQUAL SERVICE </li>
                                            <li className='condition-answer mt-1'>•	YOU HAVE THE RIGHT TO BE INFORMED Please contact us if you would like to exercise any of these rights. </li>

                                        </ul>
                                    </li>
                                    <li>
                                        <label className='pc-question' id='pc-I'>
                                            Contact Us
                                        </label>
                                        <p className='condition-answer mt-3'>Don't hesitate to contact us if you have any questions via  </p>
                                        <p className='condition-answer'><span style={{ fontWeight: "500" }}>Email:</span> info@yeldam.com </p>
                                        <p className='condition-answer'><span style={{ fontWeight: "500" }}>Via this link:</span> https://www.yeldam.com/contactus</p>
                                    </li>
                                </ul>
                            </div>
                        </ScrollPanel>

                    </div>
                    <div className="col-lg-3 col-md-3 pc-filters">
                        <div className="d-flex">
                            <ul id="privacylist" className="p-2 d-flex flex-column gap-3">

                                <li><a className='pc-listitem' href="#pc-I">Definitions and Key Terms</a></li>
                                <li><a className='pc-listitem' href="#pc-II">When You Download Our App and Create an Account ("Account")</a></li>
                                <li ><a className='pc-listitem' href="#pc-III">Is the Information Collected Through Our Service Secure? </a></li>
                                <li ><a className='pc-listitem' href="#pc-IV">How Do We Protect Your Information</a></li>
                                <li ><a className='pc-listitem' href="#pc-V">Update or Correct Your Information </a></li>
                                <li ><a className='pc-listitem' href="#pc-VI">Sale of Business </a></li>
                                <li ><a className='pc-listitem' href="#pc-VII">Affiliates </a></li>
                                <li ><a className='pc-listitem' href="#pc-VIII">Social Media Providers </a></li>
                                <li ><a className='pc-listitem' href="#pc-IX"> Profile Verification Information (Including Biometric Information)</a></li>
                                <li ><a className='pc-listitem' href="#pc-XI">Links</a></li>
                                <li ><a className='pc-listitem' href="#pc-XII">Advertising</a></li>
                                <li ><a className='pc-listitem' href="#pc-XIII">Kids Privacy</a></li>
                                <li ><a className='pc-listitem' href="#pc-XIV">Messages</a></li>
                                <li ><a className='pc-listitem' href="#pc-XV">Tracking Technologies</a></li>
                                <li ><a className='pc-listitem' href="#pc-XVI">Changes to Our Policy</a></li>
                                <li ><a className='pc-listitem' href="#pc-XVII">Third-party Services</a></li>
                                <li ><a className='pc-listitem' href="#pc-XVIII">Law and Harm</a></li>
                                <li ><a className='pc-listitem' href="#pc-XIX">Contact Us</a></li>

                            </ul>
                        </div>
                    </div>

                </div>
            </div>

            <div className="row">
                <Footer localities={list} city={city} search={search} type={"restaurant"} />
            </div>
        </div>
    );
}

export default PrivacyAndCookie;
