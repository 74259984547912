import React from "react";
import "./categoriesCard.component.css";
import "primeicons/primeicons.css";
import { Card } from "primereact/card";
import { yeldamwebimagessas } from "../../../../config/constants";
import { useNavigate } from "react-router-dom";
import ImageLoader from "../../loader/imageloader.component";


type props = {
  category: any;
};
const CategoryCard: React.FC<props> = ({ category }) => {
  const navigate = useNavigate();

  const handlenavigation = (categoried: any) => {
    const latitude = localStorage.getItem("latitude") || "";
    const longitude = localStorage.getItem("longitude") || "";
    const queryParams = new URLSearchParams();
    const cityFromStorage = localStorage.getItem("city") || "";
    const parsedCity = JSON.parse(cityFromStorage);
    queryParams.append('city', parsedCity.city);
    if (latitude && longitude) {
      queryParams.append("lat", latitude);
      queryParams.append("long", longitude);
    }
    if (categoried.name === "Pure Veg") {
      queryParams.append(categoried.originalType, "Pure-veg");
    }
    if (categoried.name === "Non Veg") {
      queryParams.append(categoried.originalType, "Non-Veg");
    }
    else {
      queryParams.append(categoried.originalType, categoried.name);
    }
    const queryString = queryParams.toString();
    navigate(`/searchrestaurant?${queryString}`);
  }


  return (
    <div className="category-card-div justify-content-between d-flex mb-3  scrolling-wrapper">
      {category?.map((categoried: any, index: number) => (
        <div className="d-flex" key={index}>
          <Card key={index} className="category-Card d-flex align-items-end justify-content-center" onClick={() => { handlenavigation(categoried); }}>
            <div className="d-flex justify-content-center align-items-center">
              <ImageLoader className={"category-image"} src={categoried.imageurl + "?" + yeldamwebimagessas} alt={"image"} width={"auto"} height={"100px"} />
            </div>

            <div className="Category-Name-div d-flex justify-content-center mb-4  p-5">
              <p className="Category-Name"> {categoried.name}</p>

            </div>
          </Card>
        </div>
      ))}
    </div>
  );
};

export default CategoryCard;
