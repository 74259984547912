import React, { ReactNode, useEffect, useState } from "react";
import "./dietitianAdvancedSearch.pages.css";
import Navbar from "../../components/UI/Navbar/navBar.component";
import Loader from "../../components/UI/loader/loader.components";
import DietitianAdvancedSearchResults from "./../../components/DietitianAdvancedSearchResults/dietitianAdvancedSearchResults.component";
import DietitianMap from "../../components/DietitianMap/dietitianMap.component";
import DietitianFilters from "../../components/DietitianFilters/dietitianFilters.component";
import { Button } from "primereact/button";
import {
  AutoComplete,
  AutoCompleteChangeEvent,
  AutoCompleteCompleteEvent,
} from "primereact/autocomplete";
import { AdvDietitianSearch, Dietitiansearch } from "../../api/dietitians.api";
import { useLocation, useNavigate } from "react-router-dom";
import DietitianDetails from "../../components/DietitianDetails/dietitianDetails.component";
import { Skeleton } from "primereact/skeleton";
import { devsas, yeldamwebimagessas } from "../../config/constants";
import oops from "../../assets/images/oops.svg";
import { DietitianLikeProvider } from "../../components/LikeContext/dietitianlikecontext";
import { CheckDietitianLike } from "../../api/dietitians.api";
import { ProgressSpinner } from "primereact/progressspinner";

interface DietitiansInfo {
  addedby: string;
  address: string;
  imageurl: string | string[];
  agentname: string;
  // avgTotalRating: string;
  dietitianname: string;
  gender: string;
  speciality: string[];
  degree: string;
  experience: string;
  languages: string[];
  about: any[];
  services: string[];
  clinicname: string;
  clinicaddress: string;
  locality: string;
  city: string;
  district: string;
  state: string;
  country: string;
  createdAt: string;
  location: string[];
  pincode: string;
  phoneno: string;
  email: string;
  mode: string;
  fees: string;
  isLiked: boolean;
  dietitianid: string;
  reviewCount: string;
  timings: string[];
  website: string;
  socialmedia: string;
  dietitianimages: string[];
  certificationimages: string[];
  clinicimages: string[];
  updatedAt: string;
  _id: string;
}
interface Suggestions {
  type: string;
  info: DietitiansInfo;
}

const DietitianAdvancedSearch = () => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [isDialogCollapsed, setIsDialogCollapsed] = useState(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(true);
  const [showdietitian, setshowdietitian] = useState(true);
  const [showMap, setShowMap] = useState(true);
  const [showDietitianDetails, setShowDietitianDetails] = useState(false);
  const [selectedDietitian, setSelectedDietitian] = useState<any>();
  const [dietitiansdata, setdietitiansdata] = useState<DietitiansInfo[]>([]);
  const [city, setCity] = useState(localStorage.getItem("city") as string);
  const [itemskeletonLoad, setItemSkeletonLoad] = useState(false);
  const [filteredStore, setFilteredStore] = useState<any[]>();
  const [searchLoad, setSearchLoad] = useState(false);
  const [dietitianFilters, setDietitianFilters] = useState<any>({});
  const [clickedMarkerIndex, setClickedMarkerIndex] = useState<number | null>(
    null
  );
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [dietitianSpecificData, setDietitianSpecificData] = useState<any>();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    setDietitianFilters(
      JSON.parse(
        new URLSearchParams(location.search).get("dietitianfilters") as string
      ) || ""
    );
    if (window.innerWidth > 640 && window.innerWidth < 1109) {
      setIsCollapsed(true);
    }
    const handleResize = () => {
      if (window.innerWidth < 701) {
        setShowMap(false);
        setshowdietitian(true);
      } else {
        setShowMap(true);
        setshowdietitian(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleShowMap = () => {
    setShowMap(true);
    setshowdietitian(false);
  };
  const handleShowdietitian = () => {
    setShowMap(false);
    setshowdietitian(true);
  };

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
    setIsDialogCollapsed(!isDialogCollapsed);
  };
  const handleOpenToggle = () => {
    setIsCollapsed(false);
    setIsDialogCollapsed(!isDialogCollapsed);
  };
  const searchss = async (event: AutoCompleteCompleteEvent) => {
    setItemSkeletonLoad(true);
    // getSuggestions api call for search dropdown "Need to remove" *****
    const cityFromStorage = localStorage.getItem("city") || "";
    const parsedCity = JSON.parse(cityFromStorage);
    console.log("city:", parsedCity);
    const resData = await Promise.resolve(
      Dietitiansearch(parsedCity.city, event.query)
    );
    if (resData.data.data.results.length > 0) {
      setItemSkeletonLoad(false);
      setFilteredStore(
        resData.data.data.results.map((item: any) => {
          if (item.Type === "Dietitian") {
            return {
              type: item.Type,
              info: {
                id: item.info.dietitianId,
                dietitianname: item.info.dietitianname,
                locality: item.info.locality,
                imageurl:
                  Array.isArray(item.info.imageurl) &&
                    item.info.imageurl.length > 0
                    ? item.info.imageurl[0] + "?" + devsas
                    : item.info?.imageurl + "?" + yeldamwebimagessas || "", // Assuming 'devsas' is a variable with the necessary value
              },
            };
          }
          return {}; // Default empty object if none of the conditions match
        })
      );
    }

    if (resData.data.data.results.length === 0) {
      setItemSkeletonLoad(false);
      setFilteredStore([
        {
          type: "We're a bit lost here! Can you rephrase?",
          info: { dietitianname: "Oops!", imageurl: "" },
        },
      ]);
    }
  };
  const handleAutoCompleteClick = (e: any) => {
    setSearchLoad(true);
    const queryParams = new URLSearchParams();
    const cityFromStorage = localStorage.getItem("city") || "";
    const parsedCity = JSON.parse(cityFromStorage);
    setDietitianFilters({});

    // Set the selected search term
    setSelectedDietitian(e.value.info.dietitianname);
    setShowDietitianDetails(true);

    setTimeout(() => {
      if (e.value.type === "Dietitian") {
        queryParams.append("city", parsedCity.city);
        queryParams.append("type", e.value.type);
        queryParams.append("dietitianname", e.value.info.dietitianname);
        queryParams.append("id", e.value.info.id);
        const queryString = queryParams.toString();
        navigate(`/searchdietitian/?${queryString}`);
      }
    }, 500);
    setSearchLoad(false);
  };
  const handleOnKeyClick = () => {
    setSearchLoad(true);
    const queryParams = new URLSearchParams();
    const cityFromStorage = localStorage.getItem("city") || "";
    const parsedCity = JSON.parse(cityFromStorage);

    if (
      selectedDietitian === undefined ||
      selectedDietitian === "" ||
      selectedDietitian === null
    ) {
      console.log("no datata");
      setSelectedDietitian("");
      setSearchLoad(false);
    } else {
      setSearchLoad(true);
      setDietitianFilters({});
      setTimeout(() => {
        queryParams.append("city", parsedCity.city);
        queryParams.append("searchTerm", selectedDietitian);
        const queryString = queryParams.toString();
        navigate(`/searchdietitian/?${queryString}`);
      }, 500);
    }
    setSearchLoad(false);
  };

  const itemTemplate = (item: Suggestions) => {
    if (!item || !item.info) {
      return null; // Or handle the case where item or item.info is undefined/null
    }

    const imageSrc = Array.isArray(item.info.imageurl)
      ? item.info.imageurl[0]
      : item.info.imageurl;

    return (
      <>
        {!itemskeletonLoad ? (
          <div className="auto-item-div">
            {imageSrc && (
              <div className="auto-item-div-li">
                <img
                  alt={item.type}
                  src={imageSrc}
                  className="auto-item-image"
                />
              </div>
            )}
            <div className="auto-item-details-div">
              <p className="auto-item-name">
                <span>{item.info.dietitianname}</span>
              </p>
              <p className="auto-item-address mb-0">
                <span>{item.info.locality ? item.info.locality : ""}</span>
              </p>
              {item.type !== "Dietitian" && (
                <p color="#4F4F4F" className="auto-item-type">
                  <span>{item.type}</span>
                </p>
              )}
            </div>
          </div>
        ) : (
          <div className="auto-item-div">
            <div className="auto-item-div-li">
              <Skeleton className="auto-item-image" height="4rem"></Skeleton>
            </div>

            <div className="auto-item-details-div gap-2">
              <p className="auto-item-name">
                <Skeleton></Skeleton>
              </p>
              <p color="#4F4F4F" className="auto-item-type">
                <Skeleton></Skeleton>
              </p>
            </div>
          </div>
        )}
      </>
    );
  };


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const restid = params.get("id") || "";
    if (restid && dietitiansdata.length > 0) {
      detailsDialog(restid, 0);
    }
  }, [dietitiansdata]);


  const clearAllFilters = () => {
    const params = new URLSearchParams(location.search);
    params.delete("dietitianfilters");
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params}`
    );
    setDietitianFilters({});

    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox: any) => {
      checkbox.checked = false;
    });

    // Uncheck all radio buttons
    const radioButtons = document.querySelectorAll('input[type="radio"]');
    radioButtons.forEach((radioButton: any) => {
      radioButton.checked = false;
    });
    setTimeout(() => {
      const queryParams = new URLSearchParams();
      const city = localStorage.getItem("city");
      if (city) {
        const parsedCity = JSON.parse(city);
        queryParams.append("city", parsedCity.city);
      }
      const queryString = queryParams.toString();
      navigate(`/searchdietitian?${queryString}`);
    }, 0);
  };
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleFiltersUpdate = (updatedFilters: any) => {
    setDietitianFilters(updatedFilters);
  };

  const filtersArray = Object.values(dietitianFilters).flatMap((value) =>
    Array.isArray(value) ? value : [value]
  );
  const removeFilter = (keyToRemove: any, index?: number) => {
    const updatedFilters = { ...dietitianFilters };
    if (Array.isArray(updatedFilters[keyToRemove])) {
      updatedFilters[keyToRemove].splice(index, 1);
    } else {
      delete updatedFilters[keyToRemove];
    }

    setDietitianFilters(updatedFilters);
  };

  console.log("object diet selected filters", dietitianFilters);

  useEffect(() => {
    setIsLoaded(true);
    setShowDietitianDetails(false);
    const fetchData = async () => {
      const params = new URLSearchParams(location.search);
      const id = params.get("id") || "";
      const city = params.get("city") || "";
      const type = params.get("type") || "";
      const searchTerm =
        params.get("dietitianname") || params.get("searchTerm") || "";
      setSelectedDietitian(searchTerm);
      const clinicaddress = params.get("clinicaddress") || "";
      const locality = params.get("locality") || "";
      const gender = params.get("gender") || "";
      const latitude = params.get("latitude") || "";
      const longitude = params.get("longitude") || "";
      const experience = params.get("experience") || "";
      const experienceMin = params.get("experienceMin") || "";
      let experienceMax = params.get("experienceMax") || "";
      experienceMax = experienceMax.replace(/\D/g, '');
      const speciality = params.get("speciality") || "";
      const languages = params.get("languages") || "";

      try {
        const responsestoredata = await AdvDietitianSearch(
          id,
          city,
          searchTerm,
          clinicaddress,
          locality,
          gender,
          latitude,
          longitude,
          experience,
          experienceMin,
          experienceMax,
          speciality,
          languages
        );
        const data = responsestoredata.data.data;
        setdietitiansdata(responsestoredata.data.data.data);
        setIsLoaded(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoaded(false);
      }
    };
    fetchData();
  }, [location.search]);

  const detailsDialog = async (id: any, index: number) => {
    setShowDietitianDetails(true);
    console.log("detailslog: id", id);
    const data = dietitiansdata.find((item: any) => {
      return item._id == id;
    });
    console.log("detailslog: data", data);
    if (data && localStorage.getItem("accessToken") !== null) {
      const likedData = await CheckDietitianLike(id);
      console.log("likedData:", likedData)
      if (likedData && likedData.data.data.data !== undefined) {
        data.isLiked = likedData.data.data.data;
      } else {
        data.isLiked = false;
      }
    }
    console.log("detailslog: data1", data);
    setDietitianSpecificData(data);
    setClickedMarkerIndex(index);
  };
  const toggleDetails = () => {
    setShowDietitianDetails(false);

    setClickedMarkerIndex(null);
  };
  const handleMarkerClick = (index: number) => {
    setClickedMarkerIndex(index);
    setActiveIndex(0);
  };
  const [isLiked, setIsLiked] = useState(false);

  const handleLikeToggle = () => {
    setIsLiked(prevState => !prevState);
  };
  const handleClearInput = () => {
    setSelectedDietitian('');
  };

  return (
    <>
      <div className="container-fluid p-0 adv">
        <div className="row">
          <Navbar />
        </div>
        <div className="d-flex search-container">
          <div className={`p-0 dietcol2div ${isCollapsed ? "d-none" : ""}`}>
            <div className="d-flex justify-content-between align-items-center p-2">
              <span className="d-flex filterheadandcount">
                Filters{" "}
                {filtersArray.length > 0 ? (
                  <>
                    <span className=" ml-1">({filtersArray.length}) </span>
                  </>
                ) : (
                  " "
                )}
              </span>
              <i
                className="bi bi-arrow-bar-left"
                onClick={handleCollapseToggle}
              ></i>
            </div>
            <hr style={{ color: "#ececec", height: "1px", opacity: "100%" }} />
            {dietitianFilters && (
              <div className="">
                {Object.keys(dietitianFilters).length > 0 && (
                  <div className="selected-head d-flex justify-content-between align-items-center">
                    <span className="selected-filters-head">
                      Selected Filters
                    </span>
                    <span
                      className="clear-all-text"
                      onClick={clearAllFilters}
                      style={{ cursor: "pointer" }}
                    >
                      Clear All
                    </span>
                  </div>
                )}

                <div
                  className={`d-flex gap-2 scrolling-wrapper mt-2 ${dietitianFilters.length ? "filters-display-div" : ""
                    }`}
                >
                  {/* {storefilters.map((item, index) => (
                  <span className="filtered-item gap-1 ml-2 mb-2" key={index}>
                    {item}
                    <i className="pi pi-times"
                      style={{ fontSize: "12px", color: "#818181" }}
                      onClick={() => removeFilter(index)}
                    ></i>
                  </span>
                ))} */}
                  {Object.entries(dietitianFilters).map(
                    ([key, value], index) => {
                      if (Array.isArray(value)) {
                        // Render multiple items for array values
                        return value.map((item, i) => (
                          <span
                            className="filtered-item gap-1 ml-2 mb-1"
                            key={`${key}-${i}`}
                          >
                            {item}

                            <i
                              className="pi pi-times"
                              style={{
                                fontSize: "12px",
                                color: "#818181",
                                marginLeft: "4px",
                              }}
                              onClick={() => removeFilter(key)}
                            ></i>
                          </span>
                        ));
                      } else {
                        // Render single item for other values
                        return (
                          <span
                            className="filtered-item gap-1 ml-2 mb-1"
                            key={index}
                          >
                            {value as ReactNode}

                            <i
                              className="pi pi-times"
                              style={{
                                fontSize: "12px",
                                color: "#818181",
                                marginLeft: "4px",
                              }}
                              onClick={() => removeFilter(key)}
                            ></i>
                          </span>
                        );
                      }
                    }
                  )}
                </div>
              </div>
            )}
            <hr style={{ color: "#ececec", height: "1px", opacity: "100%" }} />
            <div className="p-1 width-filters-bar"
              style={{ maxHeight: "calc(100vh - 196px)", overflowY: "auto" }}
            >
              <DietitianFilters
                dietitianFilters={dietitianFilters}
                setDietitianFilters={setDietitianFilters}
                onFiltersUpdate={handleFiltersUpdate}
              />
            </div>

            <div className="fixed-bottom applydiv">
              <Button
                label="Apply"
                severity="success"
                raised
                className={`Apply-filter-btn col-5 pr-2 ${filtersArray.length > 0 ? "show-green-rest-filter" : ""
                  }`}
                onClick={() => {
                  if (filtersArray.length > 0 || selectedDietitian) {
                    const queryParams = new URLSearchParams();
                    const city = localStorage.getItem("city") as string;
                    let parsedCity = JSON.parse(city);
                    queryParams.append("city", parsedCity.city);
                    if (selectedDietitian) {
                      queryParams.append(
                        "searchTerm",
                        selectedDietitian as string
                      );
                    }
                    if (dietitianFilters["experience"]) {
                      const [experienceMin, experienceMax] = dietitianFilters["experience"][0].split(" to ");
                      queryParams.append("experienceMin", experienceMin || "");
                      queryParams.append("experienceMax", experienceMax || "");
                    }
                    queryParams.append(
                      "dietitianfilters",
                      JSON.stringify(dietitianFilters)
                    );
                    console.log("dietianFilters:");
                    queryParams.append(
                      "languages",
                      dietitianFilters["languages"] || ""
                    );
                    queryParams.append(
                      "gender",
                      dietitianFilters["gender"] || ""
                    );
                    // queryParams.append(
                    //   "experienceMin",
                    //   experienceMin || ""
                    // );
                    // queryParams.append(
                    //   "experienceMax",
                    //   experienceMax || ""
                    // );

                    queryParams.append(
                      "experience",
                      dietitianFilters["experience"] || ""
                    );
                    const queryString = queryParams.toString();
                    navigate(`/searchdietitian/?${queryString}`);
                  }
                }}
              />
            </div>
          </div>
          <div className={`p-2 dietcoldiv ${isCollapsed ? "" : "d-none"}`}>
            <div className="d-flex flex-column justify-content-center gap-4 p-0">
              <div className="d-flex justify-content-center">
                <i
                  className="bi bi-arrow-bar-right"
                  onClick={handleOpenToggle}
                ></i>
              </div>
              <div className=" d-flex justify-content-center">
                <span className="d-flex align-items-center rotate-text">
                  Filters{" "}
                  {filtersArray.length > 0 ? (
                    <>
                      <span className="">({filtersArray.length}) </span>
                    </>
                  ) : (
                    " "
                  )}
                </span>
              </div>
            </div>
          </div>
          <DietitianLikeProvider initialDietitians={dietitiansdata}>
            <div
              className={`p-0 ${!showdietitian && "Result-search-box-div"} ${window.innerWidth < 700 ? "full-width" : ""
                }`}
              style={{
                borderRight: "1px solid #dedede",
                borderLeft: "1px solid #dedede",
                maxWidth: "700px",
              }}
            >
              <div className="Dietitian-Result-search-box-row">
                <div className="mt-3">
                  <div className="flex gap-3 auto-coplete-space">
                    <div className="Result-search-box p-inputgroup flex-1 mb-2">
                      <AutoComplete
                        // onKeyUp={handleKeyPress}
                        field="name"
                        className="search-adv-input"
                        placeholder="Search dietitians"
                        style={{ fontFamily: "roboto" }}
                        value={selectedDietitian}
                        suggestions={filteredStore}
                        completeMethod={searchss}
                        itemTemplate={itemTemplate}
                        onKeyDown={(e: any) => {
                          if (e.key === "Enter") {
                            const panel = document.querySelector(
                              '.p-autocomplete-panel[data-pc-section="panel"]'
                            ) as HTMLElement | null;
                            if (panel) {
                              panel.style.display = "none";
                            }
                            handleOnKeyClick();
                          }
                        }}
                        onChange={(e: AutoCompleteChangeEvent) => {
                          setSelectedDietitian(e.value);
                          if (e.value.info) {
                            setSelectedDietitian("");
                            setSelectedDietitian(e.value);
                            handleAutoCompleteClick(e);
                          }
                        }}
                      />
                      {selectedDietitian && (
                        <i
                          className="pi pi-times searchclear"
                          onClick={handleClearInput}
                          style={{
                            cursor: 'pointer',
                            position: "relative",
                            right: "5px",
                            top: "18px",
                            zIndex: 2,
                            color: "#818181",
                            fontSize: "12px"
                          }}
                        ></i>
                      )}
                      {searchLoad ? (
                        <ProgressSpinner className="searchload-spinner" />
                      ) : (
                        <Button
                          icon="pi pi-search"
                          name="search"
                          aria-label="search"
                          className="p-button-warning"
                          onClick={() => {
                            handleOnKeyClick();
                          }}
                        />
                      )}
                    </div>
                    {/* Auto complete for dietitian search */}
                  </div>
                </div>

                {!showdietitian ? (
                  ""
                ) : (
                  <div className="Search-results-div">
                    {!isLoaded ? "" :
                      <div>
                        <div className="d-flex gap-3 align-items-center pl-3">
                          <Skeleton width="30%" height="120px" ></Skeleton>
                          <div style={{ width: "70%", height: "153px", display: "flex", flexDirection: "column", gap: '8px', justifyContent: "center" }}>
                            <Skeleton width="50%" height="10%" ></Skeleton>
                            <Skeleton width="60%" height="10%" ></Skeleton>
                            <Skeleton width="70%" height="10%" ></Skeleton>
                            <Skeleton width="80%" height="10%" ></Skeleton>
                            <Skeleton width="90%" height="10%" ></Skeleton>
                          </div>
                        </div>

                        <div className="d-flex gap-3 align-items-center pl-3">
                          <Skeleton width="30%" height="120px" ></Skeleton>
                          <div style={{ width: "70%", height: "153px", display: "flex", flexDirection: "column", gap: '8px', justifyContent: "center" }}>
                            <Skeleton width="50%" height="10%" ></Skeleton>
                            <Skeleton width="60%" height="10%" ></Skeleton>
                            <Skeleton width="70%" height="10%" ></Skeleton>
                            <Skeleton width="80%" height="10%" ></Skeleton>
                            <Skeleton width="90%" height="10%" ></Skeleton>
                          </div>
                        </div>

                        <div className="d-flex gap-3 align-items-center pl-3">
                          <Skeleton width="30%" height="120px" ></Skeleton>
                          <div style={{ width: "70%", height: "153px", display: "flex", flexDirection: "column", gap: '8px', justifyContent: "center" }}>
                            <Skeleton width="50%" height="10%" ></Skeleton>
                            <Skeleton width="60%" height="10%" ></Skeleton>
                            <Skeleton width="70%" height="10%" ></Skeleton>
                            <Skeleton width="80%" height="10%" ></Skeleton>
                            <Skeleton width="90%" height="10%" ></Skeleton>
                          </div>
                        </div>

                        <div className="d-flex gap-3 align-items-center pl-3">
                          <Skeleton width="30%" height="120px" ></Skeleton>
                          <div style={{ width: "70%", height: "153px", display: "flex", flexDirection: "column", gap: '8px', justifyContent: "center" }}>
                            <Skeleton width="50%" height="10%" ></Skeleton>
                            <Skeleton width="60%" height="10%" ></Skeleton>
                            <Skeleton width="70%" height="10%" ></Skeleton>
                            <Skeleton width="80%" height="10%" ></Skeleton>
                            <Skeleton width="90%" height="10%" ></Skeleton>
                          </div>
                        </div>

                        <div className="d-flex gap-3 align-items-center pl-3">
                          <Skeleton width="30%" height="120px" ></Skeleton>
                          <div style={{ width: "70%", height: "153px", display: "flex", flexDirection: "column", gap: '8px', justifyContent: "center" }}>
                            <Skeleton width="50%" height="10%" ></Skeleton>
                            <Skeleton width="60%" height="10%" ></Skeleton>
                            <Skeleton width="70%" height="10%" ></Skeleton>
                            <Skeleton width="80%" height="10%" ></Skeleton>
                            <Skeleton width="90%" height="10%" ></Skeleton>
                          </div>
                        </div>

                        <div className="d-flex gap-3 align-items-center pl-3">
                          <Skeleton width="30%" height="120px" ></Skeleton>
                          <div style={{ width: "70%", height: "153px", display: "flex", flexDirection: "column", gap: '8px', justifyContent: "center" }}>
                            <Skeleton width="50%" height="10%" ></Skeleton>
                            <Skeleton width="60%" height="10%" ></Skeleton>
                            <Skeleton width="70%" height="10%" ></Skeleton>
                            <Skeleton width="80%" height="10%" ></Skeleton>
                            <Skeleton width="90%" height="10%" ></Skeleton>
                          </div>
                        </div>

                        <div className="d-flex gap-3 align-items-center pl-3">
                          <Skeleton width="30%" height="120px" ></Skeleton>
                          <div style={{ width: "70%", height: "153px", display: "flex", flexDirection: "column", gap: '8px', justifyContent: "center" }}>
                            <Skeleton width="50%" height="10%" ></Skeleton>
                            <Skeleton width="60%" height="10%" ></Skeleton>
                            <Skeleton width="70%" height="10%" ></Skeleton>
                            <Skeleton width="80%" height="10%" ></Skeleton>
                            <Skeleton width="90%" height="10%" ></Skeleton>
                          </div>
                        </div>
                      </div>
                    }
                    {dietitiansdata && dietitiansdata.length > 0 ? (
                      <DietitianAdvancedSearchResults
                        dietitiansdata={dietitiansdata}
                        onClick={detailsDialog}
                        setActiveIndex={setActiveIndex}
                      />
                    ) : (
                      ""
                    )}
                    {!isLoaded && dietitiansdata.length === 0 ? (
                      <div className="d-flex justify-content-center align-items-center">
                        <img src={oops} alt="" />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </div>

            {!showMap ? (
              ""
            ) : (
              <div className="col d-flex map-results-div justify-content-center align-items-center p-0">
                <DietitianMap
                  data={dietitiansdata}
                  city={city}
                  clickedMarkerIndex={clickedMarkerIndex}
                  onMarkerClick={handleMarkerClick}
                  onClick={detailsDialog}
                />
              </div>
            )}

            {showDietitianDetails && dietitianSpecificData ? (
              <div
                className="RestdetailsDialog d-flex align-items-center justify-content-center"
                style={{
                  position: "absolute",
                  left: isDialogCollapsed ? "480px" : "680px",
                  ...(window.innerWidth >= 641 && window.innerWidth <= 1110 && {
                    left: isDialogCollapsed ? "241px" : "41px",
                  }),
                }}
              >
                <div className="RestdetailsDialog-content">
                  <div className="RestdetailsDialog-content-details">
                    <div className="RestdetailsDialog-details">
                      <DietitianDetails
                        dietitiansdata={dietitianSpecificData}
                        onClose={toggleDetails}
                        isLiked={isLiked}
                        onLikeToggle={handleLikeToggle}
                        setActiveIndex={setActiveIndex}
                        activeIndex={activeIndex}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </DietitianLikeProvider>
        </div>



        <div className="fab justify-content-evenly align-items-center gap-6">
          {!showdietitian ? (
            <Button
              className="listings"
              label="List View"
              icon="pi pi-list"
              text
              raised
              severity="success"
              onClick={() => handleShowdietitian()}
            />
          ) : (
            <Button
              className="listings"
              label="Map View"
              icon="pi pi-map"
              text
              raised
              severity="success"
              onClick={() => handleShowMap()}
            />
          )}

          <div className="separator"></div>
          <div className="col-md-2 d-flex align-items-center">
            <Button
              className="filter-listings listings"
              icon="pi pi-filter"
              text
              raised
              severity="success"
              label="Filters"
              onClick={() => navigate('/mobiledietitianfilters')}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DietitianAdvancedSearch;
