import React, { useState, useEffect, useRef } from "react";
import { Card } from "primereact/card";
import "./favStoreCard.component.css";
import { Toast } from "primereact/toast";
import "bootstrap-icons/font/bootstrap-icons.css";
// import { LikedRestaurant } from "../../../../api/ratings.api";
import { GetUserLikedStores, GetUserLikes } from "../../../../api/user.api";
import { devsas, yeldamwebimagessas } from "../../../../config/constants";
import RestaurantTimings from "../../RestaurantTimings/RestaurantTimings.component";
import FoodPreferences from "../../FoodPreferences/foodpreferences.component";
import { Rating } from "react-simple-star-rating";
import { useNavigate } from "react-router-dom";

const FavoriteStore: React.FC<any> = ({ likedStores }) => {
  const [like, setLike] = useState<boolean>(false);


  console.log("object liked stores", likedStores);

  //   const LikeFunction = async (id:string) => {
  //     if (localStorage.getItem("accessToken") === null) {
  //       setVisible(true);
  //     } else {
  //       setTimeout(async () => {
  //         setLike(!like);
  //         if (like === true) {
  //           const resData = await LikedRestaurant(id as string, false);
  //           setLike(false);
  //         }
  //         if (like === false) {
  //           const resData = await LikedRestaurant(id as string, true);
  //           setLike(true);

  //         }
  //       }, 0);
  //     }
  //   };

  //   useEffect(() => {

  //     if (ndata && ndata.length > 0) {
  //       const likesArray = ndata.map((item: any) => item.isLiked);

  //       setLike(likesArray);
  //     }
  //   }, [ndata]);

  const getDayName = () => {
    var d = new Date();
    return d.toLocaleDateString("en-in", { weekday: "long" });
  };

  //   const handleMetersChange = (meters: any) => {
  //     const metersValue = meters;
  //     const kilometersValue = (metersValue / 1000).toFixed(2);
  //     return kilometersValue;
  //   };


  const navigate = useNavigate();
  return (
    <>
      {likedStores.length === 0 ? (
        <div className="d-flex align-items-center justify-content-center flex-column mt-6 mb-6 nodata-div">
          <img style={{ height: "143px", width: "285px" }} src={'https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/about/nofavStore.webp' + "?" + yeldamwebimagessas} alt='' />
          <span className="oops mt-2">Let's build your favorite list!</span>
          <span className="no-data-found">Add some to your list for quick access to your go-to spots!</span>
          <button className="explore_btn mt-2" onClick={() =>  navigate('/organicstores')}>Explore Now</button>
        </div>

      ) : (
        <>
          <div className=" profile-rest-store-cards mb-1 mt-4">
            {likedStores.map((item: any) => (
              <Card
                key={item.storeID}
                header={
                  <div className="rest-image-div">
                    <img src={item.storeimages[0] + "?" + devsas} className="headerimg" alt="" />
                    <div className="card-icons">
                      <div className="card-like-icon">
                        <i
                          className={like ? "pi pi-heart" : "pi pi-heart-fill"}
                          onClick={() => {
                            // LikeFunction(item._id);
                          }}
                        ></i>
                      </div>
                      <div className=""></div>
                    </div>
                  </div>
                }
                className="fav-card"
              >
                <div
                  className="rest-content pt-0"
                  onClick={() => {
                    const queryParams = new URLSearchParams();
                    queryParams.append("city", item.city);
                    queryParams.append("storename", item.storename);
                    queryParams.append('id',item?._id);
                    const queryString = queryParams.toString();
                    navigate(`/searchstore?${queryString}`);
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center ">
                    <div className="Res_">{item.storename}</div>
                    <div
                      className="d-flex align-items-center"
                      style={{ whiteSpace: "nowrap", gap: "0.1rem",lineHeight:"1px" }}
                    >
                      <i
                        className="bi bi-star-fill"
                        style={{ color: "#F0C916",fontSize:"14px" }}
                      ></i>
                      <span className="rating-count">{item.averageRating}</span>
                      <span className="reviews-count">({item.reviewCount})</span>
                    </div>
                  </div>

                  <div className="Address">
                    {item.address + "," + item.city}
                  </div>
                  <div className="d-flex justify-content-between align-items-center pt-1 pb-0">
                    <RestaurantTimings timings={[JSON.parse(item.timings)]} />
                  </div>
                </div>
              </Card>
            ))}
          </div>
          <div className="mobile-view-sugg-store d-flex flex-column">
            {likedStores.map((item: any) => (
              <div>
                <Card
                  key={item.storeID}
                  className="mobile-card mt-3 mb-3"
                >
                  <div className="d-flex gap-2 align-items-center">
                    <div className="store-image-div col-lg-4 col-md-4 col-sm-4">
                      <div className="rest-image-div">
                        <img src={item.storeimages[0] + "?" + devsas} className="favstoreimg" alt="" />
                        <div className="adv-card-icons">
                          <div className="adv-card-like-icon">
                            <i
                              className={like ? "pi pi-heart" : "pi pi-heart-fill"}

                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="details-div d-flex flex-column gap-1 col-8 pt-0 pb-0 pl-0">

                      <div className="store-name">{item.storename}</div>
                      <div className="rating-review-count d-flex gap-1 align-items-center">
                        <span className="rating-value">{parseFloat(item.averageRating).toFixed(1)}</span>
                        <Rating
                          initialValue={parseFloat(item.averageRating)}
                          readonly={true}
                          transition
                          size={20}
                          allowFraction
                        />
                        <span className="review-count-value">({item.reviewCount})</span>
                      </div>
                      <div className="store-address-mob">{item.address + "," + item.city + "," + item.state + "-" + item.pincode}</div>
                      <div className="d-flex justify-content-between align-items-center pt-1 pb-0">
                        <RestaurantTimings timings={[JSON.parse(item.timings)]} />
                      </div>
                      <div className="service-options d-flex align-items-center gap-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <path
                            d="M2.25 1.5L2.25 4.93934L7.5 10.1893L10.9393 6.75L5.68934 1.5L2.25 1.5ZM1.5 1.5C1.5 1.08579 1.83579 0.75 2.25 0.75H5.68934C5.88825 0.75 6.07902 0.829018 6.21967 0.96967L11.4697 6.21967C11.7626 6.51256 11.7626 6.98744 11.4697 7.28033L8.03033 10.7197C7.73744 11.0126 7.26256 11.0126 6.96967 10.7197L1.71967 5.46967C1.57902 5.32902 1.5 5.13825 1.5 4.93934V1.5Z"
                            fill="#818181"
                          />
                          <path
                            d="M4.125 3.75C3.91789 3.75 3.75 3.58211 3.75 3.375C3.75 3.16789 3.91789 3 4.125 3C4.33211 3 4.5 3.16789 4.5 3.375C4.5 3.58211 4.33211 3.75 4.125 3.75ZM4.125 4.5C4.74632 4.5 5.25 3.99632 5.25 3.375C5.25 2.75368 4.74632 2.25 4.125 2.25C3.50368 2.25 3 2.75368 3 3.375C3 3.99632 3.50368 4.5 4.125 4.5Z"
                            fill="#818181"
                          />
                          <path
                            d="M0.75 5.31434C0.75 5.51325 0.829017 5.70402 0.969669 5.84467L6.5625 11.4375L6.53033 11.4697C6.23744 11.7626 5.76256 11.7626 5.46967 11.4697L0.219669 6.21967C0.0790171 6.07902 0 5.88825 0 5.68934V2.25C0 1.83579 0.335786 1.5 0.75 1.5V5.31434Z"
                            fill="#818181"
                          />
                        </svg>

                        {item.serviceoptions.map((option: string, index: number) => (
                          <span key={index} className="store-services">{option}</span>
                        ))}
                      </div>

                    </div>
                  </div>

                </Card>
                <hr />

              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default FavoriteStore;
