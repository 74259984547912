import { Button } from 'primereact/button';
import { ScrollPanel } from 'primereact/scrollpanel';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const MobileFilters = () => {
    const foodtype = ["Organic", "Healthy", "Traditional"];
    const preference = ["Pure Veg", "Non-Vegetarian"];
    const values = ["Service Driven", "Health Driven", "Quality Driven"];
    const diettype = ["Vegan", "Gluten-free", "Keto", "Nut Free", "Pescatarian", "Kosher", "Halal", "Dairy-free"];
    const cuisine = ["Chinese", "Italian", "Continental", "Indian", "Mexican", "Japanese", "Thai", "Mediterranean", "North Indian", "South Indian", "Chettinad", "Spanish", "Mughlai", "American", "Asian", "Arabian", "Andhra", "Bengali", "Rajasthani", "Korean", "Sichuan", "Oriental", "Burmese", "European", "French", "Tibetan", "Kashmiri", "Kolhapuri", "Gujarati", "Parsi", "Iranian", "Singaporean", "Jain", "Goan", "Manglorean"];
    const eaterytype = ["Restaurant", "Bakery", "Cafe", "Cloud Kitchen", "Tiffens", "Store", "Dhaba", "Sweets", "Deserts", "Fruit Salad", "Vegetable Salad", "Oats", "Mess", "Juices"];
    const serving = ["Tiffins", "Meals", "Buffet", "Ala Carte", "Thali"];
    const services = ["Dine in", "Take away", "Delivery"];
    const feature = ["Wifi", "Parking", "Valet Parking", "Wallet Accepted", "Cards Accepted", "Lift", "Air Conditioned"];

    const [filters, setFilters] = useState<any[]>([]);
    const [isChecked, setIsChecked] = useState(false);
    
    const onSelect = (e: any) => {
        setIsChecked(true); 
        
        if (preference.includes(e)) {
            setFilters([e]); 
        } else {
            setFilters([...filters, e]); 
        }
    }
    const onRemove = (e: any) => {

        const hasFilters = filters.filter((item: any) => e !== item).length > 0;
        setIsChecked(hasFilters);
        const newfilters = filters.filter((item: any) => e !== item);
        setFilters(newfilters);
    }

    const clearAllFilters = () => {
        setFilters([]);
    };
    const navigate = useNavigate();

    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    const isActive = (section: any) => splitLocation === section ? "nav-link fil-opt-active" : "nav-link adv-fil-opt";


    const [isPreferencesOpen, setIsPreferencesOpen] = useState(true);
    const handlePreferencesDropdownToggle = () => {
        setIsPreferencesOpen(true);
        setIsFoodTypeOpen(false);
        setIsDietTypeOpen(false);
        setIsCuisineOpen(false);
        setIsServingOpen(false);
        setIsOutletOpen(false);
        setIsValuesOpen(false);
        setIsServiceOpen(false);
        setIsFeaturesOpen(false);


    };
    const [isFoodTypeOpen, setIsFoodTypeOpen] = useState(false);
    const handleFoodTypeDropdownToggle = () => {
        setIsFoodTypeOpen(!isFoodTypeOpen);
        setIsPreferencesOpen(false);
        setIsDietTypeOpen(false);
        setIsCuisineOpen(false);
        setIsServingOpen(false);
        setIsOutletOpen(false);
        setIsValuesOpen(false);
        setIsServiceOpen(false);
        setIsFeaturesOpen(false);
    };
    const [isDietTypeOpen, setIsDietTypeOpen] = useState(false);
    const handleDietTypeDropdownToggle = () => {
        setIsDietTypeOpen(!isDietTypeOpen);
        setIsFoodTypeOpen(false);
        setIsPreferencesOpen(false);
        setIsCuisineOpen(false);
        setIsServingOpen(false);
        setIsOutletOpen(false);
        setIsValuesOpen(false);
        setIsServiceOpen(false);
        setIsFeaturesOpen(false);
    };
    const [isCuisineOpen, setIsCuisineOpen] = useState(false);
    const handleCuisineDropdownToggle = () => {
        setIsCuisineOpen(!isCuisineOpen);
        setIsFoodTypeOpen(false);
        setIsPreferencesOpen(false);
        setIsDietTypeOpen(false);
        setIsServingOpen(false);
        setIsOutletOpen(false);
        setIsValuesOpen(false);
        setIsServiceOpen(false);
        setIsFeaturesOpen(false);
    };
    const [isServingOpen, setIsServingOpen] = useState(false);
    const handleServingDropdownToggle = () => {
        setIsServingOpen(!isServingOpen);
        setIsFoodTypeOpen(false);
        setIsPreferencesOpen(false);
        setIsDietTypeOpen(false);
        setIsCuisineOpen(false);
        setIsOutletOpen(false);
        setIsValuesOpen(false);
        setIsServiceOpen(false);
        setIsFeaturesOpen(false);
    };
    const [isOutletOpen, setIsOutletOpen] = useState(false);
    const handleOutletDropdownToggle = () => {
        setIsOutletOpen(!isOutletOpen);
        setIsFoodTypeOpen(false);
        setIsPreferencesOpen(false);
        setIsDietTypeOpen(false);
        setIsCuisineOpen(false);
        setIsServingOpen(false);
        setIsValuesOpen(false);
        setIsServiceOpen(false);
        setIsFeaturesOpen(false);
    };
    const [isValuesOpen, setIsValuesOpen] = useState(false);
    const handleValuesDropdownToggle = () => {
        setIsValuesOpen(!isValuesOpen);
        setIsFoodTypeOpen(false);
        setIsPreferencesOpen(false);
        setIsDietTypeOpen(false);
        setIsCuisineOpen(false);
        setIsServingOpen(false);
        setIsOutletOpen(false);
        setIsServiceOpen(false);
        setIsFeaturesOpen(false);
    };
    const [isServiceOpen, setIsServiceOpen] = useState(false);
    const handleServiceDropdownToggle = () => {
        setIsServiceOpen(!isServiceOpen);
        setIsFoodTypeOpen(false);
        setIsPreferencesOpen(false);
        setIsDietTypeOpen(false);
        setIsCuisineOpen(false);
        setIsServingOpen(false);
        setIsOutletOpen(false);
        setIsValuesOpen(false);
        setIsFeaturesOpen(false);
    };
    const [isFeaturesOpen, setIsFeaturesOpen] = useState(false);
    const handleFeaturesDropdownToggle = () => {
        setIsFeaturesOpen(!isFeaturesOpen);
        setIsFoodTypeOpen(false);
        setIsPreferencesOpen(false);
        setIsDietTypeOpen(false);
        setIsCuisineOpen(false);
        setIsServingOpen(false);
        setIsOutletOpen(false);
        setIsValuesOpen(false);
        setIsServiceOpen(false);
    };

    
  return (
    <div className='container animate' style={{ overflow: "hidden" }}>
          <div className='filters-close-head pt-4 d-flex justify-content-between align-items-center mb-2'>
                    <span className='f-head'>Filters</span>
                    <i className="pi pi-times cls_icons"
                        onClick={() => navigate("/searchrestaurant")}
                    ></i>
                </div>
                <hr style={{color:"#ececec",opacity:"100%"}}/>
       <div className='mobile-filters'>
                    <div className='col-6 filters-options-adv p-0'>
                        <ul className='opt-menu'>

                            <li className='list-menu'>
                                <a className={`adv-fil-opt ${isActive("preferences")}`} onClick={handlePreferencesDropdownToggle} >
                                    Preference
                                </a>
                            </li>
                            <li className='list-menu'>
                                <a className={`adv-fil-opt ${isActive("foodtype")}`} onClick={handleFoodTypeDropdownToggle}>
                                    Food Type
                                </a>
                            </li>
                            <li className='list-menu'>
                                <a className={`adv-fil-opt ${isActive("values")}`} onClick={handleValuesDropdownToggle}>
                                    Values
                                </a>
                            </li>
                            <li className='list-menu'>
                                <a className={`adv-fil-opt ${isActive("diettype")}`} onClick={handleDietTypeDropdownToggle}  >
                                    Diet Type
                                </a>
                            </li>
                            <li className='list-menu'>
                                <a className={`adv-fil-opt ${isActive("cuisine")}`} onClick={handleCuisineDropdownToggle} >
                                    Cuisine
                                </a>
                            </li>
                            <li className='list-menu'>
                                <a className={`adv-fil-opt ${isActive("service")}`} onClick={handleServiceDropdownToggle}>
                                    Service
                                </a>
                            </li>
                            <li className='list-menu'>
                                <a className={`adv-fil-opt ${isActive("features")}`} onClick={handleFeaturesDropdownToggle}>
                                    Features & Facilities
                                </a>
                            </li>
                            <li className='list-menu'>
                                <a className={`adv-fil-opt ${isActive("outlet")}`} onClick={handleOutletDropdownToggle}>
                                    Outlet
                                </a>
                            </li>
                            <li className='list-menu'>
                                <a className={`adv-fil-opt ${isActive("serving")}`} onClick={handleServingDropdownToggle}>
                                    Serving
                                </a>
                            </li>
                        </ul>
                    </div>
                    <ScrollPanel style={{ width: "100%", height: "calc(100vh - 130px)" }} >
                        <div className='pl-3 pt-3'>

                            {isPreferencesOpen && (
                                <div className="submenu p-0 mt-3 mb-3 gap-3 preferences-submenu">
                                    {preference.map((e: any, index) => (
                                        <div key={index} className="">
                                            <label className="d-flex gap-2">
                                                <input
                                                    className='check-wrapper'
                                                    type="radio"
                                                    id={`custom-checkbox-${index}`}
                                                    name="feature"
                                                    // checked={tags.includes(e)}
                                                    // onChange={(filterName)=>handleChange(filterName,"preference")}
                                                    onChange={(event) => event.target.checked ? onSelect(e) : onRemove(e)}
                                                    value={e}
                                                />
                                                <span className="advfilterItemData">
                                                    {e}
                                                </span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}


                            {isFoodTypeOpen && (
                                <div className="submenu mt-3 mb-3 p-0 gap-3  foodtype-submenu">
                                    {foodtype.map((e: any, index) => (
                                        <div key={index} className="filter-item">
                                            <label className="d-flex gap-2">
                                                <input
                                                    className='check-wrapper'
                                                    type="checkbox"
                                                    id={`custom-checkbox-${index}`}
                                                    name="feature"
                                                    // checked={tags.includes(e)}
                                                    // onChange={(filterName) => handleChange(filterName, "foodtype")}
                                                    onChange={(event) => event.target.checked ? onSelect(e) : onRemove(e)}
                                                    value={e}
                                                />
                                                <span className="advfilterItemData">
                                                    {e}
                                                </span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {isValuesOpen && (
                                <div className="submenu mt-3 mb-3 p-0 gap-3  foodtype-submenu">
                                    {values.map((e: any, index) => (
                                        <div key={index} className="filter-item">
                                            <label className="d-flex gap-2">
                                                <input
                                                    className='check-wrapper'
                                                    type="checkbox"
                                                    id={`custom-checkbox-${index}`}
                                                    name="feature"
                                                    // checked={tags.includes(e)}
                                                    // onChange={(filterName) => handleChange(filterName, "foodtype")}
                                                    onChange={(event) => event.target.checked ? onSelect(e) : onRemove(e)}
                                                    value={e}
                                                />
                                                <span className="advfilterItemData">
                                                    {e}
                                                </span>
                                            </label>
                                        </div>
                                    ))}
                                </div>

                            )}

                            {isDietTypeOpen && (
                                <div className="submenu mt-3 mb-3 p-0 gap-3 diettype-submenu">
                                    {diettype.map((e: any, index) => (
                                        <div key={index} className="filter-item">
                                            <label className="d-flex gap-2">
                                                <input
                                                    className='check-wrapper'
                                                    type="checkbox"
                                                    id={`custom-checkbox-${index}`}
                                                    name="feature"
                                                    // checked={tags.includes(e)}
                                                    value={e}
                                                    onChange={(event) => event.target.checked ? onSelect(e) : onRemove(e)}
                                                // onChange={(filterName) => handleChange(filterName, "diettype")}
                                                />
                                                <span className='advfilterItemData'>
                                                    {e}
                                                </span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {isCuisineOpen && (
                                <div className="submenu mt-3 mb-3 p-0 gap-3 cuisine-submenu">
                                    {cuisine.map((e: any, index) => (
                                        <div key={index} className="filter-item">
                                            <label className="d-flex gap-2">
                                                <input
                                                    className='check-wrapper'
                                                    type="checkbox"
                                                    id={`custom-checkbox-${index}`}
                                                    name="feature"
                                                    // checked={tags.includes(e)}
                                                    value={e}
                                                    onChange={(event) => event.target.checked ? onSelect(e) : onRemove(e)}
                                                // onChange={(filterName) => handleChange(filterName, "cuisine")}
                                                />
                                                <span className="advfilterItemData">
                                                    {e}
                                                </span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {isServiceOpen && (
                                <div className="submenu mt-3 mb-3 p-0 gap-3 service-submenu">
                                    {services.map((e: any, index) => (
                                        <div key={index} className="filter-item">
                                            <label className="d-flex gap-2">
                                                <input
                                                    className='check-wrapper'
                                                    type="checkbox"

                                                    id={`custom-checkbox-${index}`}
                                                    name="feature"
                                                    // checked={tags.includes(e)}
                                                    value={e}
                                                    onChange={(event) => event.target.checked ? onSelect(e) : onRemove(e)}
                                                // onChange={(filterName) => handleChange(filterName, "serviceoptions")}
                                                />
                                                <span className="advfilterItemData">
                                                    {e}
                                                </span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {isFeaturesOpen && (
                                <div className="submenu mt-3 mb-3 p-0 gap-3 features-submenu">
                                    {feature.map((e: any, index) => (
                                        <div key={index} className="filter-item">
                                            <label className="d-flex gap-2">
                                                <input
                                                    className='check-wrapper'
                                                    type="checkbox"
                                                    id={`custom-checkbox-${index}`}
                                                    name="feature"
                                                    // checked={tags.includes(e)}
                                                    value={e}
                                                    onChange={(event) => event.target.checked ? onSelect(e) : onRemove(e)}
                                                // onChange={(filterName) => handleChange(filterName, "features")}
                                                />
                                                <span className="advfilterItemData">
                                                    {e}
                                                </span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {isServingOpen && (
                                <div className="submenu mt-3 mb-3 p-0 gap-3 serving-submenu">
                                    {serving.map((e: any, index) => (
                                        <div key={index} className="filter-item">
                                            <label className="d-flex gap-2">
                                                <input
                                                    className='check-wrapper'
                                                    type="checkbox"
                                                    id={`custom-checkbox-${index}`}
                                                    name="feature"
                                                    // checked={tags.includes(e)}
                                                    value={e}
                                                    onChange={(event) => event.target.checked ? onSelect(e) : onRemove(e)}
                                                // onChange={(filterName) => handleChange(filterName, "serving")}
                                                />
                                                <span className="advfilterItemData">
                                                    {e}
                                                </span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {isOutletOpen && (
                                <div className="submenu mt-3 mb-3 p-0 gap-3 outlet-submenu">
                                    {eaterytype.map((e: any, index) => (
                                        <div key={index} className="filter-item">
                                            <label className="d-flex gap-2">
                                                <input
                                                    className='check-wrapper'
                                                    type="checkbox"
                                                    id={`custom-checkbox-${index}`}
                                                    name="feature"
                                                    // checked={tags.includes(e)}
                                                    value={e}
                                                    onChange={(event) => event.target.checked ? onSelect(e) : onRemove(e)}
                                                // onChange={(filterName) => handleChange(filterName, "eaterytype")}
                                                />
                                                <span className="advfilterItemData">
                                                    {e}
                                                </span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </ScrollPanel>

                </div>
                <hr style={{color:"#ececec",opacity:"100%"}}/>
                <div className='buttons-two-adv-filters'>
                    <Button
                        className="clr-btn-adv-filter"
                        label="Clear All"
                        severity="success"
                        onClick={clearAllFilters}
                    />
                    <Button
                        className={isChecked ? "show-green-rest-filter" : "show-rest-adv-filter"}
                        label="Show Restaurants"
                        severity="success"
                        // onClick={() => navigate(`/searchrestaurant?${queryString}`)}
                        onClick={() => navigate("/searchrestaurant")}
                    />


                </div>
    </div>
  );
}

export default MobileFilters;
