import React, { useRef, useState } from 'react';
import { Card } from "primereact/card";
import { Toast } from 'primereact/toast';
import { useNavigate } from 'react-router-dom';
import { devsas } from '../../../../config/constants';
import RestaurantTimings from '../../RestaurantTimings/RestaurantTimings.component';
import ImageLoader from '../../loader/imageloader.component';
import Confetti from 'canvas-confetti';
import { LikeStore } from '../../../../api/ratings.api';
import SignInSignUp from '../../SigninSignup/signInSignUp.component';
import { Dialog } from 'primereact/dialog';

const RecommendedStoreCard: React.FC<any> = ({
  setNvisible, storeRecData
}) => {

  const [like, setLike] = useState<boolean>(false);
  const toast = useRef<Toast>(null);
  const navigate = useNavigate();
  const [signinvisible, setSigninVisible] = useState<boolean>(false);

  const [likedItems, setLikedItems] = useState<string[]>([]);
  const [ndataa, setNdata] = useState<any>(storeRecData);

  const triggerConfetti = () => {
    Confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 },
    });
  };
  const LikeFunction = async (id:string, isLiked:boolean, isApiLiked:boolean) => {
    // Check if the user is authenticated
    if (!localStorage.getItem("accessToken")) {
      setSigninVisible(true);
      return;
    }
  
    let newLikedStatus = isApiLiked ? false : !isLiked;
    try {
      // Optimistically update UI
      console.log(newLikedStatus ? "liked" : "not liked");
      if (newLikedStatus) {
        // triggerConfetti();
        setLikedItems([...likedItems, id]);
      } else {
        setLikedItems(likedItems.filter((itemId) => itemId !== id));
      }
      setNdata((prevNdata:any) =>
        prevNdata.map((item:any) =>
          item._id === id ? { ...item, isLiked: newLikedStatus } : item
        )
      );
  
      // Make API Call
      const resData = await LikeStore(id, newLikedStatus);
    } catch (error) {
      console.error("Error:", error);
      // Revert UI changes on error (optional)
      if (newLikedStatus) {
        setLikedItems(likedItems.filter((itemId) => itemId !== id));
      } else {
        setLikedItems([...likedItems, id]);
      }
      setNdata((prevNdata:any) =>
        prevNdata.map((item:any) =>
          item._id === id ? { ...item, isLiked: !newLikedStatus } : item
        )
      );
    }
  };
  

  return (
    <div className="d-flex scrolling-wrapper justify-content-between rest-card-detail gap-4 card_main">
      {storeRecData?.slice(0, 4)?.map((item: any, i: any) => (
        <div className="" key={"recstore" + item._id}>
          <Card header={
            <div className="rest-image-div ">
              <>
                <ImageLoader className={"headerimg"} src={item.thumbnailimages[0] + "?" + devsas} alt={"image"} width={"100%"} height={"160px"}/>
              </>
              <div className="card-icons">
                  <div className="card-like-icon pt-1">
                    <i
                     className={likedItems.includes(item._id) || item.isLiked ? "pi pi-heart-fill cuisine-like-icon" : "pi pi-heart"}
                     onClick={() => {
                       LikeFunction(item._id, likedItems.includes(item._id), item.isLiked);
                     }}
                    ></i>
                  </div>
                </div>
            </div>
          } className="near-rest-card">
            <div>
              {/* <img src={restimg} style={{width:'100%',height:'180px'}}/> */}
            </div>
            <div
              className="near-store-content"
              onClick={() => {
                const queryParams = new URLSearchParams();
                queryParams.append('city', item.city);
                queryParams.append('storename', item.storename);
                queryParams.append('id', item._id);
                const queryString = queryParams.toString();
                navigate(`/searchstore?${queryString}`);
              }}
            >

              <div className="d-flex justify-content-between align-items-center mb-1">
                <div className="Res_">{item.storename}</div>
                <div
                  className="d-flex align-items-center"
                  style={{ whiteSpace: "nowrap", gap: "0.1rem" }}
                >
                  <i
                    className="bi bi-star-fill mr-1"
                    style={{ color: "#F0C916", fontSize: "14px" }}
                  ></i>
                  <span className="rating-count">{item.rating}</span>
                  <span className="reviews-count">({item.reviewCount})</span>
                </div>
              </div>

              <div className="Address mb-1">{item.address}</div>

              <div className="d-flex justify-content-between align-items-center mb-1  pb-0">
                <RestaurantTimings timings={[JSON.parse(item.timings)]} />
              </div>
              <div className="service-options d-flex align-items-center gap-1">

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M2.25 1.5L2.25 4.93934L7.5 10.1893L10.9393 6.75L5.68934 1.5L2.25 1.5ZM1.5 1.5C1.5 1.08579 1.83579 0.75 2.25 0.75H5.68934C5.88825 0.75 6.07902 0.829018 6.21967 0.96967L11.4697 6.21967C11.7626 6.51256 11.7626 6.98744 11.4697 7.28033L8.03033 10.7197C7.73744 11.0126 7.26256 11.0126 6.96967 10.7197L1.71967 5.46967C1.57902 5.32902 1.5 5.13825 1.5 4.93934V1.5Z"
                    fill="#818181"
                  />
                  <path
                    d="M4.125 3.75C3.91789 3.75 3.75 3.58211 3.75 3.375C3.75 3.16789 3.91789 3 4.125 3C4.33211 3 4.5 3.16789 4.5 3.375C4.5 3.58211 4.33211 3.75 4.125 3.75ZM4.125 4.5C4.74632 4.5 5.25 3.99632 5.25 3.375C5.25 2.75368 4.74632 2.25 4.125 2.25C3.50368 2.25 3 2.75368 3 3.375C3 3.99632 3.50368 4.5 4.125 4.5Z"
                    fill="#818181"
                  />
                  <path
                    d="M0.75 5.31434C0.75 5.51325 0.829017 5.70402 0.969669 5.84467L6.5625 11.4375L6.53033 11.4697C6.23744 11.7626 5.76256 11.7626 5.46967 11.4697L0.219669 6.21967C0.0790171 6.07902 0 5.88825 0 5.68934V2.25C0 1.83579 0.335786 1.5 0.75 1.5V5.31434Z"
                    fill="#818181"
                  />
                </svg>

                {item.servicetype?.map((option: any, index: any, array: any) => (
                  <span key={index} className="near-store-service">
                    {index > 0 && ", "}
                    {index < 2 ? option : null}
                    {index === 2 && array.length > 2 ? "..." : null}
                  </span>
                ))}

              </div>


            </div>
          </Card>
        </div>
      ))}
        <Dialog
        visible={signinvisible}
        closeOnEscape={false}
        // style={{ width: "50vw" }}
        className="login-model"
        // breakpoints={{
        //   "1920px": "50vw",
        //   "1420px": "50vw",
        //   "1120px": "50vw",
        //   "1090px": "40vw",
        //   "980px": "50vw",
        //   "840px": "60vw",
        //   "740px": "50vw",
        //   "641px": "70vw",
        //   "541px": "70vw",
        //   "490px": "80vw",
        //   "350px": "90vw",
        // }}
        onHide={() => setSigninVisible(false)}
      >
        <SignInSignUp setVisible={setSigninVisible} />
      </Dialog>
    </div>
  );
}

export default RecommendedStoreCard;
