import React, { useEffect, useState } from 'react';
import Navbar from '../../components/UI/Navbar/navBar.component';
import Footer from '../../components/UI/Footer/footer.component';
import { yeldamwebimagessas } from '../../config/constants';
import "./whyYeldam.pages.css";
import trusted from "../../assets/icons/trusted.svg";
import seamless from "../../assets/icons/seamless.svg";
import culinary from "../../assets/icons/culinary.svg";
import organicpicks from "../../assets/icons/organicpicks.svg";
import recommendations from "../../assets/icons/recommendation.svg";
import dailywellness from "../../assets/icons/dailywellness.svg";
import person from "../../assets/icons/person-fill.svg"
import { Skeleton } from 'primereact/skeleton';
import ImageLoader from '../../components/UI/loader/imageloader.component';

const WhyYeldam = () => {

    const [city, setCity] = useState<string>("");
    const [list, setList] = useState<string[]>([]);
    const [search, setSearch] = useState<string[]>([]);
    const [showImage, setShowImage] = useState(false);
    const [activeIndex, setActiveIndex] = useState<number>(1);




    useEffect(() => {
        const timer = setTimeout(() => {
            setShowImage(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const scrollToTestimonials = (index: number) => {
        setActiveIndex(index);
        const testimonialDiv = document.getElementById(`testimonial${index}`);
        if (testimonialDiv) {
            testimonialDiv.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="container-fluid p-0">
            <div className="row">
                <Navbar />
            </div>

            <div className="container internal-screen" style={{ minHeight: "calc(100vh - 50px)" }}>
                <div className="IFE-heading d-flex justify-content-between align-items-center">
                    <div className="Food-Events-heading">Why Yeldam?</div>
                    <div className="d-flex align-items-center FE-heading-nav">
                        <a href="/" className="d-flex">
                            {" "}
                            <span className="home-span">Home</span>
                        </a>
                        <i
                            className="bi bi-chevron-right"
                            style={{ color: "#C2C2C2", fontSize: "12px" }}
                        ></i>
                        <span className="event-span">Why Yeldam?</span>
                    </div>
                </div>
                <div className="why-yeldam-Body flex-column d-flex gap-5 mb-6">
                    <div className='why-yeldam-division1 d-flex justify-content-between'>
                        <div className='our-story-img col-lg-4 col-md-4'>
                            <ImageLoader src={"https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/about/ostory.webp" + "?" + yeldamwebimagessas} alt={"image"} width={"95%"} height={"320px"} />

                        </div>
                        <div className='col-lg-5 col-md-5 our-story-textarea d-flex flex-column'>
                            <p className='our-story-text'>Our Story</p>
                            <p className='our-story-text-head'>Nourishing Lives, One Bite at a Time. Your Gateway
                                to Healthier Eating in a World of Endless Choices.
                            </p>
                            <p className='our-story-para1'>In a global landscape dominated by unhealthy food habits,
                                Yeldam emerges as a revolutionary force, reshaping the way we approach nourishment.
                                With a mission to make healthier eating accessible and enjoyable, Yeldam serves
                                as your guide in navigating the diverse culinary world.</p>

                            <p className='our-story-para1'>
                                As individuals grapple with the consequences of poor dietary choices, Yeldam steps in as
                                a beacon of choice, offering a user-friendly interface and a vast selection of quality
                                restaurants. Recognizing the importance of reliable information, Yeldam ensures that
                                all details on its platform are meticulously curated and regularly updated. The user
                                experience is paramount, and the interface is designed to cater to individual preferences,
                                making restaurant selection a breeze.
                            </p>
                        </div>
                        <div className='text-img-why col-lg-3 col-md-3 d-flex align-items-center justify-content-end'>

                            <ImageLoader src={"https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/whyyeldam/whyyeldamtextimg.webp" + "?" + yeldamwebimagessas} alt={"image"} width={"95%"} height={"193px"} />

                        </div>

                    </div>
                    <div>
                        <div className='why-yeldam-division2 mb-4'>
                            <p className='why-yeldam-text'>Why Yeldam?</p>
                            <p className='why-people-choose-text m-0'>Why People Choose Us?</p>
                        </div>
                        <div className='why-yeldam-division3 d-flex gap-2 justify-content-between align-items-start'>
                            <div className='col-lg-2 col-md-2 trusted-div d-flex flex-column justify-content-center'>
                                <div className='d-flex justify-content-center mb-1'> <img src={trusted} alt='' /></div>
                                <p className='division3-heads'>
                                    Trusted Selection
                                </p>
                                <p className='division3-content'>
                                    Discover curated restaurants meeting our high health standards.
                                </p>

                            </div>
                            <div className='col-lg-2 col-md-2 seamless-div d-flex flex-column  justify-content-center'>
                                <div className='d-flex justify-content-center mb-1'>  <img src={seamless} alt='' /></div>
                                <p className='division3-heads'>
                                    Seamless Experience
                                </p>
                                <p className='division3-content'>
                                    Navigate effortlessly with our user-friendly interface, making healthy choices easy.
                                </p>

                            </div>
                            <div className='col-lg-2 col-md-2 culinary-divd-flex flex-column justify-content-center'>
                                <div className='d-flex justify-content-center mb-1'>  <img src={culinary} alt='' /> </div>
                                <p className='division3-heads'>
                                    Culinary Celebrations
                                </p>
                                <p className='division3-content'>
                                    Stay informed about exciting food events for delightful experiences.
                                </p>

                            </div>
                            <div className='col-lg-2 col-md-2 organic-pics-div d-flex flex-column  justify-content-center align-items-center'>
                                <div className='d-flex justify-content-center mb-1'> <img src={organicpicks} alt='' /> </div>
                                <p className='division3-heads'>
                                    Organic Picks
                                </p>
                                <p className='division3-content'>
                                    Effortlessly find top organic stores with our user-friendly interface.
                                </p>

                            </div>
                            <div className='col-lg-2 col-md-2 recommendations-div d-flex flex-column  justify-content-center'>
                                <div className='d-flex justify-content-center mb-1'> <img src={recommendations} alt='' /> </div>
                                <p className='division3-heads'>
                                    Recommendations
                                </p>
                                <p className='division3-content'>
                                    Yeldam learns your preferences, delivering restaurant recommendations.
                                </p>

                            </div>
                            <div className='col-lg-2 col-md-2 daily-wellness-div d-flex flex-column justify-content-center'>
                                <div className='d-flex justify-content-center mb-1'> <img src={dailywellness} alt='' /> </div>
                                <p className='division3-heads'>
                                    Daily Wellness Tips
                                </p>
                                <p className='division3-content'>
                                    Yeldam provides quick, insightful health tips for your overall well-being.
                                </p>

                            </div>

                        </div>
                    </div>
                    <div className='why-yeldam-division4 d-flex'>
                        <div className='division4-content col-lg-6 col-md-6'>
                            <p className='our-story-text'>
                                Elevate Your Lifestyle
                            </p>
                            <p className='our-story-text-head'>
                                Discover nourishing eateries, organic treasures, daily health insights,
                                and culinary events—all effortlessly united in one intuitive platform.
                            </p>
                            <p className='our-story-para1' >
                                Enter Yeldam, your lifestyle elevation hub. Navigate seamlessly through nourishing restaurants, organic finds, daily health wisdom, and exciting culinary events. Our user-friendly interface streamlines restaurant searches, presenting a carefully curated selection adhering to high health standards. Dive into an organic haven effortlessly, locating stores that resonate with your choices.
                            </p>
                            <p className='our-story-para1'>
                                Yeldam transcends, delivering daily health tips for a harmonious life and keeping you in the loop about vibrant food events. It's not just an app; it's your key to a curated, health-focused journey. Embrace Yeldam—where lifestyle elevation meets convenience in one unified experience.
                            </p>
                        </div>
                        <div className='division4-image col-lg-6 col-md-6 '>

                            <ImageLoader src={"https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/whyyeldam/elevatestoryimg.webp" + "?" + yeldamwebimagessas} alt={"image"} width={"100%"} height={"325px"} />

                        </div>
                        <div className='division4-image-mobile'>

                            <ImageLoader src={"https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/whyyeldam/elevationmobileimg.webp" + "?" + yeldamwebimagessas} alt={"image"} width={"100%"} height={"280px"} />

                        </div>

                    </div>
                    <div className='why-yeldam-division5 '>
                        <p className='why-yeldam-text'>Testimonials</p>
                        <p className='why-people-choose-text'>What Our Users Say</p>
                    </div>

                    <div>
                        <div className='why-yeldam-division6 scrolling-wrapper gap-2 d-flex'>


                            <div id='testimonial1' className='testimonial col '>
                                <p className='testimonial-text'>
                                    In my fast-paced life, Yeldam is a lifesaver. Quick restaurant searches,
                                    easy organic store finds, and practical health tips—all in one place.
                                    It simplifies my health-conscious choices
                                </p>
                                <div className='test-user-info d-flex align-items-center gap-3'>
                                    <div className='test-user-icon d-flex justify-content-center align-items-center'>
                                        <img src={person} />
                                    </div>
                                    <div className='test-user-name-area d-flex flex-column'>
                                        <p className='test-user-name'>Anita R</p>
                                        <p className='test-user-area'>Busy Bee</p>

                                    </div>

                                </div>

                            </div>
                            <div id='testimonial2' className='testimonial col'>
                                <p className='testimonial-text'>
                                    Yeldam makes my organic lifestyle a breeze. Finding stores with organic
                                    choices is effortless, and the daily health tips add that extra dose
                                    of well-being. Truly indispensable!
                                </p>
                                <div className='test-user-info d-flex align-items-center gap-3'>
                                    <div className='test-user-icon d-flex justify-content-center align-items-center'>
                                        <img src={person} />
                                    </div>
                                    <div className='test-user-name-area d-flex flex-column'>
                                        <p className='test-user-name'>Amit P</p>
                                        <p className='test-user-area'>Organic Enthusiast</p>

                                    </div>

                                </div>

                            </div>
                            <div id='testimonial3' className='testimonial col'>
                                <p className='testimonial-text'>
                                    For someone on a health journey, Yeldam is gold. The curated restaurant options,
                                    detailed info, and community reviews make every choice a healthy one.
                                    Highly recommended!
                                </p>
                                <div className='test-user-info d-flex align-items-center gap-3'>
                                    <div className='test-user-icon d-flex justify-content-center align-items-center'>
                                        <img src={person} />
                                    </div>
                                    <div className='test-user-name-area d-flex flex-column'>
                                        <p className='test-user-name'>Vikram K</p>
                                        <p className='test-user-area'>Health Seeker</p>

                                    </div>

                                </div>

                            </div>
                            <div id='testimonial4' className='testimonial col '>
                                <p className='testimonial-text'>
                                    Yeldam is my fitness buddy! From discovering healthy eats to daily health tips,
                                    it keeps my wellness journey on track. It's the perfect blend of simplicity and health wisdom.
                                </p>
                                <div className='test-user-info d-flex align-items-center gap-3'>
                                    <div className='test-user-icon d-flex justify-content-center align-items-center'>
                                        <img src={person} />
                                    </div>
                                    <div className='test-user-name-area d-flex flex-column'>
                                        <p className='test-user-name'>Rajesh S</p>
                                        <p className='test-user-area'>Fitness Fanatic</p>

                                    </div>

                                </div>

                            </div>
                            <div id='testimonial5' className='testimonial col '>
                                <p className='testimonial-text'>
                                    Yeldam is a culinary gem! It guides me to delightful restaurants and keeps me posted
                                    on food events. A must-have for anyone who loves good food and good times!
                                </p>
                                <div className='test-user-info d-flex align-items-center gap-3'>
                                    <div className='test-user-icon d-flex justify-content-center align-items-center'>
                                        <img src={person} />
                                    </div>
                                    <div className='test-user-name-area d-flex flex-column'>
                                        <p className='test-user-name'>Priya M</p>
                                        <p className='test-user-area'>Culinary Explorer</p>

                                    </div>

                                </div>

                            </div>
                            <div id='testimonial6' className='testimonial col '>
                                <p className='testimonial-text'>
                                    Yeldam is my foodie sidekick! It takes me on culinary adventures with its
                                    diverse restaurant options. The food events feature adds that extra spice
                                    to my dining experiences. Love it!
                                </p>
                                <div className='test-user-info d-flex align-items-center gap-3'>
                                    <div className='test-user-icon d-flex justify-content-center align-items-center'>
                                        <img src={person} />
                                    </div>
                                    <div className='test-user-name-area d-flex flex-column'>
                                        <p className='test-user-name'>Meera D</p>
                                        <p className='test-user-area'>Foodie Explorer</p>

                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className='d-flex gap-2 justify-content-center mt-4'>
                            <i className="pi pi-circle-fill" style={{ fontSize: '8px', color: activeIndex === 1 ? '#36a41d' : '#dedede' }} onClick={() => scrollToTestimonials(1)}></i>
                            <i className="pi pi-circle-fill" style={{ fontSize: '8px', color: activeIndex === 4 ? '#36a41d' : '#dedede' }} onClick={() => scrollToTestimonials(4)}></i>
                            <i className="pi pi-circle-fill" style={{ fontSize: '8px', color: activeIndex === 6 ? '#36a41d' : '#dedede' }} onClick={() => scrollToTestimonials(6)}></i>
                        </div>
                    </div>

                </div>
            </div>

            <div className="row">
                <Footer localities={list} city={city} search={search} type={"restaurant"} />
            </div>
        </div>
    );
}

export default WhyYeldam;
