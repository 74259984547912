import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import "./healthTipsCard.component.css";
import { yeldamwebimagessas } from "../../../../config/constants";
import { Skeleton } from 'primereact/skeleton';
import ImageLoader from "../../loader/imageloader.component";

const HealthTipsCard: React.FC<any> = ({ alltips, tips }) => {

  const [visible, setvisible] = useState(false);


  useEffect(() => {
      const timer = setTimeout(() => {
        setvisible(true);
      }, 1000);

      return () => clearTimeout(timer);
  }, []);

  return (
    <div className="scrolling-wrapper d-flex align-items-start mb-view justify-content-flex-start  mb-5">
      <div className="healthtips-card-div d-flex gap-4">
        {tips?.slice(0, 4).map((tip: any, index: any) => (
          <Card
            key={index} // Moved key attribute to Card component
            header={
              <>
           
   <ImageLoader className={"headerimg"} src={tip.tipimages+"?"+yeldamwebimagessas} alt={"image"} width={"100%"} height={"160px"}/>

            </>

            }
            className="healthtips-card"
          >
            <div className="internalTipCard p-0">
              <div className="TipHead">
              {!visible ? (
                <Skeleton width="100%" className="mb-2" ></Skeleton>
            ) : (
               <span> {tip.tipname} </span>
                )}
                </div>
              <div className="TipData">
              {!visible ? (
                <Skeleton width="100%" ></Skeleton>
            ) : (
                <span>{tip.description}</span>
                )}
                </div>
            </div>
          </Card>
        ))}
        {alltips?.map((tip: any, index: any) => (
          <Card
            key={index} // Moved key attribute to Card component
            header={
              <>
              {!visible ? (
                <Skeleton width="258px" height="176px" ></Skeleton>
            ) : (
              <img alt="Card" className="headerimg" src={tip.tipimages+"?"+yeldamwebimagessas} />
            )}
            </>
            }
            className="allhealthtips-card"
          >
            <div className="internalTipCard p-0">
            <div className="TipHead">
              {!visible ? (
                <Skeleton width="100%" className="mb-2" ></Skeleton>
            ) : (
               <span> {tip.tipname} </span>
                )}
                </div>
                <div className="TipData">
              {!visible ? (
                <Skeleton width="100%" ></Skeleton>
            ) : (
                <span>{tip.description}</span>
                )}
                </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default HealthTipsCard; // Renamed component to follow naming conventions
