import React, { useEffect, useState } from "react";
import "./healthTips.pages.css";
import Navbar from "../../components/UI/Navbar/navBar.component";
import HealthTipsCard from "../../components/UI/Cards/HealthTipsCard/healthTipsCard.component";
import { HealthTipsdata } from "../../api/home.api";
import Footer from "../../components/UI/Footer/footer.component";
import { ScrollPanel } from "primereact/scrollpanel";
import Loader from "../../components/UI/loader/loader.components";

interface Tip {
  tipname: string;
  description: string;
  tipimages: string;
}
const HealthTips: React.FC<any> = () => {
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [alltips, setTips] = useState<Tip[]>([]);
  const [city, setCity] = useState<string>("");
  const [list, setList] = useState<string[]>([]);
  const [search, setSearch] = useState<string[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(true);

  const tipsdata = async (tipCategory: string) => {
    try {
      const response = await HealthTipsdata(tipCategory);
      setTips(response.data.data);
    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {

      }
    }
  };


  useEffect(() => {
    setIsLoaded(true);
    const delayedFetch = () => {
      setTimeout(() => {
        tipsdata(selectedCategory);
        setIsLoaded(false);
      }, 500);
    };
    delayedFetch();
  }, [selectedCategory]);

  const handleCategoryChange = (category: string) => {
    setSelectedCategory(category);
  };
  return (
    <div className="container-fluid p-0" style={{overflow:"hidden"}}>
      <div className="row">
        <Navbar />
      </div>
            <div className="container internal-screen" style={{minHeight: "calc(100vh - 50px)"}}>
        <div className="IFE-heading d-flex justify-content-between align-items-center">
          <div className="Food-Events-heading">Health Tips</div>
          <div className="d-flex align-items-center FE-heading-nav">
            <a href="/" className="d-flex">
              {" "}
              <span className="home-span">Home</span>
            </a>
            <i
              className="bi bi-chevron-right"
              style={{ color: "#C2C2C2", fontSize: "12px" }}
            ></i>
            <span className="event-span">Health Tips</span>
          </div>
        </div>
        <div className="Tips-Body d-flex gap-3 mb-6 align-items-start">
          <div className="col-lg-3 col-md-3 Tips-filters">
            <div className="d-flex">
              <ul className="pt-4 pl-3 pr-3 d-flex flex-column gap-3">
                <li
                  className="d-flex align-items-center gap-1 tip-listitem "
                  onChange={() => handleCategoryChange("")}
                >
                  <input
                    type="radio"
                    id="tip-id"
                    name="tip-name"
                    value="tipvalue"
                    checked={selectedCategory === ""}
                  />
                  <label>All</label>
                </li>
                <li
                  className="d-flex align-items-center gap-1 tip-listitem"
                  onChange={() => handleCategoryChange("Diabetes")}
                >
                  <input
                    type="radio"
                    id="tip-id"
                    name="tip-name"
                    value="tipvalue"
                    checked={selectedCategory === "Diabetes"}
                  />
                  <label>Diabetes</label>
                </li>
                <li
                  className="d-flex align-items-center gap-1 tip-listitem"
                  onChange={() => handleCategoryChange("Cardio vascular")}
                >
                  <input
                    type="radio"
                    id="tip-id"
                    name="tip-name"
                    value="tipvalue"
                    checked={selectedCategory === "Cardio vascular"}
                  />
                  <label>Cardiovascular Disease</label>
                </li>
                <li
                  className="d-flex align-items-center gap-1 tip-listitem"
                  onChange={() => handleCategoryChange("Hypertension")}
                >
                  <input
                    type="radio"
                    id="tip-id"
                    name="tip-name"
                    value="tipvalue"
                    checked={selectedCategory === "Hypertension"}
                  />
                  <label>Hypertension (High Blood Pressure)</label>
                </li>
                <li
                  className="d-flex align-items-center gap-1 tip-listitem"
                  onChange={() => handleCategoryChange("Obesity")}
                >
                  <input
                    type="radio"
                    id="tip-id"
                    name="tip-name"
                    value="tipvalue"
                    checked={selectedCategory === "Obesity"}
                  />
                  <label>Obesity</label>
                </li>
                <li
                  className="d-flex align-items-center gap-1 tip-listitem"
                  onChange={() => handleCategoryChange("Asthama")}
                >
                  <input
                    type="radio"
                    id="tip-id"
                    name="tip-name"
                    value="tipvalue"
                    checked={selectedCategory === "Asthama"}
                  />
                  <label>Asthma</label>
                </li>
                <li
                  className="d-flex align-items-center gap-1 tip-listitem"
                  onChange={() => handleCategoryChange("Cancer")}
                >
                  <input
                    type="radio"
                    id="tip-id"
                    name="tip-name"
                    value="tipvalue"
                    checked={selectedCategory === "Cancer"}
                  />
                  <label>Cancer (General Prevention)</label>
                </li>
                <li
                  className="d-flex align-items-center gap-1 tip-listitem"
                  onChange={() =>
                    handleCategoryChange("Depression and Anxiety")
                  }
                >
                  <input
                    type="radio"
                    id="tip-id"
                    name="tip-name"
                    value="tipvalue"
                    checked={selectedCategory === "Depression and Anxiety"}
                  />
                  <label>Depression and Anxiety</label>
                </li>
                <li
                  className="d-flex align-items-center gap-1 tip-listitem"
                  onChange={() =>
                    handleCategoryChange("Chronic Kidney Disease")
                  }
                >
                  <input
                    type="radio"
                    id="tip-id"
                    name="tip-name"
                    value="tipvalue"
                    checked={selectedCategory === "Chronic Kidney Disease"}
                  />
                  <label>Chronic Kidney Disease</label>
                </li>
                <li
                  className="d-flex align-items-center gap-1 tip-listitem"
                  onChange={() =>
                    handleCategoryChange("Gastrointestinal Disorder")
                  }
                >
                  <input
                    type="radio"
                    id="tip-id"
                    name="tip-name"
                    value="tipvalue"
                    checked={selectedCategory === "Gastrointestinal Disorder"}
                  />
                  <label>Gastrointestinal Disorders</label>
                </li>
                <li
                  className="d-flex align-items-center gap-1 tip-listitem"
                  onChange={() => handleCategoryChange("Rheumatoid Arthritis")}
                >
                  <input
                    type="radio"
                    id="tip-id"
                    name="tip-name"
                    value="tipvalue"
                    checked={selectedCategory === "Rheumatoid Arthritis"}
                  />
                  <label>Rheumatoid Arthritis</label>
                </li>
              </ul>
            </div>
          </div>
          <div className="tip-filter-mobile-options ">
            <select
              className="form-select"
              id="tip-category-select"
              value={selectedCategory}
              onChange={(event) => handleCategoryChange(event.target.value)}
            >
              <option value="">All</option>
              <option value="Diabetes">Diabetes</option>
              <option value="Cardio vascular">Cardiovascular Disease</option>
              <option value="Hypertension">
                Hypertension (High Blood Pressure)
              </option>
              <option value="Obesity">Obesity</option>
              <option value="Asthama">Asthma</option>
              <option value="Cancer">Cancer (General Prevention)</option>
              <option value="Depression and Anxiety">
                Depression and Anxiety
              </option>
              <option value="Chronic Kidney Disease">
                Chronic Kidney Disease
              </option>
              <option value="Gastrointestinal Disorder">
                Gastrointestinal Disorders
              </option>
              <option value="Rheumatoid Arthritis">Rheumatoid Arthritis</option>
            </select>
          </div>
          <div className="col-lg-9 col-md-9 Tips-Tips  desktop-view">
            {!isLoaded ? "" : <Loader />}
            <ScrollPanel className="tips-scroll-behaviour">
              <HealthTipsCard alltips={alltips} />
            </ScrollPanel>
          </div>
          <div className=" mobile-view">
            {!isLoaded ? "" : <Loader />}
            <HealthTipsCard alltips={alltips} />
          </div>
        </div>
      </div>
      <div className="row">
        <Footer localities={list} city={city} search={search} type={"restaurant"} />
      </div>
    </div>
  );
};

export default HealthTips;
