import React, { useEffect, useRef, useState } from 'react';
import "./addStore.pages.css";
import Navbar from '../../components/UI/Navbar/navBar.component';
import Footer from '../../components/UI/Footer/footer.component';
import { InputText } from "primereact/inputtext";
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from 'primereact/button';
import getlocation from "../../assets/icons/getlocation.svg"
import { Dropdown } from 'primereact/dropdown';
import DropFileinput from '../../components/UI/DropFileInput/dropFileinput.component';
import { Checkbox } from "primereact/checkbox";
import { yeldamwebimagessas } from '../../config/constants';
import { Dialog } from 'primereact/dialog';
import subtract from "../../assets/icons/Subtract.svg";
import { SubmitUserOrganicStore } from '../../api/user.api';
import { Controller, useForm } from "react-hook-form";
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';

type storeObj = {

    storename: string;
    address: string;
    street: string;
    city: string;
    state: string;
    district: string;
    pincode: string;
    mobileno: string;
    images: any[];
    storetype: string[];
    serviceoptions: string[];
};
const initialState = {

    storename: "",
    address: "",
    street: "",
    city: "",
    state: "",
    district: "",
    pincode: "",
    mobileno: "",
    images: [],
    storetype: [],
    serviceoptions: [],
};

const AddStore: React.FC<any> = () => {
    const [storeObj, setStoreObj] = useState<storeObj>(initialState);
    const [storeImages, setStoreImages] = useState<any[]>([]);
    const [search, setSearch] = useState<string[]>([]);
    const [city, setCity] = useState("");
    const [list, setList] = useState<string[]>([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [counter, setCounter] = useState(5);
    const [countdownActive, setCountdownActive] = useState(false);
    const toast = useRef<Toast>(null);

    const ResetForm = () => {
        console.log("resetting the form");
        window.location.reload();
        setStoreObj(initialState);
    };


    const SubmitUserStoreObj = async () => {
        const show = (message: any, severity: any, summary: any) => {
            console.log("ouside of toast");
            if (toast.current) {
                console.log("going in toast");
                toast.current.show({
                    severity: severity,
                    summary: summary,
                    detail: message,
                });
            } else {
                console.log("toast issue");
            }
        };

        const excludedFields = ['storetype', 'serviceoptions'];

        const emptyFields = Object.entries(storeObj).filter(([key, value]) => {
            if (excludedFields.includes(key)) {
                return false; // Exclude fields from validation
            }
            if (Array.isArray(value)) {
                return value.length === 0;
            } else {
                return typeof value !== 'string' || value.trim() === '';
            }
        });

        if (emptyFields.length > 0) {
            setVisible(false);
            setCountdownActive(false);
            show("Please check all Mandatory filed", "warn", "Warn");
            return;
        }

        const formData = new FormData();
        formData.append('storename', storeObj.storename);
        formData.append('address', storeObj.address);
        formData.append('street', storeObj.street);
        formData.append('city', storeObj.city);
        formData.append('state', storeObj.state);
        formData.append('district', storeObj.district);
        formData.append('pincode', storeObj.pincode);
        formData.append('mobileno', storeObj.mobileno);

        if (storeObj.storetype.length > 0) {
            storeObj.storetype.forEach(type => {
                formData.append('storetype', type);
            });
        }

        if (storeObj.serviceoptions.length > 0) {
            storeObj.serviceoptions.forEach(option => {
                formData.append('serviceoptions', option);
            });
        }

        storeImages.forEach((image, index) => {
            formData.append(`images`, image);
        });

        try {
            const response = await SubmitUserOrganicStore(formData);
            if (response.status === 201) {
                console.log("Response of stores:", response.data);
                setVisible(true);
                setCountdownActive(true);
                setTimeout(() => {
                    ResetForm();
                }, 2000);
            } else {
                setVisible(false);
                setCountdownActive(false);
            }
        } catch (error) {
            console.error("Error submitting store details:", error);
            setVisible(false);
            setCountdownActive(false);
            show("Please check all Mandatory filed", "warn", "Warn");
        }
    };



    console.log("storeeee", storeObj);

    const [storetype, setStoretype] = useState<string[]>([]);
    const Storetypes = (e: any) => {
        let _Storetypes = [...storetype];

        if (e.checked) _Storetypes.push(e.value);
        else _Storetypes.splice(_Storetypes.indexOf(e.value), 1);

        setStoretype(_Storetypes);
        setStoreObj({ ...storeObj, storetype: _Storetypes });
    };

    const [serviceoptions, setServiceoptions] = useState<string[]>([]);
    const serviceoption = (e: any) => {
        let _serviceoptions = [...serviceoptions];

        if (e.checked) _serviceoptions.push(e.value);
        else _serviceoptions.splice(_serviceoptions.indexOf(e.value), 1);

        setServiceoptions(_serviceoptions);
        setStoreObj({ ...storeObj, serviceoptions: _serviceoptions });
    };

    useEffect(() => {
        let countdown: any;
        if (countdownActive && counter > 0) {
            countdown = setTimeout(() => setCounter(counter - 1), 1000);
        } else if (counter === 0) {
            setCountdownActive(false);
        }

        return () => clearTimeout(countdown);
    }, [counter, countdownActive]);

    useEffect(() => {
        if (counter === 0) {
            window.location.href = "/";
        }
    });
    console.log("count", counter);

    const defaultValues = {
        storename: "",
        street: "",
        address: "",
        city: "",
        district: "",
        state: "",
        pincode: "",
        phoneNumber: ""
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        // getValues,
        reset,
    } = useForm({ defaultValues });

    const getFormErrorMessage = (name: string) => {
        const errorObject = errors[name as keyof typeof defaultValues];

        return errorObject ? (
            <small className="p-error">{errorObject.message}</small>
        ) : (
            <small className="p-error">&nbsp;</small>
        );
    };




    return (
        <>
            <div className="container-fluid add-body-div p-0">

                <Toast  ref={toast}></Toast>

                <div className="row">
                    <Navbar />
                </div>
                <div className='col-lg-12 d-flex container gap-3 pt-4 pb-4'>
                    <div className='col-lg-5 left-image-div d-flex justify-content-center align-items-center'>
                        <img className='add-store-image' src={"https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/Homepage/Add-Restaurant/AddStore.webp" + "?" + yeldamwebimagessas} alt='' />
                    </div>
                    <div className='col-lg-7'>
                        <div className='restaurant-details-box pt-3'>
                            <div className='headhr'>
                               <span className='details-head-text'>Organic Store Details</span> 
                                <hr style={{ color: "#ececec", height: "1px", opacity: "100%",marginTop:"16px" }} />
                            </div>
                            
                            <div className='scrolling'>
                                <ScrollPanel style={{ width: "100%", height: "75vh" }}>
                                    <div className='restaurant-details-entry'>

                                        <div className="row gap-2 ">
                                            <label className="get-input-label">
                                                Organic Store Name {" "}
                                                <b style={{ color: "red" }}>*</b>
                                            </label>
                                            <div className="flex flex-column email-entry">
                                                <Controller
                                                    name="storename"
                                                    control={control}
                                                    rules={{
                                                        required: "OrganicStore Name is required.",
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            <InputText
                                                                id="Name"
                                                                className={`input-area ${classNames({ "p-invalid": fieldState.error })}`}
                                                                placeholder="Enter Organic Store Name"
                                                                aria-describedby="username-help"
                                                                value={storeObj.storename}
                                                                onChange={(e) => {
                                                                    setStoreObj({
                                                                        ...storeObj,
                                                                        storename: e.target.value,
                                                                    });
                                                                    field.onChange(e);
                                                                }}
                                                            />
                                                            {getFormErrorMessage("name")}
                                                        </>
                                                    )}
                                                />
                                            </div>

                                        </div>

                                        {/* <div className="row gap-2">
                                            <div className=" d-flex justify-content-center align-items-center get-address mb-1">
                                                <img className='location-icon' src={getlocation} alt='' />
                                                <Button className="" label="Use My Current Location" severity="success" outlined aria-label="Search"

                                                />
                                            </div>
                                        </div>

                                        <div className="d-flex justify-content-center align-items-center">
                                            <hr className="flex-grow-1" />
                                            <span className="mx-2 or">or</span>
                                            <hr className="flex-grow-1" />
                                        </div> */}

                                        <div className="row gap-2">
                                            <label className="get-input-label">
                                                Flat / Building {" "}
                                                <b style={{ color: "red" }}>*</b>
                                            </label>
                                            <div className="flex flex-column email-entry">
                                                <Controller
                                                    name="address"
                                                    control={control}
                                                    rules={{
                                                        required: "Flat/Building is required.",
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            <InputText
                                                                id="Address"
                                                                className={`input-area ${classNames({ "p-invalid": fieldState.error })}`}
                                                                placeholder="Enter Flat or Building"
                                                                aria-describedby="address-help"
                                                                value={storeObj.address}
                                                                onChange={(e) => {
                                                                    setStoreObj({
                                                                        ...storeObj,
                                                                        address: e.target.value,
                                                                    });
                                                                    field.onChange(e);
                                                                }}
                                                            />
                                                            {getFormErrorMessage("address")}
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>


                                        <div className="row gap-2">
                                            <label className="get-input-label">
                                                Area / Street / Sector {" "}
                                                <b style={{ color: "red" }}>*</b>
                                            </label>
                                            <div className="flex flex-column email-entry">
                                                <Controller
                                                    name="street"
                                                    control={control}
                                                    rules={{
                                                        required: "Area/Street/Sector is required.",
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            <InputText
                                                                id="Street"
                                                                className={`input-area ${classNames({ "p-invalid": fieldState.error })}`}
                                                                placeholder="Enter Area or Street or Sector"
                                                                aria-describedby="street-help"
                                                                value={storeObj.street}
                                                                onChange={(e) => {
                                                                    setStoreObj({
                                                                        ...storeObj,
                                                                        street: e.target.value,
                                                                    });
                                                                    field.onChange(e);
                                                                }}
                                                            />
                                                            {getFormErrorMessage("street")}
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-6 p-0 pr-2">
                                                <div className="flex flex-column gap-2">
                                                    <label className="get-input-label">
                                                        City <b style={{ color: "red" }}>*</b>
                                                    </label>
                                                    <div className="flex flex-column email-entry">
                                                        <Controller
                                                            name="city"
                                                            control={control}
                                                            rules={{
                                                                required: "City is required.",
                                                            }}
                                                            render={({ field, fieldState }) => (
                                                                <>
                                                                    <InputText
                                                                        id="city"
                                                                        className={`input-area ${classNames({ "p-invalid": fieldState.error })}`}
                                                                        placeholder="Enter City"
                                                                        value={storeObj.city}
                                                                        onChange={(e) => {
                                                                            setStoreObj({
                                                                                ...storeObj,
                                                                                city: e.target.value,
                                                                            });
                                                                            field.onChange(e);
                                                                        }}
                                                                    />
                                                                    {getFormErrorMessage("city")}
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 p-0 pl-2">
                                                <div className=" d-flex flex-column gap-2">
                                                    <label className="get-input-label">
                                                        State <b style={{ color: "red" }}>*</b>
                                                    </label>
                                                    <div className="flex flex-column email-entry">
                                                        <Controller
                                                            name="state"
                                                            control={control}
                                                            rules={{
                                                                required: "State is required.",
                                                            }}
                                                            render={({ field, fieldState }) => (
                                                                <>
                                                                    <InputText
                                                                        id="state"
                                                                        className={`input-area ${classNames({ "p-invalid": fieldState.error })}`}
                                                                        placeholder="Enter State"
                                                                        value={storeObj.state}
                                                                        onChange={(e) => {
                                                                            setStoreObj({
                                                                                ...storeObj,
                                                                                state: e.target.value,
                                                                            });
                                                                            field.onChange(e);
                                                                        }}
                                                                    />
                                                                    {getFormErrorMessage("state")}
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-6 p-0 pr-2">
                                                <div className="d-flex flex-column gap-2">
                                                    <label className="get-input-label">
                                                        District <b style={{ color: "red" }}>*</b>
                                                    </label>
                                                    <div className="flex flex-column email-entry">
                                                        <Controller
                                                            name="district"
                                                            control={control}
                                                            rules={{
                                                                required: "District is required.",
                                                            }}
                                                            render={({ field, fieldState }) => (
                                                                <>
                                                                    <InputText
                                                                        id="district"
                                                                        className={`input-area ${classNames({ "p-invalid": fieldState.error })}`}
                                                                        placeholder="Enter District"
                                                                        value={storeObj.district}
                                                                        onChange={(e) => {
                                                                            setStoreObj({
                                                                                ...storeObj,
                                                                                district: e.target.value,
                                                                            });
                                                                            field.onChange(e);
                                                                        }}
                                                                    />
                                                                    {getFormErrorMessage("district")}
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 p-0 pl-2">
                                                <div className="flex flex-column gap-2">
                                                    <label className="get-input-label">
                                                        Pincode <b style={{ color: "red" }}>*</b>
                                                    </label>
                                                    <div className="flex flex-column email-entry">
                                                        <Controller
                                                            name="pincode"
                                                            control={control}
                                                            rules={{
                                                                required: "Pincode is required.",
                                                            }}
                                                            render={({ field, fieldState }) => (
                                                                <>
                                                                    <InputText
                                                                        id="pincode"
                                                                        className={`input-area ${classNames({ "p-invalid": fieldState.error })}`}
                                                                        placeholder="Enter Pincode"
                                                                        value={storeObj.pincode}
                                                                        maxLength={6}
                                                                        keyfilter={/^[0-9]*$/}
                                                                        onChange={(e) => {
                                                                            setStoreObj({
                                                                                ...storeObj,
                                                                                pincode: e.target.value,
                                                                            });
                                                                            field.onChange(e);
                                                                        }}
                                                                    />
                                                                    {getFormErrorMessage("pincode")}
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row gap-2">
                                            <label className="get-input-label">
                                                Phone Number {" "}
                                                <b style={{ color: "red" }}>*</b>
                                            </label>
                                            <div className="flex flex-column email-entry">
                                                <Controller
                                                    name="phoneNumber"
                                                    control={control}
                                                    rules={{
                                                        required: "Phone Number is required.",
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            <InputText
                                                                id="mobileno"
                                                                className={`input-area ${classNames({ "p-invalid": fieldState.error })}`}
                                                                placeholder="Enter Phone Number"
                                                                aria-describedby="mobileno-help"
                                                                value={storeObj.mobileno}
                                                                keyfilter={/^[0-9\+-]$/}
                                                                maxLength={14}
                                                                onChange={(e) => {
                                                                    setStoreObj({
                                                                        ...storeObj,
                                                                        mobileno: e.target.value,
                                                                    });
                                                                    field.onChange(e);
                                                                }}
                                                            />
                                                            {getFormErrorMessage("mobileno")}
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="row gap-2 ">
                                            <label className="get-input-label ">
                                                Type{" "}

                                            </label>
                                            <div className="row d-flex type-checkbox">
                                                <div className="col-3">
                                                    <div className="flex align-items-center gap-2">
                                                        <Checkbox
                                                            inputId="storetype1"
                                                            name="storetype"
                                                            value="Groceries"
                                                            onChange={Storetypes}
                                                            checked={storetype.includes("Groceries")}
                                                        />
                                                        <label htmlFor="preference1" className="ml-2 service-opt-label">
                                                            Groceries
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="flex align-items-center">
                                                        <Checkbox
                                                            inputId="storetype2"
                                                            name="storetype"
                                                            value="Vegetables"
                                                            onChange={Storetypes}
                                                            checked={storetype.includes("Vegetables")}
                                                        />
                                                        <label htmlFor="preference2" className="ml-2 service-opt-label">
                                                            Vegetables
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className="col-3">
                                                    <div className="flex align-items-center">
                                                        <Checkbox
                                                            inputId="storetype3"
                                                            name="storetype"
                                                            value="Fruits"
                                                            onChange={Storetypes}
                                                            checked={storetype.includes("Fruits")}
                                                        />
                                                        <label htmlFor="storetype3" className="ml-2 service-opt-label">
                                                            Fruits
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="flex align-items-center">
                                                        <Checkbox
                                                            inputId="storetype3"
                                                            name="storetype"
                                                            value="Dairy"
                                                            onChange={Storetypes}
                                                            checked={storetype.includes("Dairy")}
                                                        />
                                                        <label htmlFor="storetype4" className="ml-2 service-opt-label">
                                                            Dairy
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="flex align-items-center">
                                                        <Checkbox
                                                            inputId="storetype5"
                                                            name="storetype"
                                                            value="Nuts"
                                                            onChange={Storetypes}
                                                            checked={storetype.includes("Nuts")}
                                                        />
                                                        <label htmlFor="storetype5" className="ml-2 service-opt-label">
                                                            Nuts
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row gap-1">
                                            <label className="get-input-label mt-3">
                                                Service Options{" "}

                                            </label>
                                            <div className="row d-flex service-options">
                                                <div className="col-4">
                                                    <div className="flex align-items-center gap-2">
                                                        <Checkbox
                                                            inputId="service1"
                                                            name="servicestype"
                                                            value="Delivery"
                                                            onChange={serviceoption}
                                                            checked={serviceoptions.includes("Delivery")}
                                                        />
                                                        <label htmlFor="service1" className="ml-2 service-opt-label">
                                                            Delivery
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="flex align-items-center">
                                                        <Checkbox
                                                            inputId="service2"
                                                            name="servicestype"
                                                            value="In-store Shopping"
                                                            onChange={serviceoption}
                                                            checked={serviceoptions.includes("In-store Shopping")}
                                                        />
                                                        <label htmlFor="service2" className="ml-2 service-opt-label">
                                                            In-store Shopping
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="flex align-items-center">
                                                        <Checkbox
                                                            inputId="service3"
                                                            name="servicestype"
                                                            value="In-store Pickup"
                                                            onChange={serviceoption}
                                                            checked={serviceoptions.includes("In-store Pickup")}
                                                        />
                                                        <label htmlFor="service3" className="ml-2 service-opt-label">
                                                            In-store Pickup
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row gap-2 pt-3'>
                                            <label className="get-input-label">
                                                Upload Images {" "}
                                                <b style={{ color: "red" }}>*</b>
                                            </label>
                                            <div>
                                                <DropFileinput
                                                    uploadedFiles={storeImages}
                                                    setUploadedFiles={setStoreImages}
                                                    resObj={storeObj}
                                                    setResObj={setStoreObj}
                                                    purpose="StoreImages"
                                                />
                                            </div>

                                        </div>

                                    </div>
                                </ScrollPanel>
                            </div>
                            <div className='d-flex justify-content-end add-rest-user-submit'>
                                <Button className="addrest_submit_btn" label="Submit" onClick={() => {
                                    SubmitUserStoreObj()

                                }} severity="success" outlined aria-label="Search"

                                />
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <Footer localities={list} city={city} search={search} type={"organicstore"} />
                </div>

            </div>

            <div className='dialog-box'>
                <Dialog
                    visible={visible}
                    closeOnEscape={false}
                    header="Event Details"
                    style={{ width: "30vw" }}
                    className="login-model"
                    breakpoints={{
                        "1920px": "50vw",
                        "1420px": "50vw",
                        "1120px": "50vw",
                        "1090px": "40vw",
                        "980px": "50vw",
                        "840px": "60vw",
                        "740px": "50vw",
                        "641px": "70vw",
                        "541px": "70vw",
                        "490px": "80vw",
                        "350px": "90vw",
                    }}
                    onHide={() => setVisible(false)}
                >
                    <div className="d-flex flex-column justify-content-center align-items-center pt-6">
                        <img className="thank-up-img mb-3" src={subtract} alt="" />
                        <p className="Thank-you">Thank You</p>
                        <p className="thank-down-content">
                            Your organic store suggestion has been submitted. We will review it and add it to our website as soon as possible.                        </p>
                        <p className="redirect">
                            Auto redirection to home page in{" "}
                            <span style={{ color: "#36a41d" }}>{counter} seconds</span>
                        </p>
                    </div>

                </Dialog>
            </div>
        </>
    );
}

export default AddStore;
