import { Avatar } from 'primereact/avatar';
import { ScrollPanel } from 'primereact/scrollpanel';
import React, { useState } from 'react';
import { Rating } from 'react-simple-star-rating';
import { yeldamwebimagessas } from '../../config/constants';
import "./storeReviews.component.css";
import norevstore from "../../assets/images/norevstore.svg"
import { Dialog } from 'primereact/dialog';
import SignInSignUp from '../UI/SigninSignup/signInSignUp.component';
import { ProgressBar } from 'primereact/progressbar';


type props = {
  reviewslist: string[];
  count: string[];
  setVisible: any
}

const StoreReviews: React.FC<props> = ({ reviewslist, count, setVisible }) => {
  const [signinvisible, setSigninVisible] = useState<boolean>(false);

  function timeSince(date: any) {
    var seconds = Math.floor((Date.now() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  };

  const AddReview = () => {
    if (localStorage.getItem("accessToken") === null) {
      setSigninVisible(true);
    } else {
      setVisible(true);
    }
  };

  return (
    <div className="container p-0">
      {/* Ratings */}
      <div>
        {count.map((item: any, index) => (
          <div key={index} className="rating-dietitian-box d-flex flex-column gap-3">
            <div className="five-start d-flex flex-column gap-3">
              <div className="d-flex col-12 p-0 justify-content-between align-items-center">
                <div className="rating-value-number align-items-center d-flex col-1 p-0">{item.rating} <i className="pi pi-star-fill ml-1" style={{ fontSize: "0.8rem", color: "#F0C916" }}></i></div>
                <ProgressBar
                  value={item.percentage}
                  className="p-progressbar"
                ></ProgressBar>
                <div className="perc col-1 py-1 px-0">{item.percentage}%</div>
              </div>
            </div>
          </div>
        ))}
        <hr className="btm-line-rating" />
      </div>

      {/* Reviews */}

      <div className="row">
        <div className="Main">
          <ScrollPanel
            style={{
              width: "100%",
              height: "350px",
              paddingLeft: "10px",
            }}
            className="custombar2"
          >
            <>
              {reviewslist.length === 0 ? (
                <div className="d-flex align-items-center justify-content-center flex-column mt-4 gap-3 nodata-div">
                  <img src={norevstore} alt='' />
                  <button className="norev-write-btn" onClick={() => AddReview()}>Write a review</button>
                </div>
              ) : (
                <div>
                  {reviewslist?.map((item: any, index: number) => {
                    return (
                      <>
                        <div className="">
                          <div className=" Restaurant-review">
                            <div className="col-12 d-flex align-items-start p-0">
                              <div className="col-2 p-0">
                                <Avatar
                                  image={item.image as string}
                                  className="Review-image"
                                  shape="circle"
                                />
                              </div>
                              <div className="col-10 d-flex flex-column review-text p-0">
                                <div className="d-flex justify-content-between p-0 align-items-center">
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      fontFamily: "rubik",
                                    }}
                                  >
                                    {item.firstname +
                                      " " +
                                      item.lastname}
                                  </span>
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "400",
                                      fontFamily: "roboto",
                                    }}
                                  >
                                    {timeSince(
                                      new Date(item.createdAt)
                                    ) + " ago"}
                                  </span>
                                </div>
                                <div className="pb-2">
                                  <Rating
                                    initialValue={parseFloat(item.totalrating)}
                                    readonly={true}
                                    transition
                                    size={15}
                                    allowFraction
                                  />
                                </div>
                                <p
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    textAlign: "justify",
                                  }}
                                >
                                  {item.review}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="mb-3"></hr>
                      </>
                    );
                  })}
                </div>
              )}
            </>

          </ScrollPanel>
        </div>
      </div>
      <Dialog
        visible={signinvisible}
        closeOnEscape={false}
        // style={{ width: "50vw" }}
        className="login-model"
        // breakpoints={{
        //   "1920px": "50vw",
        //   "1420px": "50vw",
        //   "1120px": "50vw",
        //   "1090px": "40vw",
        //   "980px": "50vw",
        //   "840px": "60vw",
        //   "740px": "50vw",
        //   "641px": "70vw",
        //   "541px": "70vw",
        //   "490px": "80vw",
        //   "350px": "90vw",
        // }}
        onHide={() => setSigninVisible(false)}
      >
        <SignInSignUp setVisible={setSigninVisible} />
      </Dialog>
    </div>
  );
}

export default StoreReviews;
