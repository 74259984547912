import { Button } from 'primereact/button';
import { ScrollPanel } from 'primereact/scrollpanel';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const MobileDietitianFilters: React.FC<any> = ({ onFiltersUpdate}) => {
  const languages = ["Telugu", "Hindi", "Tamil", "English"];
  const gender = ["Male", "Female"];
  const experience = ["1 to 5 Years", "5 to 10 Years", "10 to 15 Years", "15 to 20 Years", "20+ Years"];
  const [isChecked, setIsChecked] = useState(false);
  const [filters, setFilters] = useState<any[]>([]);
  const location = useLocation();
  const [dietitianfilters, setDietitianFilters] = useState<any>(
    JSON.parse(new URLSearchParams(location.search).get("dietitianfilters") as string) ||
    ""
  );

  const updateFilters = (key: any, value: any) => {
    setDietitianFilters((prevFilters: any) => {
      const updatedFilters = { ...prevFilters };

      if (key === "experience") {
        const [experienceMin, experienceMax] = value.split(" to ");
        updatedFilters["experienceMin"] = experienceMin;
        updatedFilters["experienceMax"] = experienceMax;
      } else if (key === "gender") {
        updatedFilters[key] = value;
      } else {
        if (updatedFilters[key] && !updatedFilters[key].includes(value)) {
          updatedFilters[key] = [...updatedFilters[key], value];
        } else {
          updatedFilters[key] = [value];
        }
      }
      // onFiltersUpdate(updatedFilters);
      return updatedFilters;
    });
  };


  const removeFilter = (key: string, valueToRemove: string) => {
    setDietitianFilters((prevFilters: any) => {
      const updatedFilters = { ...prevFilters };

      if (updatedFilters[key]) {
        if (key === "gender") {
          updatedFilters[key] = "";
        } else {
          updatedFilters[key] = updatedFilters[key].filter(
            (value: string) => value !== valueToRemove
          );
          if (updatedFilters[key].length === 0) {
            delete updatedFilters[key];
          }
        }
      }

      return updatedFilters;
    });
  };

  const onSelect = (e: any, type: any) => {
    updateFilters(type, e);
    setIsChecked(true);
  };

  const navigate = useNavigate();

  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const isActive = (section: any) =>
    splitLocation[1] === section
      ? "nav-link nav-link-active"
      : "nav-link signup";

  const [isLanguageOpen, SetIslanguageOpen] = useState(true);
  const handleLanguagedropdownToggle = () => {
    SetIslanguageOpen(true);
    setIsgenderOpen(false);
    setIsexpOpen(false);


  };
  const [isGenderOpen, setIsgenderOpen] = useState(false);
  const handleGenderdropdownToggle = () => {
    setIsgenderOpen(!isGenderOpen);
    setIsexpOpen(false);
    SetIslanguageOpen(false);

  };
  const [isExpOpen, setIsexpOpen] = useState(false);
  const handleexpdropdownToggle = () => {
    setIsexpOpen(!isExpOpen);
    SetIslanguageOpen(false);
    setIsgenderOpen(false);
  }

  const clearAllFilters = () => {
    setDietitianFilters({});

    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox: any) => {
      checkbox.checked = false;
    });

    const radioButtons = document.querySelectorAll('input[type="radio"]');
    radioButtons.forEach((radioButton: any) => {
      radioButton.checked = false;
    });
  };

  return (
    <div className='container animate' style={{ overflow: "hidden" }}>
      <div className='filters-close-head pt-4 d-flex justify-content-between align-items-center mb-2'>
        <span className='f-head'>Filters</span>
        <i className="pi pi-times cls_icons"
          onClick={() => navigate("/searchdietitian")}
        ></i>
      </div>
      <hr style={{ color: "#ececec", opacity: "100%" }} />
      <div className='mobile-filters'>
        <div className='col-6 filters-options-adv p-0'>
          <ul className='opt-menu'>
            <li className='list-menu'>
              <a className={`adv-fil-opt ${isActive("languages")}`} onClick={handleLanguagedropdownToggle} >
                Languages
              </a>
            </li>
            <li className='list-menu'>
              <a className={`adv-fil-opt ${isActive("gender")}`} onClick={handleGenderdropdownToggle}>
                Gender
              </a>
            </li>
            <li className='list-menu'>
              <a className={`adv-fil-opt ${isActive("experience")}`} onClick={handleexpdropdownToggle}>
                Experience
              </a>
            </li>
          </ul>
        </div>
        <ScrollPanel style={{ width: "100%", height: "calc(100vh - 130px)" }} >
          <div className='pl-3 pt-3'>
            {isLanguageOpen && (
              <div className="submenu mt-3 mb-3 p-0 gap-3  foodtype-submenu">
                {languages.map((e: any, index) => (
                  <div key={index} className="filter-item">
                    <label className="d-flex gap-2">
                      <input
                        className='check-wrapper'
                        type="checkbox"
                        id={`custom-checkbox-${index}`}
                        name="feature"
                        checked={
                          dietitianfilters.languages && dietitianfilters.languages.includes(e)
                        }
                        onChange={(event) =>
                          event.target.checked
                            ? onSelect(e, "languages")
                            : removeFilter("languages", e)
                        }
                        value={e}
                      />
                      <span className="advfilterItemData">
                        {e}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            )}

            {isGenderOpen && (
              <div className="submenu p-0 mt-3 mb-3 gap-3 service-submenu">
                {gender.map((e: any, index) => (
                  <div key={index} className="filter-item">
                    <label className="d-flex gap-2">
                      <input
                        className='check-wrapper'
                        type="radio"
                        id={`custom-checkbox-${index}`}
                        name="feature"

                        checked={
                          dietitianfilters.gender && dietitianfilters.gender.includes(e)
                        }
                        onChange={(event) =>
                          event.target.checked
                            ? onSelect(e, "gender")
                            : removeFilter("gender", e)
                        }
                        value={e}

                      />
                      <span className="filterItemData">
                        {e}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            )}

            {isExpOpen && (
              <div className="submenu p-0 mt-3 mb-3 gap-3 service-submenu">
                {experience.map((e: any, index) => (
                  <div key={index} className="filter-item">
                    <label className="d-flex gap-2">
                      <input
                        className='check-wrapper'
                        type="radio"
                        id={`custom-checkbox-${index}`}
                        name="exp"

                        checked={
                          dietitianfilters.experience && dietitianfilters.experience.includes(e)
                        }
                        onChange={(event) =>
                          event.target.checked
                            ? onSelect(e, "experience")
                            : removeFilter("experience", e)
                        }
                        value={e}

                      />
                      <span className="filterItemData">
                        {e}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            )}

          </div>
        </ScrollPanel>

      </div>
      <div className='buttons-two-adv-filters'>
        <Button
          className="clr-btn-adv-filter"
          label="Clear All"
          severity="success"
          onClick={clearAllFilters}
        />
        <Button
          className={isChecked ? "show-green-rest-filter" : "show-rest-adv-filter"}
          label="Show Dietitians"
          disabled={!Object.values(dietitianfilters).some(value => value !== "")}
          onClick={() => {
            const queryParams = new URLSearchParams();
            const city = localStorage.getItem("city") as string;
            let parsedCity = JSON.parse(city);
            queryParams.append("city", parsedCity.city);
            queryParams.append(
              "dietitianfilters",
              JSON.stringify(dietitianfilters)
            );
            queryParams.append("languages", dietitianfilters["languages"] || "");
            queryParams.append("gender", dietitianfilters["gender"] || "");
            queryParams.append("experience", dietitianfilters["experience"] || "");

            const queryString = queryParams.toString();
            navigate(`/searchdietitian/?${queryString}`);
          }}
        />


      </div>

    </div>
  );
}

export default MobileDietitianFilters;
