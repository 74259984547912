import React, { useState } from 'react';
import "./favDietitianCard.component.css";
import { useNavigate } from 'react-router-dom';
import { Card } from 'primereact/card';
import { devsas, yeldamwebimagessas } from '../../../../config/constants';
import { Rating } from 'react-simple-star-rating';
import "./favDietitianCard.component.css";

const FavDietitianCard: React.FC<any> = ({ likedDietitians }) => {
  const [like, setLike] = useState<boolean>(false);
  console.log("likeddddd", likedDietitians);
  const navigate = useNavigate();
  const handleMetersChange = (meters: any) => {
    const metersValue = meters;
    const kilometersValue = (metersValue / 1000).toFixed(2);
    return kilometersValue;
  };

  return (
    <>
      {likedDietitians.length === 0 ? (
        <div className="d-flex align-items-center justify-content-center flex-column mt-6 mb-6 nodata-div">
          <img style={{ height: "143px", width: "285px" }} src={'https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/about/nofavDietitian.webp' + "?" + yeldamwebimagessas} alt='' />
          <span className="oops mt-2">Let's build your favorite list!</span>
          <span className="no-data-found">Keep track of your favorite dietitians for future reference</span>
          <button className="explore_btn mt-2" onClick={() => navigate('/dieticians')}>Explore Now</button>
        </div>

      ) : (
        <>
          {likedDietitians.length > 0 && (
            <div>
              <div className=" profile-rest-store-cards mb-1 gap-5">
                {likedDietitians?.map((item: any, i: any) => (
                  <div className="" key={"near" + item._id}>
                    <Card className="favdietitianscard mt-3"
                      onClick={() => {
                        const queryParams = new URLSearchParams();
                        queryParams.append('city', item.city);
                        queryParams.append('dietitianname', item.dietitianname);
                        queryParams.append('id', item._id);
                        const queryString = queryParams.toString();
                        navigate(`/searchdietitian?${queryString}`);
                      }}>

                      <div className="like-icon-diet">
                        <i
                          className={like ? "pi pi-heart cuisine-like-icon" : "pi pi-heart-fill"}
                          style={{paddingLeft:"6px"}}
                        ></i>
                      </div>
                      <div className="d-flex justify-content-center">
                        <img
                          className="dietitianimage"
                          src={item.dietitianimages[0] + "?" + devsas}
                        />
                      </div>

                      <div className="d-flex flex-column justify-content-center align-items-center dietitianinfo mt-3">
                        <p className="diettianname m-0">{item.dietitianname}</p>
                        <p className="speciality mb-1"> {item.speciality.join(" / ")}</p>
                        <p className="experiance mb-1">{item.experience} Years of Experience</p>
                        <p className="education  mb-1" style={{ textAlign: "center" }}>{item.degree}</p>
                        <div className="rating-review-count d-flex gap-1 align-items-center justify-content-center mb-2">
                          <span className="rating-value">{parseFloat(item.averageRating).toFixed(1)}</span>
                          <Rating
                            initialValue={parseFloat(item.averageRating)}
                            readonly={true}
                            transition
                            size={16}
                            allowFraction
                          />
                          <span className="review-count-value" style={{ color: "#3c3c3c" }}>({item.reviewCount})</span>
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
              </div>

              <div className="mobile-view-sugg-store d-flex flex-column">
                {likedDietitians.map((item: any) => (
                  <div>
                    <Card key={item?.restID} className="mobile-card mt-3 mb-3">
                      <div className="d-flex gap-3 align-items-center">
                        <div className="store-image-div col-lg-4 col-md-4 col-sm-4">
                          <div className="rest-image-div">
                            <img
                              src={item.dietitianimages[0] + "?" + devsas}
                              className="favstoreimg"
                              alt=""
                            />
                            <div className="adv-card-icons">
                              <div className="adv-card-like-icon">
                                <i
                                  className={
                                    like ? "pi pi-heart" : "pi pi-heart-fill"
                                  }
                                ></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="details-div d-flex flex-column gap-1 col-8 pt-0 pb-0 pl-0">

                          <div className="diettianname">{item.dietitianname}</div>
                          <p className="speciality mb-1"> {item.speciality.join(" / ")}</p>
                          <p className="experiance mb-1">{item.experience} Years of Experience</p>
                          <p className="education mb-1">{item.degree}</p>
                          <div className="rating-review-count d-flex gap-1 align-items-center">
                            <span className="rating-value">
                              {parseFloat(item.averageRating).toFixed(1)}
                            </span>
                            <Rating
                              initialValue={parseFloat(item.averageRating)}
                              readonly={true}
                              transition
                              size={20}
                              allowFraction
                            />
                            <span className="review-count-value">
                              ({item.reviewCount})
                            </span>
                          </div>


                        </div>
                      </div>
                    </Card>
                    <hr />
                  </div>
                ))}
              </div >
            </div>
          )}
        </>
      )}
    </>
  );
}

export default FavDietitianCard;
