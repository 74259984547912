import React, { useState, useEffect, useRef } from "react";
import { Card } from "primereact/card";
import "./healthyDelightsCard.component.css";


type props = {
  delighthead: string;
  delightline:string;
  delightimg:string;
};

const healthyDelightsCard: React.FC<props> = ({ delighthead,delightline,delightimg }) => {
  return (
    <>
      <div className="delights-card-div">
        <Card className="delights-card d-flex align-items-center justify-content-center" >
          <div className="deligts-inside-div d-flex">
            <div className="deligts-div d-flex flex-column gap-2 mt-1">
              <span className="delight-head">{delighthead}</span>
              <span className="delight-line">{delightline}</span>
              <span className="delight-Arrow" style={{marginTop:"15px"}}>
                  <i className="bi bi-arrow-right iccon"></i>
                </span>
            </div>
            <div className="col-4 d-flex justify-content-center mt-2 align-items-center delightimg">
              <img src={delightimg} style={{ width: "83px" }} alt="hh" />
              </div>
          </div>
        </Card>
      </div>
    </>
  );
};
export default healthyDelightsCard;
