import React, { useEffect, useState } from "react";
import "./restaurantCard.component.css";
import { Card } from "primereact/card";
import { Rating } from "react-simple-star-rating";
import RestaurantTimings from "../../RestaurantTimings/RestaurantTimings.component";
import FoodPreferences from "../../FoodPreferences/foodpreferences.component";
import { CheckLike, LikedRestaurant } from "../../../../api/ratings.api";
import { Dialog } from "primereact/dialog";
import SignInSignUp from "../../SigninSignup/signInSignUp.component";
import { useLike } from "../../../LikeContext/likecontext.component";
import Confetti from 'canvas-confetti';
import ImageLoader from "../../loader/imageloader.component";
import { Skeleton } from "primereact/skeleton";

type props = {
  StoreName: string;
  Address: string;
  city: string;
  state: string;
  pincode: string;
  reviewsCount: string;
  storecardimg: string;
  rating: string;
  onClick: any;
  serviceoptions: string[];
  timings: any;
  preference: any;
  likerest: boolean;
  restaurantid: string;
};

const RestaurantCard: React.FC<props> = ({
  StoreName,
  Address,
  city,
  state,
  pincode,
  reviewsCount,
  storecardimg,
  rating,
  timings,
  onClick,
  preference,
  likerest,
  restaurantid
}) => {

  const [signinvisible, setSigninVisible] = useState<boolean>(false);
  const { likedRests, toggleLike } = useLike();
  const isLikedrest = likedRests.includes(restaurantid);


  const handleLikeClick = () => {
    toggleLike(restaurantid, likerest);
    if (localStorage.getItem("accessToken") === null) {

    }
    else if (isLikedrest === false) {

      triggerConfetti();
    }
  };

  const triggerConfetti = () => {

    Confetti({
      particleCount: 200,
      angle: 60,
      spread: 70,
      origin: { x: 0 },
    });
    Confetti({
      particleCount: 200,
      angle: 120,
      spread: 70,
      origin: { x: 1 },
    });

  };

  return (
    <>
      <div className="organic-store-card-div">
        <Card className="Organic-store-card" onClick={onClick}>
          <div className="d-flex horizantal-card align-items-start">
            <div className="image-div col-lg-4 col-md-4 col-sm-3">
              <div className="rest-image-div">
                <ImageLoader className="imgloader" src={storecardimg} alt={"image"} width={"120px"} height={"120px"} />
                <div className="adv-card-icons">
                  <div className="adv-card-like-icon">
                    <i
                      className={isLikedrest ? "pi pi-heart-fill cuisine-like-icon " : "pi pi-heart cuisine-like-icon"}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLikeClick();
                      }}
                    >
                    </i>
                  </div>
                </div>
              </div>
            </div>    
              <div className="details-div d-flex flex-column col-8 pt-0 pb-0 pl-0">
                <div className="mb-1">
                  <FoodPreferences preferences={preference} />
                </div>
                <div className="store-name mb-1">{StoreName}</div>
                <div className="rating-review-count d-flex gap-1 align-items-center mb-2">
                  <span className="rating-value">{parseFloat(rating).toFixed(1)}</span>
                  <Rating
                    initialValue={parseFloat(rating)}
                    readonly={true}
                    transition
                    size={16}
                    allowFraction
                  />
                  <span className="review-count-value">({reviewsCount})</span>
                </div>
                <div className="store-address mb-2">{Address}, {city}, {state} - {pincode}</div>
                <div className="d-flex justify-content-between align-items-center">
                  <RestaurantTimings timings={[JSON.parse(timings)]} />
                </div>
              </div>
          </div>
        </Card>
      </div>

      <Dialog
        visible={signinvisible}
        closeOnEscape={false}
        // style={{ width: "50vw" }}
        className="login-model"
        // breakpoints={{
        //   "1920px": "50vw",
        //   "1420px": "50vw",
        //   "1120px": "50vw",
        //   "1090px": "40vw",
        //   "980px": "50vw",
        //   "840px": "60vw",
        //   "740px": "50vw",
        //   "641px": "70vw",
        //   "541px": "70vw",
        //   "490px": "80vw",
        //   "350px": "90vw",
        // }}
        onHide={() => setSigninVisible(false)}
      >
        <SignInSignUp setVisible={setSigninVisible} />
      </Dialog>
    </>
  );
};

export default RestaurantCard;
