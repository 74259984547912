import React, { useEffect, useState } from "react";
import "primeicons/primeicons.css";
import { Card } from "primereact/card";
import { NavLink } from "react-router-dom";
import "./foodEventsCard.component.css";
import { Skeleton } from "primereact/skeleton";

type Props = {
  eventsdata: any;
};
type EventData = {
  eventtitle: string;
  description: string[];
  referencelink: string;
  eventstartdate: any;
};


const FoodEvents: React.FC<Props> = ({ eventsdata }) => {

  const [visible, setvisible] = useState(false);

console.log("object events data",eventsdata);
  useEffect(() => {
    const timer = setTimeout(() => {
      setvisible(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const formatDate = (inputDate: string) => {
    const dateParts = inputDate.split('/').map(Number);
  
    const formattedDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0])
      .toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      });
    return formattedDate;
  };

  const extractDate = (dateString: string) => {
    const formattedDate = formatDate(dateString);
    const day = formattedDate.split(' ')[0]; // Extracts the day
    const month = formattedDate.split(' ')[1]; // Extracts the full month name
    return { day, month };
  };

  return (
    <div className="d-lg-flex d-md-flex d-grid grid-cols-1 gap-2 event-card-div ">
      {eventsdata.slice(0, 3).map((event: EventData, index: number) => (
        <Card
          key={index}
          className="event-Card d-flex align-items-center"
        >
          <div className="d-flex gap-2  align-items-center p-2">
            <div className=" col-lg-3 col-md-4 col-sm-3 col-3  d-flex flex-column  align-items-center pt-1 pb-1 Event-date">
              <div>
                {!visible ? (
                  <Skeleton width="100%" className="mb-2" ></Skeleton>
                ) : (
                  <span className="Event-Day">{extractDate(event.eventstartdate).day}</span>
                )}
              </div>
              <div>
                {!visible ? (
                  <Skeleton width="100%" className="mb-2" ></Skeleton>
                ) : (
                  <span className="Event-Month"> {extractDate(event.eventstartdate).month} </span>
                )}
              </div>
            </div>
            <div className="col-8 d-flex flex-column gap-1 mx-1">
              <>
              {!visible ? (
                  <Skeleton width="100%" className="mb-2" ></Skeleton>
                ) : (
              <span className="Event-Name">{event.eventtitle}</span>
              )}
              </>

              <>
              {!visible ? (
                  <Skeleton width="100%" className="mb-2" ></Skeleton>
                ) : (
                  <span>
              {event.description &&
                event.description.length > 0 && ( // Null check and length check
                  <span className="Event-Matter">
                    {event.description[0]}
                  </span>
                )}
                </span>
                )}
                </>
              <NavLink to={event.referencelink} target="_blank">
                <span className="d-flex align-items-center Event-KnowMore">
                  know more
                  <i
                    className="pi pi-angle-right"
                    style={{ fontSize: "12px" }}
                  ></i>
                </span>
              </NavLink>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default FoodEvents;
