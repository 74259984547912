import axios from 'axios'
import { apiUrl } from '../config/constants'
const HttpInstance = axios.create({
    baseURL: apiUrl,
    headers: {
      'Accept': '*/*',
     'Authorization': "Bearer " + localStorage.getItem('accessToken') || null
    }
})


export default HttpInstance;

