import React, { useEffect } from "react";

import Images from "../../../assets/images/blobImages";

import "./footer.component.css";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { yeldamwebimagessas } from "../../../config/constants";

const Footer: React.FC<any> = ({ localities, city, search, type }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handlePopularCategoriesClick = () => {
    if (location.pathname === "/") {
      // If already on the home screen, scroll to the section
      const popularCategoriesSection =
        document.getElementById("popularcategories");
      if (popularCategoriesSection) {
        window.scrollTo({
          top: popularCategoriesSection.offsetTop,
          behavior: "smooth",
        });
      }
    } else {
      navigate("/#popularcategories");
    }
  };
  const handleCuisineClick = () => {
    if (location.pathname === "/") {
      // If already on the home screen, scroll to the section
      const cuisineSection = document.getElementById("cuisine");
      if (cuisineSection) {
        window.scrollTo({
          top: cuisineSection.offsetTop,
          behavior: "smooth",
        });
      }
    } else {
      navigate("/#cuisine");
    }
  };

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#popularcategories") {
      const popularCategoriesSection =
        document.getElementById("popularcategories");
      if (popularCategoriesSection) {
        window.scrollTo({
          top: popularCategoriesSection.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, []);
  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#cuisine") {
      const cuisineSection = document.getElementById("cuisine");
      if (cuisineSection) {
        window.scrollTo({
          top: cuisineSection.offsetTop,
          behavior: "smooth",
        });
      }
    }
  }, []);

  return (
    <>
      <div className="Footer_Div">
        <div className="container p-0">
          <div className="row pt-2">
            <div className="col d-flex justify-content-evenly p-0">
              <div className="col d-flex flex-column gap-2">
                <NavLink to="/whyyeldam">
                  <label className="Footer-Heading">
                    About Us {" "}
                  </label>
                </NavLink>
                <ul>
                  <li>
                    <NavLink to="/whyyeldam">
                      <ul>
                        <li className="Footer-Content">Why Yeldam</li>
                      </ul>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/healthyrestaurants">
                      <ul>
                        <li className="Footer-Content">Restaurants</li>
                      </ul>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/aboutorganicstore">
                      <ul>
                        <li className="Footer-Content">Organic Stores</li>
                      </ul>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/faq">
                      <ul>
                        <li className="Footer-Content">FAQ</li>
                      </ul>
                    </NavLink>
                  </li>
                  {/* 
                  <li>
                    <NavLink to="/comingsoon">
                      <ul>
                        <li className="Footer-Content">Site Map</li>
                      </ul>

                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink to="/contactus">
                      {" "}
                      <ul>
                        <li className="Footer-Content">Contact Us</li>
                      </ul>
                    </NavLink>
                  </li>
                </ul>
              </div>

              <div className="col d-flex flex-column gap-2">
                <label className="Footer-Heading">Quick Links</label>
                <ul>
                  <li>
                    <NavLink to="/healthtips">
                      <ul>
                        <li className="Footer-Content">Health Tips</li>
                      </ul>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/#cuisine" onClick={handleCuisineClick}>
                      <ul>
                        <li className="Footer-Content">Cuisine</li>
                      </ul>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/terms">
                      <ul>
                        <li className="Footer-Content">Terms & Conditions</li>
                      </ul>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/privacy">
                      <ul>
                        <li className="Footer-Content">
                          Privacy & Cookie Policy
                        </li>
                      </ul>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/comingsoon">
                      {" "}
                      <ul>
                        <li className="Footer-Content"> DMCA Compliance</li>
                      </ul>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>

            <div className="d-flex justify-content-evenly col p-0">
              <div className="col d-flex flex-column gap-2">
                <label className="Footer-Heading">Top Locations</label>
                <ul>
                  <li
                    className="Footer-Content"
                    onClick={() => {
                      const cityData = { city: "Hyderabad", code: "HYD" };
                      localStorage.setItem("city", JSON.stringify(cityData));
                      const queryParams = new URLSearchParams();
                      if (type === "restaurant") {
                        const city = localStorage.getItem("city") || "";
                        queryParams.append("city", city);
                        const queryString = queryParams.toString();
                        navigate(`/searchrestaurant/?${queryString}`);
                      } else if (type === "organicstore") {
                        const cityFromStorage = localStorage.getItem("city");
                        let city = "";
                        if (cityFromStorage) {
                          const parsedCity = JSON.parse(cityFromStorage);
                          city = parsedCity.city;
                        }
                        queryParams.append('city', city);
                        const queryString = queryParams.toString();
                        navigate(`/searchstore?${queryString}`);
                      } else if (type === "dietitian") {
                        const cityFromStorage = localStorage.getItem("city");
                        let city = "";
                        if (cityFromStorage) {
                          const parsedCity = JSON.parse(cityFromStorage);
                          city = parsedCity.city;
                        }
                        queryParams.append('city', city);
                        const queryString = queryParams.toString();
                        navigate(`/searchdietitian?${queryString}`);
                      }
                    }}
                  >
                    Hyderabad
                  </li>

                  <li
                    className="Footer-Content"
                    onClick={() => {
                      const cityData = { city: "Chennai", code: "CHE" };
                      localStorage.setItem("city", JSON.stringify(cityData));
                      const queryParams = new URLSearchParams();
                      if (type === "restaurant") {
                        const city = localStorage.getItem("city") || "";
                        queryParams.append("city", city);
                        const queryString = queryParams.toString();
                        navigate(`/searchrestaurant/?${queryString}`);
                      } else if (type === "organicstore") {
                        const cityFromStorage = localStorage.getItem("city");
                        let city = "";
                        if (cityFromStorage) {
                          const parsedCity = JSON.parse(cityFromStorage);
                          city = parsedCity.city;
                        }
                        queryParams.append('city', city);
                        const queryString = queryParams.toString();
                        navigate(`/searchstore?${queryString}`);
                      } else if (type === "dietitian") {
                        const cityFromStorage = localStorage.getItem("city");
                        let city = "";
                        if (cityFromStorage) {
                          const parsedCity = JSON.parse(cityFromStorage);
                          city = parsedCity.city;
                        }
                        queryParams.append('city', city);
                        const queryString = queryParams.toString();
                        navigate(`/searchdietitian?${queryString}`);
                      }

                    }}
                  >
                    Chennai
                  </li>
                  <li
                    className="Footer-Content"
                    onClick={() => {
                      const cityData = { city: "Bengaluru", code: "BEN" };
                      localStorage.setItem("city", JSON.stringify(cityData));
                      const queryParams = new URLSearchParams();
                      if (type === "restaurant") {
                        const city = localStorage.getItem("city") || "";
                        queryParams.append("city", city);
                        const queryString = queryParams.toString();
                        navigate(`/searchrestaurant/?${queryString}`);
                      } else if (type === "organicstore") {
                        const cityFromStorage = localStorage.getItem("city");
                        let city = "";
                        if (cityFromStorage) {
                          const parsedCity = JSON.parse(cityFromStorage);
                          city = parsedCity.city;
                        }
                        queryParams.append('city', city);
                        const queryString = queryParams.toString();
                        navigate(`/searchstore?${queryString}`);
                      } else if (type === "detitian") {
                        const cityFromStorage = localStorage.getItem("city");
                        let city = "";
                        if (cityFromStorage) {
                          const parsedCity = JSON.parse(cityFromStorage);
                          city = parsedCity.city;
                        }
                        queryParams.append('city', city);
                        const queryString = queryParams.toString();
                        navigate(`/searchdietitian?${queryString}`);
                      }
                    }}
                  >
                    Bengaluru
                  </li>
                  <li
                    className="Footer-Content"
                    onClick={() => {
                      const cityData = { city: "Mumbai", code: "MUM" };
                      localStorage.setItem("city", JSON.stringify(cityData));
                      const queryParams = new URLSearchParams();
                      if (type === "restaurant") {
                        const city = localStorage.getItem("city") || "";
                        queryParams.append("city", city);
                        const queryString = queryParams.toString();
                        navigate(`/searchrestaurant/?${queryString}`);
                      } else if (type === "organicstore") {
                        const cityFromStorage = localStorage.getItem("city");
                        let city = "";
                        if (cityFromStorage) {
                          const parsedCity = JSON.parse(cityFromStorage);
                          city = parsedCity.city;
                        }
                        queryParams.append('city', city);
                        const queryString = queryParams.toString();
                        navigate(`/searchstore?${queryString}`);
                      } else if (type === "dietitian") {
                        const cityFromStorage = localStorage.getItem("city");
                        let city = "";
                        if (cityFromStorage) {
                          const parsedCity = JSON.parse(cityFromStorage);
                          city = parsedCity.city;
                        }
                        queryParams.append('city', city);
                        const queryString = queryParams.toString();
                        navigate(`/searchdietitian?${queryString}`);
                      }
                    }}
                  >
                    Mumbai
                  </li>
                  <li
                    className="Footer-Content"
                    onClick={() => {
                      const cityData = { city: "New Delhi", code: "NDL" };
                      localStorage.setItem("city", JSON.stringify(cityData));
                      const queryParams = new URLSearchParams();
                      if (type === "restaurant") {
                        const city = localStorage.getItem("city") || "";
                        queryParams.append("city", city);
                        const queryString = queryParams.toString();
                        navigate(`/searchrestaurant/?${queryString}`);
                      } else if (type === "organicstore") {
                        const cityFromStorage = localStorage.getItem("city");
                        let city = "";
                        if (cityFromStorage) {
                          const parsedCity = JSON.parse(cityFromStorage);
                          city = parsedCity.city;
                        }
                        queryParams.append('city', city);
                        const queryString = queryParams.toString();
                        navigate(`/searchstore?${queryString}`);
                      } else if (type === "dietitian") {
                        const cityFromStorage = localStorage.getItem("city");
                        let city = "";
                        if (cityFromStorage) {
                          const parsedCity = JSON.parse(cityFromStorage);
                          city = parsedCity.city;
                        }
                        queryParams.append('city', city);
                        const queryString = queryParams.toString();
                        navigate(`/searchdietitian?${queryString}`);
                      }
                    }}
                  >
                    New Delhi
                  </li>
                  <li
                    className="Footer-Content"
                    onClick={() => {
                      const cityData = { city: "Kolkata", code: "KOL" };
                      localStorage.setItem("city", JSON.stringify(cityData));
                      const queryParams = new URLSearchParams();
                      if (type === "restaurant") {
                        const city = localStorage.getItem("city") || "";
                        queryParams.append("city", city);
                        const queryString = queryParams.toString();
                        navigate(`/searchrestaurant/?${queryString}`);
                      } else if (type === "organicstore") {
                        const cityFromStorage = localStorage.getItem("city");
                        let city = "";
                        if (cityFromStorage) {
                          const parsedCity = JSON.parse(cityFromStorage);
                          city = parsedCity.city;
                        }
                        queryParams.append('city', city);
                        const queryString = queryParams.toString();
                        navigate(`/searchstore?${queryString}`);
                      } else if (type === "dietitian") {
                        const cityFromStorage = localStorage.getItem("city");
                        let city = "";
                        if (cityFromStorage) {
                          const parsedCity = JSON.parse(cityFromStorage);
                          city = parsedCity.city;
                        }
                        queryParams.append('city', city);
                        const queryString = queryParams.toString();
                        navigate(`/searchdietitian?${queryString}`);
                      }
                    }}
                  >
                    Kolkata{" "}
                  </li>
                </ul>
              </div>

              <div className="col d-flex flex-column gap-3">
                <div className="Footer-Heading">Social Links</div>
                <div className="Social_Icons d-flex ">
                  <a
                    className="text-reset"
                    href="https://www.instagram.com/yeldam_letsgo/"
                    target="_blank"
                    aria-label=""
                  >
                    {" "}
                    <img
                      className="Icon_Sizing"
                      src={Images.instagram + "?" + yeldamwebimagessas}
                      alt=""
                      aria-label=""
                    />
                  </a>
                  <a
                    className="text-reset"
                    href="https://www.linkedin.com/company/yeldam/"
                    target="_blank"
                    aria-label=""
                  >
                    {" "}
                    <img
                      className="Icon_Sizing"
                      src={Images.linkedin + "?" + yeldamwebimagessas}
                      alt=""
                      aria-label=""
                    />
                  </a>
                  <a
                    className="text-reset"
                    href="https://www.facebook.com/Yeldam.letsgo"
                    target="_blank"
                    aria-label=""
                  >
                    <img
                      className="Icon_Sizing"
                      src={Images.facebook + "?" + yeldamwebimagessas}
                      alt=""
                      aria-label=""
                    />
                  </a>
                  <a
                    href="https://twitter.com/yeldam_letsgo"
                    target="_blank"
                    aria-label=""
                  >
                    {" "}
                    <img
                      className="Icon_Sizing1"
                      src={Images.twitter + "?" + yeldamwebimagessas}
                      alt=""
                    />
                  </a>
                </div>
                <div className="badges d-flex flex-column gap-3">
                  <a href="/comingsoon" aria-label="">
                    <img
                      className="g-badge"
                      alt=""
                      src={
                        "https://yeldamimages.azureedge.net/yeldamwebimages/google-play-badge.png" +
                        "?" +
                        yeldamwebimagessas
                      }
                    />
                  </a>
                  <a href="/comingsoon" aria-label="">
                    <img
                      className="a-badge"
                      alt=""
                      src={
                        "https://yeldamimages.azureedge.net/yeldamwebimages/apple-store-badge.png" +
                        "?" +
                        yeldamwebimagessas
                      }
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr style={{ color: "#DEDEDE", opacity: "100%" }} />
        <div className="copy-rights-div d-flex justify-content-center">
          <h1 className="copy-rights-text">Copyright © 2024 Yeldam. All rights reserved.</h1>
        </div>
      </div>
    </>
  );
};

export default Footer;
