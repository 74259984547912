import React, { createContext, useContext, useEffect, useState } from 'react';
import { CheckDietitianLike, LikeDietitian } from '../../api/dietitians.api';
import { Dialog } from 'primereact/dialog';
import SignInSignUp from '../UI/SigninSignup/signInSignUp.component';
import { bool } from 'yup';

interface LikeContextType {
  likedDietitians: string[];
  toggleDietitianLike: (dietitianId: string, isLikedstatus: boolean) => void;
}

const DietitianLikeContext = createContext<LikeContextType | undefined>(undefined);

interface DietitianLikeProviderProps {
  children: React.ReactNode;
  initialDietitians: any;
}

export const DietitianLikeProvider: React.FC<DietitianLikeProviderProps> = ({ children, initialDietitians }) => {
  const [likedDietitians, setLikedDietitians] = useState<string[]>([]);
  const [signinvisible, setSigninVisible] = useState<boolean>(false);

  const toggleDietitianLike = async (dietitianId: string, isLikedstatus: boolean) => {
    try {
      // Call the API function to toggle like status in the database
      if (localStorage.getItem("accessToken") === null) {
        setSigninVisible(true);
      }
      if (likedDietitians.includes(dietitianId) || isLikedstatus) {
        const isLiked = await CheckDietitianLike(dietitianId);
        if (isLiked) {
          // If already liked, unlike it
          await LikeDietitian(dietitianId, false);
          setLikedDietitians(prevLikedDietitians => prevLikedDietitians.filter(id => id !== dietitianId));
        }
        else {
          await LikeDietitian(dietitianId, true);
          setLikedDietitians(prevLikedDietitians => [...prevLikedDietitians, dietitianId]);
        }
      } else {
        // If not liked, like it
        await LikeDietitian(dietitianId, true);
        setLikedDietitians(prevLikedDietitians => [...prevLikedDietitians, dietitianId]);
      }
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };
  useEffect(() => {
    // Initialize liked restaurants from initial data
    const initialLikedDietitians = initialDietitians.filter((dietitian: any) => dietitian.isLiked).map((dietitian: any) => dietitian._id);
    setLikedDietitians(initialLikedDietitians);
  }, [initialDietitians]);

  return (
    <DietitianLikeContext.Provider value={{ likedDietitians, toggleDietitianLike }}>
      {children}
      <Dialog
        visible={signinvisible}
        closeOnEscape={false}
        className="login-model"
        onHide={() => setSigninVisible(false)}
      >
        <SignInSignUp setVisible={setSigninVisible} />
      </Dialog>
    </DietitianLikeContext.Provider>

  );
};

export const useDietitianLike = () => {
  const context = useContext(DietitianLikeContext);
  if (!context) {
    throw new Error('useLike must be used within a LikeProvider');
  }
  return context;
};

