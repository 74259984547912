import { Skeleton } from 'primereact/skeleton';
import React, { useEffect, useState } from 'react';

const ImageLoader: React.FC<{ src: string; alt: string; width: string, height:string,className?:string }> = ({ src, alt, width, height,className }) => {
    const [loaded, setLoaded] = useState(false);
  
    useEffect(() => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setLoaded(true);
      };
    }, [src]);
  
    return (
        <>
        {!loaded &&  <Skeleton width={width} height={height} ></Skeleton>
        }
        <img src={src} alt={alt} className={className} style={{ display: loaded ? 'block' : 'none',width:width,height:height }} />
        </>
    );
  };
  
  export default ImageLoader;