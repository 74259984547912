import React, { useEffect, useState } from "react";
import "./foodEvents.pages.css";
import Navbar from "../../components/UI/Navbar/navBar.component";
import Footer from "../../components/UI/Footer/footer.component";
import { NavLink } from "react-router-dom";
import { FoodEventsdata } from "../../api/home.api";
import { Card } from "primereact/card";
import FoodEvents from "../../components/UI/Cards/FoodEventsCard/foodEventsCard.component";
import { ScrollPanel } from "primereact/scrollpanel";
  
  type EventData = {
   
    eventtitle: string;
    description: string[];
    referencelink: string;
    eventstartdate: any;
  };

const FoodEvent: React.FC<any> = () => {

    const [eventsdata, seteventsdata] = useState<EventData[]>([]);
    const [search, setSearch] = useState<string[]>([]);
    const [city, setCity] = useState(""); 
    const [list, setList] = useState<string[]>([]);
     const cityFromStorage = localStorage.getItem("city") || "";
     const CityName = JSON.parse(cityFromStorage).city;
     const eventdata = async () =>{
        try {
          const eventsdatacard = await FoodEventsdata(CityName);
          seteventsdata(eventsdatacard.data.data);
        } catch (err: any) {
          if (err.code === "ERR_BAD_REQUEST") {
          }
        }
      };

     
      useEffect(() =>{
        if(CityName != ""){
        let timer: NodeJS.Timeout;
        const delayedFetch = () => {
    
          timer = setTimeout(() => {
            
            eventdata();
          })
        };
        delayedFetch();
      }
      },[CityName]);

  return (
    <div className="container-fluid p-0">
      <div className="row">
        <Navbar />
      </div>
      <div className="container">
        <div className="Food-E-heading d-flex justify-content-between align-items-center mt-6 mb-4">
          <div className="Food-Events-heading">Food Events</div>
          <div className="d-flex align-items-center FE-heading-nav">
            <a href="/" className="d-flex">
              {" "}
              <span className="home-span">Home</span>
            </a>
            <i
              className="bi bi-chevron-right"
              style={{ color: "#C2C2C2", fontSize: "12px" }}
            ></i>
            <span className="event-span">Food Events</span>
          </div>
        </div>
        <div>
        <ScrollPanel className="events-scroll-behaviour">
              <FoodEvents eventsdata={eventsdata}/>    
              
              </ScrollPanel>       
         </div>
         
      </div>
      <div className="row">
          <Footer localities={list} city={city} search={search} type={"restaurant"} />
        </div>
    </div>
  );
};

export default FoodEvent;
