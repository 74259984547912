import React, { useEffect, useRef, useState } from 'react';
import "./HomeOrganicStore.page.css"
import Loader from '../../components/UI/loader/loader.components';
import Navbar from '../../components/UI/Navbar/navBar.component';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import { AutoComplete, AutoCompleteChangeEvent, AutoCompleteCompleteEvent } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import currentlocation from "../../assets/icons/currentlocation.svg";
import locationnot from "../../assets/images/locationnot.png";
import bigadd from "../../assets/images/bigadd.png";
import smalladd1 from "../../assets/images/smalladd1.png"
import smalladd2 from "../../assets/images/smalladd2.png"
import NearStoreCard from '../../components/UI/Cards/NearStoreCard/NearStoreCard.component';
import storecardimg from "../../assets/images/storecardimg.png"
import { Skeleton } from 'primereact/skeleton';
import storeadd1 from "../../assets/images/storeadd1.png";
import storeadd2 from "../../assets/images/storeadd2.png";
import storeadd3 from "../../assets/images/storeadd3.png";
import storeadd4 from "../../assets/images/storeadd4.png"
import Footer from '../../components/UI/Footer/footer.component';
import Localities from "../../components/UI/PopolarLocalities/popularLocalities.component";
import { GetLocalities, NearestStoress, Organicstoresearch, RecommendedStores } from '../../api/home.api';
import { useNavigate } from 'react-router-dom';
import { devsas, yeldamwebimagessas } from '../../config/constants';
import RecommendedStoreCard from "../../components/UI/Cards/RecommendedStoreCard/RecommendedStoreCard.component"
import ImageLoader from '../../components/UI/loader/imageloader.component';


interface City {
  city: string;
  code: string;
}
interface StoresInfo {
  storeId: string;
  storename: string;
  address: string;
  imageurl: string | string[];
}

interface Suggestions {
  type: string;
  info: StoresInfo;
}
const HomeOrganicStores: React.FC<any> = () => {
  const [isLoaded, setIsLoaded] = useState<boolean>(true);
  const [isTopVisible, setTopVisible] = useState(false);
  const [cityLoad, setCityLoad] = useState(false);
  const [selectedCity, setSelectedCity] = useState<City | null>(null);
  const [geoperm, setGeoperm] = useState<boolean>(false);
  const [locerrorvisible, setLocerrorVisible] = useState<boolean>(false);
  const [nvisible, setNvisible] = useState(true);
  const [visible, setVisible] = useState<boolean>(false);
  const [searchBarStyle, setSearchBarStyle] = useState({});
  const [searchLoad, setSearchLoad] = useState(false);
  const [showimg, setshowimg] = useState(false);
  const [list, setList] = useState<string[]>([]);
  const [city, setCity] = useState<string>("");
  const [search, setSearch] = useState<string[]>([]);
  const [selectedStore, setSelectedStore] = useState<any>();
  const [itemskeletonLoad, setItemSkeletonLoad] = useState(false);
  const [filteredStore, setFilteredStore] = useState<any[]>();
  const [storeData, setStoreData] = useState<any[]>([]);
  const [storeRecData, setStoreRecData] = useState<any[]>([]);
  const navigate = useNavigate();
  const isFirstRender = useRef(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setshowimg(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll to top
    });
  };

  const cities = [
    // { city: "Current Location", code: "UCL" },
    { city: "Hyderabad", code: "HYD" },
    { city: "Chennai", code: "CHE" },
    { city: "Bengaluru", code: "BEN" },
    { city: "Mumbai", code: "MUM" },
    { city: "New Delhi", code: "NDL" },
    { city: "Kolkata", code: "KOL" },

  ];

  const getLocalities = async (e: any) => {
    const resData = await GetLocalities(e);
    if (resData.data.data) {
      setList(resData.data.data);
    } else {
      setList([]);
    }
  };

  const locList = list.map((locality) => ({ locality }));
  const searchss = async (event: AutoCompleteCompleteEvent) => {
    setItemSkeletonLoad(true);
    const cityFromStorage = localStorage.getItem("city") || "";
    const parsedCity = JSON.parse(cityFromStorage);
    const resData = await Promise.resolve(
      Organicstoresearch(parsedCity.city, event.query)
    );

    if (resData.data.data.results.length > 0) {
      setItemSkeletonLoad(false);
      setFilteredStore(
        resData.data.data.results.map((item: any) => {
          if (item.Type === 'Organic Store') {
            return {
              type: item.Type,
              info: {
                id: item.info.storeId,
                storename: item.info.storename,
                address: item.info.address,
                imageurl:
                  Array.isArray(item.info.imageurl) && item.info.imageurl.length > 0
                    ? item.info.imageurl[0] + "?" + devsas
                    : item.info?.imageurl + "?" + yeldamwebimagessas || "", // Assuming 'devsas' is a variable with the necessary value
              },
            };
          }
          // Add conditions for other types if necessary
          return {}; // Default empty object if none of the conditions match
        })
      );
    }

    if (resData.data.data.results.length === 0) {
      setItemSkeletonLoad(false);
      setFilteredStore([
        {
          type: "We're a bit lost here! Can you rephrase?",
          info: { storename: "Oops!", imageurl: "" },
        },
      ]);
    }
  };
  const handleAutoCompleteClick = (e: any) => {
    setSearchLoad(true);
    const queryParams = new URLSearchParams();

    if (String(e.value.Type).includes("We could not understand")) {
      console.log("no datata");
      setSelectedStore("");
      setSearchLoad(false);
    } else {
      setSelectedStore(e.value.info.storename);
      setSearchLoad(true);

      setTimeout(() => {
        if (e.value.type === "Organic Store") {
          queryParams.append('city', city);
          queryParams.append("type", e.value.type);
          queryParams.append("storename", e.value.info.storename);
          queryParams.append("id", e.value.info.id)
          const queryString = queryParams.toString();
          navigate(`/searchstore/?${queryString}`);
        }

      }, 1000);
    }
  };
  const itemTemplate = (item: Suggestions) => {
    if (!item || !item.info) {
      return null; // Or handle the case where item or item.info is undefined/null
    }

    const imageSrc = Array.isArray(item.info.imageurl)
      ? item.info.imageurl[0]
      : item.info.imageurl;

    return (
      <>
        {!itemskeletonLoad ? (
          <div className="auto-item-div">
            {imageSrc && item.type !== "Dish" && (
              <div className="auto-item-div-li">
                <img
                  alt={item.type}
                  src={imageSrc}
                  className="auto-item-image"
                />
              </div>
            )}
            <div className="auto-item-details-div">
              <p className="auto-item-name">
                <span>{item.info.storename}</span>
              </p>
              <p className="auto-item-address mb-0">
                <span>
                  {item.info.address ? item.info.address.split(',').pop()?.trim() : ''}
                </span>
              </p>
              {item.type !== "Organic Store" && (
                <p color="#4F4F4F" className="auto-item-type">
                  <span>{item.type}</span>
                </p>
              )}

            </div>
          </div>
        ) : (
          <div className="auto-item-div">
            <div className="auto-item-div-li">
              <Skeleton className="auto-item-image" height="4rem"></Skeleton>
            </div>

            <div className="auto-item-details-div gap-2">
              <p className="auto-item-name">
                <Skeleton></Skeleton>
              </p>
              <p color="#4F4F4F" className="auto-item-type">
                <Skeleton></Skeleton>
              </p>
            </div>
          </div>
        )}
      </>
    );
  };
  const handleOnKeyClick = () => {
    setSearchLoad(true);
    const queryParams = new URLSearchParams();

    if (selectedStore === undefined || selectedStore === "" || selectedStore === null) {
      console.log("no datata");
      setSelectedStore("");
      setSearchLoad(false);
    } else {
      setSearchLoad(true);
      setTimeout(() => {
        queryParams.append('city', city);
        queryParams.append("searchTerm", selectedStore);
        const queryString = queryParams.toString();
        navigate(`/searchstore/?${queryString}`);

      }, 1000);
    }
  };
  const handleResize = () => {
    if (window.innerWidth < 800) {
      setSearchBarStyle({ borderRadius: "28px 28px 28px 28px" });
    } else {
      setSearchBarStyle({ borderRadius: "0px 28px 28px 0px" });
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  };
  const handleScroll = () => {
    // Show or hide the button based on scroll position
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollThreshold = 300; // Adjust this value as needed

    setTopVisible(scrollTop > scrollThreshold && scrollTop > 0);
  };
  useEffect(() => {
    setVisible(false);
    const cityFromStorage = localStorage.getItem("city") || "";
    let parsedCity;

    if (cityFromStorage) {
      try {
        parsedCity = JSON.parse(cityFromStorage);
        setSelectedCity(parsedCity);
      } catch (error) {
        console.error("Error parsing city:", error);
      }
    }

    if (!cityFromStorage) {
      setVisible(true);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setIsLoaded(true);
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const cityFromStorage = localStorage.getItem("city") || "";
    setVisible(false);
    if (!cityFromStorage) {
      setVisible(true);
    } else {
      let parsedCity = JSON.parse(cityFromStorage);
      setCity(parsedCity.city);
      getLocalities(parsedCity.city || "");

      if (
        localStorage.getItem("latitude") !== null &&
        localStorage.getItem("longitude") !== null
      ) {
        StoresNearYou();
      }
      RecommendedForYou()
    }

    setIsLoaded(false);
  }, [selectedCity]);

  const generateCityCode = (cityName: string): string => {
    const formattedCityName = cityName.trim().toUpperCase();
    const cityCode = formattedCityName.slice(0, 3); // Extract first three letters

    return cityCode;
  };

  const fetchCity = async (lat: number, long: number) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyDlcgJD3UPle2nAu6z9_UxoJ4v8HHiIMEg&result_type=locality`
      );
      const data = await response.json();
      console.log(data);
      console.log(data?.plus_code.compound_code.split(' ')[1]);
      //const cityName = data?.results[0]?.address_components[0]?.long_name || "Unknown City";
      const cityName = data?.plus_code.compound_code.split(' ')[1].replace(',', '') || "Unknown City";
      const generatedCode = generateCityCode(cityName);
      const cityObj: City = {
        city: cityName,
        code: generatedCode,
      };
      localStorage.setItem("city", JSON.stringify(cityObj));
      setSelectedCity(cityObj);
      setCityLoad(false);
    } catch (error) {
      console.error("Error fetching city:", error);
    }
  };

  const success = (position: any) => {
    localStorage.setItem("latitude", position.coords.latitude);
    localStorage.setItem("longitude", position.coords.longitude);
    fetchCity(position.coords.latitude, position.coords.longitude);
  };
  const poserror = async (err: any) => {
    console.warn(err);
    setGeoperm(false);
    setLocerrorVisible(true);
    if (!localStorage.getItem("city")) {
      setNvisible(true);
    }
  };
  const selectedCityTemplate = (option: City) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <div>{option.city}</div>
        </div>
      );
    }
  };

  const cityOptionTemplate = (option: City) => {
    return (
      <div className="flex align-items-center gap-2">
        <div>
          {option.city === "Current Location" ? (
            <img
              alt={option.city}
              src={currentlocation}
              className={""}
              style={{ width: "18px" }}
            />
          ) : (
            <div style={{ width: "18px" }}></div>
          )}
        </div>
        <div>{option.city}</div>
      </div>
    );
  };
  const handleEnablecurrentlocation = async () => {
    try {
      setCityLoad(true);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            success(position);
          },
          (error) => {
            console.error("Error getting current position:", error.message);
            setCityLoad(false);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser");
        setCityLoad(false);
      }
    } catch (error) {
      console.error("Error enabling current location:", error);
      setCityLoad(false);
    }
  };


  // change city while selecting city in dropdown

  const onChangeHandler = async (e: any) => {
    if (e.city === "Current Location") {
      setCityLoad(true);
      if (navigator.geolocation) {
        await navigator.geolocation.getCurrentPosition(success, poserror);
        setSelectedCity(
          cities.filter((item) => item.city === localStorage.getItem("city"))[0]
        );
      } else {
        console.error("Geolocation is not supported by this browser");
        setCityLoad(false);
      }
    } else {
      setVisible(false);
      setLocerrorVisible(false);
      setSelectedCity(e);
      setCityLoad(false);
      localStorage.setItem("city", JSON.stringify(e));
    }
  };
  const StoresNearYou = async () => {
    setGeoperm(true);
    try {
      const res = await NearestStoress(
        localStorage.getItem("latitude"),
        localStorage.getItem("longitude"),
        4
      );
      setStoreData(res.data.data);
      setNvisible(false);
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access:", error.response.data);
      } else {
        console.error("Error fetching nearest restaurants:", error.message);
      }
      console.error("Error fetching nearest restaurants:", error.message);
    }
  };

  const RecommendedForYou = async () => {
    setGeoperm(true);
    try {
      const res = await RecommendedStores(city, 4);

      console.log("recdata", res.data.data)
      setStoreRecData(res.data.data);
      setNvisible(false);
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        console.error("Unauthorized access:", error.response.data);
      } else {
        console.error("Error fetching nearest restaurants:", error.message);
      }
      console.error("Error fetching nearest restaurants:", error.message);
    }
  };

  console.log("stdata", storeData);

  const handleClearInput = () => {
    setSelectedStore('');
  };

  return (
    <div className='container-fluid p-0'>
      {/* {!isLoaded ? "" : <Loader />} */}
      <div className="row">
        <Navbar />
      </div>
      <div className="scrollTop">
        <button
          className={`back-to-top-btn ${isTopVisible ? "show" : "hide"}`}
          onClick={scrollToTop}
        >
          <i className="pi pi-angle-up" style={{ fontSize: "17px" }}></i>
        </button>
      </div>

      {/* stores search body */}
      <div className='background-color container-fluid p-3'>
        <div className="container d-flex search-template-stores justify-content-center flex-wrap">

          <div className="store-location col-lg-3">
            <div className="d-flex align-items-center">
              <div className="col p-0 ml-3">
                <div
                  className="map-pin"
                  style={{
                    textAlign: "center",
                  }}
                >
                  {cityLoad ? (
                    <ProgressSpinner
                      style={{
                        width: "24px",
                        height: "24px",
                        color: "black",
                        marginTop: "5px",
                      }}
                    />
                  ) : (
                    <i
                      className="bi bi bi-geo-alt-fill"
                      style={{ fontSize: "16px", color: "#36a41d" }}
                    ></i>
                  )}
                </div>
              </div>
              <div className="col m-0 p-0 ml-2">
                <div className="dropdown-city">
                  <Dropdown
                    value={selectedCity}
                    onChange={(e: any) =>
                      onChangeHandler(e.value)
                    }
                    options={cities}
                    optionLabel="name"
                    placeholder="Select a City"
                    valueTemplate={selectedCityTemplate}
                    itemTemplate={cityOptionTemplate}
                    className="w-full md:w-9rem"
                    style={{ border: "none", outline: "none" }}
                  />
                </div>
              </div>
              <div className="col d-flex justify-content-center align-items-center p-0">
                <div className="vertical-line"></div>
              </div>
            </div>
          </div>

          <div className="store-search d-flex align-items-center justify-content-between col-lg-6" >
            <div
              className=" p-inputgroup"
              id="searchbar"
              style={searchBarStyle}
            >
              <AutoComplete
                field="name"
                className="search-Input"
                placeholder="Search organic stores by area, store name, or store type"
                style={{ fontFamily: "roboto" }}
                value={selectedStore}
                suggestions={filteredStore}
                completeMethod={searchss}
                itemTemplate={itemTemplate}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleOnKeyClick(); // Call your onSubmit function here
                  }
                }}
                onChange={(e: AutoCompleteChangeEvent) => {
                  setSelectedStore(e.value);
                  if (e.value.info) {
                    setSelectedStore(e.value);
                    handleAutoCompleteClick(e);
                  }
                  if (!e.value) {
                    if (window.innerWidth < 800) {
                      setSearchBarStyle({
                        borderRadius: "28px 28px 28px 28px",
                      });
                    } else {
                      setSearchBarStyle({
                        borderRadius: "0px 28px 28px 0px",
                      });
                    }
                  } else {
                    handleResize();
                  }
                }}

              />
              {selectedStore && (
                            <i
                              className="pi pi-times searchclear"
                              onClick={handleClearInput}
                              style={{
                                cursor: 'pointer',
                                position: "relative",
                                right: "12px",
                                top:"14px",
                                zIndex: 2,
                                color: "#818181",
                                fontSize: "12px"
                              }}
                            ></i>
                          )}

            </div>
            <div>
              {searchLoad ? (
                <ProgressSpinner className="stores-searchload-spinner" />
              ) : (
                <Button
                  icon="pi pi-search"
                  // label='Search'
                  name="search"
                  aria-label="search"
                  className="store-search-btn d-flex justify-content-center"
                  onClick={() => {
                    handleOnKeyClick()
                  }}
                />
              )}
            </div>

          </div>
        </div>
      </div>

      {/* big adds */}
      <div className='container p-0 bigadds d-flex flex-wrap align-items-start mt-5 mb-5'>
        <div className='col-lg-8 col-md-8 col-sm-12 biggadd'>
          <img className="img-fluid" src={bigadd} />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 d-flex justify-content-center">
          <div className="row m-0 p-0 d-flex justify-content-center">
            <div className="col-lg-12 col-md-12 col-sm-5 col-add-6 smalladd1 d-flex justify-content-center align-items-center">
              <img className="img-fluid" style={{ cursor: "pointer" }} src={smalladd1}
                onClick={() => {
                  const queryParams = new URLSearchParams();
                  queryParams.append("city", city);
                  queryParams.append("storetype", "Millets");
                  const queryString = queryParams.toString();
                  navigate(`/searchstore?${queryString}`);
                }}
                alt="Small Advertisement 1" />
            </div>
            <div className="col-lg-12 col-md-12 col-sm-5 col-add-6 d-flex justify-content-center align-items-center">
              <img className="img-fluid" style={{ cursor: "pointer" }} src={smalladd2}
                onClick={() => {
                  const queryParams = new URLSearchParams();
                  queryParams.append("city", city);
                  queryParams.append("storetype", "Oil");
                  const queryString = queryParams.toString();
                  navigate(`/searchstore?${queryString}`);
                }}
                alt="Small Advertisement 2" />
            </div>
          </div>
        </div>

      </div>

      {/* organic stores near you */}
      {storeData && storeData.length > 0 && geoperm && (
        <div className="background-color container-fluid pb-5">
          <div className="container">
            <div className="d-flex flex-column ">
              <div className="rest-main mb-2 mt-5">
                <h5 className="d-flex Card-heading font_subhead ">
                  Organic Stores Near You
                </h5>
              </div>
            </div>
            <NearStoreCard setNVisible={setNvisible} storeData={storeData} />
          </div>
        </div>
      )}
      {!geoperm && (
        <div className="background-color container-fluid p-0">
          <div className="container pt-5 pb-5">
            <div className="d-flex justify-content-center align-items-center noloc-div">
              <div>
                <img
                  src={locationnot}
                  style={{ width: "auto", height: "230px" }}
                  alt=""
                />
              </div>
              <div className="nolocationtext">
                <p className="letshelp-text">Let’s help you find the organic stores around you.</p>
                <p className="disc-rest-text">Enable location in your browser to see organic stores nearby.</p>
                <Button
                  className="button_enable"
                  label="Enable Location"
                  severity="success"
                  onClick={handleEnablecurrentlocation}
                />
              </div>

            </div>
          </div>
        </div>
      )}

      {/* adds */}
      <div className="container-fluid p-0">
        <div className="container">
          <div className="row mb-5 mt-5">
            <div className="scrolling-wrapper">
              <div className="marquee-content gap-3">
                <div className="col d-flex align-items-center justify-content-start ">
                  {!showimg ? (
                    <Skeleton width="255px" height="117px"></Skeleton>
                  ) : (
                    <div style={{ cursor: "pointer" }} onClick={() => {
                      const queryParams = new URLSearchParams();
                      queryParams.append("city", city);
                      queryParams.append("storetype", "Vegetables");
                      const queryString = queryParams.toString();
                      navigate(`/searchstore?${queryString}`);
                    }}>
                      <ImageLoader src={storeadd1} className="ad_small_img" width="auto" height="120px" alt="" />
                    </div>
                  )}
                </div>
                <div className="col d-flex align-items-center justify-content-center">
                  {!showimg ? (
                    <Skeleton width="255px" height="117px"></Skeleton>
                  ) : (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const queryParams = new URLSearchParams();
                        queryParams.append("city", city);
                        queryParams.append("storetype", "Dairy");
                        const queryString = queryParams.toString();
                        navigate(`/searchstore?${queryString}`);
                      }}
                    >
                      <ImageLoader src={storeadd2} className="ad_small_img" width="auto" height="120px" alt="" />
                    </div>
                  )}
                </div>
                <div className="col d-flex align-items-center justify-content-end">
                  {!showimg ? (
                    <Skeleton width="255px" height="117px"></Skeleton>
                  ) : (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const queryParams = new URLSearchParams();
                        queryParams.append("city", city);
                        queryParams.append("storetype", "Groceries");
                        const queryString = queryParams.toString();
                        navigate(`/searchstore?${queryString}`);
                      }}
                    >
                      <ImageLoader src={storeadd4} className="ad_small_img" width="auto" height="120px" alt="" />
                    </div>
                  )}
                </div>

                <div className="col d-flex align-items-center justify-content-end">
                  {!showimg ? (
                    <Skeleton width="255px" height="117px"></Skeleton>
                  ) : (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const queryParams = new URLSearchParams();
                        queryParams.append("city", city);
                        queryParams.append("storetype", "Fruits");
                        const queryString = queryParams.toString();
                        navigate(`/searchstore?${queryString}`);
                      }}
                    >
                      <ImageLoader src={storeadd3} className="ad_small_img" width="auto" height="120px" alt="" />
                    </div>

                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Recommended organic stores */}
      {storeRecData && storeRecData.length > 0 ? (
        <div className="background-color container-fluid pb-5 p-0">
          <div className="container ">
            <div className="d-flex flex-column">
              <div className="rest-main mb-2 mt-5">
                <h5 className="d-flex Card-heading font_subhead ">
                  Recommended Organic Stores
                </h5>
              </div>
            </div>
            <RecommendedStoreCard setNVisible={setNvisible} storeRecData={storeRecData} />
          </div>
        </div>
      ) : (
        ""
      )}

      {/* popular localities */}
      <div className="container-fluid p-0">
        <div className="container">
          <div className="d-flex flex-column ">
            <div className="rest-main mb-2">
              <h5 className="d-flex Card-heading font_subhead mt-5">
                Popular localities in {city}
              </h5>
            </div>

            <Localities
              locality={locList}
              city={localStorage.getItem("city") as string}
              type={"organicstore"}
            />
          </div>
        </div>
      </div>
      {/* footer */}
      <div className="row">
        <Footer localities={list} city={city} search={search} type={"organicstore"} />
      </div>

    </div>
  );
}

export default HomeOrganicStores;
