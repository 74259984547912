import React, { useRef, useState } from 'react';
import "./restaurantMenu.component.css";
import { devsas } from '../../../config/constants';
import { Image } from "primereact/image";
import { Galleria } from 'primereact/galleria';
import nomenu from "../../../assets/images/nomenu.png"

type Props = {
    restaurantmenuimages: any[];
};

const RestaurantMenu: React.FC<Props> = ({ restaurantmenuimages }) => {
    const galleria = useRef<Galleria>(null);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    console.log(restaurantmenuimages);

    const itemTemplate = (item: any) => {
        return <img src={item + "?" + devsas} alt={item.alt} style={{ width: '100%', display: 'block' }} />;
    };

    const thumbnailTemplate = (item: any) => {
        return <img src={item + "?" + devsas} alt={item.alt} style={{ display: 'block' }} />;
    };

    return (
        <div className='d-flex flex-wrap pt-2 rest-img row'>
            {restaurantmenuimages.length === 0 ? (
                <div className='d-flex justify-content-center flex-wrap gap-3'>
                    <img src={nomenu} alt='no menu' />
                    <p className='nomenutext'>Restaurant's menu is currently unavailable</p>
                </div>
            ) : (
                <>
                    <Galleria
                        ref={galleria}
                        value={restaurantmenuimages}
                        numVisible={7}
                        style={{ maxWidth: '850px' }}
                        activeIndex={activeIndex}
                        onItemChange={(e) => setActiveIndex(e.index)}
                        fullScreen
                        showItemNavigators
                        showThumbnails={false}
                        item={itemTemplate}
                        thumbnail={thumbnailTemplate}
                    />
                    <div className="grid" style={{ maxWidth: '400px' }}>
                        {restaurantmenuimages.map((image: any, index: number) => (
                            <div className="col-4" key={index}>
                                <img
                                    src={image + "?" + devsas}
                                    alt={image.alt}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setActiveIndex(index);
                                        galleria.current?.show();
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default RestaurantMenu;
