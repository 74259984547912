import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const DietitianFilters: React.FC<any> = ({ dietitianFilters,
    setDietitianFilters,
    onFiltersUpdate, }) => {

    const languages = ["Telugu", "Hindi", "Tamil", "English"];
    const gender = ["Male", "Female"];
    const experience = ["1 to 5 Years", "5 to 10 Years", "10 to 15 Years", "15 to 20 Years", "20+ Years"];
    const specialties = ["Clinical Dietitian", "Sports Dietitian", "Pediatric Dietitian", "Gerontological Dietitian", "General Dietitian"];
    const moc = ["Online", "Offline"];

    const [isChecked, setIsChecked] = useState(false);

    const updateFilters = (key: any, value: any) => {

        setDietitianFilters((prevFilters: any) => {

            let updatedFilters = { ...prevFilters };

            if (key === "experience") {
                updatedFilters[key] = [value];
            } else if (key === "gender") {
                updatedFilters[key] = [value];
            } else {
                if (updatedFilters[key] && !updatedFilters[key].includes(value)) {
                    updatedFilters[key] = [...updatedFilters[key], value];
                } else {
                    updatedFilters[key] = [value];
                }
            }
            onFiltersUpdate(updatedFilters);
            return updatedFilters;
        });
    };


    const removeFilter = (key: string, valueToRemove: string) => {
        setDietitianFilters((prevFilters: any) => {
            const updatedFilters = { ...prevFilters };

            if (updatedFilters[key]) {
                if (key === "gender") {
                    updatedFilters[key] = "";
                } else {
                    updatedFilters[key] = updatedFilters[key].filter(
                        (value: string) => value !== valueToRemove
                    );
                    if (updatedFilters[key].length === 0) {
                        delete updatedFilters[key];
                    }
                }
            }

            return updatedFilters;
        });
    };

    const onSelect = (e: any, type: any) => {
        updateFilters(type, e);
        setIsChecked(true);
    };

    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    const isActive = (section: any) =>
        splitLocation[1] === section
            ? "nav-link nav-link-active"
            : "nav-link signup";

    const [isLanguageOpen, SetIslanguageOpen] = useState(true);
    const handleLanguagedropdownToggle = () => {
        SetIslanguageOpen(!isLanguageOpen);
    };
    const [isGenderOpen, setIsgenderOpen] = useState(true);
    const handleGenderdropdownToggle = () => {
        setIsgenderOpen(!isGenderOpen);
    };
    const [isExpOpen, setIsexpOpen] = useState(true);
    const handleexpdropdownToggle = () => {
        setIsexpOpen(!isExpOpen);
    }
    const [isSpopen, setIsSpopen] = useState(true);
    const handlespdropdownToggle = () => {
        setIsSpopen(!isSpopen);
    }
    const [isMocOpen, setIsMocOpen] = useState(true);
    const handlemocdropdowntoggle = () => {
        setIsMocOpen(!isMocOpen);
    }
    const handleToggle = () => {
        setIsChecked(!isChecked);
    };

    return (
        <div className="d-flex flex-column mt-2 gap-2">


            <label className="d-flex gap-2 align-items-center my-2">
            <input
                                        className="check-wrapper ml-1"
                                        type="checkbox"
                                        id={`custom-checkbox`}
                                        name=""
                                        checked={
                                            dietitianFilters?.moc &&
                                            dietitianFilters?.moc.includes()
                                        }
                                      
                                     
                                    />
                <span className="filterItemData">Registered Dietitian</span>
            </label>

            <div className="">
                <a
                    className={isActive("Specialties")}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#f5f5f5",
                        padding: "0px 5px",
                    }}
                    onClick={handlespdropdownToggle}
                >
                    <h6 className="mt-2 align-left filterheading">Specialties</h6>
                    <i
                        className={`pi ${isSpopen ? "pi-angle-up" : "pi-angle-down"
                            }`}
                        style={{ fontSize: "1rem", color: "#3C3C3C" }}
                    ></i>
                </a>

                {isSpopen && (
                    <div className="submenu p-0 mt-3 mb-3 gap-3  language-submenu">
                        {specialties.map((e: any, index) => (
                            <div key={index} className="filter-item">
                                <label className="d-flex gap-2">
                                    <input
                                        className="check-wrapper ml-1"
                                        type="checkbox"
                                        id={`custom-checkbox-${index}`}
                                        name=""
                                        checked={
                                            dietitianFilters?.specialties &&
                                            dietitianFilters?.specialties.includes(e)
                                        }
                                        onChange={(event) =>
                                            event.target.checked
                                                ? onSelect(e, "specialties")
                                                : removeFilter("specialties", e)
                                        }
                                        value={e}
                                    />
                                    <span className="filterItemData">{e}</span>
                                </label>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="">
                <a
                    className={isActive("language")}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#f5f5f5",
                        padding: "0px 5px",
                    }}
                    onClick={handleLanguagedropdownToggle}
                >
                    <h6 className="mt-2 align-left filterheading">Language</h6>
                    <i
                        className={`pi ${isLanguageOpen ? "pi-angle-up" : "pi-angle-down"
                            }`}
                        style={{ fontSize: "1rem", color: "#3C3C3C" }}
                    ></i>
                </a>

                {isLanguageOpen && (
                    <div className="submenu p-0 mt-3 mb-3 gap-3  language-submenu">
                        {languages.map((e: any, index) => (
                            <div key={index} className="filter-item">
                                <label className="d-flex gap-2">
                                    <input
                                        className="check-wrapper ml-1"
                                        type="checkbox"
                                        id={`custom-checkbox-${index}`}
                                        name=""
                                        checked={
                                            dietitianFilters?.languages &&
                                            dietitianFilters?.languages.includes(e)
                                        }
                                        onChange={(event) =>
                                            event.target.checked
                                                ? onSelect(e, "languages")
                                                : removeFilter("languages", e)
                                        }
                                        value={e}
                                    />
                                    <span className="filterItemData">{e}</span>
                                </label>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="">
                <a
                    className={isActive("gender")}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#f5f5f5",
                        padding: "0px 5px",
                    }}
                    onClick={handleGenderdropdownToggle}
                >
                    <h6 className="mt-2 align-left filterheading">Gender</h6>
                    <i
                        className={`pi ${isGenderOpen ? "pi-angle-up" : "pi-angle-down"
                            }`}
                        style={{ fontSize: "1rem", color: "#3C3C3C" }}
                    ></i>
                </a>

                {isGenderOpen && (
                    <div className="submenu p-0 mt-3 mb-3 gap-3  language-submenu">
                        {gender.map((e: any, index) => (
                            <div key={index} className="filter-item">
                                <label className="d-flex gap-2">
                                    <input
                                        className="check-wrapper ml-1"
                                        type="radio"
                                        id={`custom-checkbox-${index}`}
                                        name="gender"
                                        checked={
                                            dietitianFilters?.gender && dietitianFilters?.gender.includes(e)
                                        }
                                        onChange={(event) =>
                                            event.target.checked
                                                ? onSelect(e, "gender")
                                                : removeFilter("gender", e)
                                        }
                                        value={e}
                                    />
                                    <span className="filterItemData">{e}</span>
                                </label>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="">
                <a
                    className={isActive("experience")}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#f5f5f5",
                        padding: "0px 5px",
                    }}
                    onClick={handleexpdropdownToggle}
                >
                    <h6 className="mt-2 align-left filterheading">Experience</h6>
                    <i
                        className={`pi ${isExpOpen ? "pi-angle-up" : "pi-angle-down"
                            }`}
                        style={{ fontSize: "1rem", color: "#3C3C3C" }}
                    ></i>
                </a>

                {isExpOpen && (
                    <div className="submenu p-0 mt-3 mb-3 gap-3  language-submenu">
                        {experience.map((e: any, index) => (
                            <div key={index} className="filter-item">
                                <label className="d-flex gap-2">
                                    <input
                                        className="check-wrapper ml-1"
                                        type="radio"
                                        id={`custom-checkbox-${index}`}
                                        name="experience"
                                        checked={
                                            dietitianFilters?.experience &&
                                            dietitianFilters?.experience.includes(e)
                                        }
                                        onChange={(event) =>
                                            event.target.checked
                                                ? onSelect(e, "experience")
                                                : removeFilter("experience", e)
                                        }
                                        value={e}
                                    />
                                    <span className="filterItemData">{e}</span>
                                </label>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="">
                <a
                    className={isActive("modeofconsultation")}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#f5f5f5",
                        padding: "0px 5px",
                    }}
                    onClick={handlemocdropdowntoggle}
                >
                    <h6 className="mt-2 align-left filterheading">Mode of Consultation</h6>
                    <i
                        className={`pi ${isMocOpen ? "pi-angle-up" : "pi-angle-down"
                            }`}
                        style={{ fontSize: "1rem", color: "#3C3C3C" }}
                    ></i>
                </a>

                {isMocOpen && (
                    <div className="submenu p-0 mt-3 mb-6 gap-3  language-submenu">
                        {moc.map((e: any, index) => (
                            <div key={index} className="filter-item">
                                <label className="d-flex gap-2">
                                    <input
                                        className="check-wrapper ml-1"
                                        type="checkbox"
                                        id={`custom-checkbox-${index}`}
                                        name=""
                                        checked={
                                            dietitianFilters?.moc &&
                                            dietitianFilters?.moc.includes(e)
                                        }
                                        onChange={(event) =>
                                            event.target.checked
                                                ? onSelect(e, "modeofconsultation")
                                                : removeFilter("modeofconsultation", e)
                                        }
                                        value={e}
                                    />
                                    <span className="filterItemData">{e}</span>
                                </label>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}

export default DietitianFilters;
