import HttpInstance from "./http.api";

type ResObj={
name:string,
email:string,
message:string,
}
export const SubmitMessage = (resObj:ResObj)=>{
const respData= HttpInstance.post("/contactus/createusermessage",resObj)
return respData
}