import React, { useEffect, useLayoutEffect, useState } from "react";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";

import "./dietitianMap.component.css";
import Locationmarker from "../../assets/images/Location.svg";
import locationflip from "../../assets/images/locationflip.svg"
import { devsas } from "../../config/constants";

L.Icon.Default.mergeOptions({
  iconRetinaUrl: Locationmarker,
  iconUrl: Locationmarker,
  shadowUrl: "",
});
interface results {
  onClick: any;
}

const location = [
  { lat: 17.4175901, lng: 78.3991192 },
  { lat: 13.0511668, lng: 80.0751056 },
  { lat: 22.572645, lng: 88.363892 },
  { lat: 12.9716, lng: 77.5946 },
  { lat: 19.07609, lng: 72.877426 },
  { lat: 28.6448, lng: 77.216721 },
];
const zoom = 10;
type IconUrl = string;

const DietitianMap: React.FC<any> = ({onClick, data, city,clickedMarkerIndex, onMarkerClick }) => {
  const  [locations,setLocations] = useState<any[]>(location)
  const [iconForMarkers, setIconForMarkers] = useState<IconUrl>(Locationmarker);
  const handleCardClick = (id: any) => {
    onClick(id);
  };
  const handleMarkerClick = (index: number) => {
    onMarkerClick(index);
  };
  const returncenter = () => {
    const cityname= JSON.parse(city)["city"]
    if (cityname === "Hyderabad") {
      return 0;
    } else if (cityname === "Chennai") {
      return 1;
    } else if (cityname === "Kolkata") {
      return 2;
    } else if (cityname === "Bengaluru") {
      return 3;
    } else if (cityname === "Mumbai") {
      return 4;
    } else if (cityname === "New Delhi") {
      return 5;
    } else {
      return 6;
    }
  };

  console.log("object of map data",data);

  const [num, setNum] = useState<number>(returncenter());

  const [renderMap, setRenderMap] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      await HandleSubmit();
      setNum(returncenter());
    };

    fetchData();
    setTimeout(() => setRenderMap(true), 1000);
  }, []);
  const HandleSubmit = async () => {
    try {
      const position = await getCurrentPosition();
      setLocations([
        ...location,
        { lat: position.coords.latitude, lng: position.coords.longitude },
      ]);
    } catch (error) {
      console.error("Error retrieving current position:", error);
    }
  };
  const getCurrentPosition = (): Promise<GeolocationPosition> => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position),
        (error) => reject(error)
      );
    });
  };
  var myIcon = L.icon({
    iconUrl: Locationmarker,
    iconSize: [38, 95],
    iconAnchor: [22, 94],
    popupAnchor: [-3, -76],
  });
  
  useEffect(() => {
    setRenderMap(false);
    setNum(returncenter());
    setTimeout(() => setRenderMap(true), 1000);
  }, [city]);
  useEffect(() => {
    if (clickedMarkerIndex !== null) {
      setIconForMarkers(locationflip);
    } else {
      setIconForMarkers(Locationmarker);
    }
  }, [clickedMarkerIndex]);
  return (
    <>
      {renderMap && (
        <>
          <MapContainer className="map" center={locations[num]} zoom={zoom}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {data?.map((item: any, i: any) => {
                                    const isMarkerClicked = i === clickedMarkerIndex;
                                    const iconSize: L.PointTuple = isMarkerClicked ? [40, 40] : [24, 40];
              return (
                <Marker
                  position={{
                    lat: item.location.coordinates[1],
                    lng: item.location.coordinates[0],
                  }}
                  key={i}
                  eventHandlers={{
                    click: () => {
                      handleCardClick(item._id);
                      handleMarkerClick(i);
                    },
                  }}
                  icon={L.icon({
                    iconUrl: isMarkerClicked ? locationflip : Locationmarker,
                    iconSize: iconSize,
                    iconAnchor: [12, 41],
                  })}

                >
                </Marker>
              );
            })}
          
          </MapContainer>
        </>
      )}
    </>
  );
};

export default DietitianMap;
