import React, { useEffect, useRef, useState } from 'react';
import "./userAddRestaurant.pages.css";
import Navbar from '../../components/UI/Navbar/navBar.component';
import Footer from '../../components/UI/Footer/footer.component';
import { InputText } from "primereact/inputtext";
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from 'primereact/button';
import getlocation from "../../assets/icons/getlocation.svg"
import { Dropdown } from 'primereact/dropdown';
import DropFileinput from '../../components/UI/DropFileInput/dropFileinput.component';
import { SubmitUserRestaurant } from '../../api/user.api';
import { Dialog } from 'primereact/dialog';
import subtract from "../../assets/icons/Subtract.svg";
import { yeldamwebimagessas } from '../../config/constants';
import { Controller, useForm } from "react-hook-form";
import { classNames } from 'primereact/utils';
import { Toast } from 'primereact/toast';


type resObj = {

    name: string;
    address: string;
    street: string;
    city: string;
    state: string;
    district: string;
    pincode: string;
    mobileno: string;
    images: any[];
};

const initialState = {

    name: "",
    address: "",
    street: "",
    city: "",
    state: "",
    district: "",
    pincode: "",
    mobileno: "",
    images: []
};

const UserAddRestaurant: React.FC<any> = () => {
    const [resObj, setResObj] = useState<resObj>(initialState);
    const [restaurantImages, setRestantImages] = useState<any[]>([]);
    const [search, setSearch] = useState<string[]>([]);
    const [city, setCity] = useState("");
    const [list, setList] = useState<string[]>([]);
    const [visible, setVisible] = useState<boolean>(false);
    const [counter, setCounter] = useState(5);
    const [countdownActive, setCountdownActive] = useState(false);
    const toast = useRef<Toast>(null);


    const ResetForm = () => {
        console.log("resetting the form");
        window.location.reload();
        setResObj(initialState);
    };


    const SubmitUserResObj = async () => {

        const show = (message: any, severity: any, summary: any) => {
            console.log("ouside of toast");
            if (toast.current) {
                console.log("going in toast");
                toast.current.show({
                    severity: severity,
                    summary: summary,
                    detail: message,
                });
            } else {
                console.log("toast issue");
            }
        };


        const emptyFields = Object.entries(resObj).filter(([key, value]) => {
            if (Array.isArray(value)) {
                return value.length === 0;

            } else {
                return typeof value !== 'string' || value.trim() === '';
            }
        });

        if (emptyFields.length > 0) {
            setVisible(false);
            setCountdownActive(false);
            show("Please check all Mandatory filed", "warn", "Warn");
            return;
        }

        const formData = new FormData();
        formData.append('name', resObj.name);
        formData.append('address', resObj.address);
        formData.append('street', resObj.street);
        formData.append('city', resObj.city);
        formData.append('state', resObj.state);
        formData.append('district', resObj.district);
        formData.append('pincode', resObj.pincode);
        formData.append('mobileno', resObj.mobileno);

        restaurantImages.forEach((image, index) => {
            formData.append(`images`, image);
        });

        try {
            const response = await SubmitUserRestaurant(formData);
            if (response.status === 201) {
                console.log("Response:", response.data);
                setVisible(true);
                setCountdownActive(true);
                setTimeout(() => {
                    ResetForm();
                }, 2000);
            } else {
                setVisible(false);
                setCountdownActive(false);
            }
        } catch (error) {
            console.error("Error submitting restaurant details:", error);
            setVisible(false);
            setCountdownActive(false);
            show("Please check all Mandatory filed", "warn", "Warn");
        }
    };




    console.log("resssss", resObj);

    useEffect(() => {
        let countdown: any;
        if (countdownActive && counter > 0) {
            countdown = setTimeout(() => setCounter(counter - 1), 1000);
        } else if (counter === 0) {
            setCountdownActive(false);
        }

        return () => clearTimeout(countdown);
    }, [counter, countdownActive]);

    useEffect(() => {
        if (counter === 0) {
            window.location.href = "/";
        }
    });
    console.log("count", counter);

    const defaultValues = {
        restaurantname: "",
        areaStreetSector: "",
        address: "",
        city: "",
        district: "",
        state: "",
        pincode: "",
        phoneNumber: ""
    };

    const {
        control,
        formState: { errors },
        handleSubmit,
        // getValues,
        reset,
    } = useForm({ defaultValues });

    const getFormErrorMessage = (name: string) => {
        const errorObject = errors[name as keyof typeof defaultValues];

        return errorObject ? (
            <small className="p-error">{errorObject.message}</small>
        ) : (
            <small className="p-error">&nbsp;</small>
        );
    };



    return (
        <>
            <div className="container-fluid add-body-div p-0">
                <Toast ref={toast}></Toast>
                <div className="row">
                    <Navbar />
                </div>

                <div className='col-lg-12 d-flex container pt-4 pb-4'>
                    <div className='col-lg-5 left-image-div d-flex justify-content-center align-items-center'>
                        <img className='add-rest-image' src={"https://yeldamimages.azureedge.net/yeldamwebimages/webp/Homepage/Add-Restaurant/addrestimg.webp" + "?" + yeldamwebimagessas} alt='' />
                    </div>
                    <div className='col-lg-7'>
                        <div className='restaurant-details-box'>
                        <div className='headhr'>
                               <span className='details-head-text'>Restaurant Details</span> 
                                <hr style={{ color: "#ececec", height: "1px", opacity: "100%",marginTop:"16px" }} />
                            </div>
                            <div className='scrolling'>
                                <ScrollPanel style={{ width: "100%", height: "75vh" }}>
                                    <div className='restaurant-details-entry'>

                                        <div className="row gap-2">
                                            <label className="get-input-label">
                                                Restaurant Name <b style={{ color: "red" }}>*</b>
                                            </label>
                                            <div className="flex flex-column email-entry">
                                                <Controller
                                                    name="restaurantname"
                                                    control={control}
                                                    rules={{
                                                        required: "Restaurant Name is required.",

                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            <InputText
                                                                id="Name"
                                                                className={`input-area ${classNames({ "p-invalid": fieldState.error })}`}
                                                                placeholder="Enter Restaurant Name"
                                                                aria-describedby="username-help"
                                                                value={resObj.name}
                                                                onChange={(e) => {
                                                                    setResObj({
                                                                        ...resObj,
                                                                        name: e.target.value,
                                                                    });
                                                                    field.onChange(e);
                                                                }}
                                                            />

                                                            {getFormErrorMessage("name")}
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        {/* <div className="row gap-2">
                                            <div className=" d-flex justify-content-center align-items-center get-address mb-1">
                                                <img className='location-icon' src={getlocation} alt='' />
                                                <Button className="" label="Use My Current Location" severity="success" outlined aria-label="Search"
                                                // onClick={() =>fetchAddress(resObj.latitude,resObj.longitude)}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <hr className="flex-grow-1" />
                                            <span className="mx-2 or">or</span>
                                            <hr className="flex-grow-1" />
                                        </div> */}
                                        <div className="row gap-2">
                                            <label className="get-input-label">
                                                Flat / Building {" "}
                                                <b style={{ color: "red" }}>*</b>
                                            </label>
                                            <div className="flex flex-column email-entry">
                                                <Controller
                                                    name="address"
                                                    control={control}
                                                    rules={{
                                                        required: "Flat / Building is required.",
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            <InputText
                                                                id="FlatOrBuilding"
                                                                className={`input-area ${classNames({ "p-invalid": fieldState.error })}`}
                                                                placeholder="Enter Flat or Building"
                                                                aria-describedby="flatOrBuilding-help"
                                                                value={resObj.address}
                                                                onChange={(e) => {
                                                                    setResObj({
                                                                        ...resObj,
                                                                        address: e.target.value,
                                                                    });
                                                                    field.onChange(e);
                                                                }}
                                                            />

                                                            {getFormErrorMessage("flatOrBuilding")}
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="row gap-2">
                                            <label className="get-input-label">
                                                Area / Street / Sector {" "}
                                                <b style={{ color: "red" }}>*</b>
                                            </label>
                                            <div className="flex flex-column email-entry">
                                                <Controller
                                                    name="areaStreetSector"
                                                    control={control}
                                                    rules={{
                                                        required: "Area / Street / Sector is required.",
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            <InputText
                                                                id="AreaStreetSector"
                                                                className={`input-area ${classNames({ "p-invalid": fieldState.error })}`}
                                                                placeholder="Enter Area or Street or Sector"
                                                                aria-describedby="areaStreetSector-help"
                                                                value={resObj.street}
                                                                onChange={(e) => {
                                                                    setResObj({
                                                                        ...resObj,
                                                                        street: e.target.value,
                                                                    });
                                                                    field.onChange(e);
                                                                }}
                                                            />

                                                            {getFormErrorMessage("areaStreetSector")}
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-6 p-0 pr-2">
                                                <div className="flex flex-column gap-2">
                                                    <label className="get-input-label">
                                                        City <b style={{ color: "red" }}>*</b>
                                                    </label>
                                                    <div className="flex flex-column email-entry">
                                                        <Controller
                                                            name="city"
                                                            control={control}
                                                            rules={{
                                                                required: "City is required.",
                                                            }}
                                                            render={({ field, fieldState }) => (
                                                                <>
                                                                    <InputText
                                                                        id="city"
                                                                        className={`input-area ${classNames({ "p-invalid": fieldState.error })}`}
                                                                        placeholder="Enter City"
                                                                        value={resObj.city}
                                                                        onChange={(e) => {
                                                                            setResObj({
                                                                                ...resObj,
                                                                                city: e.target.value,
                                                                            });
                                                                            field.onChange(e);
                                                                        }}
                                                                    />
                                                                    {getFormErrorMessage("city")}
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 p-0 pl-2">
                                                <div className=" d-flex flex-column gap-2">
                                                    <label className="get-input-label">
                                                        State <b style={{ color: "red" }}>*</b>
                                                    </label>
                                                    <div className="flex flex-column email-entry">
                                                        <Controller
                                                            name="state"
                                                            control={control}
                                                            rules={{
                                                                required: "State is required.",
                                                            }}
                                                            render={({ field, fieldState }) => (
                                                                <>
                                                                    <InputText
                                                                        id="state"
                                                                        className={`input-area ${classNames({ "p-invalid": fieldState.error })}`}
                                                                        placeholder="Enter State"
                                                                        value={resObj.state}
                                                                        onChange={(e) => {
                                                                            setResObj({
                                                                                ...resObj,
                                                                                state: e.target.value,
                                                                            });
                                                                            field.onChange(e);
                                                                        }}
                                                                    />
                                                                    {getFormErrorMessage("state")}
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-6 p-0 pr-2">
                                                <div className="d-flex flex-column gap-2">
                                                    <label className="get-input-label">
                                                        District <b style={{ color: "red" }}>*</b>
                                                    </label>
                                                    <div className="flex flex-column email-entry">
                                                        <Controller
                                                            name="district"
                                                            control={control}
                                                            rules={{
                                                                required: "District is required.",
                                                            }}
                                                            render={({ field, fieldState }) => (
                                                                <>
                                                                    <InputText
                                                                        id="district"
                                                                        className={`input-area ${classNames({ "p-invalid": fieldState.error })}`}
                                                                        placeholder="Enter District"
                                                                        value={resObj.district}
                                                                        onChange={(e) => {
                                                                            setResObj({
                                                                                ...resObj,
                                                                                district: e.target.value,
                                                                            });
                                                                            field.onChange(e);
                                                                        }}
                                                                    />
                                                                    {getFormErrorMessage("district")}
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 p-0 pl-2">
                                                <div className="flex flex-column gap-2">
                                                    <label className="get-input-label">
                                                        Pincode <b style={{ color: "red" }}>*</b>
                                                    </label>
                                                    <div className="flex flex-column email-entry">
                                                        <Controller
                                                            name="pincode"
                                                            control={control}
                                                            rules={{
                                                                required: "Pincode is required.",
                                                            }}
                                                            render={({ field, fieldState }) => (
                                                                <>
                                                                    <InputText
                                                                        id="pincode"
                                                                        maxLength={6}
                                                                        className={`input-area ${classNames({ "p-invalid": fieldState.error })}`}
                                                                        placeholder="Enter Pincode"
                                                                        value={resObj.pincode}
                                                                        keyfilter={/^[0-9]*$/}
                                                                        onChange={(e) => {
                                                                            setResObj({
                                                                                ...resObj,
                                                                                pincode: e.target.value,
                                                                            });
                                                                            field.onChange(e);
                                                                        }}
                                                                    />
                                                                    {getFormErrorMessage("pincode")}
                                                                </>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row gap-2">
                                            <label className="get-input-label">
                                                Phone Number {" "}
                                                <b style={{ color: "red" }}>*</b>
                                            </label>
                                            <div className="flex flex-column email-entry">
                                                <Controller
                                                    name="phoneNumber"

                                                    control={control}
                                                    rules={{
                                                        required: "Phone Number is required.",
                                                        pattern: {
                                                            value: /^[0-9]{10}$/, // You can adjust the pattern based on your phone number requirements
                                                            message: "Invalid Phone Number.",
                                                        },
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <>
                                                            <InputText
                                                                id="phoneNumber"
                                                                className={`input-area ${classNames({ "p-invalid": fieldState.error })}`}
                                                                placeholder="Enter Phone Number"
                                                                aria-describedby="phoneNumber-help"
                                                                value={resObj.mobileno}
                                                                keyfilter={/^[0-9\+-]$/}
                                                                maxLength={14}
                                                                onChange={(e) => {
                                                                    setResObj({
                                                                        ...resObj,
                                                                        mobileno: e.target.value
                                                                    });
                                                                    field.onChange(e);
                                                                }}
                                                            />
                                                            {getFormErrorMessage("phoneNumber")}
                                                        </>
                                                    )}
                                                />
                                            </div>
                                        </div>


                                        <div className='row gap-2 pt-3'>
                                            <label className="get-input-label">
                                                Upload Images <b style={{ color: "red" }}>*</b>
                                            </label>
                                            <div>
                                                <DropFileinput
                                                    uploadedFiles={restaurantImages}
                                                    setUploadedFiles={setRestantImages}
                                                    resObj={resObj}
                                                    setResObj={setResObj}
                                                    purpose="RestaurantImages"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </ScrollPanel>
                            </div>
                            <div className='d-flex justify-content-end add-rest-user-submit'>
                                <Button className="addrest_submit_btn" label="Submit" onClick={() => {
                                    SubmitUserResObj()

                                }} severity="success" outlined aria-label="Search"
                                // onClick={() =>fetchAddress(resObj.latitude,resObj.longitude)}
                                />
                            </div>

                        </div>
                    </div>
                </div>
                <div className="row">
                    <Footer localities={list} city={city} search={search}  type={"restaurant"} />
                </div>

            </div>

            <div className='dialog-box'>
                <Dialog
                    visible={visible}
                    closeOnEscape={false}
                    header="Event Details"
                    style={{ width: "30vw" }}
                    className="login-model"
                    breakpoints={{
                        "1920px": "50vw",
                        "1420px": "50vw",
                        "1120px": "50vw",
                        "1090px": "40vw",
                        "980px": "50vw",
                        "840px": "60vw",
                        "740px": "50vw",
                        "641px": "70vw",
                        "541px": "70vw",
                        "490px": "80vw",
                        "350px": "90vw",
                    }}
                    onHide={() => setVisible(false)}
                >
                    <div className="d-flex flex-column justify-content-center align-items-center pt-3 pb-3">
                        <img className="thank-up-img mb-3" src={subtract} alt="" />
                        <p className="Thank-you">Thank You</p>
                        <p className="thank-down-content">
                            Your restaurant suggestion has been submitted. We will review it and add it to our website as soon as possible.
                        </p>
                        <p className="redirect">
                            Auto redirection to home page in{" "}
                            <span style={{ color: "#36a41d" }}>{counter} seconds</span>
                        </p>
                    </div>

                </Dialog>
            </div>
        </>
    );
}

export default UserAddRestaurant;
