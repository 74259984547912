import React, { useState } from "react";
import { Card } from "primereact/card";
import "./dietitiansNearYou.component.css";
import dietitianimage from "../../assets/images/dietitiansimage.png";
import { Rating } from "react-simple-star-rating";
import { devsas } from "../../config/constants";
import { useNavigate } from "react-router-dom";
import { LikeDietitian } from "../../api/dietitians.api";
import { Dialog } from "primereact/dialog";
import SignInSignUp from "../UI/SigninSignup/signInSignUp.component";
// type props={
//     dietitianname:string;
//     dietitianimg:any;
//     speciality:string;
//     experiance:string;
//     education:string;
//     rating:string;
//     count:string

// }
interface LikedDietitian {
  _id: string;
  // Other properties from getlikedcuisines
}
interface Props {
  getlikeddietitians: LikedDietitian[];
}
const DietitiansNearYou: React.FC<any> = ({ setNvisible, dietitiansData }) => {
  const [ndataa, setNdata] = useState<any>(dietitiansData);
  const [signinvisible, setSigninVisible] = useState<boolean>(false);
  const [likedItems, setLikedItems] = useState<string[]>([]);
  const navigate = useNavigate();

  const LikeFunction = async (id:string, isLiked:boolean, isApiLiked:boolean) => {
    // Check if the user is authenticated
    if (!localStorage.getItem("accessToken")) {
      setSigninVisible(true);
      return;
    }
  
    let newLikedStatus = isApiLiked ? false : !isLiked;
    try {
      // Optimistically update UI
      console.log(newLikedStatus ? "liked" : "not liked");
      if (newLikedStatus) {
        // triggerConfetti();
        setLikedItems([...likedItems, id]);
      } else {
        setLikedItems(likedItems.filter((itemId) => itemId !== id));
      }
      setNdata((prevNdata:any) =>
        prevNdata.map((item:any) =>
          item._id === id ? { ...item, isLiked: newLikedStatus } : item
        )
      );
  
      // Make API Call
      const resData = await LikeDietitian(id, newLikedStatus);
    } catch (error) {
      console.error("Error:", error);
      // Revert UI changes on error (optional)
      if (newLikedStatus) {
        setLikedItems(likedItems.filter((itemId) => itemId !== id));
      } else {
        setLikedItems([...likedItems, id]);
      }
      setNdata((prevNdata:any) =>
        prevNdata.map((item:any) =>
          item._id === id ? { ...item, isLiked: !newLikedStatus } : item
        )
      );
    }
  };
  const handleMetersChange = (meters: any) => {
    const metersValue = meters;
    const kilometersValue = (metersValue / 1000).toFixed(2);
    return kilometersValue;
  };
  console.log("dietdata:", ndataa);
  return (
    <div className="d-flex scrolling-wrapper rest-card-detail dietMcard pb-5  card_main justify-content-between">
      {ndataa?.slice(0, 5)?.map((item: any, i: any) => (
        <div className="" key={"near" + item._id}>
          <Card
            className="dietitianscard"
            onClick={() => {
              const queryParams = new URLSearchParams();
              queryParams.append("city", item.city);
              queryParams.append("dietitianname", item.dietitianname);
              queryParams.append("id", item._id);
              const queryString = queryParams.toString();
              navigate(`/searchdietitian?${queryString}`);
            }}
          >
            {" "}

     
            <div className="like-icon-diet">
              <i
                className={
                  likedItems.includes(item._id) || item.isLiked
                    ? "pi pi-heart-fill cuisine-like-icon"
                    : "pi pi-heart"
                }
                onClick={(e) => {
                  e.stopPropagation();
                  LikeFunction(
                    item._id,
                    likedItems.includes(item._id),
                    item.isLiked
                  );
                }}
              ></i>
            </div>
            <div className="d-flex justify-content-center">
              <img
                className="dietitianimage"
                src={item.dietitianimages[0] + "?" + devsas}
              />
              
            </div>
            <div className="d-flex flex-column justify-content-center dietitianinfo mt-3">
              <p className="diettianname m-0">{item.dietitianname}</p>
              <p className="speciality mb-1"> {item.speciality.join("/")}</p>
              <p className="experiance mb-1">{item.experience} Years Experience</p>
              <p className="education mb-1">
                <i className="bi bi-geo-alt" style={{ fontSize: "12px" }}></i>{" "}
                {handleMetersChange(item.Distance)} Kms
              </p>
              <div className="rating-review-count d-flex gap-1 align-items-center justify-content-center">
                <span className="rating-value">
                  {parseFloat(item.rating).toFixed(1)}
                </span>
                <Rating
                  initialValue={parseFloat(item.rating)}
                  readonly={true}
                  transition
                  size={16}
                  allowFraction
                />
                <span
                  className="review-count-value"
                  style={{ color: "#3c3c3c" }}
                >
                  ({item.reviewCount})
                </span>
              </div>
            </div>
          </Card>
        </div>
      ))}
      <Dialog
        visible={signinvisible}
        closeOnEscape={false}
        // style={{ width: "50vw" }}
        className="login-model"
        // breakpoints={{
        //   "1920px": "50vw",
        //   "1420px": "50vw",
        //   "1120px": "50vw",
        //   "1090px": "40vw",
        //   "980px": "50vw",
        //   "840px": "60vw",
        //   "740px": "50vw",
        //   "641px": "70vw",
        //   "541px": "70vw",
        //   "490px": "80vw",
        //   "350px": "90vw",
        // }}
        onHide={() => setSigninVisible(false)}
      >
        <SignInSignUp setVisible={setSigninVisible} />
      </Dialog>
    </div>
  );
};

export default DietitiansNearYou;
