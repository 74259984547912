import axios, { AxiosResponse } from "axios";
import HttpInstance from "./http.api";
import { apiUrl } from "../config/constants";

const HttpInstanceImage = axios.create({
  baseURL: apiUrl,
  headers: {
    Accept: "*/*",
    "Content-Type": "multipart/form-data",
  },
});
export const NearestDietitians = async (lat: any, long: any, count: Number) => {
  const respObj = await HttpInstance.post("/dietitians/nearestdietitians", {
    latitude: lat,
    longitude: long,
    count: count,
  });
  return respObj;
};

export const DietitiansRecommended = async (
  city: string, // Ensure city is actually a string
  count: number // Ensure count is actually a number
) => {
  const cityFromStorage = localStorage.getItem("city") || "";
  const parsedCity = JSON.parse(cityFromStorage);

  const respObj = await HttpInstance.post("/dietitians/recommendeddietitians", {
    city: parsedCity?.city || "",
    count,
  });
  return respObj;
};

export const Dietitiansearch = (city: string, queryTerm: string) => {
  const respObj = HttpInstance.get(
    "/dietitians/dietitiansearchtemplate?searchTerm=" +
      queryTerm +
      "&city=" +
      city
  );
  return respObj;
};

export const AdvDietitianSearch = (
  id: string,
  city: string,
  searchTerm: string,
  clinicaddress: string,
  locality: string,
  gender: string,
  latitude: string,
  longitude: string,
  experience: string,
  experienceMin: string,
  experienceMax: string,
  speciality: string,
  languages: string,
) => {
  const respobj = HttpInstance.get(
    `/dietitians/dietitiansearch?id=${id}&city=${city}&searchTerm=${searchTerm}&clinicaddress=${clinicaddress}&locality=${locality}&gender=${gender}&latitude=${latitude}&longitude=${longitude}&experience=${experience}&experienceMin=${experienceMin}&experienceMax=${experienceMax}&specialty=${speciality}&languages=${languages}`
  );
  return respobj;
};
export const CheckDietitianLike = (dietitianID:string)=>{
  const respObj = HttpInstance.get('/dietitians/checkdietitianlike?dietitianID='+dietitianID)
  return respObj
}
export const LikeDietitian = (dietitianID:string,liked:boolean) =>{
  const respobj = HttpInstance.post('/dietitians/likedietitian',{dietitianID:dietitianID,liked:liked})
  return respobj
}
export const GiveDietitianReview =(dietitianID:string,review:string,totalrating:number)=>{
  const respObj=HttpInstance.post('/dietitians/adddietitianreview',{
      "dietitianID": dietitianID,
      "review": review,
      "totalrating":totalrating
  })
  return respObj;
}
export const GetDietitianReviews = (dietitianID: string) =>{
  const respobj = HttpInstance.post ('/dietitians/getreviewsbydietitianid?dietitianID='+dietitianID)
  return respobj;
}