import React, { useEffect, useRef, useState } from "react";
import "./dietitianDetails.component.css";
import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { devsas } from "../../config/constants";
import { Image } from "primereact/image";
import dietitianpic from "../../assets/images/dietitianpic.png";
import allphotos from "../../assets/images/allphotos.png";
import { TabPanel, TabView } from "primereact/tabview";
import DietitianOverview from "../DietitiansOverview/dietitianOverview.component";
import DietitianAbout from "../DietitianAbout/dietitianAbout.component";
import DietitianPhotos from "../DietitianPhotos/dietitianPhotos.component";
import DietitianReviews from "../DietitianReviews/dietitianReviews.component";
import { useDietitianLike } from "../LikeContext/dietitianlikecontext";
import { Dialog } from "primereact/dialog";
import SignInSignUp from "../UI/SigninSignup/signInSignUp.component";
import { Rating } from "react-simple-star-rating";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import {
  GetDietitianReviews,
  GiveDietitianReview,
} from "../../api/dietitians.api";
import { GetdietitianRatingsCount } from "../../api/ratings.api";
import { Skeleton } from "primereact/skeleton";

type props = {
  onClose: any;
  dietitiansdata: any;
  isLiked: boolean;
  onLikeToggle: () => void;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
  activeIndex: number;
};

const DietitianDetails: React.FC<props> = ({
  onClose,
  dietitiansdata,
  activeIndex,
  setActiveIndex,
}) => {
  console.log("dietitians data:", dietitiansdata);

  const [signinvisible, setSigninVisible] = useState<boolean>(false);
  const { likedDietitians, toggleDietitianLike } = useDietitianLike();
  const isLikeddietitian = likedDietitians.includes(dietitiansdata._id);
  const [visible, setVisible] = useState<boolean>(false);
  const [allowDietitianReview, setAllowDietitianReview] = useState(false);
  const [dietitianreviewError, setDietitianReviewError] =
    useState<boolean>(false);
  const [reviewValue, setReviewvalue] = useState<string>("");
  const [totalrating, setTotalRating] = useState<number>(0);
  const [sdisable, setSdisable] = React.useState(false);
  const [reviewsList, setReviewList] = useState<any>([]);
  const [skvisible, setskvisible] = useState(false);
  const [ratingsCount, setratingsCount] = useState([]);

  const toast = useRef<Toast>(null);

  const handleLikeClick = () => {
    toggleDietitianLike(dietitiansdata._id, dietitiansdata.isLiked);
  };
  const AddReview = () => {
    if (localStorage.getItem("accessToken") === null) {
      setSigninVisible(true);
    } else {
      setVisible(true);
    }
  };
  const GettingReview = async (id: string) => {
    const reviews = await GetDietitianReviews(id as string);
    console.log("object reviews and rates", reviews);
    setAllowDietitianReview(
      reviews.data.data.some(
        (obj: any) => obj["userID"] === localStorage.getItem("userid")
      )
    );
    setReviewList(reviews.data.data);
  };

  const GettingCount = async (id: string) => {
    const counting = await GetdietitianRatingsCount(id as string);
    console.log("diet per%%%", counting.data.data.ratings);
    setratingsCount(counting.data.data.ratings);
  };

  const show = (message: any, severity: any, summary: any) => {
    if (toast.current) {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
        life: 2000,
      });
    } else {
    }
  };
  const SubmitDietitianReview = async () => {
    setSdisable(true);

    if (totalrating && reviewValue) {
      await GiveDietitianReview(dietitiansdata._id, reviewValue, totalrating)
        .then((response: any) => {
          if (response.status === 201) {
            show("Success", "info", "Success");
            setVisible(false);
            setAllowDietitianReview(true);
            setTotalRating(0);
            setReviewvalue("");
            GettingReview(dietitiansdata._id);
            GettingCount(dietitiansdata._id);
          } else {
            setSdisable(false);
            show("Please fill correct details", "error", "Error");
          }
        })
        .catch((error: any) => {
          setSdisable(false);
          show(error, "error", "Error");
        });
    } else {
      if (!reviewValue) {
        setDietitianReviewError(true);
      } else {
        setDietitianReviewError(false);
      }
      show("Please fill mandatory fields", "warn", "Warning");
      setSdisable(false);
    }
  };
  const handleRating = (rate: number) => {
    setTotalRating(rate);
  };
  const handleClose = () => {
    onClose();
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      setskvisible(true);
    }, 1000);
    GettingReview(dietitiansdata._id);
    GettingCount(dietitiansdata._id);
    setSdisable(false);
    setskvisible(false);
    return () => clearTimeout(timer);
  }, [dietitiansdata]);
  return (
    <>
      <Toast ref={toast}></Toast>
      <ScrollPanel style={{ width: "100%", height: "100%" }}>
        {!skvisible ? (
          <div className="p-2">
            <Skeleton width="100%" height="210px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
            <br></br>
            <Skeleton width="100%" height="20px" ></Skeleton>
          </div>
        ) : (
          <section className="page-content details-content p-0">
            <div className="rest-det-head">
              <p className="m-0 rest-det-head-text"
                onClick={handleClose}
              >
                <i className="pi pi-arrow-left mr-2" style={{ fontSize: "0.8rem" }}></i>
                Dietitian Details
              </p>
            </div>
            <div className="ym-content-table">
              <div
                className="row mb-1 view-gallery-cols-diet">
                <Button
                  icon="pi pi-times"
                  className="details-close"
                  rounded
                  text
                  raised
                  onClick={handleClose}
                />
                <div className="row">
                  <div className="col-7 p-0 diet-prof-img">
                    <Image
                      className="die-gallery-img"
                      src={dietitiansdata.dietitianimages[0] + "?" + devsas}
                      alt="Image"
                      preview
                    />
                  </div>
                  <div className="col-4 d-flex flex-column gap-2  p-0 diet-prof-side-images">
                    {dietitiansdata.clinicimages
                      .slice(0, 2)
                      ?.map((image: any, index: any) => (
                        <>
                          <Image
                            className="gallery-sub-img"
                            src={image + "?" + devsas}
                            alt="Image"
                            key={index}
                            preview
                          />
                        </>
                      ))}
                    <Image
                      className="all-gallery-img"
                      src={allphotos}
                      alt="Image"
                      onClick={() => {
                        setActiveIndex(2);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-2 mt-3">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="rest-title d-flex flex-column gap-1">
                    <h1 className="rest-title-Name">
                      Dr. {dietitiansdata.dietitianname}
                      <span className="gender"> ({dietitiansdata.gender})</span>
                    </h1>
                    <h1 className="speciality-exp">
                      {dietitiansdata.speciality.join("/")}
                    </h1>
                  </div>
                  <div className="like-icon-div d-flex justify-content-center align-items-center">
                    <i
                      className={
                        isLikeddietitian
                          ? "pi pi-heart-fill cuisine-like-icon "
                          : "pi pi-heart cuisine-like-icon"
                      }
                      onClick={handleLikeClick}
                    ></i>
                  </div>
                </div>
                <div className="col p-0 d-flex align-items-center justify-content-center mt-2">
                  {dietitiansdata.avgTotalRating === 0 ? (
                    <div className="col-6 customer-rating-lay mt-1 d-flex align-items-center p-0">
                      <span className="be-first-review">
                        Be the first to write a review
                      </span>
                    </div>
                  ) : (
                    <div className="col-6 customer-rating-lay d-flex p-0">
                      <div className="d-flex align-items-center justify-content-center gap-1">
                        <span className="count mt-2">
                          {parseFloat(dietitiansdata.avgTotalRating).toFixed(1)}
                        </span>
                        <Rating
                          initialValue={parseFloat(dietitiansdata.avgTotalRating)}
                          readonly={true}
                          transition
                          size={20}
                          allowFraction
                        />
                        <span className="reviews-count mt-2">
                          ({dietitiansdata.reviewCount})
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="col-6 your-rating-lay p-0">
                    <div className="d-flex justify-content-end align-items-center">
                      <button
                        className={
                          allowDietitianReview ? "reviewed" : "review_btn"
                        }
                        onClick={() => AddReview()}
                        disabled={allowDietitianReview}
                      >
                        {allowDietitianReview ? (
                          <>
                            <i
                              className="pi pi-check mr-1"
                              style={{
                                fontSize: "12px",
                                color: "#36a41d",
                                fontWeight: "600",
                              }}
                            ></i>
                            {"Reviewed"}
                          </>
                        ) : (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 12 12"
                              fill="none"
                            >
                              <path
                                d="M9.10985 0.109835C9.25629 -0.0366117 9.49373 -0.0366117 9.64018 0.109835L11.8902 2.35984C12.0366 2.50628 12.0366 2.74372 11.8902 2.89016L4.39018 10.3902C4.35427 10.4261 4.31144 10.4543 4.26428 10.4732L0.514282 11.9732C0.375001 12.0289 0.215919 11.9962 0.109846 11.8902C0.00377242 11.7841 -0.0288804 11.625 0.0268322 11.4857L1.52683 7.73573C1.54569 7.68858 1.57394 7.64575 1.60985 7.60983L9.10985 0.109835ZM8.40534 1.875L10.125 3.59467L11.0947 2.625L9.37501 0.90533L8.40534 1.875ZM9.59468 4.125L7.87501 2.40533L3.00001 7.28033V7.5H3.37501C3.58212 7.5 3.75001 7.66789 3.75001 7.875V8.25H4.12501C4.33212 8.25 4.50001 8.41789 4.50001 8.625V9H4.71968L9.59468 4.125ZM2.27375 8.00659L2.1946 8.08574L1.04816 10.9519L3.91427 9.80541L3.99342 9.72626C3.85122 9.67297 3.75001 9.5358 3.75001 9.375V9H3.37501C3.1679 9 3.00001 8.83211 3.00001 8.625V8.25H2.62501C2.46421 8.25 2.32704 8.14879 2.27375 8.00659Z"
                                fill="#818181"
                              />
                            </svg>
                            {"Write a review"}
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row Rest-Detail-tabview">
                  <TabView
                    activeIndex={activeIndex}
                    onTabChange={(e) => setActiveIndex(e.index)}
                  >
                    <TabPanel header="Overview">
                      <DietitianOverview
                        clinicname={dietitiansdata.clinicname}
                        clinicaddress={dietitiansdata.clinicaddress}
                        cliniclocation={dietitiansdata.locality}
                        city={dietitiansdata.city}
                        pincode={dietitiansdata.pincode}
                        latitude={dietitiansdata.location.coordinates[1]}
                        longitude={dietitiansdata.location.coordinates[0]}
                        timings={dietitiansdata.timings}
                        phoneno={dietitiansdata.phoneno}
                        email={dietitiansdata.email}
                        mode={dietitiansdata.mode}
                        fees={dietitiansdata.fees}
                        clinicinstaurl={dietitiansdata.clinicinstaurl}
                      />
                    </TabPanel>
                    <TabPanel header="About">
                      <DietitianAbout
                        degree={dietitiansdata.degree}
                        experience={dietitiansdata.experience}
                        languages={dietitiansdata.languages}
                        about={dietitiansdata.about}
                        certification={dietitiansdata.certification}
                        speciality={dietitiansdata.speciality}
                        services={dietitiansdata.services}
                        awards={dietitiansdata.awards}
                        dietitianwebsite={dietitiansdata.dietitianwebsite}
                        dietitianinstaurl={dietitiansdata.dietitianinstaurl}
                      />
                    </TabPanel>
                    <TabPanel header="Photos">
                      <DietitianPhotos
                        clinicimages={dietitiansdata.clinicimages}
                      />
                    </TabPanel>
                    <TabPanel header="Reviews">
                      <DietitianReviews
                        reviewslist={reviewsList}
                        count={ratingsCount}
                        setVisible={setVisible}
                      />
                    </TabPanel>
                  </TabView>
                </div>
              </div>
            </div>
          </section>
        )}
      </ScrollPanel>
      <Dialog
        visible={visible}
        closeOnEscape={false}
        className="review-model"
        onHide={() => setVisible(false)}
      >
        <div className="review-dialog-box">
          <div className="d-flex justify-content-between align-items-center pb-2">
            <p className="write-heading ">Write a Review</p>
            <i
              className="pi pi-times p-2 dialog_cancle_btn"
              style={{ color: "#3c3c3c" }}
              onClick={() => setVisible(false)}
            ></i>
          </div>
          <hr style={{ color: "#d9d9d9", opacity: "100%" }} />
          <div className="write-rating mb-4">
            <p className="text-rating-above mb-1">
              Tell us how would you like to rate dietitian <b style={{ color: "red" }}>*</b>
            </p>
            <Rating
              onClick={handleRating}
              initialValue={0}
              transition
              size={24}
            />
          </div>
          <div className="row gap-2">
            <div className="d-flex justify-content-between">
              <div>
                <label className="exp-text">
                  Tell us how was your experience? <b style={{ color: "red" }}>*</b>
                </label>
              </div>
              <div className="d-flex align-items-end" style={{ fontSize: "12px", width: "45px" }}>
                {reviewValue.length}/{250}
              </div>
            </div>
            <div>
              <InputTextarea
                className="exp-text-area"
                id="experience"
                placeholder="Write here"
                style={{
                  fontFamily: "rubik",
                  width: "100%",
                  borderRadius: "5px",
                  borderColor: dietitianreviewError ? "red" : "",
                }}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setReviewvalue(e.target.value)
                }
                onFocus={() => setDietitianReviewError(false)}
                autoResize
                rows={5}
                maxLength={250}
              />
              {dietitianreviewError && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  Please complete all required fields to submit your review.
                </div>
              )}
            </div>

          </div>
          <div className="mt-3 d-flex justify-content-end">
            <Button
              className="dietitianreview_submit_btn"
              label="Submit"
              severity="success"
              onClick={SubmitDietitianReview}
              disabled={!totalrating || !reviewValue}

            />
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={signinvisible}
        closeOnEscape={false}
        // style={{ width: "50vw" }}
        className="login-model"
        // breakpoints={{
        //   "1920px": "50vw",
        //   "1420px": "50vw",
        //   "1120px": "50vw",
        //   "1090px": "40vw",
        //   "980px": "50vw",
        //   "840px": "60vw",
        //   "740px": "50vw",
        //   "641px": "70vw",
        //   "541px": "70vw",
        //   "490px": "80vw",
        //   "350px": "90vw",
        // }}
        onHide={() => setSigninVisible(false)}
      >
        <SignInSignUp setVisible={setSigninVisible} />
      </Dialog>
    </>
  );
};

export default DietitianDetails;
