import React, { useEffect, useState } from "react";
import "./cusineCard.component.css";
import "primeicons/primeicons.css";
import { Card } from "primereact/card";
import { yeldamwebimagessas } from "../../../../config/constants";
import { useNavigate } from "react-router-dom";
import {
  LikedCuisine,
  GetLikedCuisine
} from "../../../../api/ratings.api";
import { Dialog } from "primereact/dialog";
import SignInSignUp from "../../SigninSignup/signInSignUp.component";

type props = {
  cuisine: any;

};
interface Cuisine {
  _id: string;
  name: string;
  isLiked: boolean;
  cuisineId: string;
  imageurl: string; // Assuming this property exists in your data
}

interface LikedCuisine {
  _id: string;
  // Other properties from getlikedcuisines
}

interface Props {
  cuisines: Cuisine[];
  getlikedcuisines: LikedCuisine[];
}

const CusineCard: React.FC<props> = ({ cuisine }) => {
  const navigate = useNavigate();
  const [like, setLike] = useState<boolean>(false);
  const [cuisinelist, setcuisinelist] = useState([]);
  const [visible, setVisible] = useState<boolean>(false);

  // console.log("cuisine", cuisine)

  const handlenavigation = (cusine: any) => {
    console.log("cusine", cusine);
    const latitude = localStorage.getItem("latitude") || "";
    const longitude = localStorage.getItem("longitude") || "";
    const queryParams = new URLSearchParams();
    const cityFromStorage = localStorage.getItem("city") || "";
    const parsedCity = JSON.parse(cityFromStorage);
    queryParams.append('city', parsedCity.city);
    if (latitude && longitude) {
      queryParams.append("lat", latitude);
      queryParams.append("long", longitude);
    }
    queryParams.append(cusine.originalType, cusine.name);
    const queryString = queryParams.toString();
    navigate(`/searchrestaurant?${queryString}`);
  }

  const LikeFunction = async (id: any, isLiked: any) => {
    console.log("object id", id);


    if (localStorage.getItem("accessToken") === null) {
      setVisible(true);
    } else {
      setTimeout(async () => {

        if (isLiked === true) {
          const resData = await LikedCuisine(id as string, false);
          // setLike(false);
          console.log("not liked");
          GetLikeFunction();
        }
        if (isLiked === false) {
          const resData = await LikedCuisine(id as string, true);
          // setLike(true);
          console.log("liked");
          GetLikeFunction();
        }
      }, 0);
    }
  };

  const GetLikeFunction = async () => {

    const likedData = await GetLikedCuisine();
    const getLikedIds = likedData.data.data
      .filter((cuisine: { _id: any; isLiked: boolean }) => cuisine.isLiked)
      .map((cuisine: { _id: any }) => cuisine._id);
    const cuisinesWithLikes = cuisine.map((cuisine: any) => {   
      return {
        ...cuisine,
        isLiked: getLikedIds.includes(cuisine._id),
      };
    });
    setcuisinelist(cuisinesWithLikes);
  };

  
  useEffect(() => {
    GetLikeFunction();
  }, [cuisine]);



  return (
    <>
      <div className="cusine-card-div d-flex mb-5 scrolling-wrapper" style={{ gap: "8px" }}>
        {cuisinelist.map((cusine: any, index: number) => (
          <div className="d-flex" key={index}>
            <Card
              key={index}
              className="cusine-Card d-flex align-items-end justify-content-center" style={{ marginLeft: "2px" }}
              onClick={() => {
                handlenavigation(cusine)
              }}
            >
              <div className="d-flex justify-content-center align-items-center">
                <img src={cusine.imageurl + "?" + yeldamwebimagessas} className="cusine-image" alt="" />
              </div>
              <div className="Cusine-Name-div d-flex justify-content-center">
                <span className="cusine-Name">{cusine.name}</span>
              </div>
              <div className="d-flex justify-content-center">
                <div className="cuisine-like-count gap-1">
                  <i
                    className={cusine.isLiked ? "pi pi-heart-fill  cuisine-like-icon" : "pi pi-heart"}

                    onClick={(e) => {
                      e.stopPropagation();
                      LikeFunction(cusine._id, cusine.isLiked)
                    }}>
                      
                    </i>

                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>

      <Dialog
        visible={visible}
        closeOnEscape={false}
        // style={{ width: "50vw" }}
        className="login-model"
        // breakpoints={{
        //   "1920px": "50vw",
        //   "1420px": "50vw",
        //   "1120px": "50vw",
        //   "1090px": "70vw",
        //   "980px": "70vw",
        //   "840px": "80vw",
        //   "740px": "95vw",
        //   "641px": "95vw",
        //   "541px": "95vw",
        //   "490px": "95vw",
        //   "350px": "95vw",
        // }}
        onHide={() => setVisible(false)}
      >
        <SignInSignUp setVisible={setVisible} />
      </Dialog>
    </>
  );
};
export default CusineCard;
