import HttpInstance from "./http.api";

export const LikedRestaurant = (restID:string,liked:boolean)=>{
    const respObj = HttpInstance.post('/user/likedrestaurants',{restID:restID,liked:liked})
    return respObj
}

export const GetLikedStore = ()=>{
    const respObj = HttpInstance.get('/organicstore/userlikedstores')
    return respObj
}

export const LikeStore = (storeID:string,liked:boolean) =>{
    const respobj = HttpInstance.post('/organicstore/likeorganicstore',{storeID:storeID,liked:liked})
    return respobj
}

export const GiveReview =(restID:string,foodrating:number,ambiencerating:number,portionrating:number,pricerating:number,hospitalityrating:number,review:string)=>{
    const respObj=HttpInstance.post('/review/addreview',{
        "restID": restID,
        "review": review,
        "foodrating": foodrating,
        "pricerating": pricerating,
        "ambiencerating": ambiencerating,
        "portionrating": portionrating,
        "hospitalityrating": hospitalityrating
    })
    return respObj;
}

export const CheckLike = (restID:string)=>{
    const respObj = HttpInstance.get('/user/checklike?restID='+restID)
    return respObj
}

export const CheckReview = (restID:string)=>{
    const respObj = HttpInstance.post('/review/getuserreview',{restID:restID})
    return respObj;
}

export const GetReviews = (restID:string)=>{
    const respObj = HttpInstance.post('/review/getreviewsbyrestid?restID='+restID)
    return respObj;
}
export const GetStoreReviews = (storeID: string) =>{
    const respobj = HttpInstance.post ('/organicstore/getreviewsbystoreid?storeID='+storeID)
    return respobj;
}
export const GetStoresRatingsCount = (storeID: string) =>{
    const respobj = HttpInstance.get('/organicstore/storereviewcount?storeID='+storeID)
    return respobj; 

}
export const GetdietitianRatingsCount = (dietitianID: string) =>{
    const respobj = HttpInstance.get('/dietitians/dietitianreviewcount?dietitianID='+dietitianID)
    return respobj; 

}


export const LikedCuisine = (cuisineID:string,liked:boolean)=>{
    const respObj = HttpInstance.post('/user/likedcuisines',{cuisineID:cuisineID,liked:liked})
    return respObj
}

export const GetLikedCuisine = ()=>{
    const respObj = HttpInstance.post('/user/checklikedcuisine')
    return respObj
}

export const GiveStoreReview =(storeID:string,review:string,totalrating:number)=>{
    const respObj=HttpInstance.post('/organicstore/addstorereview',{
        "storeID": storeID,
        "review": review,
        "totalrating":totalrating
    })
    return respObj;
}
export const CheckStoreLike = (storeID:string)=>{
    const respObj = HttpInstance.get('/organicstore/checkstorelike?storeID='+storeID)
    return respObj
}