import React, { useRef, useState } from 'react';
import "./storePhotos.component.css";
import { devsas } from '../../config/constants';
import { Image } from "primereact/image";
import { Galleria } from 'primereact/galleria';

type props = {
  storeimages: any;
}

const StorePhotos: React.FC<props> = ({ storeimages }) => {

  const galleria = useRef<Galleria>(null);
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const itemTemplate = (item: any) => {
    return <img src={item + "?" + devsas} alt={item.alt} style={{ width: '100%', display: 'block' }} />;
  }

  const thumbnailTemplate = (item: any) => {
    return <img src={item + "?" + devsas} alt={item.alt} style={{ display: 'block' }} />;
  }

  return (
    
    <div className='d-flex flex-wrap pt-2 rest-img row'>
      <Galleria ref={galleria} value={storeimages} numVisible={7} style={{ maxWidth: '850px' }}
        activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
       fullScreen showItemNavigators showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate} />
      <div className="grid" style={{ maxWidth: '400px' }}>
        {
          storeimages && storeimages.map((image: any, index: any) => {
            let imgEl = <img src={image + "?" + devsas} alt={image.alt} style={{ cursor: 'pointer' }} onClick={
              () => { setActiveIndex(index); galleria.current?.show() }
            } />
            return (
              <div className="col-4" key={index}>
                {imgEl}
              </div>
            )
          })
        }
      </div>
    </div>


  );
}

export default StorePhotos;
