import React from "react";
import "./exploreCard.component.css";
import "primeicons/primeicons.css";
import { Card } from "primereact/card";
import { yeldamwebimagessas } from "../../../../config/constants";
import { useNavigate } from "react-router-dom";
import ImageLoader from "../../loader/imageloader.component";


type props = {
 exp: any;
 
 };
const ExploreCard: React.FC<props> = ({exp}) => {
  
  const navigate = useNavigate();

  const handlenavigation = (explo:any)=> {
  console.log("explo",explo);
  const latitude = localStorage.getItem("latitude") || "";
  const longitude = localStorage.getItem("longitude")|| "";
    const queryParams = new URLSearchParams();
    const cityFromStorage = localStorage.getItem("city") || "";
    const parsedCity = JSON.parse(cityFromStorage);
    queryParams.append('city', parsedCity.city);
    if (latitude && longitude) {
      queryParams.append("lat", latitude);
      queryParams.append("long", longitude);
    }
    if (explo.name === "Healthy Breakfast"){
      queryParams.append(explo.originalType, "breakfast");
    }
    if (explo.name === "Budget Friendly"){
      queryParams.append(explo.originalType, "500");
    }
    else{
      queryParams.append(explo.originalType, explo.name);
    }
     const queryString = queryParams.toString();
     navigate(`/searchrestaurant?${queryString}`);
  }
  return (
    <>
      <div className="explore-card-div d-flex justify-content-between mb-5 scrolling-wrapper gap-3">
      {exp.map((explo: any, index: number) => (
        <div className="d-flex" key={index}>
        <Card key={index} className="explore-card d-flex justify-content-center align-items-center gap-2" onClick={()=>{ handlenavigation(explo)}}>
          <div className="row d-flex justify-content-center align-items-center" style={{marginTop: "5px"}}>
            <div className="d-flex justify-content-center align-items-center img-div">
              <ImageLoader className={"explore-image"} src={explo.imageurl+"?"+yeldamwebimagessas} alt={"image"} width={"100px"} height={"100px"}/>
            </div>
            <div className="explore-Name-div d-flex justify-content-center align-items-center">
              <p className="explore-Name"> {explo.name}</p>
            </div>
          </div>
        </Card>
        </div>
       ))}
      </div>
    </>
  );
};

export default ExploreCard;
