import React from "react";
import { Card } from "primereact/card";
import "./whyDietMatters.component.css";
type props={
  diettipname:string;
  diettipdescription:string;
  diettipimg:any
}

const WhyDietMatters:React.FC<props> = ({
  diettipname,
  diettipdescription,
  diettipimg
}) => {
  return (
    
      <Card
        header={
          <>
            <img
              className={"headerimg"}
              src={diettipimg}
              alt={"image"}
              width={"100%"}
              height={"160px"}
            />
          </>
        }
        className="dietmatterscard"
      >
        <p className="diettipname mb-1">{diettipname}</p>
        <p className="diettip_description mb-0">
        {diettipdescription}
        </p>
      </Card>
    
  );
};

export default WhyDietMatters;
