import React from "react";
import { useNavigate } from "react-router-dom";
import "./popularLocalities.component.css";

type Props = {
  city: string;
  locality: any;
  type:any;
};

const PopularLocation: React.FC<Props> = ({ city, locality,type }) => {
  const navigate = useNavigate();
  const handlenavigation = (locality:any)=> {
    console.log("cusine",locality);
    const latitude = localStorage.getItem("latitude") || "";
    const longitude = localStorage.getItem("longitude")|| "";
      const queryParams = new URLSearchParams();
      const cityFromStorage = localStorage.getItem("city") || "";
      const parsedCity = JSON.parse(cityFromStorage);
      queryParams.append('city', parsedCity.city);
      if (latitude && longitude) {
        queryParams.append("latitude", latitude);
        queryParams.append("longitude", longitude);
      }
      if(type==="restaurant"){
        queryParams.append("address1", locality);
        const queryString = queryParams.toString();
        navigate(`/searchrestaurant?${queryString}`);
      }
      else if(type==="organicstore"){
        queryParams.append("address", locality);
        const queryString = queryParams.toString();
        navigate(`/searchstore?${queryString}`);
      }
      else if(type==="dietitian"){
        queryParams.append("locality", locality);
        const queryString = queryParams.toString();
        navigate(`/searchdietitian?${queryString}`);
      }

    }
  return (
    <div className="row mb-0">
      <div className="d-flex Localities-div mb-5">
        {locality.slice(0,8).map((item: any, index: number) => {
          return (
            <div
              key={index}
              className="localities col-lg-4 col-md-4 col-mb-12"
              onClick={() => {
                handlenavigation(item.locality.locality)
              }}
            >
              <span className="d-flex align-item-center">
                {item.locality.locality}
              </span>
              <i className="pi pi-angle-right" />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PopularLocation;
