import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Brand } from "../../../assets/images/logo.svg";
import "./navBar.component.css";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Dialog } from "primereact/dialog";
import SigninSignup from "../../UI/SigninSignup/signInSignUp.component";
import { classNames } from "primereact/utils";

const Navbar = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [visible, setVisible] = useState<boolean>(false);
  const isActive = (section: any) =>
    splitLocation[1] === section
      ? "nav-link nav-link-active"
      : "nav-link signup";

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  const Logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("image");
    localStorage.removeItem("username");
    localStorage.removeItem("userid");
    localStorage.removeItem("likedRests");
    localStorage.removeItem("likedDietitians");
    window.location.reload();
  };

  const toCamelCase = (str: any) => {
    if (str) {
      return str
        .replace(/\b(\w)/g, function (match: any, capture: any) {
          return capture.toUpperCase();
        })
        .replace(/\s+/g, " ");
    }
  };

  const handleAddRest = () => {
    if (localStorage.getItem("accessToken") === null) {
      setVisible(true);
    } else {
      console.log("navigate to add store");
      navigate("/useraddrestaurant");
    }
  };

  const handleAdd = () => {
    if (localStorage.getItem("accessToken") === null) {
      setVisible(true);
    } else {
      console.log("navigate to add store");
      navigate("/addstore");
    }
  };

  const [isADropdownOpen, setADropdownOpen] = useState(false);
  const [isAboutDropdownOpen, setisAboutDropdownOpen] = useState(false);
  const [isAddlistingDropdownOpen, setisAddlistingDropdownOpen] =
    useState(false);
  const [isDieDropdownOpen, setDieDropdownOpen] = useState(false);

  const handlelistingDropdownToggle = () => {
    setisAddlistingDropdownOpen(!isAddlistingDropdownOpen);
  };

  const handleADropdownToggle = () => {
    setADropdownOpen(!isADropdownOpen);
  };
  const handleAboutDropdownToggle = () => {
    setisAboutDropdownOpen(!isAboutDropdownOpen);
  };

  const handleDieDropdownToggle = () => {
    setDieDropdownOpen(!isDieDropdownOpen);
  };

  const aboutmenuLeft = useRef<Menu>(null);
  const listingmenuLeft = useRef<Menu>(null);
  const profilemenu = useRef<Menu>(null);
  const dieyiciansmenu = useRef<Menu>(null);

  const searchstorenavigate = () => {
    const latitude = localStorage.getItem("latitude") || "";
    const longitude = localStorage.getItem("longitude") || "";
    const queryParams = new URLSearchParams();
    const cityFromStorage = localStorage.getItem("city") || "";
    const parsedCity = JSON.parse(cityFromStorage);
    queryParams.append("city", parsedCity.city);
    if (latitude && longitude) {
      queryParams.append("lat", latitude);
      queryParams.append("long", longitude);
    }
    const queryString = queryParams.toString();
    navigate(`/searchstore/?${queryString}`);
  };

  let itemsss = [
    {
      command: () => {
        // toast>.current.show({ severity: 'info', summary: 'Info', detail: 'Item Selected', life: 3000 });
      },
      template: (item: any, options: any) => {
        return (
          <div className="container">
            <div className="row">
              <div className="col">
                <button
                  onClick={() => handleAddRest()}
                  className={classNames(
                    options.className,
                    "w-full p-link flex align-items-center gap-2 text-color about-submenu-item"
                  )}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 21C10.22 21 8.47991 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89472 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17294C11.99 2.82567 13.7996 3.0039 15.4442 3.68509C17.0887 4.36628 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C21 14.387 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.387 21 12 21ZM12 4.5C10.5166 4.5 9.0666 4.93987 7.83323 5.76398C6.59986 6.58809 5.63856 7.75943 5.07091 9.12988C4.50325 10.5003 4.35473 12.0083 4.64411 13.4632C4.9335 14.918 5.64781 16.2544 6.6967 17.3033C7.7456 18.3522 9.08197 19.0665 10.5368 19.3559C11.9917 19.6453 13.4997 19.4968 14.8701 18.9291C16.2406 18.3614 17.4119 17.4001 18.236 16.1668C19.0601 14.9334 19.5 13.4834 19.5 12C19.5 10.0109 18.7098 8.10323 17.3033 6.6967C15.8968 5.29018 13.9891 4.5 12 4.5Z"
                      fill="#63BF58"
                    />
                    <path
                      d="M12 16.75C11.8019 16.7474 11.6126 16.6676 11.4725 16.5275C11.3324 16.3874 11.2526 16.1981 11.25 16V8C11.25 7.80109 11.329 7.61032 11.4697 7.46967C11.6103 7.32902 11.8011 7.25 12 7.25C12.1989 7.25 12.3897 7.32902 12.5303 7.46967C12.671 7.61032 12.75 7.80109 12.75 8V16C12.7474 16.1981 12.6676 16.3874 12.5275 16.5275C12.3874 16.6676 12.1981 16.7474 12 16.75Z"
                      fill="#63BF58"
                    />
                    <path
                      d="M16 12.75H8C7.80109 12.75 7.61032 12.671 7.46967 12.5303C7.32902 12.3897 7.25 12.1989 7.25 12C7.25 11.8011 7.32902 11.6103 7.46967 11.4697C7.61032 11.329 7.80109 11.25 8 11.25H16C16.1989 11.25 16.3897 11.329 16.5303 11.4697C16.671 11.6103 16.75 11.8011 16.75 12C16.75 12.1989 16.671 12.3897 16.5303 12.5303C16.3897 12.671 16.1989 12.75 16 12.75Z"
                      fill="#63BF58"
                    />
                  </svg>
                  <div className="flex flex-column align">
                    <span className="text-900 fw-600 fs-14">
                      Suggest Restaurant
                    </span>
                    <span className="fs-12">Share hidden gems now</span>
                  </div>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <button
                  onClick={() => handleAdd()}
                  className={classNames(
                    options.className,
                    "w-full p-link flex align-items-center gap-2 text-color about-submenu-item"
                  )}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M15.75 1.125C16.3713 1.125 16.875 1.62868 16.875 2.25V15.75C16.875 16.3713 16.3713 16.875 15.75 16.875H2.25C1.62868 16.875 1.125 16.3713 1.125 15.75V2.25C1.125 1.62868 1.62868 1.125 2.25 1.125H15.75ZM2.25 0C1.00736 0 0 1.00736 0 2.25V15.75C0 16.9926 1.00736 18 2.25 18H15.75C16.9926 18 18 16.9926 18 15.75V2.25C18 1.00736 16.9926 0 15.75 0H2.25Z"
                      fill="#63BF58"
                    />
                    <path
                      d="M9 4.5C9.31066 4.5 9.5625 4.75184 9.5625 5.0625V8.4375H12.9375C13.2482 8.4375 13.5 8.68934 13.5 9C13.5 9.31066 13.2482 9.5625 12.9375 9.5625H9.5625V12.9375C9.5625 13.2482 9.31066 13.5 9 13.5C8.68934 13.5 8.4375 13.2482 8.4375 12.9375V9.5625H5.0625C4.75184 9.5625 4.5 9.31066 4.5 9C4.5 8.68934 4.75184 8.4375 5.0625 8.4375H8.4375V5.0625C8.4375 4.75184 8.68934 4.5 9 4.5Z"
                      fill="#63BF58"
                    />
                  </svg>
                  <div className="flex flex-column align">
                    <span className="text-900 fw-600 fs-14">
                      Suggest Organic Store
                    </span>
                    <span className="fs-12">Share your favorite stores</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  let proitemsss = [
    {
      command: () => {
        // toast>.current.show({ severity: 'info', summary: 'Info', detail: 'Item Selected', life: 3000 });
      },
      template: (item: any, options: any) => {
        return (
          <div className="container">
            <div className="row">
              <div className="col">
                <button
                  onClick={() => navigate("/profile")}
                  className={classNames(
                    options.className,
                    "w-full p-link flex align-items-center gap-2 text-color about-submenu-item"
                  )}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M8 8.25C7.25832 8.25 6.5333 8.03007 5.91661 7.61801C5.29993 7.20596 4.81928 6.62029 4.53545 5.93506C4.25163 5.24984 4.17736 4.49584 4.32206 3.76841C4.46675 3.04098 4.8239 2.3728 5.34835 1.84835C5.8728 1.3239 6.54098 0.966751 7.26841 0.822057C7.99584 0.677362 8.74984 0.751625 9.43506 1.03545C10.1203 1.31928 10.706 1.79993 11.118 2.41661C11.5301 3.0333 11.75 3.75832 11.75 4.5C11.75 5.49456 11.3549 6.44839 10.6517 7.15165C9.94839 7.85491 8.99456 8.25 8 8.25ZM8 2.25C7.55499 2.25 7.11998 2.38196 6.74997 2.62919C6.37996 2.87643 6.09157 3.22783 5.92127 3.63896C5.75098 4.0501 5.70642 4.5025 5.79323 4.93895C5.88005 5.37541 6.09434 5.77632 6.40901 6.09099C6.72368 6.40566 7.12459 6.61995 7.56105 6.70677C7.99751 6.79359 8.44991 6.74903 8.86104 6.57873C9.27217 6.40843 9.62358 6.12005 9.87081 5.75003C10.118 5.38002 10.25 4.94501 10.25 4.5C10.25 3.90326 10.0129 3.33097 9.59099 2.90901C9.16903 2.48705 8.59674 2.25 8 2.25Z"
                      fill="#63BF58"
                    />
                    <path
                      d="M15 15.25C14.8019 15.2474 14.6126 15.1676 14.4725 15.0275C14.3324 14.8874 14.2526 14.6981 14.25 14.5C14.25 12.55 13.19 11.25 8 11.25C2.81 11.25 1.75 12.55 1.75 14.5C1.75 14.6989 1.67098 14.8897 1.53033 15.0303C1.38968 15.171 1.19891 15.25 1 15.25C0.801088 15.25 0.610322 15.171 0.46967 15.0303C0.329018 14.8897 0.25 14.6989 0.25 14.5C0.25 9.75 5.68 9.75 8 9.75C10.32 9.75 15.75 9.75 15.75 14.5C15.7474 14.6981 15.6676 14.8874 15.5275 15.0275C15.3874 15.1676 15.1981 15.2474 15 15.25Z"
                      fill="#63BF58"
                    />
                  </svg>
                  <div className="flex flex-column align">
                    <span className="text-900 fw-600 fs-14">My Account</span>
                    <span className="fs-12">Your space, your settings</span>
                  </div>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <button
                  onClick={() => Logout()}
                  className={classNames(
                    options.className,
                    "w-full p-link flex align-items-center gap-2 text-color about-submenu-item"
                  )}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M9.00002 17.9996C7.22114 17.998 5.48262 17.4693 4.00403 16.4803C2.52545 15.4913 1.37312 14.0862 0.692579 12.4427C0.0120423 10.7991 -0.166174 8.99074 0.180439 7.24595C0.527051 5.50117 1.38295 3.89823 2.64002 2.63959C2.70897 2.56883 2.79138 2.51259 2.88241 2.4742C2.97344 2.4358 3.07123 2.41602 3.17002 2.41602C3.26882 2.41602 3.36661 2.4358 3.45763 2.4742C3.54866 2.51259 3.63108 2.56883 3.70002 2.63959C3.84047 2.78021 3.91936 2.97084 3.91936 3.16959C3.91936 3.36834 3.84047 3.55896 3.70002 3.69959C2.96315 4.38621 2.37213 5.21421 1.96221 6.1342C1.55229 7.0542 1.33187 8.04733 1.31411 9.05436C1.29634 10.0614 1.48159 11.0617 1.8588 11.9956C2.23601 12.9294 2.79745 13.7778 3.50964 14.49C4.22183 15.2022 5.07016 15.7636 6.00405 16.1408C6.93793 16.518 7.93822 16.7033 8.94525 16.6855C9.95228 16.6677 10.9454 16.4473 11.8654 16.0374C12.7854 15.6275 13.6134 15.0365 14.3 14.2996C14.9979 13.6046 15.5516 12.7787 15.9294 11.8691C16.3072 10.9596 16.5017 9.98446 16.5017 8.99959C16.5017 8.01472 16.3072 7.03954 15.9294 6.13003C15.5516 5.22051 14.9979 4.39456 14.3 3.69959C14.1596 3.55896 14.0807 3.36834 14.0807 3.16959C14.0807 2.97084 14.1596 2.78021 14.3 2.63959C14.369 2.56883 14.4514 2.51259 14.5424 2.4742C14.6334 2.4358 14.7312 2.41602 14.83 2.41602C14.9288 2.41602 15.0266 2.4358 15.1176 2.4742C15.2087 2.51259 15.2911 2.56883 15.36 2.63959C16.6171 3.89823 17.473 5.50117 17.8196 7.24595C18.1662 8.99074 17.988 10.7991 17.3075 12.4427C16.6269 14.0862 15.4746 15.4913 13.996 16.4803C12.5174 17.4693 10.7789 17.998 9.00002 17.9996Z"
                      fill="#63BF58"
                    />
                    <path
                      d="M9 9.75C8.80189 9.74741 8.61263 9.66756 8.47253 9.52747C8.33244 9.38737 8.25259 9.19811 8.25 9V1C8.25 0.801088 8.32902 0.610322 8.46967 0.46967C8.61032 0.329018 8.80109 0.25 9 0.25C9.19891 0.25 9.38968 0.329018 9.53033 0.46967C9.67098 0.610322 9.75 0.801088 9.75 1V9C9.74741 9.19811 9.66756 9.38737 9.52747 9.52747C9.38737 9.66756 9.19811 9.74741 9 9.75Z"
                      fill="#63BF58"
                    />
                  </svg>
                  <div className="flex flex-column align">
                    <span className="text-900 fw-600 fs-14">Log Out</span>
                    <span className="fs-12">Leaving? See you Later</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  let itemss = [
    {
      command: () => {
        //toast>.current.show({ severity: 'info', summary: 'Info', detail: 'Item Selected', life: 3000 });
      },
      template: (item: any, options: any) => {
        return (
          <div className="container p-0 d-flex flex-column gap-3">
            <div className="">
              <div className="">
                <button
                  onClick={() => navigate("/whyyeldam")}
                  className={classNames(
                    options.className,
                    " p-link flex  gap-2 text-color about-submenu-item"
                  )}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M4.74357 6.52406C4.74229 6.66049 4.85392 6.77228 4.99035 6.77228H8.02493C8.163 6.77228 8.27426 6.65999 8.28089 6.52208C8.37544 4.55715 9.5942 3.07214 11.9072 3.07214C13.7403 3.07214 15.4206 3.98868 15.4206 6.19519C15.4206 7.8925 14.4192 8.67327 12.8407 9.86139C11.0416 11.1683 9.61581 12.6959 9.71765 15.174L9.73124 16.1664C9.73312 16.3032 9.84449 16.413 9.98122 16.413H12.9811C13.1192 16.413 13.2311 16.3011 13.2311 16.163V15.4625C13.2311 13.5446 13.9609 12.9844 15.9298 11.4908C17.5592 10.2518 19.2565 8.87694 19.2565 5.99151C19.2565 1.95191 15.8449 0 12.1109 0C8.62234 0 4.78911 1.67428 4.74357 6.52406ZM8.91991 21.5219C8.91991 22.9477 10.0571 24 11.6186 24C13.2481 24 14.3683 22.9477 14.3683 21.5219C14.3683 20.0453 13.2481 19.0099 11.6186 19.0099C10.0571 19.0099 8.91991 20.0453 8.91991 21.5219Z"
                      fill="#63BF58"
                    />
                  </svg>{" "}
                  <div className="flex flex-column align">
                    <span className="text-900 fw-600 fs-14">Why Yeldam</span>
                    <span className="fs-12">Who we are & what we do</span>
                  </div>
                </button>
              </div>
              {/* <div className="col">
                <button onClick={() => navigate("/comingsoon")} className={classNames(options.className, 'w-full p-link flex align-items-center p-2 pl-1 gap-3 text-color about-submenu-item')}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M10.5 6.375C10.5 6.99632 9.99632 7.5 9.375 7.5C8.75368 7.5 8.25 6.99632 8.25 6.375C8.25 5.75368 8.75368 5.25 9.375 5.25C9.99632 5.25 10.5 5.75368 10.5 6.375Z" fill="#63BF58" />
                    <path d="M9.20801 8.68799L11.2022 10.0175L13.9848 7.23485C14.099 7.12071 14.2733 7.09241 14.4177 7.1646L17.25 8.62501V11.25C17.25 11.6642 16.9142 12 16.5 12H7.5C7.08579 12 6.75 11.6642 6.75 11.25V10.5C6.75 10.5 9.05928 8.58884 9.20801 8.68799Z" fill="#63BF58" />
                    <path d="M7.5 13.5C7.08579 13.5 6.75 13.8358 6.75 14.25C6.75 14.6642 7.08579 15 7.5 15H16.5C16.9142 15 17.25 14.6642 17.25 14.25C17.25 13.8358 16.9142 13.5 16.5 13.5H7.5Z" fill="#63BF58" />
                    <path d="M7.5 16.5C7.08579 16.5 6.75 16.8358 6.75 17.25C6.75 17.6642 7.08579 18 7.5 18H12C12.4142 18 12.75 17.6642 12.75 17.25C12.75 16.8358 12.4142 16.5 12 16.5H7.5Z" fill="#63BF58" />
                    <path d="M3 3C3 1.34314 4.34315 0 6 0H18C19.6569 0 21 1.34315 21 3V21C21 22.6569 19.6569 24 18 24H6C4.34315 24 3 22.6569 3 21V3ZM18 1.5H6C5.17157 1.5 4.5 2.17157 4.5 3V21C4.5 21.8284 5.17157 22.5 6 22.5H18C18.8284 22.5 19.5 21.8284 19.5 21V3C19.5 2.17157 18.8284 1.5 18 1.5Z" fill="#63BF58" />
                  </svg>
                  <div className="flex flex-column align">
                    <span className="text-900 fw-600 fs-14">Blog</span>
                    <span className="fs-12">Check latest articles </span>
                  </div>
                </button>
              </div> */}
            </div>

            <div className="">
              <button
                onClick={() => navigate("/healthyrestaurants")}
                className={classNames(
                  options.className,
                  " p-link flex align-items-center gap-2 text-color about-submenu-item"
                )}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M4.45607 2.02381C4.74104 1.69134 5.15706 1.5 5.59495 1.5H18.405C18.8429 1.5 19.259 1.69134 19.5439 2.02381L23.4583 6.59061C23.8079 6.9984 24 7.51779 24 8.05489V8.4375C24 10.405 22.405 12 20.4375 12C19.2943 12 18.2769 11.4615 17.625 10.6244C16.9731 11.4615 15.9557 12 14.8125 12C13.6693 12 12.6519 11.4615 12 10.6244C11.3481 11.4615 10.3307 12 9.1875 12C8.0443 12 7.02687 11.4615 6.375 10.6244C5.72314 11.4615 4.7057 12 3.5625 12C1.59499 12 0 10.405 0 8.4375V8.05489C0 7.51779 0.192132 6.9984 0.541673 6.59061L4.45607 2.02381ZM7.125 8.4375C7.125 9.57659 8.04841 10.5 9.1875 10.5C10.3266 10.5 11.25 9.57659 11.25 8.4375C11.25 8.02329 11.5858 7.6875 12 7.6875C12.4142 7.6875 12.75 8.02329 12.75 8.4375C12.75 9.57659 13.6734 10.5 14.8125 10.5C15.9516 10.5 16.875 9.57659 16.875 8.4375C16.875 8.02329 17.2108 7.6875 17.625 7.6875C18.0392 7.6875 18.375 8.02329 18.375 8.4375C18.375 9.57659 19.2984 10.5 20.4375 10.5C21.5766 10.5 22.5 9.57659 22.5 8.4375V8.05489C22.5 7.87585 22.436 7.70272 22.3194 7.56679L18.405 3H5.59495L1.68056 7.56679C1.56404 7.70272 1.5 7.87585 1.5 8.05489V8.4375C1.5 9.57659 2.42341 10.5 3.5625 10.5C4.70159 10.5 5.625 9.57659 5.625 8.4375C5.625 8.02329 5.96079 7.6875 6.375 7.6875C6.78921 7.6875 7.125 8.02329 7.125 8.4375ZM2.25 12.75C2.66421 12.75 3 13.0858 3 13.5V22.5H4.5V15C4.5 14.1716 5.17157 13.5 6 13.5H10.5C11.3284 13.5 12 14.1716 12 15V22.5H21V13.5C21 13.0858 21.3358 12.75 21.75 12.75C22.1642 12.75 22.5 13.0858 22.5 13.5V22.5H23.25C23.6642 22.5 24 22.8358 24 23.25C24 23.6642 23.6642 24 23.25 24H0.75C0.335786 24 0 23.6642 0 23.25C0 22.8358 0.335786 22.5 0.75 22.5H1.5V13.5C1.5 13.0858 1.83579 12.75 2.25 12.75ZM6 22.5H10.5V15H6V22.5ZM13.5 15C13.5 14.1716 14.1716 13.5 15 13.5H18C18.8284 13.5 19.5 14.1716 19.5 15V19.5C19.5 20.3284 18.8284 21 18 21H15C14.1716 21 13.5 20.3284 13.5 19.5V15ZM18 15H15V19.5H18V15Z"
                    fill="#63BF58"
                  />
                </svg>
                <div className="flex flex-column align">
                  <span className="text-900 fw-600 fs-14">Restaurants</span>
                  <span className="fs-12">Serves Healthy Food </span>
                </div>
              </button>
            </div>
            <div className="">
              <button
                onClick={() => navigate("/aboutorganicstore")}
                className={classNames(
                  options.className,
                  " p-link flex align-items-center gap-2 text-color  about-submenu-item"
                )}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M4.45607 2.02381C4.74104 1.69134 5.15706 1.5 5.59495 1.5H18.405C18.8429 1.5 19.259 1.69134 19.5439 2.02381L23.4583 6.59061C23.8079 6.9984 24 7.51779 24 8.05489V8.4375C24 10.405 22.405 12 20.4375 12C19.2943 12 18.2769 11.4615 17.625 10.6244C16.9731 11.4615 15.9557 12 14.8125 12C13.6693 12 12.6519 11.4615 12 10.6244C11.3481 11.4615 10.3307 12 9.1875 12C8.0443 12 7.02687 11.4615 6.375 10.6244C5.72314 11.4615 4.7057 12 3.5625 12C1.59499 12 0 10.405 0 8.4375V8.05489C0 7.51779 0.192132 6.9984 0.541673 6.59061L4.45607 2.02381ZM7.125 8.4375C7.125 9.57659 8.04841 10.5 9.1875 10.5C10.3266 10.5 11.25 9.57659 11.25 8.4375C11.25 8.02329 11.5858 7.6875 12 7.6875C12.4142 7.6875 12.75 8.02329 12.75 8.4375C12.75 9.57659 13.6734 10.5 14.8125 10.5C15.9516 10.5 16.875 9.57659 16.875 8.4375C16.875 8.02329 17.2108 7.6875 17.625 7.6875C18.0392 7.6875 18.375 8.02329 18.375 8.4375C18.375 9.57659 19.2984 10.5 20.4375 10.5C21.5766 10.5 22.5 9.57659 22.5 8.4375V8.05489C22.5 7.87585 22.436 7.70272 22.3194 7.56679L18.405 3H5.59495L1.68056 7.56679C1.56404 7.70272 1.5 7.87585 1.5 8.05489V8.4375C1.5 9.57659 2.42341 10.5 3.5625 10.5C4.70159 10.5 5.625 9.57659 5.625 8.4375C5.625 8.02329 5.96079 7.6875 6.375 7.6875C6.78921 7.6875 7.125 8.02329 7.125 8.4375ZM2.25 12.75C2.66421 12.75 3 13.0858 3 13.5V22.5H21V13.5C21 13.0858 21.3358 12.75 21.75 12.75C22.1642 12.75 22.5 13.0858 22.5 13.5V22.5H23.25C23.6642 22.5 24 22.8358 24 23.25C24 23.6642 23.6642 24 23.25 24H0.75C0.335786 24 0 23.6642 0 23.25C0 22.8358 0.335786 22.5 0.75 22.5H1.5V13.5C1.5 13.0858 1.83579 12.75 2.25 12.75ZM5.25 13.5C5.66421 13.5 6 13.8358 6 14.25V19.5H18V14.25C18 13.8358 18.3358 13.5 18.75 13.5C19.1642 13.5 19.5 13.8358 19.5 14.25V19.5C19.5 20.3284 18.8284 21 18 21H6C5.17157 21 4.5 20.3284 4.5 19.5V14.25C4.5 13.8358 4.83579 13.5 5.25 13.5Z"
                    fill="#63BF58"
                  />
                </svg>
                <div className="flex flex-column align">
                  <span className="text-900 fw-600 fs-14">Organic Stores</span>
                  <span className="fs-12">One stop for groceries </span>
                </div>
              </button>
            </div>
          </div>
        );
      },
    },
  ];

  let Dieyiciansitems = [
    {
      command: () => {
        //toast>.current.show({ severity: 'info', summary: 'Info', detail: 'Item Selected', life: 3000 });
      },
      template: (item: any, options: any) => {
        return (
          <div className="container p-0 d-flex flex-column gap-3">
            <div className="">
              <div className="">
                <button
                  onClick={() => navigate("/")}
                  className={classNames(
                    options.className,
                    " p-link flex  gap-2 text-color about-submenu-item"
                  )}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M4.74357 6.52406C4.74229 6.66049 4.85392 6.77228 4.99035 6.77228H8.02493C8.163 6.77228 8.27426 6.65999 8.28089 6.52208C8.37544 4.55715 9.5942 3.07214 11.9072 3.07214C13.7403 3.07214 15.4206 3.98868 15.4206 6.19519C15.4206 7.8925 14.4192 8.67327 12.8407 9.86139C11.0416 11.1683 9.61581 12.6959 9.71765 15.174L9.73124 16.1664C9.73312 16.3032 9.84449 16.413 9.98122 16.413H12.9811C13.1192 16.413 13.2311 16.3011 13.2311 16.163V15.4625C13.2311 13.5446 13.9609 12.9844 15.9298 11.4908C17.5592 10.2518 19.2565 8.87694 19.2565 5.99151C19.2565 1.95191 15.8449 0 12.1109 0C8.62234 0 4.78911 1.67428 4.74357 6.52406ZM8.91991 21.5219C8.91991 22.9477 10.0571 24 11.6186 24C13.2481 24 14.3683 22.9477 14.3683 21.5219C14.3683 20.0453 13.2481 19.0099 11.6186 19.0099C10.0571 19.0099 8.91991 20.0453 8.91991 21.5219Z"
                      fill="#63BF58"
                    />
                  </svg>{" "}
                  <div className="flex flex-column align">
                    <span className="text-900 fw-600 fs-14">
                      Search Dietician
                    </span>
                    <span className="fs-12">Find your nutrition guide</span>
                  </div>
                </button>
              </div>
              {/* <div className="col">
                <button onClick={() => navigate("/comingsoon")} className={classNames(options.className, 'w-full p-link flex align-items-center p-2 pl-1 gap-3 text-color about-submenu-item')}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M10.5 6.375C10.5 6.99632 9.99632 7.5 9.375 7.5C8.75368 7.5 8.25 6.99632 8.25 6.375C8.25 5.75368 8.75368 5.25 9.375 5.25C9.99632 5.25 10.5 5.75368 10.5 6.375Z" fill="#63BF58" />
                    <path d="M9.20801 8.68799L11.2022 10.0175L13.9848 7.23485C14.099 7.12071 14.2733 7.09241 14.4177 7.1646L17.25 8.62501V11.25C17.25 11.6642 16.9142 12 16.5 12H7.5C7.08579 12 6.75 11.6642 6.75 11.25V10.5C6.75 10.5 9.05928 8.58884 9.20801 8.68799Z" fill="#63BF58" />
                    <path d="M7.5 13.5C7.08579 13.5 6.75 13.8358 6.75 14.25C6.75 14.6642 7.08579 15 7.5 15H16.5C16.9142 15 17.25 14.6642 17.25 14.25C17.25 13.8358 16.9142 13.5 16.5 13.5H7.5Z" fill="#63BF58" />
                    <path d="M7.5 16.5C7.08579 16.5 6.75 16.8358 6.75 17.25C6.75 17.6642 7.08579 18 7.5 18H12C12.4142 18 12.75 17.6642 12.75 17.25C12.75 16.8358 12.4142 16.5 12 16.5H7.5Z" fill="#63BF58" />
                    <path d="M3 3C3 1.34314 4.34315 0 6 0H18C19.6569 0 21 1.34315 21 3V21C21 22.6569 19.6569 24 18 24H6C4.34315 24 3 22.6569 3 21V3ZM18 1.5H6C5.17157 1.5 4.5 2.17157 4.5 3V21C4.5 21.8284 5.17157 22.5 6 22.5H18C18.8284 22.5 19.5 21.8284 19.5 21V3C19.5 2.17157 18.8284 1.5 18 1.5Z" fill="#63BF58" />
                  </svg>
                  <div className="flex flex-column align">
                    <span className="text-900 fw-600 fs-14">Blog</span>
                    <span className="fs-12">Check latest articles </span>
                  </div>
                </button>
              </div> */}
            </div>

            <div className="">
              <button
                onClick={() => navigate("/")}
                className={classNames(
                  options.className,
                  " p-link flex align-items-center gap-2 text-color about-submenu-item"
                )}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M4.45607 2.02381C4.74104 1.69134 5.15706 1.5 5.59495 1.5H18.405C18.8429 1.5 19.259 1.69134 19.5439 2.02381L23.4583 6.59061C23.8079 6.9984 24 7.51779 24 8.05489V8.4375C24 10.405 22.405 12 20.4375 12C19.2943 12 18.2769 11.4615 17.625 10.6244C16.9731 11.4615 15.9557 12 14.8125 12C13.6693 12 12.6519 11.4615 12 10.6244C11.3481 11.4615 10.3307 12 9.1875 12C8.0443 12 7.02687 11.4615 6.375 10.6244C5.72314 11.4615 4.7057 12 3.5625 12C1.59499 12 0 10.405 0 8.4375V8.05489C0 7.51779 0.192132 6.9984 0.541673 6.59061L4.45607 2.02381ZM7.125 8.4375C7.125 9.57659 8.04841 10.5 9.1875 10.5C10.3266 10.5 11.25 9.57659 11.25 8.4375C11.25 8.02329 11.5858 7.6875 12 7.6875C12.4142 7.6875 12.75 8.02329 12.75 8.4375C12.75 9.57659 13.6734 10.5 14.8125 10.5C15.9516 10.5 16.875 9.57659 16.875 8.4375C16.875 8.02329 17.2108 7.6875 17.625 7.6875C18.0392 7.6875 18.375 8.02329 18.375 8.4375C18.375 9.57659 19.2984 10.5 20.4375 10.5C21.5766 10.5 22.5 9.57659 22.5 8.4375V8.05489C22.5 7.87585 22.436 7.70272 22.3194 7.56679L18.405 3H5.59495L1.68056 7.56679C1.56404 7.70272 1.5 7.87585 1.5 8.05489V8.4375C1.5 9.57659 2.42341 10.5 3.5625 10.5C4.70159 10.5 5.625 9.57659 5.625 8.4375C5.625 8.02329 5.96079 7.6875 6.375 7.6875C6.78921 7.6875 7.125 8.02329 7.125 8.4375ZM2.25 12.75C2.66421 12.75 3 13.0858 3 13.5V22.5H4.5V15C4.5 14.1716 5.17157 13.5 6 13.5H10.5C11.3284 13.5 12 14.1716 12 15V22.5H21V13.5C21 13.0858 21.3358 12.75 21.75 12.75C22.1642 12.75 22.5 13.0858 22.5 13.5V22.5H23.25C23.6642 22.5 24 22.8358 24 23.25C24 23.6642 23.6642 24 23.25 24H0.75C0.335786 24 0 23.6642 0 23.25C0 22.8358 0.335786 22.5 0.75 22.5H1.5V13.5C1.5 13.0858 1.83579 12.75 2.25 12.75ZM6 22.5H10.5V15H6V22.5ZM13.5 15C13.5 14.1716 14.1716 13.5 15 13.5H18C18.8284 13.5 19.5 14.1716 19.5 15V19.5C19.5 20.3284 18.8284 21 18 21H15C14.1716 21 13.5 20.3284 13.5 19.5V15ZM18 15H15V19.5H18V15Z"
                    fill="#63BF58"
                  />
                </svg>
                <div className="flex flex-column align">
                  <span className="text-900 fw-600 fs-14">
                    Login for Dietician
                  </span>
                  <span className="fs-12">Access to a RD Portal</span>
                </div>
              </button>
            </div>
            <div className="">
              <button
                onClick={() => navigate("/healthtips")}
                className={classNames(
                  options.className,
                  " p-link flex align-items-center gap-2 text-color  about-submenu-item"
                )}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M4.45607 2.02381C4.74104 1.69134 5.15706 1.5 5.59495 1.5H18.405C18.8429 1.5 19.259 1.69134 19.5439 2.02381L23.4583 6.59061C23.8079 6.9984 24 7.51779 24 8.05489V8.4375C24 10.405 22.405 12 20.4375 12C19.2943 12 18.2769 11.4615 17.625 10.6244C16.9731 11.4615 15.9557 12 14.8125 12C13.6693 12 12.6519 11.4615 12 10.6244C11.3481 11.4615 10.3307 12 9.1875 12C8.0443 12 7.02687 11.4615 6.375 10.6244C5.72314 11.4615 4.7057 12 3.5625 12C1.59499 12 0 10.405 0 8.4375V8.05489C0 7.51779 0.192132 6.9984 0.541673 6.59061L4.45607 2.02381ZM7.125 8.4375C7.125 9.57659 8.04841 10.5 9.1875 10.5C10.3266 10.5 11.25 9.57659 11.25 8.4375C11.25 8.02329 11.5858 7.6875 12 7.6875C12.4142 7.6875 12.75 8.02329 12.75 8.4375C12.75 9.57659 13.6734 10.5 14.8125 10.5C15.9516 10.5 16.875 9.57659 16.875 8.4375C16.875 8.02329 17.2108 7.6875 17.625 7.6875C18.0392 7.6875 18.375 8.02329 18.375 8.4375C18.375 9.57659 19.2984 10.5 20.4375 10.5C21.5766 10.5 22.5 9.57659 22.5 8.4375V8.05489C22.5 7.87585 22.436 7.70272 22.3194 7.56679L18.405 3H5.59495L1.68056 7.56679C1.56404 7.70272 1.5 7.87585 1.5 8.05489V8.4375C1.5 9.57659 2.42341 10.5 3.5625 10.5C4.70159 10.5 5.625 9.57659 5.625 8.4375C5.625 8.02329 5.96079 7.6875 6.375 7.6875C6.78921 7.6875 7.125 8.02329 7.125 8.4375ZM2.25 12.75C2.66421 12.75 3 13.0858 3 13.5V22.5H21V13.5C21 13.0858 21.3358 12.75 21.75 12.75C22.1642 12.75 22.5 13.0858 22.5 13.5V22.5H23.25C23.6642 22.5 24 22.8358 24 23.25C24 23.6642 23.6642 24 23.25 24H0.75C0.335786 24 0 23.6642 0 23.25C0 22.8358 0.335786 22.5 0.75 22.5H1.5V13.5C1.5 13.0858 1.83579 12.75 2.25 12.75ZM5.25 13.5C5.66421 13.5 6 13.8358 6 14.25V19.5H18V14.25C18 13.8358 18.3358 13.5 18.75 13.5C19.1642 13.5 19.5 13.8358 19.5 14.25V19.5C19.5 20.3284 18.8284 21 18 21H6C5.17157 21 4.5 20.3284 4.5 19.5V14.25C4.5 13.8358 4.83579 13.5 5.25 13.5Z"
                    fill="#63BF58"
                  />
                </svg>
                <div className="flex flex-column align">
                  <span className="text-900 fw-600 fs-14">Health Tips</span>
                  <span className="fs-12">Small steps, big changes</span>
                </div>
              </button>
            </div>
            <div className="">
              <button
                onClick={() => navigate("/")}
                className={classNames(
                  options.className,
                  " p-link flex align-items-center gap-2 text-color  about-submenu-item"
                )}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M4.45607 2.02381C4.74104 1.69134 5.15706 1.5 5.59495 1.5H18.405C18.8429 1.5 19.259 1.69134 19.5439 2.02381L23.4583 6.59061C23.8079 6.9984 24 7.51779 24 8.05489V8.4375C24 10.405 22.405 12 20.4375 12C19.2943 12 18.2769 11.4615 17.625 10.6244C16.9731 11.4615 15.9557 12 14.8125 12C13.6693 12 12.6519 11.4615 12 10.6244C11.3481 11.4615 10.3307 12 9.1875 12C8.0443 12 7.02687 11.4615 6.375 10.6244C5.72314 11.4615 4.7057 12 3.5625 12C1.59499 12 0 10.405 0 8.4375V8.05489C0 7.51779 0.192132 6.9984 0.541673 6.59061L4.45607 2.02381ZM7.125 8.4375C7.125 9.57659 8.04841 10.5 9.1875 10.5C10.3266 10.5 11.25 9.57659 11.25 8.4375C11.25 8.02329 11.5858 7.6875 12 7.6875C12.4142 7.6875 12.75 8.02329 12.75 8.4375C12.75 9.57659 13.6734 10.5 14.8125 10.5C15.9516 10.5 16.875 9.57659 16.875 8.4375C16.875 8.02329 17.2108 7.6875 17.625 7.6875C18.0392 7.6875 18.375 8.02329 18.375 8.4375C18.375 9.57659 19.2984 10.5 20.4375 10.5C21.5766 10.5 22.5 9.57659 22.5 8.4375V8.05489C22.5 7.87585 22.436 7.70272 22.3194 7.56679L18.405 3H5.59495L1.68056 7.56679C1.56404 7.70272 1.5 7.87585 1.5 8.05489V8.4375C1.5 9.57659 2.42341 10.5 3.5625 10.5C4.70159 10.5 5.625 9.57659 5.625 8.4375C5.625 8.02329 5.96079 7.6875 6.375 7.6875C6.78921 7.6875 7.125 8.02329 7.125 8.4375ZM2.25 12.75C2.66421 12.75 3 13.0858 3 13.5V22.5H21V13.5C21 13.0858 21.3358 12.75 21.75 12.75C22.1642 12.75 22.5 13.0858 22.5 13.5V22.5H23.25C23.6642 22.5 24 22.8358 24 23.25C24 23.6642 23.6642 24 23.25 24H0.75C0.335786 24 0 23.6642 0 23.25C0 22.8358 0.335786 22.5 0.75 22.5H1.5V13.5C1.5 13.0858 1.83579 12.75 2.25 12.75ZM5.25 13.5C5.66421 13.5 6 13.8358 6 14.25V19.5H18V14.25C18 13.8358 18.3358 13.5 18.75 13.5C19.1642 13.5 19.5 13.8358 19.5 14.25V19.5C19.5 20.3284 18.8284 21 18 21H6C5.17157 21 4.5 20.3284 4.5 19.5V14.25C4.5 13.8358 4.83579 13.5 5.25 13.5Z"
                    fill="#63BF58"
                  />
                </svg>
                <div className="flex flex-column align">
                  <span className="text-900 fw-600 fs-14">Blog</span>
                  <span className="fs-12">Check latest articles</span>
                </div>
              </button>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div
        className="offcanvas offcanvas-start"
        data-bs-scroll="true"
        tabIndex={-1}
        id="offcanvasWithBothOptions"
        aria-labelledby="offcanvasWithBothOptionsLabel"
      >
        <div className="offcanvas-header">
          <div className="logo ">
            <a href="/" aria-label="">
              <Brand width={120} height={60} />
            </a>
          </div>{" "}
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <ul className="navbar-nav justify-content-center align-items-start flex-grow-1 gap-1">
            <li className="nav-item">
              <a className={isActive("")} href="/" aria-label="">
                Restaurants
              </a>
            </li>

            <li className="nav-item dropdown">
              <a className={isActive("organicstores")} href="/organicstores">
                <span> Organic Stores</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className={isActive("dieticians")}
                aria-controls="dieticians_menu"
                href="/dieticians"
                aria-haspopup
              >
                <span>Dietitians</span>
              </a>
            </li>

            <li className="nav-item">
              <a
                className={isActive("healthtips")}
                href="/healthtips"
                aria-label=""
              >
                Health Tips
              </a>
            </li>

            <li
              className="nav-item dropdown"
              onClick={handleAboutDropdownToggle}
            >
              <a className={isActive("about")} style={{ gap: "10px" }}>
                <span> About</span>
                <i
                  className={`pi ${isAboutDropdownOpen ? "pi-chevron-up" : "pi-chevron-down"
                    }`}
                  style={{ fontSize: "0.7rem" }}
                ></i>
              </a>

              {isAboutDropdownOpen && (
                <ul
                  className="about-submenu"
                  style={{ listStyle: "none", paddingLeft: "1.2rem" }}
                >
                  <li className="submenu-item">
                    <a href="/whyyeldam">
                      <span> Why Yeldam </span>
                    </a>
                  </li>
                  {/* <li className="submenu-item">
                    <a href="/comingsoon">
                      <span>Blog</span>
                    </a>

                  </li> */}
                  <li className="submenu-item">
                    <a href="/healthyrestaurants">
                      <span> Restaurants </span>
                    </a>
                  </li>
                  <li className="submenu-item">
                    <a href="/aboutorganicstore">
                      <span>Organic Stores</span>
                    </a>
                  </li>
                </ul>
              )}
            </li>

            <li
              className="nav-item dropdown"
              onClick={handlelistingDropdownToggle}
            >
              <a className={isActive("listing")} style={{ gap: "10px" }}>
                <span> Add Listing</span>
                <i
                  className={`pi ${isAddlistingDropdownOpen
                    ? "pi-chevron-up"
                    : "pi-chevron-down"
                    }`}
                  style={{ fontSize: "0.7rem", color: "#323130" }}
                ></i>
              </a>

              {isAddlistingDropdownOpen && (
                <ul
                  className="organic-submenu"
                  style={{ listStyle: "none", paddingLeft: "1.2rem" }}
                >
                  <li className="submenu-item">
                    <a href="/useraddrestaurant">
                      <span> Suggest Restaurant</span>
                    </a>
                  </li>
                  <li className="submenu-item">
                    <a href="/addstore">
                      <span> Suggest Organic Store</span>
                    </a>
                  </li>
                  {/* Add more submenu items as needed */}
                </ul>
              )}
            </li>

            <li className="nav-item">
                <a
                  className={isActive("comingsoon")}
                  href="/businesscomingsoon"
                  aria-label=""
                >
                  <span> Yeldam Business</span>
                </a>
              </li>
            {!localStorage.getItem("accessToken") ? (
              <li className="nav-item mt-1">
                <Button
                  className="button_register"
                  label="Sign In/Sign Up"
                  severity="success"
                  data-bs-dismiss="offcanvas"
                  onClick={() => setVisible(true)}
                />
              </li>
            ) : (
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle d-flex"
                  href="/"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  aria-label=""
                >
                  {(localStorage.getItem("image") as string) === "undefined" ||
                    (localStorage.getItem("image") as string) === null ? (
                    <Avatar
                      label={
                        localStorage.getItem("username")?.toString()[0]
                        // .toUpperCase()
                      }
                      className="mr-2"
                      shape="circle"
                    />
                  ) : (
                    <Avatar
                      image={localStorage.getItem("image") as string}
                      className="mr-2"
                      shape="circle"
                    />
                  )}{" "}
                  <div className="flex flex-column align">
                    <span className="user-name">
                      {toCamelCase(localStorage.getItem("username"))}
                    </span>
                  </div>
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li className="profile-li">
                    <a
                      href="/profile"
                      className="dropdown-item"
                      style={{ fontSize: "14px !important" }}
                      aria-label=""
                    >
                      <i
                        className="pi pi-user d-flex gap-2"
                        style={{ fontSize: "15px", marginRight: "10px" }}
                      >
                        <span style={{ fontFamily: "rubik" }}>Profile</span>
                      </i>
                    </a>
                  </li>
                  <li className="profile-li">
                    <a
                      href="/"
                      className="dropdown-item"
                      style={{ fontSize: "14px !important" }}
                      onClick={Logout}
                      aria-label=""
                    >
                      <i
                        className="pi pi-power-off"
                        style={{ fontSize: "14px", marginRight: "10px" }}
                      ></i>
                      <span style={{ fontFamily: "rubik" }}>Logout</span>
                    </a>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
        <div className="offcanvas-backdrop fade show"></div>
      </div>
      <nav className="navbar">
        <div className="container mob-nav-shadow">
          <div
            className="menu-icon"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasWithBothOptions"
            aria-controls="offcanvasWithBothOptions"
            onClick={handleShowNavbar}
          >
            <i className="pi pi-bars" style={{ fontSize: "1.5rem" }}></i>
          </div>
          <div className="logo">
            {splitLocation[1] === "search" ||
              splitLocation[1] === "organicstores" ||
              splitLocation[1] === "profile" ||
              splitLocation[1] === "restaurant" ||
              splitLocation[1] === "foodevent" ||
              splitLocation[1] === "healthtips" ||
              splitLocation[1] === "addrestaurant" ||
              splitLocation[1] === "privacy" ||
              splitLocation[1] === "terms" ||
              splitLocation[1] === "comingsoon" ||
              splitLocation[1] === "businesscomingsoon" ||
              splitLocation[1] === "pagenotfound" ||
              splitLocation[1] === "addlisting" ||
              splitLocation[1] === "contactus" ||
              splitLocation[1] === "pagenotfound" ||
              splitLocation[1] === "faq" ||
              splitLocation[1] === "addstore" ||
              splitLocation[1] === "searchstore" ||
              splitLocation[1] === "useraddrestaurant" ||
              splitLocation[1] === "searchrestaurant" ||
              splitLocation[1] === "dieticians" ||
              splitLocation[1] === "searchdietitian" ||
              splitLocation[1] === "healthyrestaurants" ||
              splitLocation[1] === "aboutorganicstore" ||
              splitLocation[1] === "whyyeldam" ? (
              <>
                <a href="/" aria-label="">
                  <Brand width={90} height={36} />
                </a>
              </>
            ) : (
              <>
                <a
                  href="/comingsoon"
                  className="d-flex gap-2 use-app"
                  aria-label=""
                >
                  <i
                    className="bi bi-phone"
                    style={{
                      fontSize: "1.1rem",
                      fontWeight: "600",
                      color: "#238119",
                    }}
                  ></i>
                  <span className="App-text">Get the app</span>
                </a>
              </>
            )}
          </div>

          {!localStorage.getItem("accessToken") ? (
            ""
          ) : (
            <>
              <a
                className="d-flex profile-image-nav"
                href="/profile"
                role="button"
                aria-expanded="false"
                aria-label=""
              >
                {(localStorage.getItem("image") as string) === "undefined" ||
                  (localStorage.getItem("image") as string) === null ? (
                  <Avatar
                    label={localStorage
                      .getItem("username")
                      ?.toString()[0]
                      .toUpperCase()}
                    className="mr-1"
                    shape="circle"
                  />
                ) : (
                  <Avatar
                    image={localStorage.getItem("image") as string}
                    className="mr-1"
                    shape="circle"
                  />
                )}
              </a>
            </>
          )}
          <div className={`nav-elements  ${showNavbar && ""}`}>
            <ul>
              {/* <li>
                <NavLink to="/">Home</NavLink>
              </li> */}
              <li className="nav-item">
                <a className={isActive("")} href="/" aria-label="">
                  Restaurants
                </a>
              </li>

              <li className="nav-item">
                <a
                  className={isActive("organicstores")}
                  href="/organicstores"
                  aria-label=""
                >
                  <span> Organic Stores</span>
                </a>
              </li>

              <li className="nav-item">
                <a
                  className={isActive("dieticians")}
                  aria-controls="dieticians_menu"
                  href="/dieticians"
                  aria-haspopup
                >
                  <span>Dietitians</span>
                </a>
              </li>

              <li className="nav-item" onClick={handleADropdownToggle}>
                <a
                  className={isActive("about")}
                  aria-controls="about_menu"
                  onClick={(event) => aboutmenuLeft.current?.toggle(event)}
                  aria-haspopup
                >
                  <span>About</span>
                  <i
                    className={"pi pi-chevron-down"}
                    style={{ fontSize: "0.7rem" }}
                  ></i>
                </a>
                <Menu
                  model={itemss}
                  popup
                  ref={aboutmenuLeft}
                  id="about_menu"
                />
              </li>

              <li className="nav-item" onClick={handlelistingDropdownToggle}>
                <a
                  className={isActive("addlisting")}
                  aria-controls="listing_menu"
                  onClick={(event) => listingmenuLeft.current?.toggle(event)}
                  aria-haspopup
                >
                  <span>Add Listing</span>
                  <i
                    className={"pi pi-chevron-down"}
                    style={{ fontSize: "0.7rem" }}
                  ></i>
                </a>
                <Menu
                  model={itemsss}
                  popup
                  ref={listingmenuLeft}
                  id="listing_menu"
                />
              </li>

              <li className="nav-item mr-3">
                <a
                  className={isActive("comingsoon")}
                  href="/businesscomingsoon"
                  aria-label=""
                >
                  <span> Yeldam Business</span>
                </a>
              </li>


              {!localStorage.getItem("accessToken") ? (
                <li>
                  <Button
                    className="button_register"
                    label="Sign In/Sign Up"
                    severity="success"
                    // data-bs-dismiss="offcanvas"
                    onClick={() => {
                      setVisible(true);
                    }}
                  />
                </li>
              ) : (
                <li className="nav-item dropdown" style={{ cursor: "pointer" }}>
                  <div
                    className="nav-link dropdown-toggle d-flex"
                    onClick={(event) => profilemenu.current?.toggle(event)}
                    aria-label="Dropdown_Toggle"
                  >
                    {(localStorage.getItem("image") as string) ===
                      "undefined" ||
                      (localStorage.getItem("image") as string) === null ? (
                      <Avatar
                        label={localStorage
                          .getItem("username")
                          ?.toString()[0]
                          .toUpperCase()}
                        className="mr-2"
                        shape="circle"
                      />
                    ) : (
                      <Avatar
                        image={localStorage.getItem("image") as string}
                        className="mr-2"
                        shape="circle"
                      />
                    )}
                    <i
                      className={"pi pi-chevron-down"}
                      style={{ fontSize: "0.7rem", color: "#3c3c3c" }}
                    ></i>
                    {/* <div className="flex flex-column align">
                      <span className="user-name">{toCamelCase(localStorage.getItem("username"))}</span>
                    </div> */}
                  </div>
                  <Menu model={proitemsss} popup ref={profilemenu} />
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
      <div>
        <Dialog
          visible={visible}
          closeOnEscape={false}
          // style={{ width: "auto", borderRadius: "12px" }}
          className="login-model"
          // breakpoints={{
          //   "2560px": "50vw",
          //   "1920px": "50vw",
          //   "1420px": "50vw",
          //   "1120px": "50vw",
          //   "1090px": "70vw",
          //   "980px": "70vw",
          //   "840px": "80vw",
          //   "740px": "95vw",
          //   "641px": "95vw",
          //   "541px": "95vw",
          //   "490px": "95vw",
          //   "350px": "95vw",
          // }}
          onHide={() => setVisible(false)}
        >
          <SigninSignup setVisible={setVisible} />
        </Dialog>
      </div>
    </>
  );
};

export default Navbar;
