import React, { createContext, useContext, useEffect, useState } from 'react';
import { CheckLike, LikedRestaurant } from '../../api/ratings.api';
import { Dialog } from 'primereact/dialog';
import SignInSignUp from '../UI/SigninSignup/signInSignUp.component';

interface LikeContextType {
  likedRests: string[];
  toggleLike: (restId: string,isLikedstatus:boolean) => void;
}

const LikeContext = createContext<LikeContextType | undefined>(undefined);

interface LikeProviderProps {
  children: React.ReactNode;
  initialRestaurants:any;
}

export const LikeProvider: React.FC<LikeProviderProps> = ({ children ,initialRestaurants}) => {
  const [likedRests, setLikedRests] = useState<string[]>([]);
  const [signinvisible, setSigninVisible] = useState<boolean>(false);

  const toggleLike = async (restId: string, isLikedstatus:boolean) => {
    try {
      // Call the API function to toggle like status in the database
      if (localStorage.getItem("accessToken") === null) {
        setSigninVisible(true);
      }
      if (likedRests.includes(restId) || isLikedstatus) {
        const isLiked = await CheckLike(restId);
        if (isLiked) {
        // If already liked, unlike it
        await LikedRestaurant(restId, false);
        setLikedRests(prevLikedRests => prevLikedRests.filter(id => id !== restId));
        }
        else{
        await LikedRestaurant(restId, true);
        setLikedRests(prevLikedRests => [...prevLikedRests, restId]);
        }
      } else {
        // If not liked, like it
        await LikedRestaurant(restId, true);
        setLikedRests(prevLikedRests => [...prevLikedRests, restId]);
      }
    } catch (error) {
      console.error('Error toggling like:', error);
    }
  };
  useEffect(() => {
    // Initialize liked restaurants from initial data
    const initialLikedRests = initialRestaurants.filter((restaurant:any) => restaurant.isLiked).map((restaurant:any) => restaurant._id);
    setLikedRests(initialLikedRests);
  }, [initialRestaurants]);


  useEffect(() => {
    const storedLikedRests = localStorage.getItem('likedRests');
    if (storedLikedRests) {
      setLikedRests(JSON.parse(storedLikedRests));
    }
  }, []);

  // Save likedRests to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('likedRests', JSON.stringify(likedRests));
  }, [likedRests]);
  

  return (
    <LikeContext.Provider value={{ likedRests, toggleLike }}>
      {children}
      <Dialog
      visible={signinvisible}
      closeOnEscape={false}
      className="login-model"
      onHide={() => setSigninVisible(false)}
      >
    <SignInSignUp setVisible={setSigninVisible} />
    </Dialog>
    </LikeContext.Provider>
    
  );
};

export const useLike = () => {
  const context = useContext(LikeContext);
  if (!context) {
    throw new Error('useLike must be used within a LikeProvider');
  }
  return context;
};

