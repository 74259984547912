import React from "react";
import "./dietitianAdvancedSearchResults.component.css";
import { ScrollPanel } from "primereact/scrollpanel";
import DietitianCard from "../UI/Cards/DietitianCard/dietitianCard";
import dietitianpic from "../../assets/images/dietitianpic.png";
import { devsas } from "../../config/constants";
interface results {
  onClick: any;
  dietitiansdata: any;
  setActiveIndex: React.Dispatch<React.SetStateAction<number>>;
}

const DietitianAdvancedSearchResults: React.FC<results> = ({
  dietitiansdata,
  onClick,
  setActiveIndex,
}) => {
  const handleCardClick = (id: any, index: number) => {
    onClick(id, index);
    setActiveIndex(0);
  };
  console.log("advanceddata:", dietitiansdata._id);
  return (
    <>
      <div className="Results-Heading d-flex justify-content-between p-2 align-items-center mb-0">
      <p className="rest-result-arrow ml-1">Dietitians <i className="pi pi-chevron-right" style={{fontSize:"10px"}}></i> <span>Search Results</span></p>
      </div>
      <ScrollPanel className="col-lg-12 card-scroll-behavior">

        {dietitiansdata.map((item: any, index: number) => {
          return (
            <DietitianCard
              dietitianname={item.dietitianname}
              dietitianimg={item.dietitianimages[0] + "?" + devsas}
              reviewsCount={item.reviewCount}
              speciality={item.speciality.join("/")}
              experience={item.experience}
              degree={item.degree}
              rating={item.avgTotalRating}
              // timings={item.timings}
              // serviceoptions={item.serviceoptions}
              likeddietitian={item.isLiked}
              dietitianid={item._id}
              onClick={() => handleCardClick(item._id, index)}
            />
          );
        })}
      </ScrollPanel>
    </>
  );
};

export default DietitianAdvancedSearchResults;
