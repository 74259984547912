import React, { useEffect, useState } from 'react';
import Navbar from '../../components/UI/Navbar/navBar.component';
import Footer from '../../components/UI/Footer/footer.component';
import "./aboutOrganicStore.pages.css";
import { yeldamwebimagessas } from '../../config/constants';
import { Skeleton } from 'primereact/skeleton';

const AboutOrganicStore = () => {

    const [city, setCity] = useState<string>("");
    const [list, setList] = useState<string[]>([]);
    const [search, setSearch] = useState<string[]>([]);
    const [showImage, setShowImage] = useState(false);


    useEffect(() => {
        const timer = setTimeout(() => {
            setShowImage(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="container-fluid p-0">
            <div className="row">
                <Navbar />
            </div>

                  <div className="container internal-screen" style={{minHeight: "calc(100vh - 50px)"}}>
                <div className="IFE-heading d-flex justify-content-between align-items-center">
                    <div className="Food-Events-heading">Organic Stores</div>
                    <div className="d-flex align-items-center FE-heading-nav">
                        <a href="/" className="d-flex">
                            {" "}
                            <span className="home-span">Home</span>
                        </a>
                        <i
                            className="bi bi-chevron-right"
                            style={{ color: "#C2C2C2", fontSize: "12px" }}
                        ></i>
                        <span className="event-span">Organic Stores</span>
                    </div>
                </div>
                <div className="about-stores-Body flex-column d-flex gap-6 mb-6">
                    <div className='about-stores-division1 d-flex gap-3 '>
                        <div className='about-stores-division1-content col-lg-6 col-md-6 d-flex flex-column'>
                            <p className='stores-division-head'>
                                Welcome to Yeldam's Organic Store Haven
                            </p>
                            <p className='stores-division-text'>
                                In the Yeldam Universe you not only get to find the best suited dining out option but also the most nourishing organic purchases. Our Organic Stores service is your gateway to healthier shopping for your meals' raw material.
                            </p>
                            <p className='stores-division-text'>
                                In today's lifestyle it is very common to feel doubt about those commercial brand purchases from convenience stores or super-markets. Every product feels in-organic and chemically adulterated, which won't do justice to your healthy cooked meals at home. If the raw material itself is not natural, how can you expect your finished product to do justice to your well-being.
                            </p>
                            <p className='stores-division-text'>
                                Through our Organic Store Locator, users can easily discover fresh produce, dairy, poultry, vegetables/fruits and more. An array of essentials in their purest form, that are perfect for your home cooked healthy meal. The Organic Store Locator on Yeldam is designed for those who value cooking for themselves and seek assurance of the ingredients involved.
                            </p>

                        </div>
                        <div className='col-lg-6 col-md-6 about-store-divison1-image'>
                            {!showImage ? (
                                <Skeleton width="100%" height="280px" ></Skeleton>
                            ) : (
                                <img src={'https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/about/aosimg1.webp' + "?" + yeldamwebimagessas} alt='' />
                            )}
                        </div>


                    </div>
                    <div className='about-stores-division2 d-flex gap-3 '>
                        <div className='col-lg-6 col-md-6 about-store-divison2-image'>
                            {!showImage ? (
                                <Skeleton width="100%" height="330px" ></Skeleton>
                            ) : (
                                <img src={'https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/about/aosimg2.webp' + "?" + yeldamwebimagessas} alt='' />
                            )}
                        </div>
                        <div className='about-stores-division2-content col-lg-6 col-md-6 d-flex flex-column'>
                            <p className='stores-division-head'>
                                Ensuring Authenticity Through Rigorous Checks
                            </p>
                            <p className='stores-division-text'>
                                At Yeldam Organic Store Locator, authenticity and service is the priority. Before we feature any organic store on our website, we follow a rigorous onboarding process to ensure genuinity before it becomes a part of your organic living.
                            </p>

                            <div className="accordion" id="accordionExample">
                                <div className="item1-accordion">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button acc-q"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                        >

                                            Thorough Authentication Checks
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            When we get a new Organic Store suggestion, we make sure to conduct
                                            a thorough evaluation to verify the claims and genuineness of every Organic Store.                                        </div>
                                    </div>
                                </div>
                                <div className="item1-accordion">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button collapsed acc-q"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >

                                            Community-Driven Reviews
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            The fellow healthy living conscious consumers in the community play
                                            a pivoting role in making every Organic Store feature a success.
                                            The reviews they leave on the platform regarding their experience
                                            with the store provide real-time insight into the quality and reliability.
                                            Hence urge all the new users as well to leave their comments.
                                        </div>
                                    </div>
                                </div>
                                <div className="item1-accordion">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button collapsed acc-q"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                        >

                                            Informed Decision-Making
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            Due to all the information kept forth for the consumers, the quote,
                                            ' Transparency is the key,' stands true at Yeldam Organic Store locator.
                                            This is our way to empower you to make informed decisions about the
                                            products you consume and give you confidence in all your organic buys.
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>


                    </div>

                    <div className='about-stores-division3 d-flex gap-3 '>
                        <div className='about-stores-division3-content col-lg-6 col-md-6 d-flex flex-column'>
                            <p className='stores-division-head'>
                                Your Organic Journey Starts Here:
                            </p>
                            <p className='stores-division-text'>
                                Yeldam is a community and not just a platform, we value your commitment
                                to healthy lifestyles and organic eating. Every store listed is a testament
                                to quality and authenticity, we welcome you to join this journey with us together.
                            </p>
                            <div className='d-flex flex-column'>
                                <div className='journey-1 d-flex'>
                                    <div className='icon-tick col-lg-1 col-md-1'>
                                        <i className="pi pi-check" style={{ color: "#36a41d" }}></i>
                                    </div>
                                    <p className='stores-division-text col-lg-11 col-md-11'>
                                        Discover a world of wholesome and organic product stores that are in sync with your healthy cooking and well-being aspirations.
                                    </p>

                                </div>
                                <div className='journey-2 d-flex'>
                                    <div className='icon-tick col-lg-1 col-md-1'>
                                        <i className="pi pi-check" style={{ color: "#36a41d" }}></i>
                                    </div>
                                    <p className='stores-division-text col-lg-11 col-md-11'>
                                        We extend a heartfelt and warm invitation to you to join us on this exciting journey. Not just a user but also a part of this community; suggest, review and use the Organic Store locator.
                                    </p>

                                </div>
                                <div className='journey-3 d-flex'>
                                    <div className='icon-tick col-lg-1 col-md-1'>
                                        <i className="pi pi-check" style={{ color: "#36a41d" }}></i>
                                    </div>
                                    <p className='stores-division-text col-lg-11 col-md-11'>
                                        Begin your organic journey today!
                                    </p>

                                </div>

                            </div>
                            
                        </div>
                        <div className='col-lg-6 col-md-6 about-store-divison3-image d-flex'>
                            {!showImage ? (
                                <Skeleton width="100%" height="225px" ></Skeleton>
                            ) : (
                                <img src={'https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/about/os3a.webp' + "?" + yeldamwebimagessas} alt='' />
                            )}
                        </div>

                    </div>



                </div>
            </div>


            <div className="row">
                <Footer localities={list} city={city} search={search} type={"restaurant"}  />
            </div>

        </div>
    );
}

export default AboutOrganicStore;
