import React, { useState } from "react";
import "./Faq.page.css";
import { Accordion, AccordionTab } from "primereact/accordion";
import Navbar from "../../components/UI/Navbar/navBar.component";
import Footer from "../../components/UI/Footer/footer.component";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [city, setCity] = useState<string>("");

  const [list, setList] = useState<string[]>([]);

  const [search, setSearch] = useState<string[]>([]);

  return (
    <div className="Faq-main">
      <div className="row">
        <Navbar />
      </div>
      <div className="container mb-5">
        <div className="d-flex justify-content-between align-items-center mt-4 mb-4">
          <span className="faq-heading">Frequently Asked Questions</span>
          <div className="d-flex align-items-center FE-heading-nav">
            <a href="/" className="d-flex">
              {" "}
              <span className="home-span">Home</span>
            </a>
            <i
              className="bi bi-chevron-right"
              style={{ color: "#C2C2C2", fontSize: "12px" }}
            ></i>
            <span className="event-span">FAQ's</span>
          </div>
        </div>

        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                What is Yeldam?
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yeldam is a platform that assists users in finding healthy food
                options offering restaurants.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                How does Yeldam work?
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Visitors and users of the website can look up health-friendly
                and wholesome dining experience offering restaurants by
                location, cuisine type, or dietary preferences and restrictions,
                such as gluten-free, vegetarian, dairy free, etc.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                Is Yeldam free to use?
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yes, Yeldam is absolutely free for users.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button
                className="accordion-button collapsed Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                How accurate is the restaurant information provided by Yeldam?
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yeldam is continuously stiving to provide the most accurate
                restaurant information, but a major part of the info also relies
                upon the establishment. While our efforts are to verify each
                data and keep it up to date, we are unable to always guarantee
                its accuracy. Operating hours, menu items, pricing and
                availability of health food options are subject to changes on
                the establishment's front without giving Yeldam any prior
                notice.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFive">
              <button
                className="accordion-button collapsed Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                How many restaurants are listed on Yeldam?
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yeldam currently works on a database of thousands of such
                restaurants.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingSix">
              <button
                className="accordion-button collapsed Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                Can users leave reviews on Yeldam?
              </button>
            </h2>
            <div
              id="collapseSix"
              className="accordion-collapse collapse"
              aria-labelledby="headingSix"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yes, reviews are a major part of the Yeldam life, and we
                encourage the users to leave reviews and also upload photos of
                the establishments they visit. This helps fellow health-food
                enthusiast to find good nutritious options.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingSeven">
              <button
                className="accordion-button collapsed Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                Can restaurants list themselves on Yeldam?
              </button>
            </h2>
            <div
              id="collapseSeven"
              className="accordion-collapse collapse"
              aria-labelledby="headingSeven"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Currently, we only allow the customers to add the restaurant of
                their liking on Yeldam. However, very soon the owners of such
                food joints will be able to register for free by submitting
                their information to Yeldam.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingEight">
              <button
                className="accordion-button collapsed Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEight"
                aria-expanded="false"
                aria-controls="collapseEight"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                Does Yeldam offer any other services besides restaurant search?
              </button>
            </h2>
            <div
              id="collapseEight"
              className="accordion-collapse collapse"
              aria-labelledby="headingEight"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yeldam is presently focusing on assisting users in finding the
                right wholesome food establishments, but soon we are looking to
                expand our services.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingNine">
              <button
                className="accordion-button collapsed Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseNine"
                aria-expanded="false"
                aria-controls="collapseNine"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                How is Yeldam different than other applications?
              </button>
            </h2>
            <div
              id="collapseNine"
              className="accordion-collapse collapse"
              aria-labelledby="headingNine"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yeldam exclusively focuses on healthy dining experiences and
                robust screening of such restaurants who claim to provide the
                aforementioned and we are India’s only such platform. The
                integration of health tips with the elite and enriching food
                events section sets Yeldam apart from others in the running.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTen">
              <button
                className="accordion-button collapsed Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTen"
                aria-expanded="false"
                aria-controls="collapseTen"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                Are the health tips given by professionals?
              </button>
            </h2>
            <div
              id="collapseTen"
              className="accordion-collapse collapse"
              aria-labelledby="headingTen"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yes, all health tips on Yeldam are curated in collaboration with
                the best and certified nutrition and health experts.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingEleven">
              <button
                className="accordion-button collapsed Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEleven"
                aria-expanded="false"
                aria-controls="collapseEleven"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                How often the health tips are update or added?
              </button>
            </h2>
            <div
              id="collapseEleven"
              className="accordion-collapse collapse"
              aria-labelledby="headingEleven"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yeldam adds health tips according to the updates required and of
                course public demand as well as health trends.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTweleve">
              <button
                className="accordion-button collapsed Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTweleve"
                aria-expanded="false"
                aria-controls="collapseTweleve"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                Are the health tips tailored to different dietary preferences?
              </button>
            </h2>
            <div
              id="collapseTweleve"
              className="accordion-collapse collapse"
              aria-labelledby="headingTweleve"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yes, the health tips include all dietary restrictions and
                preferences so that everyone can find relevant information
                according to their choices.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThirteen">
              <button
                className="accordion-button collapsed Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThirteen"
                aria-expanded="false"
                aria-controls="collapseThirteen"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                What type of events are featured on Yeldam?
              </button>
            </h2>
            <div
              id="collapseThirteen"
              className="accordion-collapse collapse"
              aria-labelledby="headingThirteen"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yeldam food event section features a range of affairs happening
                around you, right from food festivals to culinary workshops, you
                can find it all.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFourteen">
              <button
                className="accordion-button collapsed Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFourteen"
                aria-expanded="false"
                aria-controls="collapseFourteen"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                How to find food events in your area?
              </button>
            </h2>
            <div
              id="collapseFourteen"
              className="accordion-collapse collapse"
              aria-labelledby="headingFourteen"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                You can search for any events happening around you via the
                location filter.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFifteen">
              <button
                className="accordion-button collapsed Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFifteen"
                aria-expanded="false"
                aria-controls="collapseFifteen"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                Can I submit information about food events that are relevant and
                happening in the Yeldam operational regions?
              </button>
            </h2>
            <div
              id="collapseFifteen"
              className="accordion-collapse collapse"
              aria-labelledby="headingFifteen"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                We will be more than happy to receive any such information and
                will encourage you to share such details by filling the form on
                the Contact Us page, or via email/call.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingSixteen">
              <button
                className="accordion-button collapsed Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSixteen"
                aria-expanded="false"
                aria-controls="collapseSixteen"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                Does Yeldam sells tickets/passes for these said events?
              </button>
            </h2>
            <div
              id="collapseSixteen"
              className="accordion-collapse collapse"
              aria-labelledby="headingSixteen"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yeldam only offers additional information to the users regarding
                relevant event, but ticketing and registration will particularly
                be handled by the official event organisers.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingSeventeen">
              <button
                className="accordion-button collapsed Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeventeen"
                aria-expanded="false"
                aria-controls="collapseSeventeen"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                As a restaurant owner, how can I add my restaurant to Yeldam?
              </button>
            </h2>
            <div
              id="collapseSeventeen"
              className="accordion-collapse collapse"
              aria-labelledby="headingSeventeen"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                To add restaurant, the owner must login by clicking ADD
                RESTAURANT and then log-in. You may add the establishment
                through a form in which all details of restaurants must be
                provided in the fields. You must also upload images of the said
                restaurant.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingEighteen">
              <button
                className="accordion-button collapsed Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEighteen"
                aria-expanded="false"
                aria-controls="collapseEighteen"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                What benefits does adding my restaurant on Yeldam provide?
              </button>
            </h2>
            <div
              id="collapseEighteen"
              className="accordion-collapse collapse"
              aria-labelledby="headingEighteen"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yeldam is a platform that is dedicated solely on providing
                healthy food options. If a restaurant is offering such services,
                it will be helpful for the establishment to be on Yeldam, since
                we have a vast user base, and the rating system also aids a
                restaurant's footfall.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwentyone">
              <button
                className="accordion-button collapsed Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwentyone"
                aria-expanded="false"
                aria-controls="collapseTwentyone"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                What is the Search Organic Store?
              </button>
            </h2>
            <div
              id="collapseTwentyone"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwentyone"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yeldam not only provides information about healthy dining
                options but also healthy and organic groceries through its
                Search Organic Store. You can find any good Organic Product
                store in your city or near you through Yeldam.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwentytwo">
              <button
                className="accordion-button collapsed Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwentytwo"
                aria-expanded="false"
                aria-controls="collapseTwentytwo"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                What kind of items are available in these suggested stores?
              </button>
            </h2>
            <div
              id="collapseTwentytwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwentytwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                All organically grown products from veggies to fruits and dairy
                to poultry are available in these stores (depending on the
                seller).
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwentythree">
              <button
                className="accordion-button collapsed Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwentythree"
                aria-expanded="false"
                aria-controls="collapseTwentythree"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                What information can I get on Yeldam about an Organic Store near
                me?
              </button>
            </h2>
            <div
              id="collapseTwentythree"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwentythree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yeldam Search Organic Store finds you the best suited result to
                your search with the contact information, location and even
                reviews.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwentyfour">
              <button
                className="accordion-button collapsed Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwentyfour"
                aria-expanded="false"
                aria-controls="collapseTwentyfour"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                How will I know the particular Organic Store shown to me is
                serving all it claims?
              </button>
            </h2>
            <div
              id="collapseTwentyfour"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwentyfour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                You can contact the store suggested to you directly on the given
                number and ask the seller for your desired products. And we
                display the details of store type in Yeldam.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwentyfive">
              <button
                className="accordion-button collapsed Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwentyfive"
                aria-expanded="false"
                aria-controls="collapseTwentyfive"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                What is the difference between supermarket and Organic Stores?
              </button>
            </h2>
            <div
              id="collapseTwentyfive"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwentyfive"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Supermarket's, though shelving many kinds of products under one
                roof, hardly offer all organic material. Whereas a specific
                Organic Store will offer only naturally cultivated articles and
                will have proof of its authenticity.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwentysix">
              <button
                className="accordion-button collapsed Question"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwentysix"
                aria-expanded="false"
                aria-controls="collapseTwentysix"
              >
                <i className="bi bi-dot" style={{ color: "#3c3c3c" }}></i>
                Can I suggest an Organic Store to Yeldam?
              </button>
            </h2>
            <div
              id="collapseTwentysix"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwentysix"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                Yes, you can very well suggest any organic store to Yeldam
                Search Organic Store through the Add a Store option in the
                Navigation Bar.
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <div className="row">
        <Footer localities={list} city={city} search={search} type={"restaurant"} />
      </div>
    </div>
  );
};

export default Faq;
