import React from 'react';
import "./dropFileinput.component.css";

type props = {
  uploadedFiles: any[];
  setUploadedFiles: Function;
  resObj: any;
  setResObj: Function;
  purpose: string;
};

const DropFileinput:React.FC<props>= ({
  uploadedFiles,
  setUploadedFiles,
  resObj,
  setResObj,
  purpose,
}) => {

  const handleUploadFiles = (files: File[]) => {
    if (purpose === "RestaurantImages") {
      console.log("RestaurantImages", purpose);
      setResObj({
        ...resObj,
        images: files,
      });
    }
    if (purpose === "StoreImages") {
      console.log("StoreImages", purpose);
      setResObj({
        ...resObj,
        images: files,
      });
    }
    setUploadedFiles(files);
  };

  const handleFileEvent = (e: any) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    console.log("chosen files", chosenFiles, "purpose,", purpose);
    handleUploadFiles(chosenFiles);
  };
  const fileRemove = (fileToRemove: any) => {
    const updatedFiles = uploadedFiles.filter(
      (item: any) => item !== fileToRemove
    );
    setUploadedFiles(updatedFiles);
  };

  return (
    
        <>
          <div className="drop-file-input">
            <div className="container d-flex flex-column justify-content-center align-items-center">
              <div className="d-flex justify-content-center align-items-center">
                <i
                  className="pi pi-upload"
                  style={{
                    color: "#605E5C",
                    padding: "10px",
                    background: "#F9F9F9",
                    borderRadius: "10px",
                  }}
                ></i>
              </div>
              <div className="d-flex justify-content-center align-items-center img-upload-label">
                <label htmlFor={`upload-input-${purpose}`} className="upload-label gap-1">
                  <div >
                 <p className="Image-placeholder"> Drag and drop file here or
                {" "}
                
                <span> Choose file</span>
                </p>
                  </div>
                  
                    <span className='file-size-text'>
                      Supports only JPG, PNG & JPEG.
                    </span>
                  
                </label>
              </div>
            </div>
    
            <input
              id={`upload-input-${purpose}`}
              type="file"
              value=""
              multiple
              onChange={handleFileEvent}
              accept="image/png,image/jpg,image/jpeg"
            />
          </div>
          <div className="grid mt-2 ml-1 gap-3">
            {uploadedFiles.map((item: any, index: any) => (
              <div key={index} id="Imagecrd" style={{ position: "relative" }}>
                <img className="uploadimage" src={URL.createObjectURL(item)} alt="" />
                <i
                  className="pi pi-trash icontrash"
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    margin: "8px",
                  }}
                ></i>
              </div>
            ))}
          </div>
        </>
      );
    };
    

export default DropFileinput;
