import React, { useState } from 'react';
import "./terms.pages.css";
import Navbar from '../../components/UI/Navbar/navBar.component';
import { ScrollPanel } from 'primereact/scrollpanel';
import Footer from '../../components/UI/Footer/footer.component';

const Terms = () => {
    const [city, setCity] = useState<string>("");
    const [list, setList] = useState<string[]>([]);
    const [search, setSearch] = useState<string[]>([]);

    const listItems = document.querySelectorAll('#firstList .terms-listitem');
    const labels = document.querySelectorAll('#secondList .terms-question');

    listItems.forEach((item, index) => {
        item.addEventListener('click', () => {
            labels[index].scrollIntoView({ behavior: 'smooth' });
        });
    
        item.addEventListener('mouseover', () => {
            labels[index].scrollIntoView({ behavior: 'smooth' });
        });
    });

    return (
        <div className="container-fluid p-0">
            <div>
                <Navbar />
            </div>
                  <div className="container internal-screen" style={{minHeight: "calc(100vh - 50px)"}}>
                <div className="TC-heading d-flex justify-content-between align-items-center">
                    <div className="Food-Events-heading">Terms & Conditions</div>
                    <div className="d-flex align-items-center FE-heading-nav">
                        <a href="/" className="d-flex">
                            {" "}
                            <span className="home-span">Home</span>
                        </a>
                        <i
                            className="bi bi-chevron-right"
                            style={{ color: "#C2C2C2", fontSize: "12px" }}
                        ></i>
                        <span className="event-span">Terms & Conditions</span>
                    </div>
                </div>
                <div className='terms-body d-flex gap-3'>
                    <div className="col-lg-3 col-md-3 Terms-filters">
                        <div className="d-flex">
                            <ul id="firstList" className="p-2 d-flex flex-column gap-3">
                                
                                <li><a className='terms-listitem' href="#term-I">I. Acceptance of terms</a></li>
                                <li><a className='terms-listitem' href="#term-II">II. Definitions</a></li>
                                <li ><a className='terms-listitem' href="#term-III">III. Eligibility to use the services </a></li>
                                <li ><a className='terms-listitem' href="#term-IV">IV. Changes to the terms </a></li>
                                <li ><a className='terms-listitem' href="#term-V">V. Translation of the terms </a></li>
                                <li ><a className='terms-listitem' href="#term-VI">VI. Provision of the services being offered by Yeldam </a></li>
                                <li ><a className='terms-listitem' href="#term-VII">VII. Account Access and Usage </a></li>
                                <li ><a className='terms-listitem' href="#term-VIII">VIII. Disclaimers </a></li>
                                <li ><a className='terms-listitem' href="#term-IX"> IX. Eligibility to Use the Services</a></li>
                                <li ><a className='terms-listitem' href="#term-X">X. Content Ownership and Proprietary Rights</a></li>
                                <li ><a className='terms-listitem' href="#term-XI">XI. Content Removal and Third-Party Links</a></li>
                                <li ><a className='terms-listitem' href="#term-XII">XII. Customer Reviews and Guidelines</a></li>
                                <li ><a className='terms-listitem' href="#term-XIII">XIII. Restrictions on Use</a></li>
                                <li ><a className='terms-listitem' href="#term-XIV">XIV. Customer Feedback</a></li>
                                <li ><a className='terms-listitem' href="#term-XV">XV. Advertising</a></li>
                                <li ><a className='terms-listitem' href="#term-XVI">XVI. General Terms and Conditions</a></li>
                                <li ><a className='terms-listitem' href="#term-XVII">XVII. Food Hygiene Ratings</a></li>
                                <li ><a className='terms-listitem' href="#term-XVIII">XVIII. Limitation of Liability</a></li>
                                <li ><a className='terms-listitem' href="#term-XIX">XIX. Notice of copyright infringement</a></li>
                                <li ><a className='terms-listitem' href="#term-XX">XX. Contact us </a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='col-lg-9 col-md-9 terms-conditions'>
                        <ScrollPanel className="terms-scroll-behaviour">
                            <div className='d-flex'>
                                <ul id="secondList" className='p-2 d-flex flex-column gap-2'>
                                    <li>
                                        <label className='terms-question' id='term-I'>
                                            I. Acceptance of terms
                                        </label>
                                        <p className='condition-answer mt-2'>
                                            Welcome to the Yeldam website and platform! These Terms of Service (the "Terms")
                                            are designed to inform you about your rights and responsibilities when using the
                                            Yeldam website at www.yeldam.com (the "Site") and the related mobile or software
                                            applications ("Yeldam Platform"). These Terms apply to all current and future
                                            users of Yeldam's services, including individuals who have access to the
                                            'restaurant business page' for managing their claimed business listings.
                                        </p>
                                        <p className='condition-answer'>Please read these Terms carefully. By accessing or using the Yeldam Platform,
                                            you are agreeing to these Terms and concluding a legally binding contract
                                            with Yeldam Limited (formerly known as Yeldam Private Limited and Yeldam
                                            Media Private Limited) and/or its affiliates (excluding Yeldam Foods Private Limited)
                                            (hereinafter collectively referred to as "Yeldam"). You may not use the Services
                                            if you do not accept the Terms or are unable to be bound by the Terms. Your
                                            use of the Yeldam Platform is at your own risk, including the risk that you
                                            might be exposed to content that is objectionable or otherwise inappropriate.</p>
                                        <p className='condition-answer'>To use the Services, you must first agree to the Terms. You can accept the Terms by:</p>
                                        <ul className='ml-4' style={{ listStyleType: "disc" }}>
                                            <li className='condition-answer'>• Clicking to accept or agree to the Terms, where it is made available to you
                                                by Yeldam in the user interface for any particular Service.</li>
                                            <li className='condition-answer'>• Using the Services. In this case, you understand and agree that Yeldam will treat your use of the Services
                                                as acceptance of the Terms from that point onwards.</li>
                                        </ul>

                                    </li>
                                    <li>
                                        <label className='terms-question mb-2' id='term-II'>
                                            II. Definitions
                                        </label>
                                        <p className='sub-heading-terms'>Customer</p>
                                        <p className='condition-answer'>Customer "Customer" or "You" or "Your" refers to you, as a customer of the Services.
                                            A customer is someone who accesses or uses the Services to share, display, host, publish, transact, or upload information,
                                            views, or pictures and includes other persons jointly participating in using the Services, including without limitation
                                            a user having access to the 'restaurant business page' to manage claimed business listings or otherwise.</p>
                                        <p className='sub-heading-terms'>Content</p>
                                        <ul className='ml-4 mb-3' style={{ listStyleType: "disc" }}>
                                            <li className='condition-answer mb-1'>
                                            •  "Content" encompasses various forms of information or data, such as reviews, images, photos, audio, video,
                                                location data, nearby places, and more.
                                            </li>
                                            <li className='condition-answer mb-1'>• "Your Content" or "Customer Content" refers to the content you upload, share, or transmit through the Services,
                                                including likes, ratings, reviews, images, photos, messages, chat communication, and profile information.</li>
                                            <li className='condition-answer mb-1'>
                                            • "Yeldam Content" includes the content created and provided by Yeldam in connection with the Services,
                                                such as visual interfaces, interactive features, graphics, design, compilation, computer code, software,
                                                aggregate ratings, reports, and other usage-related data associated with your account.
                                            </li>
                                            <li className='condition-answer mb-1'>• "Third Party Content" refers to content originating from parties other than Yeldam or its Customers,
                                                such as Restaurant Partners, which is accessible through the Services.</li>
                                        </ul>
                                        <p className='sub-heading-terms'>Restaurant(s)</p>
                                        <p className='condition-answer'>"Restaurant" means the restaurants listed on the Yeldam Platform.</p>
                                    </li>
                                    <li>
                                        <label className='terms-question mb-2' id='term-III'>
                                            III. Eligibility to use the services
                                        </label>
                                        <p className='condition-answer'>
                                            By using the Services, you represent and guarantee that you are at least eighteen (18) years
                                            old and fully capable of understanding and agreeing to these Terms. You must also comply with
                                            all applicable laws and regulations in your country of residence when accessing and using the Services.
                                        </p>
                                    </li>
                                    <li>
                                        <label className='terms-question mb-2' id='term-IV'>IV. Changes to the terms</label>
                                        <p className='condition-answer'>Yeldam reserves the right to modify, amend, change, or update these Terms at its discretion. It is your responsibility to review the Terms periodically to ensure ongoing compliance. Your continued use of the Yeldam Platform after any
                                            modifications or changes to the Terms signifies your acceptance of the updated terms.</p>
                                    </li>
                                    <li>
                                        <label className='terms-question mb-2' id='term-V'>V. Translation of the terms</label>
                                        <p className='condition-answer'>Yeldam may provide translations of the Terms into other languages for convenience.
                                            However, the English version of the Terms shall govern your relationship with Yeldam. In case of any inconsistencies
                                            between the English version and translated versions, the English version prevails.</p>
                                    </li>
                                    <li>
                                        <label className='terms-question mb-2' id='term-VI'>
                                            VI. Provision of the services being offered by Yeldam
                                        </label>
                                        <p className='condition-answer'>
                                            Yeldam is continuously evolving to provide the best experience and information to its customers.
                                            The form and nature of the Services may require changes and improvements. Therefore,
                                            Yeldam reserves the right to suspend, cancel, or discontinue any products or services
                                            at any time without notice. Yeldam may also modify and alter the content, products,
                                            and services on the site without prior notification. Additionally, Yeldam may provide
                                            updates or upgrades to its software or applications, which you may be required to install to access or use the Services.
                                        </p>
                                    </li>
                                    <li>
                                        <label className='terms-question mb-2' id='term-VII'>
                                            VII. Account Access and Usage
                                        </label>
                                        <p className='condition-answer'>
                                            To access certain features of the Services, including claiming business listings, you must create an account.
                                            The personal information you provide during account creation is governed by our Privacy Policy.
                                            You are responsible for maintaining the confidentiality and security of your account,
                                            including password protection and any activities conducted through your account.
                                            You must immediately notify us of any unauthorized use or access to your account.
                                        </p>
                                        <p className='condition-answer'>
                                            You may also register using your credentials from select third-party social networking sites,
                                            confirming your ownership of such accounts. By doing so, you authorize us to collect necessary
                                            information in accordance with your account settings and instructions.
                                        </p>
                                        <p className='condition-answer'>
                                            By creating an account, you confirm that all provided information is true, accurate, and up to date.
                                            You must not impersonate others, create accounts for anyone other than yourself, use email addresses
                                            not belonging to you, create multiple accounts without authorization, or provide false information
                                            to claim a business listing unlawfully. False claiming of a business listing may result in liability
                                            for substantial damages to Yeldam or third parties
                                        </p>
                                        <p className='condition-answer'>By creating an account, you agree to receive
                                            communications related to the Yeldam Platform and Services.
                                            You can manage your preferences for non-essential communications through your account settings.</p>
                                    </li>
                                    <li>
                                        <label className='terms-question mb-2' id='term-VIII'>
                                            VIII. Disclaimers
                                        </label>
                                        <p className='condition-answer'>
                                            The Content on the Services is for informational purposes only. Yeldam disclaims any liability for outdated information and reserves the right to make changes and corrections without prior notice. Yeldam does not guarantee the quality of goods, prices listed in menus, or availability of all menu items at any Restaurant/Merchant.
                                        </p>
                                        <p className='condition-answer'>
                                            All images and information on the Services are believed to be owned by or licensed to Yeldam unless stated otherwise. Unauthorized reproduction or republishing of these images is prohibited without prior written permission from Yeldam.
                                        </p>
                                        <p className='condition-answer'>
                                            Certifications or information regarding Certifications displayed on a Restaurant's listing page are for informational purposes only. Yeldam does not guarantee the validity, authenticity, reliability, or accuracy of such Certifications or related information. Reliance on Certifications is at your own risk, and Yeldam assumes no liability for any losses or damages arising from their use or any discrepancies with applicable laws or regulations.
                                        </p>
                                        <p className='condition-answer'>
                                            Yeldam reserves the right to charge subscription and/or membership fees or other charges related to its products or services on the Yeldam Platform.
                                        </p>
                                        <p className='condition-answer'>
                                            Yeldam may introduce referral and/or incentive-based programs governed by their respective terms and conditions. Violation of program rules or engagement in fraudulent/unlawful activities may result in termination/suspension of your account or earned credits/points
                                        </p>
                                        <p className='condition-answer'>Yeldam may offer credits, promo codes, vouchers, or other forms of cashback at its discretion. Yeldam reserves the right to modify, convert, cancel, or discontinue these offerings.</p>
                                        <p className='condition-answer'>These are just highlights of the Terms. We encourage you to read the full Terms for a comprehensive understanding of your rights and obligations.</p>
                                    </li>
                                    <li>
                                        <label className='terms-question mb-2' id='term-IX'>
                                            IX. Eligibility to Use the Services
                                        </label>
                                        <p className='condition-answer'>By using the Services, you represent and warrant that you are at least eighteen
                                            (18) years old and fully capable of understanding and agreeing to these Terms. You must
                                            also comply with all applicable laws and regulations in your country of residence when accessing and using the Services.</p>
                                        <p className='sub-heading-terms'>Changes to the Terms</p>
                                        <p className='condition-answer ml-4'>Yeldam reserves the right to modify, amend, change, or update these Terms at its discretion.
                                            It is your responsibility to review the Terms periodically to ensure ongoing compliance.
                                            Your continued use of the Yeldam Platform after any modifications or changes to the
                                            Terms signifies your acceptance of the updated terms.</p>
                                        <p className='sub-heading-terms'>Other Terms</p>
                                        <p className='condition-answer ml-4'>You acknowledge and agree to abide by
                                            the following additional terms and conditions regarding your use of the Yeldam Platform:</p>
                                        <p className='sub-heading-terms ml-4'>a. Permitted Use</p>
                                        <p className='condition-answer ml-4'>You agree to use the Services in compliance with the Terms and all applicable laws,
                                            regulations, and accepted practices in your jurisdiction. The data owned by Yeldam, whether accessed through the Services
                                            or any other means, is for personal use only, unless expressly permitted in writing by Yeldam for commercial purposes.</p>
                                        <p className='sub-heading-terms ml-4'>b. Access to Services </p>
                                        <p className='condition-answer ml-4'>You will only access the Services through the interface provided by Yeldam,
                                            unless otherwise authorized in a separate agreement. You will not attempt to access the Services through
                                            automated means or use any unauthorized methods to interfere with or disrupt the Services.</p>
                                        <p className='sub-heading-terms ml-4'>c. Service Interference</p>
                                        <p className='condition-answer ml-4'> You agree not to engage in any activity that interferes with or disrupts
                                            the Services or the associated servers and networks. This includes refraining from deleting or revising content
                                            posted by other customers, engaging in spamming, or any unsolicited emailing, posting, or messaging.</p>

                                    </li>
                                    <li>
                                        <label className='terms-question mb-2' id='term-X'>
                                            X. Content Ownership and Proprietary Rights
                                        </label>
                                        <p className='sub-heading-terms'>Ownership</p>
                                        <p className='condition-answer'>Yeldam is the sole and exclusive copyright owner of the Services and its content.
                                            We also own the intellectual and proprietary rights, including copyrights, trademarks, and trade names associated
                                            with the Services. The Services contain original works developed through substantial time, effort, and resources.
                                            You agree to protect Yeldam's proprietary rights and not to disclose any confidential information without prior written consent.</p>
                                        <p className='sub-heading-terms'>Restrictions</p>
                                        <p className='condition-answer'>
                                            You are not permitted to modify, reproduce, publicly display, or exploit Yeldam's content without express authorization.
                                            You will not remove, conceal, or alter any copyright or proprietary notice. Any infringement may result in legal
                                            proceedings and remedies under applicable laws.
                                        </p>
                                    </li>
                                    <li>
                                        <label className='terms-question mb-2' id='term-XI'>
                                            XI. Content Removal and Third-Party Links
                                        </label>
                                        <p className='sub-heading-terms'>Content Management</p>
                                        <p className='condition-answer'>Yeldam reserves the right to remove, block, or disable access to any objectionable content
                                            on the Services at our sole discretion. This includes content that violates the Terms or may harm
                                            the Services or our customers. However, we will comply with applicable legal requirements.</p>

                                        <p className='sub-heading-terms'>Third-Party Content</p>
                                        <p className='condition-answer'>The Services may include or link to third-party materials or services.
                                            Yeldam does not endorse or guarantee the accuracy, legality, or quality of such third-party content.
                                            We are not responsible for any disputes or liabilities arising from third-party services or content.
                                            Your use of third-party services is subject to their respective terms and policies.</p>
                                    </li>
                                    <li>
                                        <label className='terms-question mb-2' id='term-XII'>
                                            XII. Customer Reviews and Guidelines
                                        </label>
                                        <p className='sub-heading-terms'>Customer Reviews</p>
                                        <p className='condition-answer'>Customer reviews and ratings on the Yeldam Platform reflect the opinions of customers,
                                            not Yeldam. Yeldam provides a neutral platform for communication and does
                                            not endorse the content posted by customers or restaurant partners.</p>
                                        <p className='condition-answer'>Users have the option of writing a review for restaurants & organic stores after login.  </p>
                                        <p className='condition-answer'>Through Our Platform, the User has the right to speak and express its views and opinions freely, however, each User is responsible and liable to ensure that the contents of its review are genuine, truthful and accurate. You agree and acknowledge that We do not guarantee the authenticity of the reviews that are written on our Platform (unless the contents of the review has expressly been verified and validated by Us through our dispute resolution services).</p>
                                        <p className='condition-answer'>You acknowledge that Your reviews have the power of affecting or influencing other Users or their decisions and, therefore, You agree to not post fake or misleading reviews on the Platform. While posting a review on Our Platform, You are required to be honest, transparent and respectful and to not use unparliamentary language towards other Users. You are responsible to ensure that Your reviews do not threaten or constitute to defamation or any kind of harassment towards Us and/or Our Users.</p>
                                        <p className='condition-answer'>You are prohibited from threatening Our Users and/or displaying verbal violence or abusive language towards them on the Platform. You are also prohibited to use or disclose on the Platform any private, proprietary or confidential information belonging to any other User. We strictly prohibit You from posting any paid reviews on Our Platform.</p>
                                        <p className='condition-answer'>You are required to ensure that Your reviews are factual and can be proven with evidence available to You, if required. If any of Your reviews are found to be offensive, improper or inaccurate, We may, at Our sole discretion, delete the review suo moto or based on a report or complaint by any other User. We also reserve the right to suspend or terminate Your Account and Our Services to You under such circumstances.</p>
                                        <p className='condition-answer'>You agree and acknowledge that We will not be responsible or liable for the reviews of any Users or consequences thereof.</p>
                                        <p className='sub-heading-terms'>Guidelines and Policies</p>
                                        <p className='condition-answer'>By using the Services, you agree to comply with our content guidelines and privacy policy. You acknowledge that Yeldam is not liable for offensive, defamatory, or illegal third-party content. We reserve the right to monitor and take action on violations, as necessary, to protect the integrity of the Services and enforce the Terms.</p>

                                    </li>
                                    <li>
                                        <label className='terms-question mb-2' id='term-XIII'>
                                            XIII. Restrictions on Use
                                        </label>
                                        <p className='sub-heading-terms'>Use Restrictions</p>
                                        <p className='condition-answer'>You agree not to engage in activities that violate our guidelines and policies or applicable laws.
                                            This includes refraining from posting objectionable, false, or misleading content, impersonating others, distributing viruses or
                                            harmful code, and interfering with the functionality of the Services. You also agree not to disclose Yeldam's confidential
                                            information or use the Services for unlawful purposes.</p>
                                        <p className='sub-heading-terms'>Communication Conduct</p>
                                        <p className='condition-answer'>While using the Yeldam Platform, you will communicate respectfully and avoid using abusive, derogatory, or unlawful language. Yeldam reserves the right to suspend chat support services or block your access if you violate this conduct requirement.</p>
                                        <p className='sub-heading-terms'>Compliance and Remedies</p>
                                        <p className='condition-answer'>You acknowledge that Yeldam may block your access to the Yeldam Platform and take appropriate actions if you fail to comply with applicable laws, the Terms, or the privacy policy. Yeldam has the right to remove non-compliant content and pursue legal remedies as permitted by law.</p>
                                        <p className='condition-answer'>By using the Yeldam Platform, you confirm your acceptance of these additional terms and conditions. Failure to comply may result in the termination of your access to the Services.</p>
                                    </li>
                                    <li>
                                        <label className='terms-question mb-2' id='term-XIV'>
                                            XIV. Customer Feedback
                                        </label>
                                        <p className='condition-answer'>By providing any ideas, suggestions, changes, or documents regarding Yeldam's existing business
                                            ("Feedback"), you acknowledge and agree to the following terms:</p>
                                        <p className='condition-answer'>(i) Your Feedback should not contain confidential, secretive, or proprietary information of third parties. </p>
                                        <p className='condition-answer'>(ii) Yeldam has no obligation to maintain the confidentiality of such Feedback and may use it freely without any restrictions. </p>
                                        <p className='condition-answer'>(iii) Yeldam may have already received similar Feedback from other customers or may be working on similar ideas. </p>
                                        <p className='condition-answer'>(iv) By providing Feedback, you grant Yeldam a binding, non-exclusive, royalty-free, perpetual,
                                            global license to use, modify, develop, publish, distribute, and sublicense the Feedback. </p>
                                        <p className='condition-answer'>Additionally, you waive any claims or assertions against Yeldam and its customers regarding the Feedback.
                                            Please provide specific Feedback on Yeldam's existing products or marketing strategies, excluding ideas that Yeldam's policy prohibits.
                                            Yeldam does not accept or consider unsolicited ideas, including those for new advertising campaigns, promotions, products, technologies,
                                            enhancements, processes, materials, marketing plans, or product names. Do not submit unsolicited ideas, creative artwork,
                                            suggestions, or other works ("Submissions") to Yeldam or its employees. This policy aims to prevent misunderstandings
                                            or disputes when Yeldam's products or marketing strategies resemble submitted ideas.
                                            If you still submit such ideas despite our request, the above terms regarding Submissions will apply.</p>
                                        <p className='sub-heading-terms'>Terms of Idea Submission</p>
                                        <p className='condition-answer'>By submitting ideas, you agree that: </p>
                                        <p className='condition-answer'>(1) Your Submissions and their Contents become the property of Yeldam without any compensation to you. </p>
                                        <p className='condition-answer'>(2) Yeldam may use or distribute the Submissions and their contents freely. </p>
                                        <p className='condition-answer'>(3) Yeldam is not obligated to review the Submission. </p>
                                        <p className='condition-answer'>(4) There is no obligation to keep the Submissions confidential.</p>
                                    </li>
                                    <li>
                                        <label className='terms-question mb-2' id='term-XV'>
                                            XV. Advertising
                                        </label>
                                        <p className='condition-answer'>Certain Services may display advertisements and promotions supported by advertising revenue.
                                            These advertisements may be tailored to the content of information stored on the Services or user queries. Yeldam reserves
                                            the right to change the manner, mode, and extent of advertising without specific notice. By accessing and using the Services,
                                            you consent to Yeldam placing such advertising on the Services. Some parts of the site may include advertising information,
                                            promotional material, or content submitted by third parties or customers. The responsibility for ensuring that such material
                                            complies with applicable laws lies solely with the party providing the information. Any correspondence or business dealings
                                            with advertisers other than Yeldam found on or through the Yeldam Platform and mobile apps are solely between you and
                                            the advertiser. Yeldam is not liable for any errors, omissions, inaccuracies, losses, or damages arising from
                                            such dealings or the presence of other advertisers on the Yeldam Platform and mobile application.</p>
                                    </li>
                                    <li>
                                        <label className='terms-question mb-2' id='term-XVI'>
                                            XVI. General Terms and Conditions
                                        </label>
                                        <p className='condition-answer'>Yeldam acts as a facilitator and does not manufacture, sell, or distribute food,
                                            beverages, or products. Yeldam places orders on behalf of customers with Restaurant Partners/Stores under
                                            the customer's authority. The sale and purchase of food, beverages, or products occur directly between
                                            customers and Restaurant Partners/Stores under a contract for sale and purchase.</p>
                                        <p className='sub-heading-terms'>General Terms</p>
                                        <p className='sub-heading-terms ml-4'>a. Interpretation</p>
                                        <p className='condition-answer ml-6'>Section and subject headings in these Terms are for reference only and shall not be used to interpret any provisions. </p>
                                        <p className='sub-heading-terms ml-4'>b. Entire Agreement and Waiver</p>
                                        <p className='condition-answer ml-6'>The Terms, along with the Privacy Policy and Guidelines and Policies, constitute the entire agreement between you and Yeldam regarding the Services. No failure or delay by Yeldam in exercising any right, power, or privilege under the Terms shall be deemed a waiver, nor shall it preclude any further exercise of that right. </p>
                                        <p className='sub-heading-terms ml-4'>c. Severability</p>
                                        <p className='condition-answer ml-6'>If any provision of these Terms is deemed unlawful, invalid, or unenforceable, that provision shall be severed, and the remainder of the Terms shall continue in effect. </p>
                                        <p className='sub-heading-terms ml-4'>d. Partnership or Agency</p>
                                        <p className='condition-answer ml-6'>These Terms do not establish a partnership or agency relationship between you and Yeldam. </p>
                                        <p className='sub-heading-terms ml-4'>e. Governing Law</p>
                                        <p className='condition-answer ml-6'>The governing law and jurisdiction depend on your country of residence as stated in the Terms.</p>

                                    </li>
                                    <li>
                                        <label className='terms-question mb-2' id='term-XVII'>
                                            XVII. Spam Policy
                                        </label>
                                        <p className='condition-answer'>Yeldam strictly prohibits the use of spam activities, including the transmission of unsolicited messages or advertisements. Users are not allowed to engage in any form of unsolicited commercial communication. Content that is identified as spam will be promptly removed, and users found in violation may face immediate account termination. Users participating in email marketing must adhere to anti-spam laws, providing clear opt-out mechanisms. Violations may result in account suspension, termination, or legal action. Users are encouraged to report instances of spam, and the platform reserves the right to update this policy as needed. Continued use of the platform implies acceptance of the current spam policy.</p>
                                    </li>
                                    <li>
                                        <label className='terms-question mb-2' id='term-XVIII'>
                                            XVIII. Limitation of Liability
                                        </label>
                                        <p className='condition-answer'>To the fullest extent permitted by applicable law, Yeldam Parties shall not be liable for any damages resulting from </p>
                                        <p className='condition-answer'>(i) errors, mistakes, or inaccuracies of content</p>
                                        <p className='condition-answer'>(ii) personal injury or property damage arising from your use of the Services </p>
                                        <p className='condition-answer'>(iii) unauthorized access to or use of our servers or personal information stored therein</p>
                                        <p className='condition-answer'>(iv) interruption or cessation of transmission to or from our servers</p>
                                        <p className='condition-answer'>(v) transmission of bugs, viruses, or similar harmful elements by third parties</p>
                                        <p className='condition-answer'>(vi) loss of your data or content from the Services</p>
                                        <p className='condition-answer'>(vii) errors or omissions in any content, or any loss or damage incurred from using such content.</p>
                                        <p className='condition-answer'>(viii) disclosure of information pursuant to these Terms or our Privacy Policy</p>
                                        <p className='condition-answer'>(ix) failure to keep your password or account details secure</p>
                                        <p className='condition-answer'>(x) loss or damage incurred by you, including loss or damage resulting from reliance on advertising or relationships with other advertisers found on the Services, or </p>
                                        <p className='condition-answer'>(xi) delay or failure in performance due to causes beyond Yeldam's reasonable control. Yeldam Parties shall not be liable for any indirect, incidental, special, punitive, exemplary, or consequential damages, including loss of profit, goodwill, business reputation, or data.</p>
                                        <p className='sub-heading-terms'>Indemnification</p>
                                        <p className='condition-answer'>You agree to indemnify and hold harmless the Yeldam Parties from any third-party claims, damages, actions, proceedings, losses, liabilities, costs, and expenses (including legal fees) arising from </p>
                                        <p className='condition-answer'>(i) Your Content</p>
                                        <p className='condition-answer'>(ii) unauthorized use of the Services or products/services included in the Services</p>
                                        <p className='condition-answer'>(iii) your access to and use of the Services</p>
                                        <p className='condition-answer'>(iv) your violation of any rights of another party, or </p>
                                        <p className='condition-answer'>(v) your breach of these Terms, including infringement of copyright or intellectual property rights. Yeldam reserves the right to settle claims without your consent and to assume exclusive defense and control of any matter you are required to indemnify. You agree to cooperate with our defense of these claims.</p>
                                    </li>
                                    <li>
                                        <label className='terms-question mb-2' id='term-XIX'>
                                            XIX. Notice of copyright infringement</label>
                                            <p className='condition-answer'>Yeldam is not liable for copyright infringements by end users or third parties on the Yeldam Platform.  Yeldam respects intellectual property rights and may remove or disable access to infringing material on the Services. If you believe your copyright has been infringed, please follow the specified procedure to file a notification.</p>
                                    </li>
                                    <li>
                                        <label className='terms-question mb-2' id="term-XX"> XX. Contact us</label>
                                        <p className='condition-answer'>For any queries or concerns, please free to contact us on info@yeldam.com </p>
                                    </li>

                                </ul>
                            </div>
                        </ScrollPanel>
                    </div>
                </div>
            </div>
            <div className="row">
                <Footer localities={list} city={city} search={search} type={"restaurant"} />
            </div>
        </div>
    );
}

export default Terms;
