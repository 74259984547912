import React, { useState, useRef, useEffect } from "react";
import { Card } from "primereact/card";
import "bootstrap-icons/font/bootstrap-icons.css";
import { devsas,yeldamwebimagessas } from "../../../../config/constants";



const RecommendedCard: React.FC<any> = ({reccomended}) => {
  const [like, setLike] = useState<boolean>(false);

  const handleMetersChange = (meters: number) => {
    const kilometersValue = (meters / 1000).toFixed(2);
    return kilometersValue;
  };

  const getDayName = () => {
    var d = new Date();
    return d.toLocaleDateString("en-in", { weekday: "long" });
  };

  return (
    <div className="d-flex scrolling-wrapper rest-card-detail gap-2 card_main mb-4">
      
      {reccomended?.slice(0, 8).map((item: any, i: any) => (
        <div className="d-flex mb-2" key={"recom"+item._id} style={{width:"100%"}}>
        <Card header={
              <div className="rest-image-div">
                <img src={item.thumbnails[0]+"?"+devsas} className="headerimg" alt="" />
                <div className="card-icons">
                  <div className="card-like-icon">
                    <i
                      className={like ? "pi pi-heart-fill" : "pi pi-heart"}
                      // onClick={() => {
                      //   LikeFunction();
                      // }}
                    ></i>
                  </div>
                </div>
              </div>
            } className="near-rest-card">
          <div className="rest-content">

           <div>
                <div>
                  {item.preference.includes("Veg") &&
                    !item.preference.includes("Non-Veg") && (
                      <div className="veg d-flex ">
                        <img src={"https://yeldamimages.azureedge.net/yeldamwebimages/webp/Homepage/Special-Restaurant/vegicon.webp"+"?"+yeldamwebimagessas} style={{width:"10px",height:"10px"}} alt=""/>
                        Veg
                        </div>
                    )}
                  {item.preference.includes("Non-Veg") &&
                    !item.preference.includes("Veg") && (
                      <div className="nonveg d-flex">
                                                <img src={"https://yeldamimages.azureedge.net/yeldamwebimages/webp/Homepage/Special-Restaurant/nonvegicon.webp"+"?"+yeldamwebimagessas} style={{width:"10px",height:"10px"}} alt=""/>

                        Non-Veg</div>
                    )}
                  {item.preference.includes("Veg") &&
                    item.preference.includes("Non-Veg") && (
                      <div className="d-flex gap-3">
                        <div className="veg d-flex">
                        <img src={"https://yeldamimages.azureedge.net/yeldamwebimages/webp/Homepage/Special-Restaurant/vegicon.webp"+"?"+yeldamwebimagessas} style={{width:"10px",height:"10px"}} alt=""/>
                          Veg</div>
                        <div className="nonveg d-flex">
                        <img src={"https://yeldamimages.azureedge.net/yeldamwebimages/webp/Homepage/Special-Restaurant/nonvegicon.webp"+"?"+yeldamwebimagessas} style={{width:"10px",height:"10px"}} alt=""/>

                          Non-Veg</div>
                      </div>
                    )}
                </div>
              </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="Res_">{item.name}</div>
              <div
                className="d-flex align-items-center"
                style={{ whiteSpace: "nowrap", gap: "0.1rem" }}
              >
                <i
                  className="bi bi-star-fill ml-1"
                  style={{ color: "#F0C916" }}
                ></i>
                <span className="rating-count">{item.rating}</span>
                <span className="reviews-count">({item.reviewCount})</span>
              </div>
            </div>
            <div className="Address mt-1">{item.address1}</div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-center distance-text">
                <i className="bi bi-geo-alt" style={{ fontSize: "12px" }}></i>{" "}
                {handleMetersChange(item.Distance)} Kms
              </div>
              <div style={{ padding: "0px" }}>
                <span
                  className="avg"
                  style={{
                    color: "#3C3C3C",
                    whiteSpace: "nowrap",
                    fontSize: "12px",
                  }}
                >
                  <i
                    className="bi bi-currency-rupee mr-1"
                    style={{ fontSize: "12px" }}
                  ></i>
                  {item.averagecost} for two
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-between mt-1">
                <div className="Time_Distance d-flex flex-row">
                  {item.timings.map(
                    (entry: {
                      day: any;
                      timings: { opens: any; closes: any }[];
                    }) => {
                      if (getDayName().toLowerCase() == entry.day) {
                        var today = new Date().toLocaleTimeString("en-US", {
                          timeZone: "Asia/Kolkata",
                          hour12: true,
                          hour: "numeric",
                          minute: "numeric",
                        });
                        if (
                          today >= entry.timings[0].opens &&
                          today <= entry.timings[0].closes
                        ) {
                          return (
                            <div className="open-timing">
                              <i
                                className="bi bi-door-open mr-1"
                                style={{ color: "#36a41d", fontSize: "12px" }}
                              />
                              Open Now
                            </div>
                          );
                        } else {
                          return (
                            <div className="closing-timing">
                              <i className="bi bi-door-closed mr-1" />
                              Closed Now
                            </div>
                          );
                        }
                      }
                    }
                  )}
                </div>
                <div>
                  {/* display the time to open or closed */}
                  {item.timings.map(
                    (entry: {
                      day: any;
                      timings: { opens: any; closes: any }[];
                    }) => {
                      if (getDayName().toLowerCase() == entry.day) {
                        var today = new Date().toLocaleTimeString("en-US", {
                          timeZone: "Asia/Kolkata",
                          hour12: true,
                          hour: "numeric",
                          minute: "numeric",
                        });
                        if (
                          today >= entry.timings[0].opens &&
                          today <= entry.timings[0].closes
                        ) {
                          return (
                            <div className="close-text">
                              <i
                                className="bi bi-door-closed mr-1"
                                style={{ color: "#323130", fontSize: "12px" }}
                              ></i>
                              Closes at{entry.timings[0].closes}
                            </div>
                          );
                        } else {
                          return (
                            <div className="close-text">
                              <i
                                className="bi bi-door-open mr-1"
                                style={{ color: "#323130", fontSize: "12px" }}
                              ></i>
                              Opens at {entry.timings[0].opens}
                            </div>
                          );
                        }
                      }
                    }
                  )}
                </div>
              </div>
          </div>
        </Card>
        </div>
      ))}
      
    </div>
  );
};

export default RecommendedCard;
