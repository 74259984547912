import React, { useState } from 'react';
import { Card } from "primereact/card";
import "./exploreDietitianCard.component.css";

type props = {
    exploredietitanimage: any;
    hovereddietitianimage: any;
    exploredietitianspeciality: string;
    exploredietitiantagline: string;
}
const ExploreDietitianCard: React.FC<props> = ({
    exploredietitanimage,
    hovereddietitianimage,
    exploredietitianspeciality,
    exploredietitiantagline
}) => {
    const [hover, setHover] = useState(false);
    return (
        <div>
            <Card
                className='exploredietitiancard'
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <div className='d-flex flex-column justify-content-center align-items-center'>
                    <div className='exploreDimgdiv d-flex justify-content-center align-items-center mb-2'>
                        
                        <img
                            src={hover ? hovereddietitianimage : exploredietitanimage}
                            alt="Dietitian"
                            className="dietitian-image"
                        />
                    </div>
                    <p className='dietspecialhead'>{exploredietitianspeciality}</p>
                    <p className='dietspecialtag'>{exploredietitiantagline}</p>
                </div>
            </Card>
        </div>
    );
}

export default ExploreDietitianCard;
