import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../../pages/Home/home.page";
import StoreSearch from "../../pages/OrganicStoreAdvancedSearch/organicStoreAdvanceSearch.page";
import FoodEvents from "../../pages/FoodEvents/foodEvents.pages";
import HealthTips from "../../pages/HealthTips/healthTips.pages";
import Profile from "../../pages/Profile/profile.page";
import Faq from "../../pages/FAQ's/Faq.page";
import SignInSignUp from "../UI/SigninSignup/signInSignUp.component";
import ContactUs from "../../pages/ContactUs/contactUs.pages";
import UserAddRestaurant from "../../pages/UserAddRestaurant/userAddRestaurant.pages";
import AddStore from "../../pages/UserAddOrganicStore/addStore.pages";
import Comingsoon from "../../pages/CoomingSoon/comingSoon.pages";
import Terms from "../../pages/TermsAndConditions/terms.pages";
import RestaurantAdvanceSearch from "../../pages/RestaurantAdvancedSearch/RestaurantAdvanceSearch.page";
import PrivacyAndCookie from "../../pages/PrivacyAndCookie/privacyAndCookie.pages";
import HealthyRestaurants from "../../pages/AboutHealthyRestaurants/healthyRestaurants.pages";
import AboutOrganicStore from "../../pages/AboutOrganicStores/aboutOrganicStore.pages";
import WhyYeldam from "../../pages/WhyYeldam/whyYeldam.pages";
import AdvancedFilters from "../../components/AdvancedFilters/AdvancedFilters.component";
import MobileFilters from "../MobileFilters/MobileFilters.component";
import MobileStoreFilters from "../MobileFilters/MobileStoreFilters.component";
import HomeOrganicStores from "../../pages/HomeOrganicStores/HomeOrganicStores.page";
import DietitianAdvancedSearch from "../../pages/DietitianAdvancedSearch/dietitianAdvancedSearch.pages";
import HomeDieticians from "../../pages/HomeDieticians/HomeDieticians.page";
import MobileDietitianFilters from "../MobileFilters/MobileDietitianFilters.component";
import ComingsoonBusiness from "../../pages/CoomingSoon/comingsoonBusiness.pages";

export const AppRouter: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = () => {
    const userToken = localStorage.getItem("accessToken");

    if (!userToken || userToken === "undefined") {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  };
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/organicstores" element={<HomeOrganicStores/>}/>
        <Route path="/dieticians" element={<HomeDieticians/>}/>
        <Route path="/searchstore" element={<StoreSearch />} />
        <Route path="/searchrestaurant" element={<RestaurantAdvanceSearch />} />
        <Route path="/searchdietitian" element={<DietitianAdvancedSearch/>} />
        <Route path="/foodevents" element={<FoodEvents />} />
        <Route path="/healthtips" element={<HealthTips />} />
        <Route path="/useraddrestaurant" element={<UserAddRestaurant />} />
        <Route path="/addstore" element={<AddStore />} />
        <Route path={`/profile`} element={isLoggedIn ? <Profile /> : <Home />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<PrivacyAndCookie />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/login" element={<SignInSignUp />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/comingsoon" element={<Comingsoon />} />
        <Route path="/businesscomingsoon" element={<ComingsoonBusiness/>}/>
        <Route path="/healthyrestaurants" element={<HealthyRestaurants />} />
        <Route path="/aboutorganicstore" element={<AboutOrganicStore />} />
        <Route path="/whyyeldam" element={<WhyYeldam />} />
        <Route path="/advancedfilters" element={<AdvancedFilters />} />
        <Route path="/mobilefilters" element={<MobileFilters />} />
        <Route path="/mobilestorefilters" element={<MobileStoreFilters />} />
        <Route path="/mobiledietitianfilters" element={<MobileDietitianFilters/>}/>

      </Routes>
    </BrowserRouter>
  );
};
