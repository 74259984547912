import React, { useEffect, useState } from 'react';
import Navbar from '../../components/UI/Navbar/navBar.component';
import "./healthyRestaurants.pages.css";
import dieteryicon from "../../assets/icons/dieteryicon.svg";
import rupeeicon from "../../assets/icons/rupeeicon.svg";
import usersicon from "../../assets/icons/usersicon.svg"
import Footer from '../../components/UI/Footer/footer.component';
import { yeldamwebimagessas } from '../../config/constants';
import { Skeleton } from 'primereact/skeleton';

const HealthyRestaurants = () => {
    const [city, setCity] = useState<string>("");
    const [list, setList] = useState<string[]>([]);
    const [search, setSearch] = useState<string[]>([]);
    const [showImage, setShowImage] = useState(false);


    useEffect(() => {
        const timer = setTimeout(() => {
            setShowImage(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="container-fluid p-0">
            <div className="row">
                <Navbar />
            </div>
                  <div className="container internal-screen" style={{minHeight: "calc(100vh - 50px)"}}>
                <div className="IFE-heading d-flex justify-content-between align-items-center">
                    <div className="Food-Events-heading">Restaurants</div>
                    <div className="d-flex align-items-center FE-heading-nav">
                        <a href="/" className="d-flex">
                            {" "}
                            <span className="home-span">Home</span>
                        </a>
                        <i
                            className="bi bi-chevron-right"
                            style={{ color: "#C2C2C2", fontSize: "12px" }}
                        ></i>
                        <span className="event-span">Restaurants</span>
                    </div>
                </div>
                <div className="about-rests-Body flex-column d-flex gap-6 mb-6">
                    <div className='about-rests-division1 d-flex gap-3 '>
                        <div className='col-lg-5 col-md-5 about-rest-divison1-image'>
                            {!showImage ? (
                                <Skeleton width="100%" height="324px" ></Skeleton>
                            ) : (
                                <img src={"https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/about/restabout.webp" + "?" + yeldamwebimagessas} alt='' />
                            )}
                        </div>
                        <div className='col-lg-7 col-md-7  about-rest-division1-content'>
                            <p className='division1-heading'>
                                Yeldam - Your Gateway to Wholesome Dining
                            </p>
                            <p className='division1-content'>
                                We are here to take you along on a journey of delicious and healthy eating.
                                Yeldam is not just your regular go-to platform for discovering random restaurants,
                                first and foremost we prioritize your well-being. We are more than a guide,
                                Yeldam is your companion on the path of healthier choices.
                            </p>
                            <div className='division1-3choices'>
                                <div className='choice1 d-flex gap-3'>
                                    <div className='col-lg-1 col-md-1 choice-icon'>
                                        <img src={dieteryicon} alt='icon' />
                                    </div>
                                    <div className='col-lg-11 col-md-11 choice1-content'>
                                        <p className='choice-head'>Dietary Preferences</p>
                                        <p className='choice-content'>Personalize your healthy journey with Yeldam, catering to diverse diets - vegan, gluten-free, and more.</p>

                                    </div>

                                </div>
                                <div className='choice2 d-flex gap-3'>
                                    <div className='col-lg-1 col-md-1 choice-icon'>
                                        <img src={rupeeicon} alt='icon' />
                                    </div>
                                    <div className='col-lg-11 col-md-11 choice2-content'>
                                        <p className='choice-head'>Budget-Friendly</p>
                                        <p className='choice-content'>Yeldam - your ally for nutritious dining on any budget, proving that health-conscious choices can be affordable and accessible.</p>

                                    </div>

                                </div>
                                <div className='choice3 d-flex gap-3'>
                                    <div className='col-lg-1 col-md-1  choice-icon'>
                                        <img src={usersicon} alt='icon' />

                                    </div>
                                    <div className='col-lg-11 col-md-11 choice2-content'>
                                        <p className='choice-head'>Culinary Diversity</p>
                                        <p className='choice-content'>Explore a world of nutritious cuisines with Yeldam, offering global flavors that prioritize health and well-being.</p>

                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>

                    <div className='about-rests-division2 d-flex  justify-content-between'>
                        <div className='comforts-div  col-lg-3 col-md-3 d-flex flex-column '>
                            <div className='comforts-div-image d-flex justify-content-center mb-3'>
                                {!showImage ? (
                                    <Skeleton width="255px" height="200px" ></Skeleton>
                                ) : (
                                    <img src={'https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/about/coffe.webp' + "?" + yeldamwebimagessas} alt='' />
                                )}
                            </div>
                            <p className='comforts-div-head'>
                                Coffee Comfort, Local Eats
                            </p>
                            <p className='comforts-div-content'>
                                Sip coffee at home while Yeldam locates nearby restaurants. Your cozy search for local eats starts with a caffeine companion.
                            </p>

                        </div>
                        <div className='dining-choice col-lg-3 col-md-3 d-flex flex-column '>
                            <div className='dining-choice-image d-flex justify-content-center mb-3'>
                                {!showImage ? (
                                    <Skeleton width="255px" height="200px" ></Skeleton>
                                ) : (
                                    <img src={'https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/about/dinein.webp' + "?" + yeldamwebimagessas} alt='' />
                                )}
                            </div>
                            <p className='comforts-div-head'>
                                Diverse Dining Choices
                            </p>
                            <p className='comforts-div-content'>
                                Explore a vast array of eateries providing healthy options—vegetarian, gluten-free, nut free. Your diverse tastes, all in one place.
                            </p>

                        </div>
                        <div className='ratings-matter  col-lg-3 col-md-3 d-flex flex-column'>
                            <div className='ratings-matter-image d-flex justify-content-center mb-3'>
                                {!showImage ? (
                                    <Skeleton width="255px" height="200px" ></Skeleton>
                                ) : (
                                    <img src={'https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/about/ratingmatter.webp' + "?" + yeldamwebimagessas} alt='' />
                                )}
                            </div>
                            <p className='comforts-div-head'>
                                Yeldam Ratings Matter
                            </p>
                            <p className='comforts-div-content'>
                                Your ratings count!Rate restaurants on Yeldam and helps the dining choices of a vibrant community.
                            </p>

                        </div>
                    </div>


                    <div className='about-rests-division3 d-flex gap-3'>
                        <div className='col-lg-5 col-md-5 about-rest-divison3-image'>
                            {!showImage ? (
                                <Skeleton width="100%" height="320px" ></Skeleton>
                            ) : (
                                <img src={'https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/about/restabtimg3.webp' + "?" + yeldamwebimagessas} alt='' />
                            )}
                        </div>
                        <div className='col-lg-7 col-md-7  about-rest-division3-content'>
                            <div className="accordion" id="accordionExample">
                                <div className="item1-accordion">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button acc-q"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"

                                        >

                                            Nutrient-Rich Options
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse show"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            At Yeldam we take pride in creating a healthy selection of restaurants that go beyond the ordinary.
                                            From vibrant and healthy salad bowls of nourishment to delicious healthy desserts.
                                            All diverse dietary needs are catered ensuring a broad spectrum of nutrient-rich options for every palate.
                                        </div>
                                    </div>
                                </div>
                                <div className="item1-accordion">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button collapsed acc-q"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >

                                            Community-Approved Authenticity
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            Community-Approved Authenticity: We do not just pick at random,
                                            the restaurants you see on the platform of Yeldam. Each eatery
                                            is highly endorsed and suggested by the consumers belonging
                                            to health-conscious community. Real users, real reviews,
                                            and real time rating are the first few steps of authentication.
                                            We do further deep dive into the joint before we show the same
                                            on our platform so you can trust the authenticity of the establishment.
                                            Your dining choices are guided by those who have been there, done that!
                                        </div>
                                    </div>
                                </div>
                                <div className="item1-accordion">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button collapsed acc-q"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                        >

                                            Growing Across Cities
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            No matter if you are at the heart of the metro cities or on a street side,
                                            exploring the town, Yeldam has you covered. Suggestions for healthy dining
                                            options on our platform span across all major cities. This does not mean
                                            those of you in the non-metro areas need to be disappointed,
                                            we are on a mission to grow pan-India and are working towards it every moment.
                                        </div>
                                    </div>
                                </div>
                                <div className="item1-accordion">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className="accordion-button collapsed acc-q"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFour"
                                        >

                                            Easy and Expansive
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFour"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            Yeldam’s user-friendly experience simplifies your quest for healthy eating options.
                                            It is easy to explore, discover and make informed decisions, that most importantly
                                            align with your dietary needs and healthy living. We hope that while we continuously
                                            expand, Yeldam becomes your steady companion in the pursuit of wholesome and nutritious
                                            eating.
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className='about-rests-division4 d-flex gap-3 align-items-center'>
                        <div className='col-lg-6 col-md-6 d-flex flex-column about-rest-division4-content'>
                            <p className='division4-head'>
                                Dine in Bliss: Culinary Stories Unfold at Every Plate
                            </p>
                            <p className='division4-text'>
                                Embark on a culinary journey where each plate narrates a tale. Explore diverse flavors, from cozy corners to grand settings. Discover hidden gems, savor local treasures, and relish top chefs' creativity.
                            </p>
                            <p className='division4-text'>
                                Every restaurant invites you into a unique narrative, making dining an unforgettable story. Indulge in moments beyond the ordinary, where each meal and bite writes a chapter in a book of cherished experiences.
                            </p>
                            <p className='division4-text'>
                                Welcome to a world where passion meets the plate, and every dining encounter unfolds a captivating story to be savored.
                            </p>

                        </div>
                        <div className='col-lg-6 col-md-6 d-flex about-rest-divison4-image'>
                            {!showImage ? (
                                <Skeleton width="100%" height="280px" ></Skeleton>
                            ) : (
                                <img src={'https://yeldamstorage.blob.core.windows.net/yeldamwebimages/webp/about/dineinrest.webp' + "?" + yeldamwebimagessas} alt='' />
                            )}

                        </div>

                    </div>
                    <div className='about-rests-division5  flex-column d-flex justify-content-center'>
                        <p className='what-is-wait d-flex justify-content-center'>
                            What is the wait?
                        </p>
                        <p className='wait-text d-flex justify-content-center'>
                            Join us now, find a restaurant or suggest one to your fellow health-conscious seekers.
                            Yeldam is where health meets flavors,
                            let us make every meal a celebration of well-being!
                        </p>

                    </div>
                </div>
            </div>
            <div className="row">
                <Footer localities={list} city={city} search={search}  type={"restaurant"} />
            </div>
        </div >
    )
}
export default HealthyRestaurants;
