import React, { useState } from "react";
import "./dietitianAbout.component.css";

type props = {
  degree: string;
  experience: string;
  languages: string[];
  about: string;
  certification: string;
  speciality: string[];
  services: string[];
  awards: string[];
  dietitianwebsite: string;
  dietitianinstaurl: string;
};

const DietitianAbout: React.FC<props> = ({
  degree,
  experience,
  languages,
  about,
  certification,
  speciality,
  services,
  awards,
  dietitianwebsite,
  dietitianinstaurl,
}) => {
  const [showFullContent, setShowFullContent] = useState(false);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const getDisplayedContent = () => {
    if (showFullContent) {
      return (
        <div>
          {about}
          <br />
          <button className="read-btn" onClick={toggleContent}>Read Less</button>
        </div>
      );
    } else {
      const maxLength = Math.ceil(about.length / 4);
      const truncatedContent = about.slice(0, maxLength);
      return (
        <div>
          {truncatedContent}...
          <br />
          <button className="read-btn" onClick={toggleContent}>Read More</button>
        </div>
      );
    }
  };

  const [showFullServices, setShowFullServices] = useState(false);
  const maxServicesToShow = 3;

  const toggleServices = () => {
    setShowFullServices(!showFullServices);
  };

  const displayedServices = showFullServices ? services : services.slice(0, maxServicesToShow);

  const [showFullAwards, setShowFullAwards] = useState(false);
  const maxAwardsToShow = 3;

  const toggleAwards = () => {
    setShowFullAwards(!showFullAwards);
  };

  const displayedAwards = showFullAwards ? awards : awards.slice(0, maxAwardsToShow);


  return (
    <div className="main d-flex flex-column gap-3">
      {/* education */}
      <div className="gap-3 d-flex align-items-start">
        <svg
          xmlns="
                     http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <g clip-path="url(#clip0_528_35837)">
            <path
              d="M17.8455 12.3535L16.4513 10.9593V6.17697L17.3616 5.84882C17.5705 5.77345 17.7099 5.5753 17.71 5.35313C17.7102 5.13103 17.5711 4.9326 17.3623 4.85698L9.03454 1.84108C8.91857 1.79907 8.79142 1.79907 8.67545 1.84108L0.347771 4.85698C0.139701 4.93235 0.000846917 5.12956 3.24761e-06 5.35085C-0.000770116 5.57213 0.136643 5.77036 0.344115 5.84724L3.4175 6.98574V10.4608C3.4175 10.5687 3.45054 10.6739 3.51223 10.7624C3.54616 10.811 3.86749 11.2499 4.69021 11.664C5.72957 12.1872 7.0928 12.4525 8.74196 12.4525C10.3899 12.4525 11.76 12.1877 12.8142 11.6655C13.6451 11.254 13.9771 10.8198 14.0123 10.7716C14.0781 10.6814 14.1135 10.5726 14.1135 10.4609V7.01976L15.3968 6.55712V10.9593L14.0026 12.3535C13.9037 12.4524 13.8481 12.5865 13.8481 12.7264C13.8481 12.8662 13.9036 13.0004 14.0026 13.0992L15.3968 14.4934V15.6632C15.3968 15.9544 15.6329 16.1905 15.9241 16.1905C16.2153 16.1905 16.4514 15.9544 16.4514 15.6632V14.4934L17.8456 13.0992C18.0515 12.8934 18.0515 12.5594 17.8455 12.3535ZM8.85501 2.89767L15.6311 5.35165L8.74446 7.83447L2.06035 5.35837L8.85501 2.89767ZM13.0589 10.2507C11.2563 11.8727 5.89978 11.6867 4.47208 10.2559V7.37643L8.55878 8.89032C8.62364 8.90058 8.68488 8.95651 8.92079 8.89194L13.0589 7.40005L13.0589 10.2507ZM15.924 13.5293L15.1211 12.7264L15.924 11.9234L16.727 12.7264L15.924 13.5293Z"
              fill="#36A41D"
            />
          </g>
          <defs>
            <clipPath id="clip0_528_35837">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <div>
          <h1 className="overview-dieti-head">Education</h1>
          <h1 className="clinic-address mt-1">{degree}</h1>
        </div>
      </div>

      {/* experience */}
      <div className="gap-3 d-flex align-items-start">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M14.5 6C14.4992 5.3372 14.2356 4.70178 13.7669 4.23311C13.2982 3.76444 12.6628 3.50079 12 3.5H10.5V3C10.5 2.60218 10.342 2.22064 10.0607 1.93934C9.77936 1.65804 9.39782 1.5 9 1.5H7C6.60218 1.5 6.22064 1.65804 5.93934 1.93934C5.65804 2.22064 5.5 2.60218 5.5 3V3.5H4C3.3372 3.50079 2.70178 3.76444 2.23311 4.23311C1.76444 4.70178 1.50079 5.3372 1.5 6V6.8065C1.5013 7.34399 1.67673 7.86659 2 8.296V12C2.00079 12.6628 2.26444 13.2982 2.73311 13.7669C3.20178 14.2356 3.8372 14.4992 4.5 14.5H11.5C12.1628 14.4992 12.7982 14.2356 13.2669 13.7669C13.7356 13.2982 13.9992 12.6628 14 12V8.296C14.3233 7.86659 14.4987 7.34399 14.5 6.8065V6ZM6.5 3C6.5 2.86739 6.55268 2.74021 6.64645 2.64645C6.74021 2.55268 6.86739 2.5 7 2.5H9C9.13261 2.5 9.25979 2.55268 9.35355 2.64645C9.44732 2.74021 9.5 2.86739 9.5 3V3.5H6.5V3ZM2.5 6C2.5 5.60218 2.65804 5.22064 2.93934 4.93934C3.22064 4.65804 3.60218 4.5 4 4.5H12C12.3978 4.5 12.7794 4.65804 13.0607 4.93934C13.342 5.22064 13.5 5.60218 13.5 6V6.8065C13.5009 7.16182 13.3752 7.50584 13.1455 7.77694C12.9158 8.04804 12.5971 8.2285 12.2465 8.286L9.9395 8.67C9.87131 8.47494 9.74437 8.30579 9.57614 8.18582C9.4079 8.06584 9.20663 8.00093 9 8H7C6.79337 8.00093 6.5921 8.06584 6.42386 8.18582C6.25563 8.30579 6.12869 8.47494 6.0605 8.67L3.7535 8.286C3.40286 8.2285 3.08415 8.04804 2.85447 7.77694C2.62478 7.50584 2.49912 7.16182 2.5 6.8065V6ZM9 9V10H7V9H9ZM11.5 13.5H4.5C4.10218 13.5 3.72064 13.342 3.43934 13.0607C3.15804 12.7794 3 12.3978 3 12V9.089C3.18824 9.1741 3.38622 9.23573 3.5895 9.2725L6 9.6725V10C6 10.2652 6.10536 10.5196 6.29289 10.7071C6.48043 10.8946 6.73478 11 7 11H9C9.26522 11 9.51957 10.8946 9.70711 10.7071C9.89464 10.5196 10 10.2652 10 10V9.6735L12.4105 9.2735C12.6138 9.23641 12.8118 9.17444 13 9.089V12C13 12.3978 12.842 12.7794 12.5607 13.0607C12.2794 13.342 11.8978 13.5 11.5 13.5Z"
            fill="#36A41D"
          />
        </svg>
        <div className="d-flex flex-column align-items-start gap-1">
          <h1 className="overview-dieti-head">Experience</h1>
          <h1 className="exp-value-text">{experience} Years</h1>
        </div>
      </div>

      {/* Languages */}
      {Array.isArray(languages) && languages.length > 0 && (
        <div className="gap-3 d-flex align-items-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M8 1C4.13129 1 1 4.13075 1 8C1 9.2278 1.32022 10.427 1.9283 11.4846L1.02638 14.2854C0.96347 14.4807 1.01517 14.6947 1.16022 14.8398C1.30388 14.9834 1.5174 15.0371 1.71457 14.9736L4.51538 14.0717C5.57303 14.6798 6.7722 15 8 15C11.8687 15 15 11.8692 15 8C15 4.13129 11.8692 1 8 1ZM8 13.9062C6.89098 13.9062 5.80994 13.5968 4.87373 13.0114C4.73904 12.9272 4.57178 12.9045 4.41615 12.9546L2.39432 13.6057L3.04544 11.5838C3.09479 11.4305 3.07396 11.263 2.98851 11.1263C2.40318 10.1901 2.09375 9.10902 2.09375 8C2.09375 4.74332 4.74332 2.09375 8 2.09375C11.2567 2.09375 13.9062 4.74332 13.9062 8C13.9062 11.2567 11.2567 13.9062 8 13.9062ZM8.68359 8C8.68359 8.37747 8.37758 8.68359 8 8.68359C7.62242 8.68359 7.31641 8.37747 7.31641 8C7.31641 7.62242 7.62242 7.31641 8 7.31641C8.37758 7.31641 8.68359 7.62242 8.68359 8ZM11.418 8C11.418 8.37747 11.112 8.68359 10.7344 8.68359C10.3568 8.68359 10.0508 8.37747 10.0508 8C10.0508 7.62242 10.3568 7.31641 10.7344 7.31641C11.112 7.31641 11.418 7.62242 11.418 8ZM5.94922 8C5.94922 8.37747 5.6432 8.68359 5.26562 8.68359C4.88815 8.68359 4.58203 8.37747 4.58203 8C4.58203 7.62242 4.88815 7.31641 5.26562 7.31641C5.6432 7.31641 5.94922 7.62242 5.94922 8Z"
              fill="#36A41D"
            />
          </svg>
          <div>
            <h1 className="overview-dieti-head mb-1">Languages Known</h1>
            <h1 className="d-flex mb-0">
              {" "}
              {languages?.map((tag, i) => (
                <div className="d-flex" key={"languages" + i}>
                  <span className="exp-value-text">
                    {i > 0 && ", "}
                    {tag.trim()}
                  </span>
                </div>
              ))}
            </h1>
          </div>
        </div>
      )}

      {/* about */}
      <div className="gap-3 d-flex align-items-start">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g clip-path="url(#clip0_1374_69462)">
              <path
                d="M7.99967 15.2328C4.01164 15.2328 0.76709 11.9882 0.76709 8.00002C0.76709 4.01188 4.01164 0.767334 7.99967 0.767334C11.9878 0.767334 15.2325 4.01188 15.2325 8.00002C15.2325 11.9882 11.9878 15.2328 7.99967 15.2328ZM7.99967 1.91841C4.64634 1.91841 1.91817 4.64659 1.91817 8.00002C1.91817 11.3536 4.64634 14.0818 7.99967 14.0818C11.3531 14.0818 14.0814 11.3536 14.0814 8.00002C14.0814 4.64659 11.3531 1.91841 7.99967 1.91841Z"
                fill="#36A41D"
              />
              <path
                d="M9.6945 11.0836L9.58055 11.5495C9.23868 11.6846 8.96541 11.7872 8.76201 11.8579C8.55839 11.9287 8.32184 11.9641 8.05214 11.9641C7.63821 11.9641 7.31614 11.8626 7.0865 11.6612C6.85686 11.459 6.74198 11.2026 6.74198 10.8917C6.74198 10.7713 6.75027 10.6476 6.76765 10.5214C6.78515 10.3952 6.81289 10.2529 6.85088 10.0939L7.27827 8.58138C7.31626 8.43646 7.3486 8.29925 7.3745 8.1694C7.40075 8.04048 7.41341 7.92181 7.41341 7.81499C7.41341 7.62184 7.37335 7.4867 7.29381 7.41073C7.21416 7.33499 7.06221 7.29654 6.8366 7.29654C6.7261 7.29654 6.6126 7.31427 6.4968 7.34846C6.38055 7.38276 6.28121 7.41602 6.19775 7.44687L6.31206 6.98046C6.592 6.8665 6.85962 6.76889 7.11562 6.68785C7.37162 6.60658 7.61347 6.56595 7.8423 6.56595C8.25335 6.56595 8.5707 6.66529 8.79344 6.86408C9.01629 7.06299 9.12771 7.32071 9.12771 7.63853C9.12771 7.70425 9.12034 7.82005 9.10469 7.98558C9.08926 8.15156 9.06072 8.30351 9.01893 8.44175L8.59338 9.9484C8.5585 10.0694 8.52719 10.2076 8.49991 10.3634C8.47183 10.5181 8.45836 10.6362 8.45836 10.7155C8.45836 10.9155 8.5029 11.0521 8.59234 11.1248C8.68247 11.1974 8.83752 11.2335 9.05784 11.2335C9.16144 11.2335 9.27919 11.215 9.40996 11.179C9.5406 11.143 9.63568 11.1112 9.6945 11.0836ZM9.80259 4.75841C9.80259 5.02086 9.70371 5.24509 9.50492 5.42938C9.30659 5.61435 9.06751 5.7069 8.78814 5.7069C8.50762 5.7069 8.26808 5.61435 8.06757 5.42938C7.86728 5.24497 7.76702 5.02086 7.76702 4.75841C7.76702 4.49643 7.86728 4.27185 8.06757 4.08515C8.26774 3.89879 8.50762 3.80566 8.78814 3.80566C9.06751 3.80566 9.30659 3.89902 9.50492 4.08515C9.70371 4.27185 9.80259 4.49643 9.80259 4.75841Z"
                fill="#36A41D"
              />
            </g>
            <defs>
              <clipPath id="clip0_1374_69462">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div>
          <h1 className="overview-dieti-head mt-1">About Dietitian</h1>
          <div className="clinic-address mt-1">{getDisplayedContent()}</div>
        </div>
      </div>

      {/* certification */}
      {certification === "" ? null : (
        <div className="gap-3 d-flex align-items-start">
          {certification === "" ? (
            ""
          ) : (
            <>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M8.5 13H4.5C3.673 13 3 12.327 3 11.5V3C3 2.173 3.673 1.5 4.5 1.5H10.5C11.327 1.5 12 2.173 12 3V5.53C12 5.806 12.2235 6.03 12.5 6.03C12.7765 6.03 13 5.806 13 5.53V3C13 1.6215 11.8785 0.5 10.5 0.5H4.5C3.1215 0.5 2 1.6215 2 3V11.5C2 12.8785 3.1215 14 4.5 14H8.5C8.7765 14 9 13.776 9 13.5C9 13.224 8.7765 13 8.5 13Z"
                    fill="#36A41D"
                  />
                  <path
                    d="M5 4.5H8.5C8.7765 4.5 9 4.276 9 4C9 3.724 8.7765 3.5 8.5 3.5H5C4.7235 3.5 4.5 3.724 4.5 4C4.5 4.276 4.7235 4.5 5 4.5Z"
                    fill="#36A41D"
                  />
                  <path
                    d="M9.68 6.5C9.68 6.224 9.4565 6 9.18 6H5C4.7235 6 4.5 6.224 4.5 6.5C4.5 6.776 4.7235 7 5 7H9.18C9.4565 7 9.68 6.776 9.68 6.5Z"
                    fill="#36A41D"
                  />
                  <path
                    d="M8.26 8.5C8.26 8.224 8.0365 8 7.76 8H5C4.7235 8 4.5 8.224 4.5 8.5C4.5 8.776 4.7235 9 5 9H7.76C8.0365 9 8.26 8.776 8.26 8.5Z"
                    fill="#36A41D"
                  />
                  <path
                    d="M5 10C4.7235 10 4.5 10.224 4.5 10.5C4.5 10.776 4.7235 11 5 11H7.53C7.8065 11 8.03 10.776 8.03 10.5C8.03 10.224 7.8065 10 7.53 10H5Z"
                    fill="#36A41D"
                  />
                  <path
                    d="M15 10C15 8.3455 13.6545 7 12 7C10.3455 7 9 8.3455 9 10C9 10.8855 9.388 11.68 10 12.23V14.7485C10 15.0255 10.1525 15.2795 10.397 15.4105C10.643 15.5425 10.937 15.528 11.169 15.3735L12 14.8155L12.833 15.3745C12.958 15.458 13.102 15.4995 13.247 15.4995C13.3685 15.4995 13.491 15.47 13.603 15.41C13.8475 15.279 14 15.025 14 14.748V12.2295C14.612 11.68 15 10.886 15 10ZM13 14.282L12.279 13.798C12.11 13.6845 11.8905 13.6845 11.7215 13.798L11.0005 14.282V12.825C11.314 12.9365 11.6495 13 12.0005 13C12.3515 13 12.687 12.936 13.0005 12.825L13 14.282ZM12 12C10.897 12 10 11.103 10 10C10 8.897 10.897 8 12 8C13.103 8 14 8.897 14 10C14 11.103 13.103 12 12 12Z"
                    fill="#36A41D"
                  />
                </svg>
              </div>
              <div>
                <h1 className="overview-dieti-head mt-1">Certification</h1>
                <h1 className="clinic-address mt-1">{certification}</h1>
              </div>
            </>
          )}
        </div>
      )}


      {Array.isArray(speciality) && speciality.length > 0 && (
        <div className="gap-3 d-flex align-items-start">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M12.0345 10.928C13.2385 9.83 14 8.254 14 6.5C14 3.1915 11.3085 0.5 8 0.5C4.6915 0.5 2 3.1915 2 6.5C2 8.254 2.7615 9.83 3.9655 10.928L2.8225 13.3765C2.7045 13.6265 2.7335 13.919 2.898 14.1405C3.0635 14.363 3.3375 14.475 3.615 14.434L4.606 14.284L5.124 15.133C5.258 15.361 5.5015 15.499 5.7655 15.499C5.7765 15.499 5.788 15.499 5.7995 15.498C6.0795 15.4855 6.3275 15.321 6.448 15.066L7.6525 12.4825C7.768 12.489 7.8825 12.5 7.9995 12.5C8.1165 12.5 8.231 12.489 8.3465 12.4825L9.5525 15.069C9.672 15.321 9.92 15.4855 10.2 15.498C10.211 15.498 10.2225 15.499 10.234 15.499C10.498 15.499 10.7415 15.361 10.8715 15.14L11.393 14.284L12.385 14.434C12.6595 14.477 12.9355 14.363 13.101 14.1405C13.2655 13.919 13.2945 13.6265 13.1775 13.3785L12.0335 10.928H12.0345ZM5.739 14.221L5.287 13.4795C5.182 13.3075 4.9845 13.216 4.785 13.2455L3.9265 13.3755L4.7775 11.5525C5.3355 11.91 5.956 12.176 6.619 12.333L5.739 14.221ZM3 6.5C3 3.743 5.243 1.5 8 1.5C10.757 1.5 13 3.743 13 6.5C13 9.257 10.757 11.5 8 11.5C5.243 11.5 3 9.257 3 6.5ZM11.215 13.2455C11.017 13.216 10.818 13.3075 10.713 13.4795L10.261 14.221L9.3805 12.333C10.0435 12.176 10.664 11.91 11.222 11.5525L12.073 13.3755L11.215 13.2455Z"
                fill="#36A41D"
              />
              <path
                d="M10.3332 4.93041L9.38466 4.65041L8.82516 3.83441C8.63866 3.56241 8.33016 3.39941 8.00016 3.39941C7.67016 3.39941 7.36166 3.56191 7.17566 3.83391L6.61616 4.64991L5.66766 4.92991C5.35116 5.02341 5.10116 5.26641 4.99916 5.57991C4.89716 5.89391 4.95666 6.23741 5.15766 6.49841L5.76116 7.28241L5.73366 8.27141C5.72466 8.60141 5.87866 8.91391 6.14566 9.10791C6.41216 9.30191 6.75716 9.35091 7.06816 9.24141L8.00016 8.90991L8.93216 9.24141C9.04116 9.28041 9.15466 9.29941 9.26716 9.29891C9.47566 9.29891 9.68166 9.23391 9.85466 9.10791C10.1217 8.91391 10.2757 8.60141 10.2667 8.27141L10.2392 7.28241L10.8427 6.49841C11.0437 6.23691 11.1032 5.89341 11.0012 5.57941C10.8992 5.26591 10.6492 5.02241 10.3327 4.92941L10.3332 4.93041ZM9.33866 6.81441C9.26866 6.90591 9.23216 7.01841 9.23516 7.13341L9.26716 8.29991L8.16766 7.90891C8.05916 7.87041 7.94116 7.87041 7.83266 7.90891L6.73266 8.30041L6.76466 7.13391C6.76766 7.01891 6.73116 6.90641 6.66116 6.81491L5.94966 5.88991L7.06916 5.55991C7.17966 5.52741 7.27516 5.45791 7.34016 5.36291L8.00016 4.40041L8.66016 5.36291C8.72516 5.45741 8.82066 5.52741 8.93116 5.55991L10.0507 5.88991L9.33916 6.81491L9.33866 6.81441Z"
                fill="#36A41D"
              />
            </svg>
          </div>
          <div>
            <h1 className="overview-dieti-head mt-1">Speciality</h1>
            <h1 className="d-flex">
              {speciality?.map((tag, i) => (
                <div className="d-flex" key={"speciality" + i}>
                  <span className="clinic-address mt-1">
                    {i > 0 && "/"}
                    {tag.trim()}
                  </span>
                </div>
              ))}
            </h1>
          </div>
        </div>
      )}

      {Array.isArray(services) && services.length > 0 && (
        <div className="gap-3 d-flex align-items-start">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M11.5 1.5H11C11 0.9485 10.5515 0.5 10 0.5H6C5.4485 0.5 5 0.9485 5 1.5H4.5C3.1215 1.5 2 2.6215 2 4V13C2 14.3785 3.1215 15.5 4.5 15.5H11.5C12.8785 15.5 14 14.3785 14 13V4C14 2.6215 12.8785 1.5 11.5 1.5ZM6 1.5H9.9995V2.5H6V1.5ZM13 13C13 13.827 12.327 14.5 11.5 14.5H4.5C3.673 14.5 3 13.827 3 13V4C3 3.173 3.673 2.5 4.5 2.5H5C5 3.0515 5.4485 3.5 6 3.5H10C10.5515 3.5 11 3.0515 11 2.5H11.5C12.327 2.5 13 3.173 13 4V13Z"
                fill="#36A41D"
              />
              <path
                d="M6.64661 4.89639L5.66711 5.87589L5.35411 5.56289C5.15861 5.36739 4.84211 5.36739 4.64711 5.56289C4.45161 5.75839 4.45161 6.07489 4.64711 6.26989L5.31361 6.93639C5.41111 7.03389 5.53911 7.08289 5.66711 7.08289C5.79511 7.08289 5.92311 7.03389 6.02061 6.93639L7.35411 5.60289C7.54961 5.40739 7.54961 5.09139 7.35411 4.89589C7.15861 4.70039 6.84261 4.70039 6.64711 4.89589L6.64661 4.89639Z"
                fill="#36A41D"
              />
              <path
                d="M11 5.5H8.5C8.224 5.5 8 5.724 8 6C8 6.276 8.224 6.5 8.5 6.5H11C11.276 6.5 11.5 6.276 11.5 6C11.5 5.724 11.276 5.5 11 5.5Z"
                fill="#36A41D"
              />
              <path
                d="M6.64661 7.89639L5.66711 8.87589L5.35411 8.56289C5.15861 8.36739 4.84211 8.36739 4.64711 8.56289C4.45161 8.75839 4.45161 9.07489 4.64711 9.26989L5.31361 9.93639C5.41111 10.0339 5.53911 10.0829 5.66711 10.0829C5.79511 10.0829 5.92311 10.0339 6.02061 9.93639L7.35411 8.60289C7.54961 8.40739 7.54961 8.09139 7.35411 7.89589C7.15861 7.70039 6.84261 7.70039 6.64711 7.89589L6.64661 7.89639Z"
                fill="#36A41D"
              />
              <path
                d="M11 8.5H8.5C8.224 8.5 8 8.724 8 9C8 9.276 8.224 9.5 8.5 9.5H11C11.276 9.5 11.5 9.276 11.5 9C11.5 8.724 11.276 8.5 11 8.5Z"
                fill="#36A41D"
              />
              <path
                d="M6.64661 10.8964L5.66711 11.8759L5.35411 11.5629C5.15861 11.3674 4.84211 11.3674 4.64711 11.5629C4.45161 11.7584 4.45161 12.0749 4.64711 12.2699L5.31361 12.9364C5.41111 13.0339 5.53911 13.0829 5.66711 13.0829C5.79511 13.0829 5.92311 13.0339 6.02061 12.9364L7.35411 11.6029C7.54961 11.4074 7.54961 11.0914 7.35411 10.8959C7.15861 10.7004 6.84261 10.7004 6.64711 10.8959L6.64661 10.8964Z"
                fill="#36A41D"
              />
              <path
                d="M11 11.5H8.5C8.224 11.5 8 11.724 8 12C8 12.276 8.224 12.5 8.5 12.5H11C11.276 12.5 11.5 12.276 11.5 12C11.5 11.724 11.276 11.5 11 11.5Z"
                fill="#36A41D"
              />
            </svg>
          </div>
          <div className="d-flex flex-column align-items-start">
            <h1 className="overview-dieti-head mt-1">Services</h1>
            <h1 className="d-flex flex-column gap-1">
              {displayedServices.map((tag, i) => (
                <div className="d-flex align-items-start" key={"services" + i}>
                  <i className="pi pi-check mr-1"></i>
                  <span className="clinic-address mt-1">{tag.trim()}</span>
                </div>
              ))}
            </h1>
            {!showFullServices && services.length > maxServicesToShow && (
              <button className="read-btn" onClick={toggleServices}>Read More</button>
            )}
            {showFullServices && (
              <button className="read-btn" onClick={toggleServices}>Read Less</button>
            )}
          </div>

        </div>
      )}

      {/* Awards & rewards */}
      {Array.isArray(awards) && awards.length > 0 && (
        <div className="gap-3 d-flex align-items-start">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M13.5 3.5H12.5635C12.6035 3.2335 12.6575 2.9635 12.7135 2.686C12.7475 2.5175 12.782 2.349 12.813 2.1795C12.8665 1.8875 12.788 1.5885 12.597 1.3595C12.4065 1.131 12.127 1 11.829 1H4.17049C3.85149 1 3.55899 1.1465 3.36899 1.4015C3.17799 1.6575 3.12099 1.9795 3.21249 2.2865C3.33099 2.683 3.40849 3.0895 3.45399 3.5H2.50049C1.67349 3.5 1.00049 4.173 1.00049 5V6.5C1.00049 7.8785 2.12199 9 3.50049 9H4.26349C4.79299 9.7895 5.56749 10.41 6.50049 10.739V11C6.50049 11.2755 6.27599 11.5 6.00049 11.5H5.50049C4.39749 11.5 3.50049 12.397 3.50049 13.5V14C3.50049 14.5515 3.94899 15 4.50049 15H11.5005C12.052 15 12.5005 14.5515 12.5005 14V13.5C12.5005 12.397 11.6035 11.5 10.5005 11.5H10.0005C9.72499 11.5 9.50049 11.2755 9.50049 11V10.739C10.433 10.4105 11.208 9.79 11.7375 9H12.5005C13.879 9 15.0005 7.8785 15.0005 6.5V5C15.0005 4.173 14.327 3.5 13.5 3.5ZM3.49999 8C2.67299 8 1.99999 7.327 1.99999 6.5V5C1.99999 4.7245 2.22449 4.5 2.49999 4.5H3.49999V6.5C3.49999 7.023 3.59299 7.528 3.76049 8H3.49999ZM8.87499 9.885C8.65399 9.942 8.49999 10.141 8.49999 10.369V11C8.49999 11.827 9.17299 12.5 9.99999 12.5H10.5C11.0515 12.5 11.5 12.9485 11.5 13.5V14H4.49999V13.5C4.49999 12.9485 4.94849 12.5 5.49999 12.5H5.99999C6.82699 12.5 7.49999 11.827 7.49999 11V10.369C7.49999 10.141 7.34549 9.942 7.12499 9.885C5.57949 9.4865 4.49999 8.0945 4.49999 6.5V4.2595C4.49999 3.494 4.38899 2.734 4.17049 2H11.829V1.9985C11.7995 2.162 11.7665 2.325 11.7335 2.4875C11.6185 3.0555 11.4995 3.643 11.4995 4.259V6.4995C11.4995 8.094 10.42 9.486 8.87449 9.8845L8.87499 9.885ZM14 6.5C14 7.327 13.327 8 12.5 8H12.2395C12.407 7.528 12.5 7.023 12.5 6.5V4.5H13.5C13.7755 4.5 14 4.7245 14 5V6.5Z"
                fill="#36A41D"
              />
            </svg>
          </div>
          <div className="d-flex flex-column align-items-start">
            <h1 className="overview-dieti-head mt-1">Awards & Recognitions</h1>
            <h1 className="d-flex flex-column mt-1 gap-1">
              {displayedAwards.map((award, i) => (
                <div className="d-flex align-items-start" key={"award" + i}>
                  <i className="pi pi-check mr-1"></i>
                  <span className="clinic-address mt-1">{award.trim()}</span>
                </div>
              ))}
            </h1>
            {!showFullAwards && awards.length > maxAwardsToShow && (
              <button className="read-btn" onClick={toggleAwards}>Read More</button>
            )}
            {showFullAwards && (
              <button className="read-btn" onClick={toggleAwards}>Read Less</button>
            )}
          </div>
        </div>
      )}

      {/* website */}
      {dietitianwebsite === "" ? null : (
        <div className="gap-3 d-flex align-items-start">
          {dietitianwebsite === "" ? (
            ""
          ) : (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M8 1C6.61553 1 5.26216 1.41054 4.11101 2.17971C2.95987 2.94888 2.06266 4.04213 1.53285 5.32122C1.00303 6.6003 0.86441 8.00776 1.13451 9.36563C1.4046 10.7235 2.07129 11.9708 3.05026 12.9497C4.02922 13.9287 5.2765 14.5954 6.63437 14.8655C7.99224 15.1356 9.3997 14.997 10.6788 14.4672C11.9579 13.9373 13.0511 13.0401 13.8203 11.889C14.5895 10.7378 15 9.38447 15 8C14.998 6.14409 14.2599 4.36477 12.9476 3.05245C11.6352 1.74012 9.85591 1.00199 8 1ZM8 14C7.3735 14 6.5605 12.7135 6.1945 10.5H9.806C9.4395 12.7135 8.6265 14 8 14ZM6.0695 9.5C6.026 9.0295 6 8.5315 6 8C6 7.4685 6.026 6.9705 6.0695 6.5H9.9305C9.974 6.9705 10 7.4685 10 8C10 8.5315 9.974 9.0295 9.9305 9.5H6.0695ZM2 8C2.00188 7.49364 2.06825 6.98959 2.1975 6.5H5.0705C5.0255 6.997 5 7.5 5 8C5 8.5 5.0255 9.003 5.0705 9.5H2.1975C2.06825 9.01041 2.00188 8.50636 2 8ZM8 2C8.6265 2 9.4395 3.2865 9.806 5.5H6.1945C6.5605 3.2865 7.3735 2 8 2ZM10.9295 6.5H13.8025C14.0658 7.48266 14.0658 8.51734 13.8025 9.5H10.9295C10.9745 9.003 11 8.5 11 8C11 7.5 10.9745 6.997 10.9295 6.5ZM13.45 5.5H10.809C10.6637 4.36813 10.3121 3.2725 9.7715 2.2675C10.5768 2.51871 11.3204 2.93626 11.954 3.4931C12.5877 4.04994 13.0974 4.73367 13.45 5.5ZM6.2285 2.2675C5.68794 3.2725 5.33629 4.36813 5.191 5.5H2.5525C2.90487 4.73392 3.41414 4.05036 4.04736 3.49353C4.68059 2.9367 5.42366 2.51902 6.2285 2.2675ZM2.5525 10.5H5.191C5.33629 11.6319 5.68794 12.7275 6.2285 13.7325C5.42366 13.481 4.68059 13.0633 4.04736 12.5065C3.41414 11.9496 2.90487 11.2661 2.5525 10.5ZM9.7715 13.7325C10.3121 12.7275 10.6637 11.6319 10.809 10.5H13.45C13.0974 11.2663 12.5877 11.9501 11.954 12.5069C11.3204 13.0637 10.5768 13.4813 9.7715 13.7325Z"
                  fill="#36A41D"
                />
              </svg>
              <div>
                <h1 className="exp-value-text-ud">
                  <a href={dietitianwebsite} className="org-data hvr" target="_blank" rel="noopener noreferrer">
                    {dietitianwebsite}
                  </a>
                </h1>
              </div>
            </>
          )}
        </div>
      )}

      {/* instagram */}
      {dietitianinstaurl === "" ? null : (
        <div className="gap-3 d-flex align-items-start">
          {dietitianinstaurl === "" ? (
            ""
          ) : (
            <>
              <i
                className="pi pi-instagram"
                style={{ color: "#36a41d", fontSize: "16px" }}
              ></i>
              <div>
                <h1 className="exp-value-text-ud">
                  <a href={dietitianinstaurl} className="org-data hvr" target="_blank" rel="noopener noreferrer">
                    {dietitianinstaurl}
                  </a>
                </h1>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default DietitianAbout;
