import React, { useState } from 'react';
import Navbar from '../../components/UI/Navbar/navBar.component';
import Footer from '../../components/UI/Footer/footer.component';
import business from "../../assets/images/businesscomingsoon.png"
import ImageLoader from '../../components/UI/loader/imageloader.component';

const ComingsoonBusiness = () => {

    const [city, setCity] = useState("");
    const [list, setList] = useState<string[]>([]);
    const [search, setSearch] = useState<string[]>([])

    return (
        <>
            <div className="container-fluid p-0 comingsoon-page">
                <div className="row">
                    <Navbar />
                </div>
                <div className='container'>
                    <div className='d-flex pt-6 pb-6 align-items-center justify-content-center'>
                        <div className='col-lg-7 col-md-7 d-flex flex-column justify-content-center cmgsoon-text-div'>
                            <span className='mob-app'>Yeldam Business</span>
                            <span className='cmg-soon'>Coming Soon</span>
                            <p className='cmg-text'>Take control of your business easily. Our business management application is launching soon.</p>
                            <a href='/' className='cmg-soon-btn-div'><button className='go_to_home_btn'>Go to Home</button></a>
                            
                        </div>
                        <div className='col-lg-5 col-md-5 d-flex justify-content-end lader-img-div'>
                        <ImageLoader src={business} alt={"image"} width={"440px"} height={"380px"} />

                        </div>

                    </div>

                </div>

                <div className="row d-flex align-items-end">
                    <Footer localities={list} city={city} search={search} type={"restaurant"} />
                </div>
            </div>
        </>
    )
}

export default ComingsoonBusiness;
