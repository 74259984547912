import React, { useState } from 'react'
import Navbar from "../../components/UI/Navbar/navBar.component";
import Footer from "../../components/UI/Footer/footer.component";
import Logo from '../../assets/images/Layer1.svg';
import { Button } from 'primereact/button';
import './comingSoon.pages.css';
import laderimage from "../../assets/images/coming_soon.png"

const Comingsoon = () => {



    const [city, setCity] = useState("");
    const [list, setList] = useState<string[]>([]);
    const [search, setSearch] = useState<string[]>([])

    return (
        <>
            <div className="container-fluid p-0 comingsoon-page">
                <div className="row">
                    <Navbar />
                </div>
                <div className='container'>
                    <div className='d-flex pt-6 pb-6 align-items-center justify-content-center'>
                        <div className='col-lg-7 col-md-7 d-flex flex-column justify-content-center cmgsoon-text-div'>
                            <span className='mob-app'>Mobile App</span>
                            <span className='cmg-soon'>Coming Soon</span>
                            <p className='cmg-text'>Our Yeldam Mobile app is on its way, designed to help you easily find healthy meal options no matter where you are.</p>
                            <a href='/' className='cmg-soon-btn-div'><button className='go_to_home_btn'>Go to Home</button></a>
                            
                        </div>
                        <div className='col-lg-5 col-md-5 d-flex justify-content-end lader-img-div'>
                            <img className='lader_img' src={laderimage} alt='image' />
                        </div>

                    </div>

                </div>

                <div className="row d-flex align-items-end">
                    <Footer localities={list} city={city} search={search} type={"restaurant"} />
                </div>
            </div>
        </>
    )
}

export default Comingsoon