import React, { useEffect, useState } from 'react';

interface RestaurantTimingsProps {
  timings: {
    [key: string]: string[];
  }[];
}

const RestaurantTimings: React.FC<RestaurantTimingsProps> = ({ timings }) => {
  const [openStatus, setOpenStatus] = useState<string>('');
  const [closeStatus, setCloseStatus] = useState<string>('');
  const [openStatusColor, setOpenStatusColor] = useState<string>('#36a41d'); // Default color for opened status
  const [closeStatusColor, setCloseStatusColor] = useState<string>('#818181'); // Default color for closed status

  const getLocalTime = () => {
    const today = new Date(new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" }));
    return today;
  };


  const convert12HourTo24Hour= (time12HourFormat:any) =>{
     // Trim the string to remove leading and trailing whitespace
     const trimmedTime = time12HourFormat.trim();
    
     // Split the trimmed time into hours, minutes, and meridiem
     const time = time12HourFormat.slice(0, -2);
     const meridiem = time12HourFormat.slice(-2).toLowerCase();
     console.log("meridiem",meridiem)
     const [hours, minutes] = time.split(":").map(Number);
 
     let hours24Format;
 
     if (meridiem && meridiem.toLowerCase() === "am") {
         hours24Format = hours === 12 ? 0 : hours;
     } else if (meridiem && meridiem.toLowerCase() === "pm") {
         hours24Format = hours === 12 ? 12 : hours + 12;
     } else {
         throw new Error("Invalid time format");
     }
 
     return `${hours24Format < 10 ? "0" + hours24Format : hours24Format}:${minutes < 10 ? "0" + minutes : minutes}`;
 
}
  useEffect(() => {
    const currentTime = getLocalTime();
    let currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
    console.log("currentHour",currentHour);

    // // Convert 24-hour format to 12-hour format
    // let meridiem = 'AM';
    // if (currentHour >= 12) {
    //     meridiem = 'PM';
    // }
    // if (currentHour > 12) {
    //     currentHour -= 12;
    // }
    console.log("currentTime",currentTime);
    console.log("currentMinute",currentMinute);

    const currentDay = currentTime.getDay();

    const timingsForCurrentDay = timings[0][Object.keys(timings[0])[currentDay-1]];
    if (timingsForCurrentDay && timingsForCurrentDay.length > 0) {
      let isOpen = false;
      console.log("timingsForCurrentDay",timingsForCurrentDay);

      timingsForCurrentDay.forEach((timeSlot: string) => {
        const [openTime, closeTime] = timeSlot.split(' to ');
        console.log("converted",convert12HourTo24Hour(openTime));
        const [openHour, openMinute] = convert12HourTo24Hour(openTime).match(/\d+/g)!.map(num => parseInt(num));
        let [closeHour, closeMinute] = convert12HourTo24Hour(closeTime).match(/\d+/g)!.map(num => parseInt(num));
        console.log("openTime",openTime);
        console.log("openHour",openHour);
        console.log("openMinute",openMinute);
        // If closing hour is before opening hour, add 24 hours to closing hour
        // if (closeHour < openHour) {
        //   closeHour += 24;
        // }

        const isOpenNow = (currentHour > openHour || (currentHour === openHour && currentMinute >= openMinute)) &&
                          (currentHour < closeHour || (currentHour === closeHour && currentMinute < closeMinute));

        if (isOpenNow) {
          isOpen = true;
        }
      });
      
      if (isOpen) {
        setOpenStatus('Open Now');
        setCloseStatus(`Closes at ${timingsForCurrentDay[timingsForCurrentDay.length - 1].split(' to ')[1]}`);
        setOpenStatusColor('#36a41d');
        setCloseStatusColor('#818181');
      } else {
        setOpenStatus('Closed Now');
        setCloseStatus(`Opens at ${timingsForCurrentDay[timingsForCurrentDay.length - 1].split(' to ')[0]}`);
        setOpenStatusColor('#D83B01');
        setCloseStatusColor('#818181');
      }
    } else {
      setOpenStatus('Closed Now');
      setCloseStatus('Timings unavailable');
      setOpenStatusColor('#D83B01');
      setCloseStatusColor('#818181');
    }

  }, []);

  return (
    <>
      <div className="open-timing d-flex justify-content-center distance-text gap-1" style={{ color: openStatusColor }}>
        <i className="bi bi-door-open mr-0" style={{ color: openStatusColor, fontSize: '12px' }} />
        {openStatus}
      </div>
      <div className="close-timing d-flex justify-content-center distance-text gap-1" style={{ color: closeStatusColor }}>
        <i className="bi bi-door-closed mr-0" style={{ color: closeStatusColor, fontSize: '12px' }} />
        {closeStatus}
      </div>
    </>
  );
};

export default RestaurantTimings;
